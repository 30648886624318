import { Node } from '@tensorleap/engine-contract';
import layersJson from '@tensorleap/engine-contract/dist/ui_components.json';
import { OneOfGraphErrorTypes } from '../wizard/errors';

export type PipeCtx = {
  readonly node: Node;
  inputShapes: Readonly<NodeInputsShapes>;
  outputShape: Shape | UnknownShape;
};

export type Shape = number[];

export type UnknownShape = null;

export type NodeInputsShapes = Shape[];

type LayerProperty = {
  name: string;
  isdefault: boolean;
  default_val: unknown;
} & (
  | {
      default_val: string;
      type: 'select';
      values: string[];
    }
  | {
      default_val: number;
      type: 'float' | 'int';
    }
  | {
      type: 'tuple';
      default_val: number[];
    }
  | {
      type: 'bool';
      default_val: boolean;
    }
  | {
      type: 'NoneType';
      default_val: null;
    }
  | {
      type: 'select';
    }
  | {
      default_val: string;
      type: 'str';
    }
);

export type Layer = {
  type: string;
  name: string;
  shape_rank?: number;
  enable_bigger_input_rank: boolean;
  hash?: { fields: string[]; calcInputShape?: boolean };
  properties: LayerProperty[];
  propertiesObj: Record<string, LayerProperty>;
  shape_calc: string[];
  receptive_fields_func: string;
};

export const layersMetadata = new Map<string, Layer>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
layersJson.forEach((layer: any) => {
  layer.propertiesObj = Object.fromEntries(
    layer.properties.map((layer: Layer) => [layer.name, layer]),
  );
  layersMetadata.set(layer.name, layer);
});

export interface ReceptiveFieldsElement {
  filterQuantity: number[];
  jumpDistance: number[];
  size: number[];
  featureStart: number[];
}

export interface ReceptiveFieldsInput {
  receptiveFieldsElements: Array<ReceptiveFieldsElement>;
  inputSize: number[];
}

export interface NodeCalculatedState {
  shape: Shape | UnknownShape;
  error?: OneOfGraphErrorTypes;
}

export type NodePresentationState = NodeCalculatedState & {
  receptiveFields: Map<string, ReceptiveFieldsInput> | null;
};
