import { SlimVersion } from '@tensorleap/api-client';
import { useState, useEffect } from 'react';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useVersionControl } from '../core/VersionControlContext';
import { ImportModel } from './ImportModel';
import { SaveCommit } from './SaveCommit';
import { ShelveAction } from './VersionControlPane';

interface VersionControlActionsProps {
  shelveAction?: ShelveAction;
  onActionCancelled: () => void;
}

export function VersionControlActions({
  shelveAction,
  onActionCancelled,
}: VersionControlActionsProps): JSX.Element | null {
  const [currentSlimVersion, setCurrentSlimVersion] = useState<SlimVersion>();
  const { currentVersion } = useCurrentProject();
  const { versions } = useVersionControl();

  useEffect(() => {
    setCurrentSlimVersion(
      versions.find(({ cid }) => cid === currentVersion?.cid),
    );
  }, [currentVersion, versions]);

  if (!(shelveAction && currentSlimVersion && currentVersion)) return null;

  return shelveAction === 'SaveCommit' ? (
    <SaveCommit onClose={onActionCancelled} currentVersion={currentVersion} />
  ) : (
    <ImportModel
      onSaveCancelled={onActionCancelled}
      currentProjectId={currentSlimVersion.projectId}
    />
  );
}
