import React, {
  FC,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { clamp } from 'ramda';
import clsx from 'clsx';

import { Box } from '../ui/mui';
import { useGesture } from 'react-use-gesture';
import { Node } from '@tensorleap/api-client';
import { Position } from '../core/position';
import { useMemoWithLatestRef } from '../core/useMemoWithLatestRef';
import { useIsMouseDown } from '../core/useIsMouseDown';
import { NetworkNodeUiRefs } from './hooks';
import { useNetworkMapContext } from '../core/NetworkMapContext';

export interface PanZoomParams {
  zoom: number;
  position: Position;
  domTarget: RefObject<HTMLElement>;
}
export const PanZoom: FC<
  PanZoomParams & {
    onContextMenu?: React.MouseEventHandler<HTMLElement>;
    onClick?: React.MouseEventHandler<HTMLElement>;
  }
> = ({ children, zoom, position, domTarget, onContextMenu, onClick }) => {
  const isMouseDown = useIsMouseDown(domTarget);
  const style = useMemo<React.CSSProperties>(
    () => ({
      transform: `translate(${position
        .map((n) => `${n}px`)
        .join(',')}) scale(${zoom})`,
    }),
    [zoom, position],
  );

  const handleClick = useCallback<React.MouseEventHandler<HTMLElement>>(
    (ev) => {
      if (!onClick || ev.target !== domTarget.current) {
        return;
      }
      onClick(ev);
    },
    [onClick, domTarget],
  );

  return (
    <Box
      ref={domTarget}
      className="w-full h-full touch-none overflow-hidden"
      onContextMenu={onContextMenu}
      onClick={handleClick}
    >
      <div
        className={clsx(
          isMouseDown && 'will-change-transform',
          'origin-top-left [contain:layout_style_size]',
        )}
        style={style}
      >
        {children}
      </div>
    </Box>
  );
};

export interface ZoomAndPosition {
  zoom: number;
  position: Position;
}

export interface PanZoomControl {
  panZoomParams: PanZoomParams;
  onFitNodeZoomToScreen: (node: Node, ref: NetworkNodeUiRefs) => void;
  onFitMapToScreen: (
    nodes: Node[],
    refs: Map<string, NetworkNodeUiRefs>,
  ) => void;
  panZoomParamsRef: RefObject<PanZoomParams>;
  onFitNodeToScreen: (nodeId: string) => void;
}
export function usePanZoom(options?: {
  allowDraggingWithChildren?: boolean;
}): PanZoomControl {
  const { nodes, nodeRefs } = useNetworkMapContext();

  const { allowDraggingWithChildren } = options || {};
  const [zoomPositionState, setZoomPosition] = useState<ZoomAndPosition>({
    zoom: 1,
    position: [0, 0],
  });

  const domTarget = useRef<HTMLElement>(null);

  useGesture(
    {
      onDrag: ({ delta: [dX, dY], event }) => {
        if (
          !allowDraggingWithChildren &&
          event.target !== event.currentTarget
        ) {
          return;
        }

        setZoomPosition(({ zoom, position: [posX, posY] }) => ({
          zoom,
          position: [posX + dX, posY + dY],
        }));
      },
      onWheel: ({ delta: [, wheelDelta], event }) => {
        event.preventDefault();
        setZoomPosition((current) => {
          if (!domTarget.current) {
            return current;
          }

          const delta = limitDelta(current.zoom, wheelDelta / 200);
          return zoomToPoint(
            current,
            calcMousePosition(event, domTarget.current),
            delta,
          );
        });
      },
      onPinch: ({ delta: [pinchDelta], event }) => {
        event.preventDefault();
        setZoomPosition((current) => {
          if (!domTarget.current) {
            return current;
          }
          const delta = limitDelta(current.zoom, pinchDelta / 100);
          return zoomToPoint(
            current,
            calcMousePosition(event as WheelEvent, domTarget.current),
            delta,
          );
        });
      },
    },
    { domTarget, eventOptions: { passive: false } },
  );

  const [panZoomParams, panZoomParamsRef] = useMemoWithLatestRef(
    () => ({
      ...zoomPositionState,
      domTarget,
    }),
    [zoomPositionState],
  );

  const onFitNodeZoomToScreen = useCallback((node, ref) => {
    setZoomPosition((current) => {
      if (!domTarget.current || !node) {
        return current;
      }
      const { width, height } = domTarget.current.getBoundingClientRect();
      return _fitToNode(node, ref, width, height);
    });
  }, []);

  const onFitMapToScreen = useCallback((nodes, refs) => {
    setZoomPosition((current) => {
      if (!domTarget.current || nodes.length === 0) {
        return current;
      }
      const { width, height } = domTarget.current.getBoundingClientRect();
      return _fitToScreen(nodes, refs, width, height);
    });
  }, []);

  const onFitNodeToScreen = useCallback(
    (nodeId: string) => {
      if (!nodeId) return;

      const node = nodes.get(nodeId);
      const ref = nodeRefs.current.get(nodeId);

      if (!node || !ref) {
        console.warn(`[fitNodeToScreen] Node id "${nodeId}" not found`);
        return;
      }

      onFitNodeZoomToScreen(node, ref);
    },
    [nodeRefs, nodes, onFitNodeZoomToScreen],
  );

  return useMemo<PanZoomControl>(
    () => ({
      panZoomParams,
      panZoomParamsRef,
      onFitMapToScreen,
      onFitNodeZoomToScreen,
      onFitNodeToScreen,
    }),
    [
      panZoomParams,
      panZoomParamsRef,
      onFitMapToScreen,
      onFitNodeZoomToScreen,
      onFitNodeToScreen,
    ],
  );
}

function limitDelta(zoom: number, device: number): number {
  const maxDelta = zoom * 0.15;
  const limitedDelta = clamp(-maxDelta, maxDelta, device);
  return limitedDelta;
}

function calcMousePosition(
  ev: { clientX: number; clientY: number },
  container: HTMLElement,
): Position {
  const { left, top } = container.getBoundingClientRect();
  return [ev.clientX - left, ev.clientY - top];
}

const clampZoom = clamp(0.025, 2.5);
function zoomToPoint(
  current: ZoomAndPosition,
  mousePosition: Position,
  d: number,
): ZoomAndPosition {
  const newZoom = clampZoom(current.zoom + d);
  if (newZoom === current.zoom) {
    return current;
  }

  const zoomChange = newZoom / current.zoom;
  const contentMousePos = {
    x: mousePosition[0] - current.position[0],
    y: mousePosition[1] - current.position[1],
  };

  return {
    zoom: newZoom,
    position: [
      mousePosition[0] - contentMousePos.x * zoomChange,
      mousePosition[1] - contentMousePos.y * zoomChange,
    ],
  };
}

function _fitToScreen(
  nodes: Node[],
  refs: Map<string, NetworkNodeUiRefs>,
  containerWidth: number,
  containerHeight: number,
): ZoomAndPosition {
  const { height, width, left, right, top, bottom } = getNodesBoundingBox(
    nodes,
    refs,
  );

  const zoom = clampZoom(
    Math.min(
      (containerWidth / (width || 1)) * 0.9,
      (containerHeight / (height || 1)) * 0.9,
      1,
    ),
  );

  return {
    zoom,
    position: [
      (containerWidth - (left + right) * zoom) / 2,
      (containerHeight - (top + bottom) * zoom) / 2,
    ],
  };
}

const FIT_TO_NODE_ZOOM = 1.3;

function _fitToNode(
  node: Node,
  ref: NetworkNodeUiRefs,
  containerWidth: number,
  containerHeight: number,
): ZoomAndPosition {
  const { left, right, top, bottom } = getNodeBoundingBox(node, ref);

  return {
    zoom: FIT_TO_NODE_ZOOM,
    position: [
      (containerWidth - (left + right) * FIT_TO_NODE_ZOOM) / 2,
      (containerHeight - (top + bottom) * FIT_TO_NODE_ZOOM) / 2,
    ],
  };
}

const DEFAULT_NODE_SIZE = 300;

type BoundingBox = {
  left: number;
  right: number;
  top: number;
  bottom: number;
  width: number;
  height: number;
};

function getNodeBoundingBox(node: Node, ref: NetworkNodeUiRefs): BoundingBox {
  const left = node.position[0];
  const top = node.position[1];
  const right = left + (ref.nodeRef?.clientWidth ?? DEFAULT_NODE_SIZE);
  const bottom = top + (ref.nodeRef?.clientWidth ?? DEFAULT_NODE_SIZE);

  return {
    left,
    right,
    top,
    bottom,
    width: right - left,
    height: bottom - top,
  };
}

export function getNodesBoundingBox(
  nodes: Node[],
  refs: Map<string, NetworkNodeUiRefs>,
): BoundingBox {
  const left = Math.min(...nodes.map((node) => node.position[0]));
  const top = Math.min(...nodes.map((node) => node.position[1]));
  const right = Math.max(
    ...nodes.map(
      (node) =>
        node.position[0] + (refs.get(node.id)?.nodeRef?.clientWidth || 0),
    ),
  );
  const bottom = Math.max(
    ...nodes.map(
      (node) =>
        node.position[1] + (refs.get(node.id)?.nodeRef?.clientHeight || 0),
    ),
  );

  return {
    left,
    right,
    top,
    bottom,
    width: right - left,
    height: bottom - top,
  };
}
