import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgIssueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M9 10.543a.771.771 0 1 0 0 1.543.771.771 0 0 0 0-1.543Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.243 4.757a6 6 0 1 1-8.485 8.485 6 6 0 0 1 8.485-8.485ZM4.2 9a4.8 4.8 0 1 1 9.6 0 4.8 4.8 0 0 1-9.6 0Zm5.4 0a.6.6 0 1 1-1.2 0V5.914a.6.6 0 1 1 1.2 0V9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgIssueIcon;
