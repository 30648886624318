import { useMemo } from 'react';
import { scaleLinear } from 'd3-scale';

// The motivation for this scales is to do a custom implementation of the d3 scales
// in scaleLog adding offset to avoid negative values and in scaleExp use pwo grow with a fix base

type Domain = [number, number];
type Range = [number, number];

/**
 * 🔧 Generic type for getter/setter methods.
 */
type SetOrGetFunction<T, ScaleT = Scale> = {
  (): T;
  (value: T): ScaleT;
};

export type ScaleType = 'linear' | 'log' | 'exp';

/**
 * 🎯 Unified Scale interface with all methods and callable functionality.
 */
export type Scale = {
  (value: number): number;
  // scale: (value: number) => number;
  invert: (value: number) => number;
  domain: SetOrGetFunction<Domain>;
  range: SetOrGetFunction<Range>;
  clamp: SetOrGetFunction<boolean>;
  ticks: (count?: number) => number[];
  nice: () => Scale;
  copy: () => Scale;
};

/**
 * 🎯 Main scale interface with all methods and callable functionality.
 */
export type ScaleLog = {
  (value: number): number;
  invert: (value: number) => number;
  domain: SetOrGetFunction<Domain, ScaleLog>;
  range: SetOrGetFunction<Range, ScaleLog>;
  clamp: SetOrGetFunction<boolean, ScaleLog>;
  ticks: (count?: number) => number[];
  nice: () => ScaleLog;
  copy: () => ScaleLog;
  base: SetOrGetFunction<number, ScaleLog>;
};

/**
 * 🎯 Main scale interface with all methods and callable functionality.
 */
type ScaleExp = {
  (value: number): number;
  // scale: (value: number) => number;
  invert: (value: number) => number;
  domain: SetOrGetFunction<Domain, ScaleLog>;
  range: SetOrGetFunction<Range, ScaleLog>;
  clamp: SetOrGetFunction<boolean, ScaleLog>;
  ticks: (count?: number) => number[];
  nice: () => ScaleExp;
  copy: () => ScaleExp;
  base: SetOrGetFunction<number, ScaleLog>;
};

const SCALE_NORMALIZE = 10;

/**
 * 🏗️ Shared helper for creating getter/setter methods.
 */
const getterSetter = <T, S>(
  scale: S,
  getValue: () => T,
  setValue: (v: T) => void,
): SetOrGetFunction<T, S> =>
  ((value?: T) => {
    if (value === undefined) return getValue();
    setValue(value);
    return scale;
  }) as SetOrGetFunction<T, S>;

/**
 * 🎚 Shared utility to generate evenly spaced ticks based on the domain.
 */
function generateTicks(domain: Domain, count = 10): number[] {
  const [d0, d1] = domain;
  const step = (d1 - d0) / (count - 1); // Divides the domain into equal segments
  return Array.from({ length: count }, (_, i) => d0 + step * i);
}

/**
 * ✨ Shared utility to adjust the domain to "nice" round numbers for better readability.
 */
function makeNice(domain: Domain): Domain {
  return [Math.floor(domain[0]), Math.ceil(domain[1])];
}

/**
 * 🚀 Logarithmic scale function with customizable base and offset.
 *
 * Formula: y = ((log_base(x + offset) - log_base(d0 + offset)) / (log_base(d1 + offset) - log_base(d0 + offset))) * (r1 - r0) + r0
 */
export function scaleLog(base = Math.E): ScaleLog {
  let domain: Domain = [0, 1];
  let range: Range = [0, 1];
  let clampEnabled = false;
  let offset = 0;
  calcOffset();

  function calcOffset() {
    if (domain[0] <= 0) {
      offset = Math.abs(domain[0]) + 1;
    }
  }

  const scale: ScaleLog = ((value: number): number => {
    const [d0, d1] = domain;
    const [r0, r1] = range;

    // Apply logarithmic transformation based on specified base and offset
    const transformedValue = Math.log(value + offset) / Math.log(base);
    const transformedDomainStart = Math.log(d0 + offset) / Math.log(base);
    const transformedDomainEnd = Math.log(d1 + offset) / Math.log(base);

    // Map the transformed value to the specified range
    const scaledValue =
      r0 +
      ((transformedValue - transformedDomainStart) * (r1 - r0)) /
        (transformedDomainEnd - transformedDomainStart);

    return clampEnabled ? Math.max(r0, Math.min(r1, scaledValue)) : scaledValue;
  }) as ScaleLog;

  /**
   * 🔁 Invert the logarithmic scaling:
   *
   * Formula: x = base^(normalized * (log_base(d1 + offset) - log_base(d0 + offset)) + log_base(d0 + offset)) - offset
   */
  scale.invert = (value: number): number => {
    const [r0, r1] = range;
    const [d0, d1] = domain;

    const transformedDomainStart = Math.log(d0 + offset) / Math.log(base);
    const transformedDomainEnd = Math.log(d1 + offset) / Math.log(base);

    const normalized = (value - r0) / (r1 - r0);
    const invertedTransformed =
      transformedDomainStart +
      normalized * (transformedDomainEnd - transformedDomainStart);

    return Math.pow(base, invertedTransformed) - offset;
  };

  scale.ticks = (count = 10): number[] => generateTicks(domain, count);

  scale.nice = (): ScaleLog => {
    domain = makeNice(domain);
    return scale;
  };

  scale.copy = (): ScaleLog =>
    scaleLog(base)
      .domain([...domain] as Domain)
      .range([...range])
      .clamp(clampEnabled);

  scale.domain = getterSetter(
    scale,
    () => domain,
    (v) => {
      domain = v;
      calcOffset();
    },
  );
  scale.range = getterSetter(
    scale,
    () => range,
    (v) => (range = v),
  );
  scale.clamp = getterSetter(
    scale,
    () => clampEnabled,
    (v) => (clampEnabled = v),
  );
  scale.base = getterSetter(
    scale,
    () => base,
    (v) => (base = v),
  );

  return scale;
}

/**
 * 🚀 Exponential scale function with customizable base.
 *
 * Formula: y = ((base^(normalized * SCALE_NORMALIZE) - base^0) / (base^SCALE_NORMALIZE - base^0)) * (r1 - r0) + r0
 */
export function scaleExp(base = Math.E): ScaleExp {
  let domain: Domain = [0, 1];
  let range: Range = [0, 1];
  let clampEnabled = false;

  const scale: ScaleExp = ((value: number): number => {
    const [d0, d1] = domain;
    const [r0, r1] = range;

    // Normalize and apply exponential transformation
    const normalized = (value - d0) / (d1 - d0);
    const clampedNormalized = clampEnabled
      ? Math.max(0, Math.min(1, normalized))
      : normalized;
    const scaledNormalized = clampedNormalized * SCALE_NORMALIZE;

    const minTransformed = Math.pow(base, 0); // base^0 = 1
    const maxTransformed = Math.pow(base, SCALE_NORMALIZE);
    const transformed = Math.pow(base, scaledNormalized);

    // Normalize the transformed value and map to the range
    const normalizedTransformed =
      (transformed - minTransformed) / (maxTransformed - minTransformed);
    return r0 + normalizedTransformed * (r1 - r0);
  }) as ScaleExp;

  /**
   * 🔁 Invert the exponential scaling:
   *
   * Formula: x = ((log_base(transformed) / SCALE_NORMALIZE) * (d1 - d0)) + d0
   */
  scale.invert = (value: number): number => {
    const [r0, r1] = range;
    const [d0, d1] = domain;

    const normalizedTransformed = (value - r0) / (r1 - r0);
    const minTransformed = Math.pow(base, 0);
    const maxTransformed = Math.pow(base, SCALE_NORMALIZE);
    const transformed =
      minTransformed +
      normalizedTransformed * (maxTransformed - minTransformed);

    const scaledNormalized = Math.log(transformed) / Math.log(base);
    const clampedNormalized = scaledNormalized / SCALE_NORMALIZE;

    return d0 + clampedNormalized * (d1 - d0);
  };

  scale.ticks = (count = 10): number[] => generateTicks(domain, count);

  scale.nice = (): ScaleExp => {
    domain = makeNice(domain);
    return scale;
  };

  scale.copy = (): ScaleExp =>
    scaleExp(base)
      .domain([...domain])
      .range([...range])
      .clamp(clampEnabled);

  scale.domain = getterSetter(
    scale,
    () => domain,
    (v) => (domain = v),
  );
  scale.range = getterSetter(
    scale,
    () => range,
    (v) => (range = v),
  );

  scale.clamp = getterSetter(
    scale,
    () => clampEnabled,
    (v) => (clampEnabled = v),
  );

  scale.base = getterSetter(
    scale,
    () => base,
    (v) => (base = v),
  );

  return scale;
}

export function useScaleFunction(
  scaleType: ScaleType,
  minDomain: number,
  maxDomain: number,
  minRange: number,
  maxRange: number,
) {
  return useMemo(
    () =>
      getScaleFunction(scaleType)
        .domain([minDomain, maxDomain])
        .range([minRange, maxRange]),
    [scaleType, minDomain, maxDomain, minRange, maxRange],
  );
}

export function getScaleFunction(scaleType: ScaleType): Scale {
  switch (scaleType) {
    case 'linear':
      return scaleLinear() as unknown as Scale;

    case 'log':
      return scaleLog();
    case 'exp':
      return scaleExp();
  }
}
