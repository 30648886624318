import { FC } from 'react';
import { DatasetVersion } from '@tensorleap/api-client';
import { DatasetSetup, Node } from '@tensorleap/engine-contract';
import { DetailsProps } from '../../layer-details/NodeDetails';
import { Connection } from '../interfaces/Connection';
import { ChangeNodePropFunc } from '../networkStateUtils';
import { Setter } from '../../core/types';
import { NodePresentationState } from '../graph-calculation/contract';

export type CalcCtx = {
  node: Node;
  dataset: { metadata: { setup?: DatasetSetup } };
};

export type BlockType =
  | 'layer'
  | 'loss'
  | 'customLoss'
  | 'optimizer'
  | 'dataset'
  | 'groundTruth'
  | 'input'
  | 'visualizer'
  | 'prediction'
  | 'metric';

export type UpdateStateCtx = {
  nodes: ROMap<string, NodeWithLabels>;
  changeNodeProperty: ChangeNodePropFunc;
  setNodeStates: Setter<ROMap<string, NodePresentationState>>;
  connectionsByOutputId: { [outputId: string]: Connection[] };
  connectionsByInputId: { [inputId: string]: Connection[] };
  datasetSetup?: DatasetSetup;
};

export type BaseDescriptor<
  T extends string = string,
  N extends string = string,
> = {
  type: T;
  name: N;
  colorTheme?: BlockType;
  getDisplayName?: (node: Node) => string;
  overrideDetails?: boolean;
  CustomDetails?: FC<DetailsProps>;
  updateState?: (node: Readonly<Node>, ctx: UpdateStateCtx) => void;
};
export type EntityDescriptor<
  T extends string = string,
  N extends string = string,
> = BaseDescriptor<T, N> & {
  detailsTitle: string;
  calcProperties?: (CalcCtx: CalcCtx) => PropertyDescriptor[];
};

export type EntityDescriptorWithoutType<N extends string = string> = Omit<
  EntityDescriptor<never, N>,
  'type'
>;
export type ConditinalLabelDescriptorWithoutType = Omit<
  ConditinalLabelDescriptor,
  'type'
>;
export type LabelDescriptorWithoutType<T extends NodeLabels> = Omit<
  LabelDescriptor<T>,
  'type'
>;

export type NodeWithLabels = Node & { labels?: string[] };
type UpdateLabelProps = {
  node: Readonly<NodeWithLabels>;
  changeNodeProperty: ChangeNodePropFunc;
  codeIntegrationVersion?: Readonly<DatasetVersion>;
};

export type LabelDescriptor<N extends NodeLabels> = BaseDescriptor<
  'label',
  N
> & {
  add: (props: UpdateLabelProps) => void;
  remove: (props: UpdateLabelProps) => void;
};

export type ConditinalLabelDescriptor = BaseDescriptor<'label'> & {
  name: NodeLabels;
  hasLabel: (node: Node['name']) => boolean;
};

export type CalcOutputFunc = () => string[];

export enum NodeTypes {
  Layer = 'Layer',
  CustomLayer = 'CustomLayer',
  Loss = 'Loss',
  Optimizer = 'Optimizer',
  Wrapper = 'wrapper',
}

export enum NodeLabels {
  Prediction = 'Prediction',
  DynamicShape = 'DynamicShape',
}
