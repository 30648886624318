import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SampleIdentity, SlimVisualization } from '@tensorleap/api-client';
import { VisDisplay } from '../dashlet/VisualizationDisplay/VisDisplay';
import { SelectedSessionRun } from '../../ui/molecules/useModelFilter';
import { useCurrentProject } from '../../core/CurrentProjectContext';
import {
  useSampleListState,
  MultiSelectType,
} from '../dashlet/SampleAnalysis/useSampleListState';
import { useMapProjectStorageUrl } from '../../core/useProjectStorage';
import {
  extractVisData,
  VisPayloadType,
} from '../dashlet/VisualizationDisplay/visDataHelpers';
import { useFullVisualizationsFromAnalysis } from '../../core/data-fetching/fullVisualizations';
import { MouseHandler } from '../../core/useSelectionGroup';
import { useToggle } from '../../core/useToggle';

export interface SampleAnalysisVisDisplayProps {
  defaultSampleIds?: SampleIdentity[];
  selectedSessionRuns: SelectedSessionRun[];
  mouseHandler: MouseHandler;
}

export function SampleAnalysisVisDisplay({
  defaultSampleIds,
  selectedSessionRuns,
  mouseHandler,
}: SampleAnalysisVisDisplayProps): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const [toggleMultiSampleSelection, setToggleMultiSampleSelection] =
    useToggle(true);
  const [selectedPayloadType, setSelectedPayloadType] =
    useState<VisPayloadType>(VisPayloadType.Metadata);

  const [activeSampleIds, setActiveSampleIds] = useState<SampleIdentity[]>(
    defaultSampleIds || [],
  );

  const { mapUrl } = useMapProjectStorageUrl();

  const sampleListState = useSampleListState({
    projectId,
    selectedSessionRuns,
    activeSampleIds,
    onActiveSamplesChange: useCallback((newActiveSampleIds) => {
      setActiveSampleIds(newActiveSampleIds);
    }, []),
  });

  const activeSlimVisualizations = useMemo(() => {
    return sampleListState.activeSamples.reduce(
      (acc, sample) => {
        Object.entries(sample.slimVisualizationsPerSessionId).forEach(
          ([key, value]) => {
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(
              ...value.map((vis) => ({ ...vis, sampleIdentity: sample.id })),
            );
          },
        );
        return acc;
      },
      {} as Record<
        string,
        (SlimVisualization & { sampleIdentity: SampleIdentity })[]
      >,
    );
  }, [sampleListState.activeSamples]);

  const { visualizations } = useFullVisualizationsFromAnalysis({
    slimVisualizationsPerSessionRunId: activeSlimVisualizations,
    selectedSessionRuns,
    mapUrl,
  });

  const visPayloadElements = useMemo(() => {
    return extractVisData({ sessionVisualizationsMap: visualizations });
  }, [visualizations]);

  useEffect(() => {
    if (toggleMultiSampleSelection) {
      setActiveSampleIds(sampleListState.samples.map((sample) => sample.id));
    } else if (
      activeSampleIds.length === 0 &&
      sampleListState.samples.length > 0
    ) {
      setActiveSampleIds([sampleListState.samples[0].id]);
    }
  }, [
    toggleMultiSampleSelection,
    sampleListState.samples,
    activeSampleIds.length,
  ]);

  useEffect(() => {
    if (toggleMultiSampleSelection) {
      setActiveSampleIds(sampleListState.samples.map((sample) => sample.id));
    }
  }, [toggleMultiSampleSelection, sampleListState.samples]);

  const onSampleSelect = useCallback(
    (sample: SampleIdentity, multiSelectType: MultiSelectType) => {
      setActiveSampleIds((prev) => {
        let newSelection: SampleIdentity[];
        if (multiSelectType === MultiSelectType.Single) {
          newSelection = [sample];
        } else {
          const index = prev.findIndex(
            (s) => s.index === sample.index && s.state === sample.state,
          );
          if (index === -1) {
            newSelection = [...prev, sample];
          } else {
            newSelection = prev.filter((_, i) => i !== index);
          }
        }
        if (
          newSelection.length !== sampleListState.samples.length &&
          toggleMultiSampleSelection
        ) {
          setToggleMultiSampleSelection();
        }
        return newSelection;
      });
    },
    [
      sampleListState.samples.length,
      setToggleMultiSampleSelection,
      toggleMultiSampleSelection,
    ],
  );

  const activeSamples = useMemo(
    () =>
      sampleListState.samples.filter((sample) =>
        activeSampleIds.some(
          (id) => id.index === sample.id.index && id.state === sample.id.state,
        ),
      ),
    [activeSampleIds, sampleListState.samples],
  );

  const deselectSample = useCallback(
    (sample: SampleIdentity) => {
      if (toggleMultiSampleSelection) {
        setToggleMultiSampleSelection();
      }
      setActiveSampleIds((prev) =>
        prev.filter(
          (s) => s.index !== sample.index || s.state !== sample.state,
        ),
      );
    },
    [setToggleMultiSampleSelection, toggleMultiSampleSelection],
  );

  return (
    <VisDisplay
      selectedSessionRuns={selectedSessionRuns}
      sampleListState={{
        ...sampleListState,
        onSampleSelect,
        activeSamples,
      }}
      visPayloadElements={visPayloadElements}
      containerType="sampleAnalysis"
      mouseHandler={mouseHandler}
      toggleMultiSampleSelection={toggleMultiSampleSelection}
      setToggleMultiSampleSelection={setToggleMultiSampleSelection}
      selectedPayloadType={selectedPayloadType}
      setSelectedPayloadType={setSelectedPayloadType}
      deselectSample={deselectSample}
      showAsGallery={false}
    />
  );
}
