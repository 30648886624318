import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNumberPlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M16.326 6.615A2.048 2.048 0 0 1 19.78 5.58c.577.576.524 1.534-.017 2.144l-3.479 3.922h4.096M8.472 20.982c.175.76.933 1.331 1.841 1.331 1.039 0 1.88-.747 1.88-1.667s-.841-1.666-1.88-1.666c1.039 0 1.88-.747 1.88-1.667s-.841-1.667-1.88-1.667c-.908 0-1.666.572-1.841 1.331M8.743 6.362l1.845-1.382v6.666M18.86 15.646l-2.478 5.6h3.9M19.64 19.182v3.13"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.667 1.333H3.333a2 2 0 0 0-2 2v21.334a2 2 0 0 0 2 2h21.334a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2ZM3.333 0A3.333 3.333 0 0 0 0 3.333v21.334A3.333 3.333 0 0 0 3.333 28h21.334A3.333 3.333 0 0 0 28 24.667V3.333A3.333 3.333 0 0 0 24.667 0H3.333Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNumberPlaceholderIcon;
