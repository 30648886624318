import { useCallback, useEffect, useMemo } from 'react';
import { Slider } from '../../ui/atoms/Slider';
import { Setter } from '../../core/types';
import { GradsItem } from '@tensorleap/api-client';
import { max } from 'lodash';
export interface DepthSlideSelectorProps {
  value?: number;
  setValue: Setter<number | undefined>;
  gradItems: GradsItem[];
  className?: string;
}
export function DepthSlideSelector({
  value,
  setValue,
  gradItems,
  className,
}: DepthSlideSelectorProps): JSX.Element {
  const onChange = useCallback(
    (_: React.ChangeEvent<object>, newValue: number | number[]) => {
      setValue(newValue as number);
    },
    [setValue],
  );
  const possibleValues = useMemo(
    () =>
      gradItems.reduce((acc, item) => {
        if (item.depth && !acc.includes(item.depth)) {
          acc.push(item.depth);
        }
        return acc;
      }, [] as number[]),
    [gradItems],
  );
  useEffect(() => {
    if (
      possibleValues &&
      possibleValues.length > 0 &&
      (value === undefined || !possibleValues.includes(value))
    ) {
      setValue(max(possibleValues));
    }
  });

  return possibleValues && possibleValues?.length > 0 ? (
    <Slider
      labelPrefix="Depth: "
      value={value}
      onChange={onChange}
      possibleValues={possibleValues}
      className={className}
    />
  ) : (
    <></>
  );
}
