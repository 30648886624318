import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgEpoch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M21.047 11.914 18 14.961c-.156.156-.365.234-.625.234s-.469-.078-.625-.234l-3.008-3.047c-.364-.417-.364-.833 0-1.25.417-.364.833-.364 1.25 0l1.68 1.68c-.13-1.563-.768-2.89-1.914-3.985-1.146-1.093-2.5-1.64-4.063-1.64-1.64 0-3.047.599-4.218 1.797-1.146 1.171-1.72 2.604-1.72 4.296 0 1.693.574 3.139 1.72 4.336 1.171 1.172 2.59 1.758 4.257 1.758 1.198 0 2.318-.351 3.36-1.054a.781.781 0 0 1 .664-.157.711.711 0 0 1 .547.352c.338.469.273.872-.196 1.21-1.302.912-2.76 1.368-4.375 1.368-2.135 0-3.958-.755-5.468-2.266C3.756 16.823 3 14.974 3 12.812c0-2.161.755-3.997 2.266-5.507C6.776 5.768 8.599 5 10.734 5c1.98 0 3.698.677 5.157 2.031 1.484 1.354 2.317 3.034 2.5 5.04l1.367-1.407c.416-.364.833-.364 1.25 0 .416.417.43.833.039 1.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEpoch;
