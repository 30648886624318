import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '../ui/mui';
import api from '../core/api-client';
import {
  Down,
  ErrorIcon,
  ForwardIcon,
  GotoLogIcon,
  KillIcon,
  LogIcon,
  NonVisible,
  PlayIcon,
  RunsIcon,
  SmallFilterEmpty,
  SmallFilterFilled,
  StopwatchIcon,
  Up,
  Vi1,
  XClose,
} from '../ui/icons';
import {} from '../core/data-fetching';
import { dateFormatter } from '../core/formatters/date-formatting';
import { Title } from '../ui/atoms/Title';
import { Table } from '../ui/model-list/table/Table';
import { useCreateFieldHelper } from '../ui/model-list/utils';
import { ModelFields } from '../ui/model-list/types';
import { HoverAction } from '../ui/model-list/table/TableRowActions';
import { Badge, IconButton, Popover, Tooltip } from '@material-ui/core';
import { useSort, sort } from '../ui/model-list/sorter';
import { Button } from '../ui/atoms/Button';
import {
  usePopupState,
  bindTrigger,
  bindPopover,
} from 'material-ui-popup-state/hooks';
import clsx from 'clsx';
import { ChartLoading } from '../ui/charts/common/ChartLoading';
import {
  ESFilter,
  JobEvent,
  JobStatus,
  JobSubType,
  JobType,
  PopulationExplorationJobParams,
  RunProcess,
  TerminateAllJobsParams,
} from '@tensorleap/api-client';
import {
  isJobEventMessage,
  isProcessUpdatedMsg,
} from '../core/websocket-message-types';
import { usePushNotifications } from '../core/PushNotificationsContext';
import {
  JOB_SUB_TYPE_TO_DISPLAY,
  DEFAULT_JOB_SUB_TYPES,
  useFetchJobList,
} from '../core/data-fetching/job-list';
import { truncateLongtail } from '../core/formatters/string-formatting';
import { EventsProgressBar } from '../ui/atoms/EventsProgressBar';
import { useMultiSelector } from '../ui/model-list/selectors';
import { Divider } from '../dashboard/Divider';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { Switch } from '../ui/atoms/Switch';
import { extractDatasetVersionDisplayName } from '../version-control/helper';
import { useEnvironmentInfo } from '../core/EnvironmentInfoContext';
import { downloadUrl } from '../url/download-url';
import { useHistory } from 'react-router-dom';
import { buildProjectUrl, getIdFromUrl, URLS_ENUM } from '../url/url-builder';
import { Truncate } from '../ui/atoms/Truncate';
import { LiveLogs } from './LiveLogs';
import { mapToVisualizationFilters } from '../model-tests/modelTestHelpers';
import { FilterElements } from '../filters/FilterElements';
import { MultiSelectIconMenu } from '../ui/atoms/MultiSelectIconMenu';
import { useLocalStorage } from '../core/useLocalStorage';
import { DisplayValue } from '../dashboard/dashlet/common/DisplayMetadata';

const NO_VALUE = '--';

export type DisplayRunProcess = {
  jobId: string;
  versionName?: string;
  projectName?: string;
  datasetName?: string;
  jobType: JobType | JobSubType;
  status: JobStatus;
  sessionName?: string;
  sessionRunName?: string;
  events?: Array<JobEvent>;
  createdAt: Date;
  duration: number;
  batchSize?: number;
  sessionRunId?: string;
  projectId?: string;
  showContinueButton: boolean;
  datasetVersionName?: string;
  logsBlobName?: string;
  jobParams: RunProcess['params'];
};

const STATUS_ICONS = {
  STARTED: <ForwardIcon className="text-primary-500" />,
  FINISHED: <Vi1 className="text-success-500" />,
  PENDING: <StopwatchIcon className="text-gray-300" />,
  FAILED: <ErrorIcon className="text-error-500" />,
  TERMINATED: <ErrorIcon className="text-gray-300" />,
  STOPPED: <ErrorIcon className="text-gray-300" />,
};

export type RunningProps = {
  className?: string;
  inline?: boolean;
  onClose?: () => void;
};

const SHOW_LONG_AUTO_RUN_JOBS_KEY = 'showLongAutoRunJobs';

export function Running({
  onClose,
  inline,
  className,
}: RunningProps): JSX.Element {
  const { textField, computed } = useCreateFieldHelper<DisplayRunProcess>();

  const displayJobListColumns: ModelFields<DisplayRunProcess> = useMemo(
    () => [
      textField('versionName', {
        label: 'MODEL',
        format: (version, { status }) => {
          const icon = STATUS_ICONS[status as keyof typeof STATUS_ICONS];
          return (
            <div className="flex gap-2 items-center">
              {icon && (
                <Tooltip title={<span className="capitalize">{status}</span>}>
                  <div>{icon}</div>
                </Tooltip>
              )}
              {truncateLongtail({
                value: version,
                startSubsetLength: 10,
                endSubsetLength: 10,
              })}
            </div>
          );
        },
      }),
      computed({
        label: 'RUN',
        format: (job) => job.sessionRunName || job.sessionName || NO_VALUE,
      }),
      textField('jobType', { label: 'type' }),
      computed({
        label: 'status',
        format: (job) =>
          EventsProgressBar({ jobStatus: job.status, events: job.events }),
      }),
      textField('createdAt', {
        sortable: { level: 'primary', direction: 'desc' },
        label: 'created at',
        format: dateFormatter.format,
      }),
      textField('duration', {
        label: 'duration',
        format: miliSecondToDuration,
        sortable: { level: 'primary', direction: 'desc' },
      }),
    ],
    [computed, textField],
  );

  const { lastServerMessage } = usePushNotifications();

  const [showLongAutoRunJobs, setShowLongAutoRunJobs] = useLocalStorage(
    SHOW_LONG_AUTO_RUN_JOBS_KEY,
    DEFAULT_JOB_SUB_TYPES,
  );

  const {
    processesList,
    isLoading,
    refetch: refetchProcessesList,
  } = useFetchJobList(showLongAutoRunJobs);

  const handleClearHistory = useCallback(async () => {
    await api.clearUserJobs();
    await refetchProcessesList();
  }, [refetchProcessesList]);

  useEffect(() => {
    if (
      isProcessUpdatedMsg(lastServerMessage) ||
      isJobEventMessage(lastServerMessage)
    )
      refetchProcessesList();
  }, [lastServerMessage, refetchProcessesList]);

  const sorter = useSort(displayJobListColumns);

  const sortedProcessesData = useMemo<DisplayRunProcess[]>(() => {
    if (!processesList) return [];

    const groupedProcessesMap = processesList.reduce((acc, process) => {
      const existingProcesses = acc.get(process.processId) || [];
      acc.set(process.processId, [...existingProcesses, process]);
      return acc;
    }, new Map<string, RunProcess[]>());

    const mappedElements = Array.from(groupedProcessesMap.values()).map(
      mapRunProcessToDisplayRunProcess,
    );
    return sort(mappedElements, sorter.sortBy).slice(0, 50);
  }, [processesList, sorter.sortBy]);

  const hoverActions = useMemo<HoverAction<DisplayRunProcess>[]>(
    () => [
      {
        title: 'Terminate Process',
        icon: <KillIcon />,
        filter: (process) =>
          ['UNSTARTED', 'STARTED', 'PENDING'].includes(process.status),
        onSelect: async (process) => {
          await api.terminateJob({
            jobId: process.jobId,
          });
          await refetchProcessesList();
        },
      },
    ],
    [refetchProcessesList],
  );
  const expander = useMultiSelector<DisplayRunProcess>({ itemIdKey: 'jobId' });
  const handleRowClick = useCallback(
    (item: DisplayRunProcess) => {
      expander.toggle(item);
    },
    [expander],
  );

  const [deleteAllJobsDialogOpen, setDeleteAllJobsDialogOpen] = useState(false);
  const openDeleteAllJobsDialog = useCallback(() => {
    setDeleteAllJobsDialogOpen(true);
  }, []);
  const closeDeleteAllJobsDialog = useCallback(() => {
    setDeleteAllJobsDialogOpen(false);
  }, []);

  const filterBadgeContent = useMemo(
    () =>
      showLongAutoRunJobs.length === JOB_SUB_TYPE_TO_DISPLAY.length
        ? undefined
        : showLongAutoRunJobs.length,
    [showLongAutoRunJobs],
  );
  return (
    <div
      className={clsx(className, 'overflow-hidden flex flex-col')}
      id={TOUR_SELECTORS_ENUM.RUNS_AND_PROCESSES_TABLE_ID}
    >
      <div className="items-center flex flex-row justify-between mb-2">
        <Title
          className={inline ? 'px-4 pt-2' : 'pb-4'}
          bottomBorderClassName="border-b-primary-500"
        >
          Runs & processes
        </Title>
        <div className="flex flex-row items-center gap-2">
          <MultiSelectIconMenu
            arrow
            options={JOB_SUB_TYPE_TO_DISPLAY}
            paperClassName="max-h-[300px]"
            icon={
              <Badge badgeContent={filterBadgeContent} color="primary">
                {filterBadgeContent ? (
                  <SmallFilterFilled />
                ) : (
                  <SmallFilterEmpty />
                )}
              </Badge>
            }
            value={showLongAutoRunJobs}
            onChange={(values) => setShowLongAutoRunJobs(values)}
          />
          <Tooltip title="Terminate all jobs">
            <IconButton onClick={openDeleteAllJobsDialog}>
              <KillIcon />
            </IconButton>
          </Tooltip>
          <TerminateAllJobsDialog
            isOpen={deleteAllJobsDialogOpen}
            onClose={closeDeleteAllJobsDialog}
          />
          <Tooltip title="Clear History">
            <IconButton onClick={handleClearHistory}>
              <NonVisible />
            </IconButton>
          </Tooltip>
          {onClose && (
            <IconButton onClick={onClose}>
              <XClose />
            </IconButton>
          )}
        </div>
      </div>
      <Table
        inline={inline}
        noData={isLoading ? <ChartLoading /> : undefined}
        className="capitalize"
        sorter={sorter}
        data={sortedProcessesData}
        fields={displayJobListColumns}
        hoverActions={hoverActions}
        expander={expander}
        expanderPosition="hoverAction"
        ExpendRowComp={RunsProcessExpandedRowComponent}
        onRowClick={handleRowClick}
        loadBulkSize={8}
      />
    </div>
  );
}

const STATUS_BACKGROUND_COLORS: Record<JobStatus, string> = {
  [JobStatus.Unstarted]: 'bg-primary-950',
  [JobStatus.Pending]: 'bg-primary-950',
  [JobStatus.Started]: 'bg-primary-950',
  [JobStatus.Finished]: 'bg-success-950',
  [JobStatus.Failed]: 'bg-error-950',
  [JobStatus.Terminated]: 'bg-error-950',
  [JobStatus.Stopped]: 'bg-error-950',
};

const calcGoToProjectTooltip = (projectName: string) =>
  `Go to ${projectName} project`;

interface RunsProcessExpandedRowComponentProps {
  item: DisplayRunProcess;
}

function RunsProcessExpandedRowComponent({
  item,
}: RunsProcessExpandedRowComponentProps) {
  const projectName = item.projectName || NO_VALUE;
  const batchSize = item.batchSize;
  const codeIntegraion = item.datasetVersionName;
  const logsBlobName = item.logsBlobName;
  const jobStatus = item.status;
  const jobId = item.jobId;

  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'logsPopover',
  });

  const [tooltipTitle, setTooltipTitle] = useState(
    calcGoToProjectTooltip(projectName),
  );

  const history = useHistory();

  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();

  const handleContinue = useCallback(() => {
    if (!item.projectId) {
      console.warn("Can't continue evaluation, missing projectId");
      return;
    }
    api.continueEvaluate({
      projectId: item.projectId,
      jobId: item.jobId,
    });
  }, [item.projectId, item.jobId]);

  const handleDownloadLogsClick = useCallback(
    async (blob: string) => {
      const fullBlobUrl = `${clientStoragePrefixUrl}/${blob}`;
      downloadUrl(fullBlobUrl);
    },
    [clientStoragePrefixUrl],
  );

  const isJobEnded = useMemo(
    () =>
      jobStatus == JobStatus.Failed ||
      jobStatus === JobStatus.Finished ||
      jobStatus === JobStatus.Terminated ||
      jobStatus === JobStatus.Stopped,
    [jobStatus],
  );

  const goToProject = useCallback(() => {
    const currentProjectId = getIdFromUrl(
      history.location.pathname,
      URLS_ENUM.PROJECT,
    );
    if (currentProjectId === item?.projectId) {
      setTooltipTitle('You are already in this project');
      setTimeout(() => calcGoToProjectTooltip(projectName), 2000);
    } else {
      history.push(buildProjectUrl(item?.projectId as string));
    }
  }, [history, item?.projectId, projectName]);

  return (
    <>
      <div
        className={clsx(
          'flex w-full flex-col',
          STATUS_BACKGROUND_COLORS[item.status],
        )}
      >
        <div className="flex flex-row items-center justify-between px-4 gap-2 py-2">
          <div className="flex flex-1 flex-row justify-start gap-6 uppercase text-sm items-center">
            {item?.projectId && (
              <KeyValue label="project">
                <Tooltip title={tooltipTitle}>
                  <div>
                    <Button
                      onClick={goToProject}
                      className="h-5 max-w-[40vh]"
                      variant="text"
                    >
                      <Truncate value={projectName} />
                    </Button>
                  </div>
                </Tooltip>
              </KeyValue>
            )}
            {batchSize !== undefined && (
              <>
                <Divider vertical />
                <KeyValue label="batch size">
                  <span>{batchSize}</span>
                </KeyValue>
              </>
            )}
            {codeIntegraion !== undefined && (
              <>
                <Divider vertical />
                <KeyValue label="code integration">
                  <span className="normal-case">{codeIntegraion}</span>
                </KeyValue>
              </>
            )}
            <DisplayExtraParams item={item} />
          </div>
          {item.showContinueButton && (
            <Button
              variant="inverted-gray"
              className="!h-8 !py-1 !px-3"
              onClick={handleContinue}
            >
              <PlayIcon className="text-success-500" /> Continue
            </Button>
          )}
          {!isJobEnded && (
            <Button
              variant="inverted-gray"
              className="!h-8 !py-1 !px-3"
              {...bindTrigger(popoverState)}
            >
              inspect process
              <GotoLogIcon className="text-gray-500" />
            </Button>
          )}
          {isJobEnded && logsBlobName && (
            <Button
              variant="inverted-gray"
              className="!h-8 !py-1 !px-3"
              onClick={() => handleDownloadLogsClick(logsBlobName)}
            >
              download logs
              <LogIcon className="text-gray-500" />
            </Button>
          )}
        </div>
        <DisplayFilters
          filters={(item.jobParams as { filters?: ESFilter[] })?.filters}
        />
      </div>

      <Popover
        {...bindPopover(popoverState)}
        classes={{
          paper:
            'max-w-[80vw] w-[80vw] h-[90vh] !p-0 !m-0 !top-16 !right-10 !left-auto',
        }}
      >
        <LiveLogs
          onClose={popoverState.close}
          jobId={jobId}
          title={`inspect ${item.jobType.toString()}`}
        />
      </Popover>
    </>
  );
}

export function RunningToolbarDialog() {
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'runningPopover',
  });

  return (
    <>
      <Tooltip title="Runs & Processes">
        <Button {...bindTrigger(popoverState)} variant="action-icon">
          <RunsIcon />
          {popoverState.isOpen ? <Up /> : <Down />}
        </Button>
      </Tooltip>
      <Popover
        {...bindPopover(popoverState)}
        classes={{
          paper:
            'bg-gray-850 border-b border-l border-gray-700 !p-0 !m-0 !top-16 !right-0 !left-auto',
        }}
      >
        <Running
          inline
          className="h-fit flex-1 max-h-[50vh] w-[80vw]"
          onClose={popoverState.close}
        />
      </Popover>
    </>
  );
}

function mapRunProcessToDisplayRunProcess(
  groupedProcesses: RunProcess[],
): DisplayRunProcess {
  const latestUpdatedProcess = groupedProcesses.reduce((latest, current) => {
    return new Date(latest.updatedAt).getTime() <
      new Date(current.updatedAt).getTime()
      ? current
      : latest;
  });

  const earliestCreatedProcess = groupedProcesses.reduce(
    (earliest, current) => {
      return new Date(earliest.createdAt).getTime() >
        new Date(current.createdAt).getTime()
        ? current
        : earliest;
    },
  );

  const process = {
    ...latestUpdatedProcess,
    createdAt: earliestCreatedProcess.createdAt,
  };

  const milliDiff: number = groupedProcesses.reduce((acc, process) => {
    return (
      acc +
      new Date(process.updatedAt).getTime() -
      new Date(process.createdAt).getTime()
    );
  }, 0);

  const showContinueButton =
    groupedProcesses.every(
      (p) =>
        p.jobType === 'Evaluate' &&
        ![JobStatus.Pending, JobStatus.Started, JobStatus.Finished].includes(
          p.status,
        ),
    ) && groupedProcesses.some((p) => p.events.length > 0);

  return {
    ...process,
    createdAt: new Date(process.createdAt),
    duration: milliDiff,
    jobType: process.jobType as JobType | JobSubType,
    events: process.events,
    batchSize: process.batchSize,
    sessionRunId: process.sessionRunId,
    projectId: process.projectId,
    showContinueButton,
    jobParams: process.params,
    datasetVersionName: process.datasetVersionInfo
      ? extractDatasetVersionDisplayName(process.datasetVersionInfo)
      : undefined,
  };
}

function miliSecondToDuration(miliSecond: number): string {
  const seconds = Math.floor(miliSecond / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ${hours % 24}h ${minutes % 60}m`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  }
  if (seconds > 0) {
    return `${seconds}s`;
  }
  return NO_VALUE;
}

interface TerminateAllJobsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}
function TerminateAllJobsDialog({
  isOpen,
  onClose,
}: TerminateAllJobsDialogProps): JSX.Element {
  const { currentProjectId } = useCurrentProject();
  const [allJobs, setAllJobs] = useState(true);
  const handleTerminateAllJobs = useCallback(() => {
    const params: TerminateAllJobsParams = {};
    if (!allJobs) {
      params['projectId'] = currentProjectId;
    }
    api.terminateAllJobs(params);
    onClose();
  }, [currentProjectId, onClose, allJobs]);
  const toggle = useCallback((v: boolean) => setAllJobs(v), []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="flex h-[12rem] w-[32rem] flex-col p-6 justify-center items-center gap-3">
        <div className="flex justify-center items-center">
          <span className="text-xl bold">
            Are you sure you want to terminate all the Jobs?
          </span>
        </div>
        {!!currentProjectId && (
          <div className="flex flex-row justify-center items-center">
            <span>Only in current project</span>
            <Switch value={allJobs} onClick={toggle} />
            <span>All jobs</span>
          </div>
        )}
        <div className="flex flex-row justify-center items-center gap-4">
          <Button onClick={handleTerminateAllJobs}>Terminate</Button>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

function isPopulationExplorationParams(
  jobType: JobType | JobSubType,
  params: RunProcess['params'],
): params is PopulationExplorationJobParams {
  return jobType === JobSubType.PopulationExploration && params !== undefined;
}
function DisplayPopulationExplorationParams({
  displayParams,
}: PopulationExplorationJobParams): JSX.Element {
  return (
    <div className="flex gap-2">
      {Object.entries(displayParams).map(
        ([key, value]) =>
          isValueNotEmpty(value) && (
            <React.Fragment key={key}>
              <Divider vertical />
              <KeyValue key={key} label={key}>
                <DisplayValue value={value} />
              </KeyValue>
            </React.Fragment>
          ),
      )}
    </div>
  );
}

function DisplayExtraParams({
  item,
}: {
  item: DisplayRunProcess;
}): JSX.Element {
  if (isPopulationExplorationParams(item.jobType, item.jobParams)) {
    return <DisplayPopulationExplorationParams {...item.jobParams} />;
  }
  return <></>;
}

function DisplayFilters({ filters }: { filters?: ESFilter[] }): JSX.Element {
  if (!filters?.length) return <></>;
  const uiFilters = filters?.map(mapToVisualizationFilters);
  return (
    <div className="flex gap-2 items-center p-4 text-sm">
      <span className="text-gray-500">FILTERS:</span>
      {uiFilters?.length ? (
        <FilterElements filters={uiFilters} readOnly filterFieldsMeta={[]} />
      ) : (
        <span>No filters</span>
      )}
    </div>
  );
}

function KeyValue({
  label,
  children,
  className,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={clsx('flex gap-2', className)}>
      <span className="text-gray-500">{label}:</span>
      {children}
    </div>
  );
}

function isValueNotEmpty(
  value: string | number | boolean | undefined,
): boolean {
  return (
    value !== undefined &&
    value !== '' &&
    value !== null &&
    (!Array.isArray(value) || value.length > 0)
  );
}
