import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgConfusionMatrixIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1h1V7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v9c0 1.105-.96 2-2.143 2H5a1 1 0 0 1-1-1v-2.5a1 1 0 0 1 1-1v-1a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1V7Zm1 6.5V17h6v-3.5H6Zm7 3.5h5a1 1 0 0 0 1-1v-2.5h-6V17Zm6-4.5V9h-6v3.5h6ZM12 9H6v3.5h6V9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgConfusionMatrixIcon;
