import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { SelectedSessionRun } from '../../../ui/molecules/useModelFilter';
import clsx from 'clsx';
import { JobStatus, SampleIdType } from '@tensorleap/api-client';
import { isJobUnfinished } from '../VisualizationDisplay/SampleList';
import {
  BG_COLOR_TEMPLATES,
  BG_COLOR_TEMPLATES_HEX,
} from '../../../ui/molecules/colorTemplates';

type IndicatorProps = {
  ids: Record<string, unknown>;
  selectedSessionRuns: SelectedSessionRun[];
  getSampleJobStatus: (index: SampleIdType, sessionRunId: string) => JobStatus;
  index: SampleIdType;
};

export function SessionRunIndicators({
  ids,
  selectedSessionRuns,
  getSampleJobStatus,
  index,
}: IndicatorProps) {
  return (
    <div className="flex gap-1 w-fit">
      {selectedSessionRuns.map((sessionRun) => {
        return (
          <IndicatorElement
            key={sessionRun.id}
            sessionRun={sessionRun}
            index={index}
            ids={ids}
            getSampleJobStatus={getSampleJobStatus}
          />
        );
      })}
    </div>
  );
}

type IndicatorElementProps = Omit<IndicatorProps, 'selectedSessionRuns'> & {
  sessionRun: SelectedSessionRun;
};
function IndicatorElement({
  sessionRun,
  index,
  ids,
  getSampleJobStatus,
}: IndicatorElementProps): JSX.Element {
  const exists = !!ids[sessionRun.id];
  const status = getSampleJobStatus(index, sessionRun.id);
  const showLinearBar = exists && isJobUnfinished(status);

  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: 'transparent',
    },
    barColorPrimary: {
      backgroundColor: BG_COLOR_TEMPLATES_HEX[sessionRun.modelUniqueKey],
    },
  })(LinearProgress);

  return (
    <Tooltip key={sessionRun.id} title={sessionRun.name}>
      <div className="flex flex-col">
        {showLinearBar ? (
          <StyledLinearProgress className={clsx('!h-2 !w-3 ')} />
        ) : (
          <div
            className={clsx(
              'h-2 w-3',
              exists
                ? BG_COLOR_TEMPLATES[sessionRun.modelUniqueKey]
                : 'bg-gray-700',
            )}
          />
        )}
      </div>
    </Tooltip>
  );
}
