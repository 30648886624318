import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  DataStateType,
  EvaluateParams,
  Job,
  Session,
  SlimVersion,
} from '@tensorleap/api-client';
import { Dialog, Button, Divider, IconButton } from '../ui/mui';
import { FwArrowIcon, SaveAs, XClose } from '../ui/icons';
import { useVersionControl } from '../core/VersionControlContext';
import { isEvaluateOrTrainingProcessAllowed } from './helper-functions';
import api from '../core/api-client';
import { useSnackbar } from '../ui/SnackbarContext';
import {
  AddToDashboardFlag,
  MonitorFlag,
  SkipMetricsEstimationFlag,
} from './RunModel/Flags';
import { SelectSessionWithEpoch } from './RunModel/SelectSessionWithEpoch';
import { EvaluatePlan } from './RunModel/EvaluatePlan';
import clsx from 'clsx';
import { DIALOG_HEIGHT_CLASSES } from './common';
import { useCurrentProject } from '../core/CurrentProjectContext';
import SvgSaveCommit from '../ui/icons/SaveCommit';
import { ConfirmDialogManyButtons } from '../ui/atoms/ConfirmDialogManyButtons';
import { useVersionControlPanelContext } from '../core/VersionControlPanelContext';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useLocalStorage } from '../core/useLocalStorage';
import { useFeatureFlags } from '../core/FeatureFlagsContext';

export const DEFAULT_BATCH_SIZE_KEY = 'DEFAULT_BATCH_SIZE';
export const SKIP_METRICS_ESTIMATION_KEY = 'SKIP_METRICS_ESTIMATION';

export type RunModelDialogProps = RunModelFormProps & {
  isOpen: boolean;
  onClose: () => void;
};

interface EvaluateDialogFormValues {
  batchSize: number;
  selectedSubsets: DataStateType[];
}

export function RunModelDialog({
  isOpen,
  onClose,
  initialVersion,
}: RunModelDialogProps): JSX.Element {
  const { handleSaveNewVersion } = useVersionControlPanelContext();
  const { isVersionChanged, handleSaveClicked } = useNetworkMapContext();

  const [continueWithoutSaving, setContinueWithoutSaving] = useState(false);

  const handleContinueWithoutSaveClicked = useCallback(
    () => setContinueWithoutSaving(true),
    [],
  );

  const saveAndContinueToTrainEvalDialog = useCallback(async () => {
    await handleSaveClicked(() => {
      setContinueWithoutSaving(true);
    });
  }, [handleSaveClicked]);

  const handleSaveAsNewClicked = useCallback(() => {
    onClose();
    setTimeout(handleSaveNewVersion, 100);
  }, [handleSaveNewVersion, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      {continueWithoutSaving || !isVersionChanged ? (
        <TrainEvalDialog onClose={onClose} initialVersion={initialVersion} />
      ) : (
        <SuggestSaveDialog
          handleContinueWithoutSaveClicked={handleContinueWithoutSaveClicked}
          handleSaveClicked={saveAndContinueToTrainEvalDialog}
          handleSaveAsNewClicked={handleSaveAsNewClicked}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
}

interface SuggestSaveDialogProps {
  handleContinueWithoutSaveClicked: () => void;
  handleSaveClicked: () => Promise<void>;
  handleSaveAsNewClicked: () => void;
  onClose: () => void;
}

function SuggestSaveDialog({
  handleContinueWithoutSaveClicked,
  handleSaveClicked,
  handleSaveAsNewClicked,
  onClose,
}: SuggestSaveDialogProps): JSX.Element {
  return (
    <ConfirmDialogManyButtons
      title={
        'Unsaved changes were recognized, a save must be performed in order to apply them'
      }
      isOpen={true}
      onClose={onClose}
      confirmButtons={[
        {
          onConfirm: handleContinueWithoutSaveClicked,
          confirmButtonText: 'Continue Without Saving',
          confirmButtonColor: 'red',
        },
        {
          onConfirm: handleSaveClicked,
          confirmButtonText: 'Save and continue',
          confirmButtonIcon: <SvgSaveCommit />,
          confirmButtonColor: 'blue',
        },
        {
          onConfirm: handleSaveAsNewClicked,
          confirmButtonText: 'Save As New Version',
          confirmButtonIcon: <SaveAs />,
          confirmButtonColor: 'blue',
        },
      ]}
    />
  );
}

interface TrainEvalDialogProps {
  onClose: () => void;
  initialVersion: SlimVersion;
}
function TrainEvalDialog({
  onClose,
  initialVersion,
}: TrainEvalDialogProps): JSX.Element {
  return (
    <div className={clsx('flex flex-col bg-gray-900', DIALOG_HEIGHT_CLASSES)}>
      <div className="flex flex-row h-16 px-8 justify-between items-center bg-gray-850">
        <h5 className="font-normal text-2xl leading-snug uppercase">
          evaluate
        </h5>
        <IconButton onClick={onClose}>
          <XClose />
        </IconButton>
      </div>
      <Evaluate initialVersion={initialVersion} onClose={onClose} />
    </div>
  );
}

type DialogBottomProps = {
  onClose: () => void;
  isValid: boolean;
  formId: string;
  submitText: ReactNode;
};

function DialogBottom({
  onClose,
  isValid,
  formId,
  submitText,
}: DialogBottomProps) {
  return (
    <div className="flex flex-row h-20 px-8 justify-end items-center bg-black">
      <Button
        variant="outlined"
        color="primary"
        className="mr-4"
        onClick={onClose}
      >
        <h6 className="font-medium text-xl uppercase leading-normal">cancel</h6>
      </Button>
      <Button
        data-track-action="train-dialog-submitted"
        type="submit"
        form={formId}
        variant="contained"
        color="primary"
        className="bg-primary-500"
        disabled={!isValid}
      >
        <h6 className="font-medium text-xl leading-normal uppercase">
          {submitText}
        </h6>
        <FwArrowIcon className="ml-2" />
      </Button>
    </div>
  );
}

const FORM_ID = 'TRAIN_FORM_ID';
const FORM_CLASSES = 'flex-1 w-[1300px] gap-6 p-8 flex flex-row';

type RunModelFormProps = {
  onClose: () => void;
  initialVersion: SlimVersion;
};

type SubmitWrapperOptions = { addToDashboard: boolean };

type SubmitWrapperReturn = (
  submitFunc: () => Promise<Job>,
  options: SubmitWrapperOptions,
) => Promise<void>;

function useSubmitWrapper(onClose: () => void): SubmitWrapperReturn {
  const { enqueueSnackbar } = useSnackbar();
  const { refetch: fetchVersions, toggleSelectSessionRun } =
    useVersionControl();

  const errorSnack = useCallback(
    (error: Error) => {
      enqueueSnackbar(error.message || error.toString(), { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  return useCallback(
    async (submitFunc: () => Promise<Job>, { addToDashboard }) => {
      try {
        const job = await submitFunc();
        onClose();
        await fetchVersions();
        const sessionRunId = job.sessionRunId;
        if (addToDashboard && sessionRunId) {
          toggleSelectSessionRun(sessionRunId);
        }
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          errorSnack(e);
        }
      }
    },
    [onClose, errorSnack, toggleSelectSessionRun, fetchVersions],
  );
}

export function Evaluate({
  onClose,
  initialVersion,
}: RunModelFormProps): JSX.Element {
  const [selectedVersion, setSelectedVersion] = useState(initialVersion);
  const [selectedSession, setSelectedSession] = useState<Session | undefined>(
    initialVersion?.sessions?.[0],
  );
  const [selectedEpoch, setSelectedEpoch] = useState<number>();
  const [nameWasUpdated, setNameWasUpdated] = useState(false);
  const [evaluationName, setEvaluationName] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [createNewSession, setCreateNewSession] = useState(false);

  const calculateBaseEvaluationName = useCallback(() => {
    const versionName = selectedVersion?.notes || 'Run';
    const sessionRunCount = selectedSession?.sessionRuns?.length || 0;
    return sessionRunCount === 0
      ? `${versionName}`
      : `${versionName}-${sessionRunCount + 1}`;
  }, [selectedSession?.sessionRuns?.length, selectedVersion?.notes]);

  useEffect(() => {
    if (!nameWasUpdated) {
      setEvaluationName(calculateBaseEvaluationName());
    }
  }, [nameWasUpdated, calculateBaseEvaluationName]);

  const updateEvaluationName = useCallback((evaluationName?: string) => {
    setNameWasUpdated(true);
    setEvaluationName(evaluationName || '');
  }, []);

  const updateDescription = useCallback((description: string) => {
    setDescription(description || '');
  }, []);

  const [addToDashboard, setAddToDashboard] = useState(true);
  const [monitor, setMonitor] = useState(false);
  const [skipMetricsEstimation, setSkipMetricsEstimation] = useLocalStorage(
    SKIP_METRICS_ESTIMATION_KEY,
    false,
  );
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const submitWrapper = useSubmitWrapper(onClose);

  const [defaultBatchSize, _] = useLocalStorage(DEFAULT_BATCH_SIZE_KEY, 8);

  const form = useForm<EvaluateDialogFormValues>({
    mode: 'onChange',
    defaultValues: {
      batchSize: defaultBatchSize,
      selectedSubsets: [
        DataStateType.Training,
        DataStateType.Validation,
        DataStateType.Test,
        DataStateType.Unlabeled,
      ],
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = form;

  const isEvaluateProcessAllowed = isEvaluateOrTrainingProcessAllowed({
    isValid,
    selectedSession,
    selectedVersion,
    createNewSession,
  });

  console.info({
    isEvaluateProcessAllowed,
    isValid,
    selectedSession,
    selectedVersion,
    createNewSession,
  });

  const onSubmit: SubmitHandler<EvaluateDialogFormValues> = async ({
    batchSize,
    selectedSubsets,
  }) => {
    if (selectedSubsets.length === 0 || selectedVersion === undefined) {
      console.error(
        'how evaluate was submitted without selecting a version or subsets?',
      );
      return;
    }

    if (
      !createNewSession &&
      (selectedSession === undefined || selectedEpoch === undefined)
    ) {
      console.error(
        'how evaluate an existing sesion was submitted without selecting a session or epoch?',
      );
    }

    const evaluateRequest: EvaluateParams = {
      projectId,
      versionId: selectedVersion.cid,
      sessionId: selectedSession?.cid,
      batchSize,
      dataStates: selectedSubsets,
      evaluatedEpoch: selectedEpoch,
      name: evaluationName,
      description,
      skipMetricsEstimation,
      monitor,
    };
    submitWrapper(() => api.evaluate(evaluateRequest), {
      addToDashboard,
    });
  };

  const {
    featureFlags: { useDashboardRangeFilter },
  } = useFeatureFlags();

  return (
    <>
      <form
        id={FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={FORM_CLASSES}
      >
        <div className="flex flex-col gap-8 flex-1 justify-start">
          <EvaluatePlan form={form} />
          <Divider orientation="horizontal" />
          <div className="flex flex-col">
            <SkipMetricsEstimationFlag
              value={skipMetricsEstimation}
              onChange={setSkipMetricsEstimation}
            />
            <AddToDashboardFlag
              value={addToDashboard}
              onChange={setAddToDashboard}
            />
            {useDashboardRangeFilter && (
              <MonitorFlag value={monitor} onChange={setMonitor} />
            )}
          </div>
        </div>
        <Divider orientation="vertical" />
        <div className="flex flex-col flex-1 gap-4">
          <span className="text-base">
            Please select which model to evaluated:
          </span>
          <SelectSessionWithEpoch
            session={selectedSession}
            onSessionChange={setSelectedSession}
            onVersionChange={setSelectedVersion}
            onEpochChange={setSelectedEpoch}
            epoch={selectedEpoch}
            initialSelectedVersion={selectedVersion}
            name={evaluationName}
            setName={updateEvaluationName}
            description={description}
            updateDescription={updateDescription}
            nameLabel="RUN NAME"
            setCreateNewSession={setCreateNewSession}
          />
        </div>
      </form>

      <DialogBottom
        onClose={onClose}
        isValid={isEvaluateProcessAllowed}
        submitText="evaluate"
        formId={FORM_ID}
      />
    </>
  );
}
