import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUpSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m12.83 10.163-3.64-3.64a.247.247 0 0 0-.36 0l-3.64 3.64a.247.247 0 0 0 0 .36l.39.39a.247.247 0 0 0 .36 0l3.07-3.07 3.07 3.07a.247.247 0 0 0 .36 0l.39-.39a.247.247 0 0 0 .078-.18.247.247 0 0 0-.078-.18Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUpSmallIcon;
