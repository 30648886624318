import { useState } from 'react';
import { LegendProps } from 'recharts';
import { chartNumberFormatter } from '../../core/formatters/number-formatting';

function linearInterpolate(
  dataBefore: number,
  dataAfter: number,
  roundingDistance: number,
) {
  return dataBefore + (dataAfter - dataBefore) * roundingDistance;
}

function linearInterpolates(
  dataBefore: number[],
  dataAfter: number[],
  roundingDistance: number,
) {
  return dataBefore.map((b, i) =>
    linearInterpolate(b, dataAfter[i], roundingDistance),
  );
}

export function interpolateArray(
  data: number[][] | number[],
  newLength: number,
) {
  const newData = Array(newLength);
  const indexRatio = (data.length - 1) / (newLength - 1);
  newData[0] = data[0];
  for (let i = 1; i <= newLength - 2; i++) {
    const oldIndex = i * indexRatio;
    const before = Math.floor(oldIndex);
    const after = Math.ceil(oldIndex);
    const roundingDistance = oldIndex - before;
    newData[i] = Array.isArray(data[before])
      ? linearInterpolates(
          data[before] as number[],
          data[after] as number[],
          roundingDistance,
        )
      : linearInterpolate(
          data[before] as number,
          data[after] as number,
          roundingDistance,
        );
  }
  newData[newLength - 1] = data[data.length - 1];
  return newData;
}

export function useDynamicLegend() {
  const [hoverDataKey, setHoverDataKey] = useState('');
  const [hiddenKeys, setHiddenKeys] = useState(new Set<string>());

  const handleLegendMouseOver: LegendProps['onMouseOver'] = (e: {
    dataKey: string;
  }) => {
    setHoverDataKey(e.dataKey);
  };

  const handleLegendMouseLeave = () => {
    setHoverDataKey('');
  };

  const handleLegendClick: LegendProps['onClick'] = (e) => {
    if (!e.dataKey) {
      console.warn(`[handleLegendClick] dataKey not found on onClick event`);
      return;
    }
    setHiddenKeys((currentSet) => {
      const newSet = new Set(currentSet);
      newSet.delete(e.dataKey) || newSet.add(e.dataKey);
      return newSet;
    });
  };

  return {
    hoverDataKey,
    hides: hiddenKeys,
    handleLegendClick,
    handleLegendMouseOver,
    handleLegendMouseLeave,
  };
}

export const formatGraphAxisNumber = (value: number) =>
  chartNumberFormatter.format(value);
