import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  ApplyTestFilter,
  CollectionIcon,
  Plus,
  Trash,
  XCloseIcon2,
} from '../../../ui/icons';
import clsx from 'clsx';
import { ListItem } from '../../../ui/atoms/List';
import { Chip } from '../../../ui/atoms/Chip';
import { SessionRunIndicators } from '../SampleAnalysis/SessionRunIndicators';
import { AddSampleAnalysisTooltip } from '../../add-analysis';
import { stopPropagation } from '../../../core/stopPropagation';
import {
  MultiSelectType,
  SampleVisualizations,
  UseSampleListState,
  isSampleIdEquals,
} from '../SampleAnalysis/useSampleListState';
import {
  isProcessUpdatedMsg,
  isJobEventMessage,
} from '../../../core/websocket-message-types';
import { usePushNotifications } from '../../../core/PushNotificationsContext';
import { useVersionControl } from '../../../core/VersionControlContext';
import {
  AnalyzeParams,
  Job,
  JobStatus,
  JobSubType,
  SampleIdentity,
  SampleIdType,
} from '@tensorleap/api-client';
import { SelectedSessionRun } from '../../../ui/molecules/useModelFilter';
import { useLazyLoad } from '../../../ui/model-list/table/useLazyLoad';
import { orderBy } from 'lodash';
import api from '../../../core/api-client';
import { useCurrentProject } from '../../../core/CurrentProjectContext';
import { ConfirmDialog } from '../../../ui/atoms/DeleteContentDialog';
import { Truncate } from '../../../ui/atoms/Truncate';

export type SamplesListProps = UseSampleListState & {
  showAnalyzeElements: boolean;
  toggleMultiSampleSelection: boolean;
  filterSelectedSamples?: () => void;
  setToggleMultiSampleSelection: () => void;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  className?: string;
};

export function isJobUnfinished(status: JobStatus): boolean {
  return [JobStatus.Pending, JobStatus.Started, JobStatus.Unstarted].includes(
    status,
  );
}

const minDate = new Date(0);
const maxDate = new Date(8640000000000000);
function getLatestVisualizationDate(sample: SampleVisualizations) {
  const visualizations = Object.values(
    sample.slimVisualizationsPerSessionId || {},
  ).flat();
  const latestCreatedAt = visualizations.reduce((latest, viz) => {
    const createdAt = new Date(viz.createdAt);
    return createdAt > latest ? createdAt : latest;
  }, minDate);
  return latestCreatedAt > minDate ? latestCreatedAt : maxDate;
}

function getJobsStatusesBySessionRunIdAndIndex(
  sampleAnalysisJobs: Job[],
): Record<SampleIdType, Record<string, JobStatus>> {
  return sampleAnalysisJobs.reduce(
    (acc, job) => {
      const sampleIndex = (job.params as AnalyzeParams)?.sampleIdentity?.index;
      const sessionRunId = job.sessionRunId;
      if (!sampleIndex || !sessionRunId) {
        console.warn('Job missing sampleIndex or sessionRunId data');
        return acc;
      }
      if (!acc[sampleIndex]) {
        acc[sampleIndex] = {};
      }
      if (
        acc[sampleIndex][sessionRunId] === undefined ||
        (!isJobUnfinished(acc[sampleIndex][sessionRunId]) &&
          isJobUnfinished(job.status))
      ) {
        acc[sampleIndex][sessionRunId] = job.status;
      }
      return acc;
    },
    {} as Record<SampleIdType, Record<string, JobStatus>>,
  );
}

function getUnfinishedJobs(
  sampleAnalysisJobListByIndex: Record<number, Record<string, JobStatus>>,
): Record<number, Record<string, JobStatus>> {
  return Object.entries(sampleAnalysisJobListByIndex).reduce(
    (indexSessionRunStatuses, [index, element]) => {
      const filteredElement = Object.entries(element).reduce(
        (sessionRunIdStatuses, [sessionRunId, jobStatus]) => {
          if (isJobUnfinished(jobStatus)) {
            sessionRunIdStatuses[sessionRunId] = jobStatus;
          }
          return sessionRunIdStatuses;
        },
        {} as Record<string, JobStatus>,
      );
      if (Object.keys(filteredElement).length) {
        indexSessionRunStatuses[parseInt(index)] = filteredElement;
      }
      return indexSessionRunStatuses;
    },
    {} as Record<number, Record<string, JobStatus>>,
  );
}

export function SampleList({
  samples,
  onSampleSelect,
  activeSamples,
  selectedSessionRuns,
  className,
  showAnalyzeElements,
  selectAllSamples,
  selectFirstSample,
  toggleMultiSampleSelection,
  setToggleMultiSampleSelection,
  filterSelectedSamples,
  removeSample,
  refetchSlimVisualizations,
}: SamplesListProps) {
  const { lastServerMessage } = usePushNotifications();

  const { currentProjectId } = useCurrentProject();
  if (!currentProjectId) {
    throw new Error('No current project');
  }

  const { selectedSessionRunMap, refetch: refreshVersionControl } =
    useVersionControl();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [sampleToDelete, setSampleToDelete] = useState<SampleIdentity>();

  const deleteDialogTitle = useMemo(() => {
    if (!sampleToDelete || !selectedSessionRuns.length) return '';
    const sessionRunsNames = selectedSessionRuns.map(({ name }) => name);
    const formattedNames =
      sessionRunsNames.length > 1
        ? `${sessionRunsNames
            .slice(0, -1)
            .join(', ')} and ${sessionRunsNames.slice(-1)}`
        : sessionRunsNames[0];
    return `Are you sure you want to delete ${sampleToDelete.index}@${sampleToDelete.state} sample from ${formattedNames} runs?`;
  }, [sampleToDelete, selectedSessionRuns]);

  const handleDeleteClick = useCallback((sample: SampleIdentity) => {
    setSampleToDelete(sample);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDeleteConfirm = useCallback(async () => {
    if (sampleToDelete) {
      const sessionRunIds = selectedSessionRuns.map(({ id }) => id);

      await api.deleteSampleAnalysis({
        projectId: currentProjectId,
        sessionRunIds,
        samplesIdentities: [sampleToDelete],
      });
      setIsDeleteDialogOpen(false);
      setSampleToDelete(undefined);
      refetchSlimVisualizations();
    }
  }, [
    sampleToDelete,
    selectedSessionRuns,
    currentProjectId,
    refetchSlimVisualizations,
  ]);

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSampleToDelete(undefined);
  }, []);

  useEffect(() => {
    if (
      isProcessUpdatedMsg(lastServerMessage) ||
      isJobEventMessage(lastServerMessage)
    )
      refreshVersionControl();
  }, [lastServerMessage, refreshVersionControl]);

  const sampleAnalysisJobListByIndex = useMemo(() => {
    const jobs = selectedSessionRuns.flatMap(
      ({ id }) => selectedSessionRunMap.get(id)?.jobs || [],
    );
    const sampleAnalysisJobs = (jobs || []).filter(
      ({ subType }) => subType === JobSubType.SampleAnalysis,
    );
    return getJobsStatusesBySessionRunIdAndIndex(sampleAnalysisJobs);
  }, [selectedSessionRunMap, selectedSessionRuns]);

  const expandedSamples = useMemo(() => {
    const samplesWithJobs = [...samples];
    if (selectedSessionRuns.length === 0) {
      return samplesWithJobs;
    }
    const unfinishedSampleJobs = getUnfinishedJobs(
      sampleAnalysisJobListByIndex,
    );
    Object.entries(unfinishedSampleJobs).forEach(
      ([sampleIndex, sampleBySessionRunId]) => {
        const sampleIndexInt = parseInt(sampleIndex);
        const sample = samplesWithJobs.find(
          ({ id: { index } }) => index === sampleIndexInt,
        );
        if (sample) {
          Object.keys(sampleBySessionRunId).forEach((sessionRunId) => {
            if (!sample.slimVisualizationsPerSessionId[sessionRunId]) {
              sample.slimVisualizationsPerSessionId[sessionRunId] = [];
            }
          });
        }
      },
    );
    return orderBy(samplesWithJobs, [getLatestVisualizationDate], ['desc']);
  }, [sampleAnalysisJobListByIndex, samples, selectedSessionRuns.length]);

  const getSampleJobStatus = useCallback(
    (index: SampleIdType, sessionRunId: string) =>
      sampleAnalysisJobListByIndex?.[index]?.[sessionRunId] ||
      JobStatus.Stopped,
    [sampleAnalysisJobListByIndex],
  );

  const handleSampleClick = useCallback(
    (sample: SampleIdentity, event: React.MouseEvent) => {
      let multiSelectType = MultiSelectType.Single;
      if (event.shiftKey) {
        multiSelectType = MultiSelectType.Shift;
      } else if (event.ctrlKey || event.metaKey) {
        multiSelectType = MultiSelectType.Control;
      }
      onSampleSelect(sample, multiSelectType);
    },
    [onSampleSelect],
  );

  const onClickToggleMultiSampleSelection = useCallback(() => {
    setToggleMultiSampleSelection();
  }, [setToggleMultiSampleSelection]);

  useEffect(() => {
    if (toggleMultiSampleSelection) {
      selectAllSamples();
    }
  }, [
    toggleMultiSampleSelection,
    selectAllSamples,
    selectFirstSample,
    samples.length,
    activeSamples.length,
  ]);

  const { visibleData: visibleSamples, lastElementRef } = useLazyLoad(samples);

  return (
    <>
      <div className={clsx('flex flex-col min-w-[16rem]', className)}>
        <div className="flex items-center px-4 py-2 justify-between font-bold text-gray-300 uppercase draggable-dashlet allow-dragging">
          <div className="flex flex-col">
            <span>{`samples${
              expandedSamples.length
                ? ` (${activeSamples.length}/${expandedSamples.length})`
                : ''
            }`}</span>
          </div>
          <div className="flex flex-row gap-2">
            {filterSelectedSamples && (
              <Tooltip title="Add a filter from the selected samples">
                <IconButton onClick={filterSelectedSamples} className="w-5 h-5">
                  <ApplyTestFilter />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={
                toggleMultiSampleSelection
                  ? 'Manually select samples'
                  : 'Select all samples'
              }
            >
              <IconButton
                onClick={onClickToggleMultiSampleSelection}
                className="w-5 h-5"
              >
                <CollectionIcon
                  className={clsx(
                    'cursor-pointer',
                    toggleMultiSampleSelection && 'text-primary-600',
                  )}
                />
              </IconButton>
            </Tooltip>
            {showAnalyzeElements && (
              <div onMouseDown={stopPropagation}>
                <AddSampleAnalysisTooltip>
                  {(toggle) => (
                    <IconButton onClick={toggle} className="w-5 h-5">
                      <Plus className="cursor-pointer w-4 h-4" />
                    </IconButton>
                  )}
                </AddSampleAnalysisTooltip>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-1 overflow-y-auto flex-col">
          {visibleSamples.length ? (
            visibleSamples.map((sample) => {
              const isActive = activeSamples.some((s) =>
                isSampleIdEquals(s.id, sample.id),
              );
              return (
                <SampleItem
                  key={`${sample.id.index}-${sample.id.state}`}
                  sample={sample}
                  isActive={isActive}
                  showAnalyzeElements={showAnalyzeElements}
                  selectedSessionRuns={selectedSessionRuns}
                  getSampleJobStatus={getSampleJobStatus}
                  handleSampleClick={handleSampleClick}
                  removeSample={removeSample}
                  handleDeleteClick={handleDeleteClick}
                />
              );
            })
          ) : (
            <NoSamples />
          )}
          <div ref={lastElementRef} />
        </div>
      </div>
      {isDeleteDialogOpen && (
        <ConfirmDialog
          title={deleteDialogTitle}
          isOpen={true}
          onClose={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
}

function SampleItem({
  sample,
  isActive,
  showAnalyzeElements,
  selectedSessionRuns,
  getSampleJobStatus,
  handleSampleClick,
  removeSample,
  handleDeleteClick,
}: {
  sample: SampleVisualizations;
  isActive: boolean;
  showAnalyzeElements: boolean;
  selectedSessionRuns: SelectedSessionRun[];
  getSampleJobStatus: (index: SampleIdType, sessionRunId: string) => JobStatus;
  handleSampleClick: (sample: SampleIdentity, event: React.MouseEvent) => void;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  handleDeleteClick: (sample: SampleIdentity) => void;
}) {
  const { id, slimVisualizationsPerSessionId } = sample;
  const noChildren =
    showAnalyzeElements &&
    Object.values(slimVisualizationsPerSessionId).flatMap((x) => x).length ===
      0;

  return (
    <div className="relative group">
      {removeSample ? (
        <div className="flex absolute z-10 h-full left-3 items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              removeSample?.(id);
            }}
            className="w-3 h-3 bg-gray-300 hover:bg-gray-400 opacity-70 hover:opacity-100"
          >
            <XCloseIcon2 className="cursor-pointer w-4 h-4 text-gray-600" />
          </IconButton>
        </div>
      ) : handleDeleteClick ? (
        <div className="flex absolute z-10 h-full left-3 items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(id);
            }}
            className="w-5 h-5 bg-gray-300 hover:bg-gray-400 opacity-70 hover:opacity-100"
          >
            <Trash className="cursor-pointer w-5 h-5 text-gray-600" />
          </IconButton>
        </div>
      ) : null}
      <ListItem selected={isActive}>
        <Tooltip title={`${id.index}@${id.state}`}>
          <button
            className="flex w-full h-full items-center justify-between"
            onClick={(event) => handleSampleClick(id, event)}
          >
            <div className="flex-1 flex flex-col justify-start items-start">
              <div className="flex font-bold flex-1 w-full max-w-[7rem] items-start">
                <Truncate value={id.index.toString()} />
              </div>
              {showAnalyzeElements && (
                <SessionRunIndicators
                  ids={slimVisualizationsPerSessionId}
                  selectedSessionRuns={selectedSessionRuns}
                  getSampleJobStatus={getSampleJobStatus}
                  index={id.index}
                />
              )}
            </div>
            <Chip className="max-h-7">
              {noChildren ? 'analyzing' : id.state}
            </Chip>
          </button>
        </Tooltip>
      </ListItem>
    </div>
  );
}

function NoSamples() {
  return (
    <div className="text-gray-400 text-sm flex-1 flex justify-center items-center">
      No results
    </div>
  );
}
