import { FormEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../core/api-client';
import { UseFetchTeams } from '../core/data-fetching/teams';
import { StateObject } from '../core/types';
import { Button } from '../ui/atoms/Button';
import { Input } from '../ui/atoms/Input';
import { PopupTransitionDialog } from '../ui/molecules/PopupTransitionDialog';
import { CircularProgress } from '../ui/mui';

export interface CreateNewTeamFormValues {
  teamName: string;
}

export interface CreateNewTeamDialogProps {
  openState: StateObject;
}

export function CreateNewTeamDialog({
  openState,
}: CreateNewTeamDialogProps): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { refetch: refetchTeams } = UseFetchTeams();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<CreateNewTeamFormValues>({
    mode: 'onChange',
    defaultValues: {
      teamName: '',
    },
  });

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        setIsSubmitting(true);
        await handleSubmit(async ({ teamName }) => {
          await api.createTeam({
            name: teamName,
            publicName: teamName,
          });
          await refetchTeams();
          reset();
          openState.setState(false);
        })(event);
      } catch (e) {
        console.error(e);
      }
      setIsSubmitting(false);
    },
    [handleSubmit, openState, refetchTeams, reset],
  );

  return (
    <PopupTransitionDialog
      open={openState.state}
      onOpenChange={openState.setState}
      dialogPanelClassName="!h-[15rem] !w-[50rem] bg-gray-850 px-20 pt-30 pb-10"
    >
      <div className="flex flex-col gap-5 h-full">
        <span className="uppercase text-xl">create new team</span>
        <form onSubmit={onSubmit} className="flex flex-col h-full">
          <Input
            type="string"
            label="TEAM NAME"
            required
            {...register('teamName', {
              required: { value: true, message: 'Value is required' },
              pattern: {
                value: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
                message: 'Team name must be lowercase letters and -',
              },
              maxLength: {
                value: 50,
                message: 'Team name cannot exceed 50 characters',
              },
            })}
            error={errors?.teamName?.message}
          />
          <div className="flex w-full h-fit mt-8 justify-center items-center">
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                className="w-60"
                disabled={!isValid || isSubmitting}
              >
                submit
              </Button>
            )}
          </div>
        </form>
      </div>
    </PopupTransitionDialog>
  );
}
