import { FilterOperatorType } from '@tensorleap/api-client';
import { useCallback, useMemo, useState } from 'react';
import {
  BTWVisualizationFilter,
  EQVisualizationFilter,
} from '../../../core/types/filters';
import { mapToVisualizationFilters } from '../../../model-tests/modelTestHelpers';
import { DEFAULT_AXIS_SIZE_INTERVAL, GRAPH_STYLE } from '../common/constants';
import { UsePieChartRequest, UsePieChart } from '../common/interfaces';

export function usePieChart({
  chartRequestData,
  localFilters,
  onFiltersChange,
  setIsMouseHover,
  setIsMouseHoverPortion,
  allLabels,
}: UsePieChartRequest): UsePieChart {
  const setLocalFilter = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any): void => {
      const pieChartPayload = data?.['payload']?.['payload'];
      const value = pieChartPayload?.[chartRequestData.xField];
      if (!onFiltersChange || value === undefined) {
        return;
      }
      const updatedFilters = (localFilters || []).map(
        mapToVisualizationFilters,
      );
      if (chartRequestData.dataDistribution === 'distinct') {
        const eqFilter: EQVisualizationFilter = {
          field: chartRequestData.xField,
          operator: FilterOperatorType.Equal,
          value,
        };
        updatedFilters.push(eqFilter);
        onFiltersChange(updatedFilters);
      } else {
        if (isNaN(Number(value))) {
          return;
        }

        const interval = Number(
          chartRequestData.xSizeInterval || DEFAULT_AXIS_SIZE_INTERVAL,
        );
        const gte = Number(value);
        const lt = gte + interval;
        const xBtwFilter: BTWVisualizationFilter = {
          field: chartRequestData.xField,
          operator: FilterOperatorType.Between,
          value: { gte, lt },
        };
        updatedFilters.push(xBtwFilter);
        onFiltersChange(updatedFilters);
      }
    },
    [
      chartRequestData.xField,
      chartRequestData.dataDistribution,
      chartRequestData.xSizeInterval,
      onFiltersChange,
      localFilters,
    ],
  );

  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const handleMouseOver = useCallback(
    (_data: unknown, index: number) => {
      setIsMouseHover(true);
      setIsMouseHoverPortion(true);
      setActiveIndex(index);
    },
    [setIsMouseHover, setIsMouseHoverPortion],
  );
  const handleMouseLeave = useCallback(
    (_data: unknown, _index: number) => {
      setIsMouseHoverPortion(false);
      setActiveIndex(undefined);
    },
    [setIsMouseHoverPortion],
  );
  const handleMouseLeaveChart = useCallback(() => {
    setIsMouseHover(false);
  }, [setIsMouseHover]);

  /***
   * The inner radius symbolizes the radius where the pie starts
   * The inner radius can be set to numbers which is pixel size or like we set it to percentage which will rescale dynamically
   * The multiplier is the total max percent which is 100 percent devided by the amount of inner splits to get seperate the jumps needed to fit all the splits in 100%
   * If index is 0 which is the first split, the inner radius will be the multiplier devided by 4 which is so that there will be an empty circle in the center instead
   * of having it filled all the way.
   */
  const getInnerRadius = useCallback(
    (index: number): `${number}%` => {
      const multiplier = GRAPH_STYLE.pie.maxRadiusPercent / allLabels.length;
      const innerRadius =
        multiplier * index || multiplier / GRAPH_STYLE.pie.innerBlankCircleSize;
      return `${innerRadius}%`;
    },
    [allLabels.length],
  );

  const getOuterRadius = useCallback(
    (index: number): `${number}%` => {
      const multiplier = GRAPH_STYLE.pie.maxRadiusPercent / allLabels.length;
      return `${
        multiplier + multiplier * index - GRAPH_STYLE.pie.cliceSeperatingPixels
      }%`;
    },
    [allLabels.length],
  );

  return useMemo(
    () => ({
      onClick: setLocalFilter,
      activeIndex,
      handleMouseOver,
      handleMouseLeave,
      handleMouseLeaveChart,
      getInnerRadius,
      getOuterRadius,
    }),
    [
      activeIndex,
      getInnerRadius,
      getOuterRadius,
      handleMouseLeave,
      handleMouseLeaveChart,
      handleMouseOver,
      setLocalFilter,
    ],
  );
}
