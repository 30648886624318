import { Label } from 'recharts';
import {
  TICK_LINE_SIZE,
  AXIS_LINE_STROKE_COLOR,
  valueFormattingFunction,
} from '../../chart.utils';
import {
  AxisDomain,
  ScaleType as ScaleTypeRecharts,
} from 'recharts/types/util/types';
import {
  CHART_LABEL_TRUNCATE_LENGTH,
  GRAPH_STYLE,
} from '../../common/constants';
import { useMemo } from 'react';
import { Props as YAxisProps } from 'recharts/types/cartesian/YAxis';
import { getLabelFontSize } from '../../hooks/utils';
import { truncateLongtail } from '../../../../core/formatters/string-formatting';
import { scaleExp, scaleLog, ScaleType } from './scale';

export interface YAxisParams {
  displayName: string;
  domain?: AxisDomain;
  scale?: ScaleType;
  showLabel?: boolean;
}

export function useYAxisProps({
  displayName,
  domain,
  scale,
  showLabel = true,
}: YAxisParams): YAxisProps {
  const fontSize = useMemo(() => getLabelFontSize(displayName), [displayName]);
  return useMemo(
    (): YAxisProps => ({
      domain,
      label: showLabel ? (
        <Label
          dx={-GRAPH_STYLE.yAxis.label.dx}
          fill={GRAPH_STYLE.gray500}
          textAnchor="middle"
          className="uppercase"
          angle={GRAPH_STYLE.yAxis.label.angle}
          fontSize={fontSize}
        >
          {truncateLongtail({
            value: displayName,
            startSubsetLength: CHART_LABEL_TRUNCATE_LENGTH,
          })}
        </Label>
      ) : undefined,

      tickSize: TICK_LINE_SIZE,
      tickFormatter: valueFormattingFunction,
      tick: GRAPH_STYLE.yAxis.tick,
      stroke: AXIS_LINE_STROKE_COLOR,
      scale: mapScaleType(scale),
    }),
    [displayName, domain, fontSize, showLabel, scale],
  );
}

function mapScaleType(
  scale: ScaleType | undefined,
): ScaleTypeRecharts | ((x: number) => number) {
  switch (scale) {
    case 'log':
      return scaleLog();
    case 'exp':
      return scaleExp();
    case 'linear':
    default:
      return 'linear';
  }
}
