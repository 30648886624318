import {
  Issue,
  IssueActionType,
  UpdateIssueParams,
} from '@tensorleap/api-client';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import { Chip } from '../ui/atoms/Chip';
import { getConstColorByString } from '../ui/molecules/colorTemplates';
import Plus from '../ui/icons/Plus';
import { Input } from '../ui/atoms/Input';
import { IconButton } from '../ui/mui';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { last } from 'lodash';

const ONE_MINUTE = 60 * 1000;

export type IssueTagsProps = {
  issue: Issue | undefined;
  isEditable?: boolean;
  updateIssue: (updateIssueParams: UpdateIssueParams) => Promise<void>;
};

export function IssueTags({
  issue,
  isEditable = false,
  updateIssue,
}: IssueTagsProps): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const { user } = useAuth();
  const [addTagTextBoxVisible, setAddTagTextBoxVisible] = useState(false);
  const tagInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAddTagTextBoxVisible(false);
  }, [isEditable]);

  const addNewTag = useCallback(
    async (newTag: string) => {
      if (!issue || !newTag || issue.tags.includes(newTag)) {
        return;
      }
      const lastActivity = last(issue.activities);
      const updatedActivities = [...issue.activities];

      if (
        lastActivity &&
        lastActivity.createdBy === user?.cid &&
        lastActivity.actionType === IssueActionType.TagsAdded &&
        new Date(lastActivity.updatedAt.getTime() + ONE_MINUTE) >= new Date()
      ) {
        updatedActivities[
          issue.activities.length - 1
        ]?.action?.tagsAdded?.tags.push(newTag);
      } else {
        updatedActivities.push({
          createdAt: new Date(),
          updatedAt: new Date(),
          createdBy: user?.cid || '',
          actionType: IssueActionType.TagsAdded,
          action: { tagsAdded: { tags: [newTag] } },
        });
      }
      issue.tags.push(newTag);
      await updateIssue({
        cid: issue.cid,
        projectId,
        tags: issue.tags,
        activities: updatedActivities,
      });
    },
    [issue, projectId, updateIssue, user?.cid],
  );

  const removeTag = useCallback(
    async (removeTag: string) => {
      if (!issue || !removeTag || !issue.tags.includes(removeTag)) {
        return;
      }

      const updateTags = issue.tags.filter((x) => x != removeTag);
      await updateIssue({
        cid: issue.cid,
        projectId,
        tags: updateTags,
      });
    },
    [issue, projectId, updateIssue],
  );

  useEffect(() => {
    if (addTagTextBoxVisible) {
      tagInputRef.current?.focus();
    }
  }, [addTagTextBoxVisible, tagInputRef]);

  return (
    <div className="flex flex-wrap justify-start gap-2 items-start h-fit w-full">
      {issue?.tags.map((tag, index) => (
        <Chip
          className={clsx('text-lg capitalize', getConstColorByString(tag))}
          key={index}
          onRemove={isEditable ? () => removeTag(tag) : undefined}
        >
          {tag}
        </Chip>
      ))}
      {isEditable &&
        (addTagTextBoxVisible ? (
          <Input
            className="h-fit w-40"
            label=""
            onChange={() => undefined}
            ref={tagInputRef}
            onBlur={(e) => {
              addNewTag(e.target.value);
              setAddTagTextBoxVisible(false);
            }}
          />
        ) : (
          <IconButton onClick={() => setAddTagTextBoxVisible(true)}>
            <Plus className="w-4 h-4" />
          </IconButton>
        ))}
    </div>
  );
}
