import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMergedObject<T extends Record<string, any>>(args: T): T {
  return useMemo<T>(
    () => args,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(args).map((k) => args[k]),
  );
}
