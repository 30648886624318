import { ConfusionMatrixTypeEnum } from '../form/ConfusionMatrix';
import { BalancedAccuracyVis } from './BalancedAccuracyVis';
import { ConfusionMatrixByLabelVis } from './ConfusionMatrixCombinationsTableVis';
import { ConfusionMatrixTableVis } from './ConfusionMatrixTableVis';
import { F1Vis } from './F1Vis';
import { MapVis } from './MapVis';
import { PrCurveVis } from './PrCurveVis';
import { RocVis } from './RocVis';
import { XYChartVizProps } from './interfaces';

const CONFUSION_MATRIX_VIS_MAP: Record<
  ConfusionMatrixTypeEnum,
  (p: XYChartVizProps) => JSX.Element
> = {
  [ConfusionMatrixTypeEnum.F1]: F1Vis,
  [ConfusionMatrixTypeEnum.BalancedAccuracy]: BalancedAccuracyVis,
  [ConfusionMatrixTypeEnum.PrCurve]: PrCurveVis,
  [ConfusionMatrixTypeEnum.Roc]: RocVis,
  [ConfusionMatrixTypeEnum.ConfusionMatrixTable]: ConfusionMatrixTableVis,
  [ConfusionMatrixTypeEnum.MeanAveragePrecision]: MapVis,
  [ConfusionMatrixTypeEnum.ConfusionMatrixByLabelVis]:
    ConfusionMatrixByLabelVis,
};

export function ConfusionMatrixVis({
  className,
  filters,
  localFilters,
  graphParams,
  sessionRuns,
  onFiltersChange,
  chartType,
  projectId,
}: XYChartVizProps) {
  const ConfusionMatrixTypeVis = CONFUSION_MATRIX_VIS_MAP[graphParams.type];

  return ConfusionMatrixTypeVis ? (
    <ConfusionMatrixTypeVis
      projectId={projectId}
      className={className}
      filters={filters}
      localFilters={localFilters}
      graphParams={graphParams}
      sessionRuns={sessionRuns}
      onFiltersChange={onFiltersChange}
      chartType={chartType}
    />
  ) : (
    <div>Unsupported confusion matrix type</div>
  );
}
