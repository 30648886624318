import { GRAPH_STYLE } from '../../common/constants';
import { useMemo } from 'react';
import { Props as ReferenceAreaProps } from 'recharts/types/cartesian/ReferenceArea';

export interface ReferenceAreaParams {
  dragStart?: number | string;
  dragEnd?: number | string;
}

export function useReferenceAreaProps({
  dragStart,
  dragEnd,
}: ReferenceAreaParams): ReferenceAreaProps | undefined {
  return useMemo(
    (): ReferenceAreaProps | undefined =>
      dragStart !== undefined && dragEnd !== undefined
        ? {
            x1: dragStart,
            x2: dragEnd,
            strokeOpacity: GRAPH_STYLE.dragStrokeOpacity,
          }
        : undefined,
    [dragEnd, dragStart],
  );
}
