import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDepthIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 3.672V6.5a.75.75 0 0 0 1.5 0V3.282a1.25 1.25 0 0 0-1.774-1.135l-7 3.232a1.25 1.25 0 0 0-.726 1.134v14.132a1.25 1.25 0 0 0 1.863 1.09l4.255-2.394a.75.75 0 0 0-.736-1.307L5.75 20.218V6.673l6.5-3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 6.714V10a.75.75 0 0 0 1.5 0V6.309a1.25 1.25 0 0 0-1.809-1.118l-5 2.5a1.25 1.25 0 0 0-.691 1.118v10.32a1.25 1.25 0 0 0 1.88 1.08l2.998-1.749a.75.75 0 1 0-.756-1.295l-2.622 1.53V8.963l4.5-2.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.25 9.714-3.5 1.75v6.422l3.5-1.75V9.714Zm-.309-1.523a1.25 1.25 0 0 1 1.809 1.118v6.982c0 .473-.267.906-.691 1.118l-4 2a1.25 1.25 0 0 1-1.809-1.118v-6.982c0-.473.268-.906.691-1.118l4-2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDepthIcon;
