import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBlocksIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M7.349 8.47H2.076c-.301 0-.556-.107-.764-.32A1.202 1.202 0 0 1 1 7.331v-4.23c0-.308.104-.569.312-.782.208-.213.463-.32.764-.32h5.273c.3 0 .555.107.763.32.208.213.312.474.312.782v4.23c0 .308-.104.58-.312.817-.208.214-.462.32-.763.32Zm-4.788-1.6h4.337V3.564H2.561V6.87Zm14.363 1.6h-5.273c-.3 0-.555-.107-.763-.32a1.202 1.202 0 0 1-.313-.818v-4.23c0-.308.105-.569.313-.782.208-.213.462-.32.763-.32h5.273c.301 0 .556.107.764.32.208.213.312.474.312.782v4.23c0 .308-.104.58-.312.817-.208.214-.463.32-.764.32Zm-4.822-1.6h4.337V3.564h-4.337V6.87ZM7.349 17H2.076c-.301 0-.556-.107-.764-.32A1.077 1.077 0 0 1 1 15.898v-4.23c0-.308.104-.568.312-.782a.978.978 0 0 1 .764-.355h5.273c.3 0 .555.118.763.355.208.214.312.474.312.782v4.23c0 .308-.104.569-.312.782-.208.213-.462.32-.763.32Zm-4.788-1.564h4.337V12.13H2.561v3.306ZM16.924 17h-5.273c-.3 0-.555-.107-.763-.32a1.077 1.077 0 0 1-.313-.782v-4.23c0-.308.105-.568.313-.782a.978.978 0 0 1 .763-.355h5.273c.301 0 .556.118.764.355.208.214.312.474.312.782v4.23c0 .308-.104.569-.312.782-.208.213-.463.32-.764.32Zm-4.822-1.564h4.337V12.13h-4.337v3.306Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBlocksIcon;
