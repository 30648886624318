import { PropsWithoutRef } from 'react';
import { UploadIcon } from '../../ui/icons';
import { useCallback } from 'react';
import { useFileSelection } from '../../core/useFileSelection';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button } from '../../ui/atoms/Button';
import clsx from 'clsx';
import { TextArea } from '../../ui/atoms/TextArea';

export type SecretEditorBoxProps = PropsWithoutRef<{
  setValue: (value: string) => void;
  value: string;
}>;

export function SecretEditorBox({
  value,
  setValue,
}: SecretEditorBoxProps): JSX.Element {
  const onFileChange = useCallback(
    async (files: File[]) => {
      if (files.length) {
        setValue(await files[0].text());
      }
    },
    [setValue],
  );
  const { openFileSelection } = useFileSelection({ onFileChange });

  const handleSetValueClick = useCallback(
    (e) => setValue(e.target.value),
    [setValue],
  );

  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: ({ files }: { files: FileList }) => {
        onFileChange(Array.from(files));
      },
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [onFileChange],
  );
  const isActive = canDrop && isOver;

  return (
    <div className="h-full w-full">
      <div
        className="border-6 border-dashed rounded-3xl h-full w-full flex flex-col border-gray-700"
        ref={dropRef}
      >
        <div className="relative flex flex-col h-[calc(100%)] w-[calc(100%-5rem)] ml-10 mr-10">
          <div className="flex flex-col h-full w-full justify-center items-center">
            <p className="w-full mt-3 mb-3 align-bottom text-center items-center justify-center">
              Drag your Secret Key File, or Copy & Paste its content here:
            </p>
            <div className="flex flex-col h-full w-full justify-center items-center relative">
              {isActive && (
                <div className="flex flex-col h-full w-full bg-opacity-50 z-20 absolute"></div>
              )}
              <TextArea
                outlineClasses={clsx(
                  'flex flex-col h-full w-full bg-gray-600 bg-opacity-50 z-10 absolute',
                  {
                    'bg-opacity-25': isActive,
                    'bg-opacity-50': !isActive,
                  },
                )}
                className="flex flex-col h-full w-full"
                label=""
                onChange={handleSetValueClick}
                value={value}
              />
              <div className="flex flex-col h-full w-full justify-center items-center z-0 absolute">
                <UploadIcon
                  className={clsx('h-1/3 w-1/3', {
                    'text-gray-700': !isActive,
                    'text-white': isActive,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="h-auto w-full flex flex-col justify-center items-center align-center mb-3 mt-3">
            <Button
              variant="filled"
              onClick={(e) => {
                e.preventDefault();
                openFileSelection();
              }}
              className={clsx('w-28 h-6 pr-0 pl-0 ', {
                'opacity-50': isActive,
                'opacity-1': !isActive,
              })}
            >
              <p className="uppercase w-full font-normal text-xs mx-auto z-0">
                upload file
              </p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
