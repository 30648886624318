import { useMemo } from 'react';
import {
  OptionDefaultObj,
  OptionType,
  OptionValueFromOption,
} from './utils/select';
import { Button } from './Button';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

export type SelectDialogProps<Option extends OptionType> = {
  isOpen: boolean;
  title: string;
  options: Option[];
  onClose: (value: OptionValueFromOption<Option> | null) => void;
};

export function SelectDialog<Option extends OptionType>({
  isOpen,
  title,
  options,
  onClose,
}: SelectDialogProps<Option>) {
  const optionWithValueAndName = useMemo<OptionDefaultObj[]>(
    () =>
      options.map((option) =>
        typeof option === 'object'
          ? (option as OptionDefaultObj)
          : ({
              value: option,
              label: String(option),
            } as OptionDefaultObj),
      ),
    [options],
  );

  return (
    <Dialog open={isOpen} onClose={() => onClose(null)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className="flex flex-col gap-2">
          {optionWithValueAndName.map(({ value, label }) => (
            <Button
              key={String(value)}
              variant="inverted-gray"
              onClick={() => onClose(value as OptionValueFromOption<Option>)}
            >
              {label}
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
