import { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '../ui/mui';
import { MenuItemData } from './LeftMenu';
import { Forward } from '../ui/icons';
import { Project } from '@tensorleap/api-client';
import {
  usePopupState,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state/hooks';
import { buildProjectUrl } from '../url/url-builder';
import { useFetchProjects } from '../core/data-fetching/projects';
import { useCurrentProject } from '../core/CurrentProjectContext';

export function LoadProjectItem({
  title,
  icon,
  hasDivider,
}: MenuItemData & { hasDivider: boolean }): JSX.Element {
  const history = useHistory();
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: null,
    disableAutoFocus: true,
  });

  const { projects } = useFetchProjects();

  const { currentProjectId } = useCurrentProject();

  const onLoadProject = useCallback(
    (project: Project) => {
      if (project.cid !== currentProjectId) {
        history.push(buildProjectUrl(project.cid));
      }
      popoverState.close();
    },
    [currentProjectId, history, popoverState],
  );

  return (
    <>
      <ListItem {...bindTrigger(popoverState)} component="li" button>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        <ListItemIcon className="pl-8">
          <Forward />
        </ListItemIcon>
      </ListItem>
      {hasDivider && <Divider />}
      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: 'bg-gray-900 m-1 w-64' }}
      >
        <div onMouseLeave={popoverState.close} className="m-1">
          <List>
            {projects?.length ? (
              projects.map((p, index) => (
                <Fragment key={index}>
                  <ListItem
                    component="li"
                    button
                    onClick={() => onLoadProject(p)}
                  >
                    <h6 className="font-semibold text-lg tracking-normal">
                      {p.name}
                    </h6>
                  </ListItem>
                  {index < projects.length - 1 && <Divider />}
                </Fragment>
              ))
            ) : (
              <ListItem component="li">
                <h6 className="font-semibold text-lg tracking-normal">
                  No added projects
                </h6>
              </ListItem>
            )}
          </List>
        </div>
      </Popover>
    </>
  );
}
