import { useCallback, useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import clsx from 'clsx';
import { NetworkEditor } from './NetworkEditor';
import { NodeDetails } from '../layer-details/NodeDetails';
import { NetworkEditorTools } from './NetworkEditorTools';
import { NetworkDrawer, NetworkTabsEnum } from './NetworkDrawer';
import { CodeIntegration } from '../layer-details/CodeIntegration';
import { NoNodeSelected } from '../layer-details/NoNodeSelected';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useVersionControl } from '../core/VersionControlContext';
import { ConfirmDialog as ConfirmValidateAssetsDialog } from '../ui/atoms/DeleteContentDialog';
import { ViDoneIcon } from '../ui/icons';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useDatasets } from '../core/DatasetsContext';
import { usePanZoom } from './PanZoom';
import { NetworkWizardCategories } from './wizard/NetworkWizardCategories';

export function NetworkEditorPane(): JSX.Element {
  const {
    selectedNodeId,
    nodes,
    validateAssetsButtonState,
    confirmValidateAssetsDialogIsOpen,
    setConfirmValidateAssetsDialogIsOpen,
    handleValidateAssetsClicked,
    openNetworkTab,
    setOpenNetworkTab,
    setPanZoom,
  } = useNetworkMapContext();

  const panZoom = usePanZoom();
  const {
    panZoomParams,
    panZoomParamsRef,
    onFitMapToScreen,
    onFitNodeToScreen,
  } = panZoom;

  useEffect(() => {
    setPanZoom(panZoom);
  }, [setPanZoom, panZoom]);

  const {
    currentVersion,
    selectedCodeIntegrationVersion,
    isModelLayersGrouped,
  } = useCurrentProject();

  const { versions, fetchVersions } = useVersionControl();
  const currentSlimVersion = useMemo(
    () => versions.find(({ cid }) => cid === currentVersion?.cid),
    [currentVersion?.cid, versions],
  );

  const handleDrawerClose = useCallback(() => {
    setOpenNetworkTab(undefined);
  }, [setOpenNetworkTab]);

  const nodeDetails = useMemo(() => {
    const selectedNode = nodes.get(selectedNodeId || '');
    if (!selectedNode) return <NoNodeSelected onClose={handleDrawerClose} />;

    return <NodeDetails onClose={handleDrawerClose} node={selectedNode} />;
  }, [nodes, selectedNodeId, handleDrawerClose]);

  useEffect(() => {
    if (selectedNodeId) {
      setOpenNetworkTab(NetworkTabsEnum.NodeDetails);
    }
  }, [selectedNodeId, setOpenNetworkTab]);

  const { changeDatasetContext, dataset } = useDatasets();

  useEffect(() => {
    if (!selectedCodeIntegrationVersion) {
      changeDatasetContext(undefined, undefined);
    }
    if (
      selectedCodeIntegrationVersion &&
      selectedCodeIntegrationVersion.datasetId !== dataset?.cid
    )
      changeDatasetContext(
        selectedCodeIntegrationVersion.datasetId,
        selectedCodeIntegrationVersion,
      );
  }, [changeDatasetContext, dataset, selectedCodeIntegrationVersion]);

  return (
    <div
      className={clsx(
        'relative overflow-hidden flex-1 box-border bg-network-950',
      )}
    >
      <NetworkEditorTools
        fitToScreen={onFitMapToScreen}
        isModelLayersGrouped={isModelLayersGrouped}
        currentSlimVersion={currentSlimVersion}
        fetchVersions={fetchVersions}
      />
      <div
        className={clsx(
          'z-50 flex flex-col items-end absolute right-0 top-4',
          openNetworkTab ? 'right-[21rem]' : 'right-4',
        )}
      >
        <NetworkWizardCategories />
      </div>
      <NetworkDrawer
        nodeDetailsContent={nodeDetails}
        codeIntegrationContent={<CodeIntegration onClose={handleDrawerClose} />}
      />
      <DndProvider backend={HTML5Backend}>
        <NetworkEditor
          panZoomParams={panZoomParams}
          panZoomParamsRef={panZoomParamsRef}
          onFitNodeToScreen={onFitNodeToScreen}
          onFitMapToScreen={onFitMapToScreen}
        />
      </DndProvider>
      <ConfirmValidateAssetsDialog
        title={validateAssetsButtonState.confirmDialogMsg || ''}
        isOpen={confirmValidateAssetsDialogIsOpen}
        onClose={() => {
          setConfirmValidateAssetsDialogIsOpen(false);
        }}
        onConfirm={() => handleValidateAssetsClicked(true)}
        confirmButtonText="Yes, revalidate assets"
        confirmButtonIcon={<ViDoneIcon />}
        confirmButtonColor="none"
        cancelButtonColor="blue"
      />
    </div>
  );
}
