import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  COMPONENT_DESCRIPTORS_MAP,
  Property,
} from '../network-editor/interfaces';
import { Empty, NodeDetailsPanel } from './NodeDetalisPanel';
import { DetailsProps } from './NodeDetails';
import { Settings } from '../ui/icons';
import clsx from 'clsx';
import { NodePropertyType } from '../network-editor/networkStateUtils';
import { Select } from '../ui/atoms/Select';
import { Input } from '../ui/atoms/Input';
import { useNetworkMapContext } from '../core/NetworkMapContext';

const NUMBERS_WITH_COMMA_REGEX = new RegExp(/^[0-9]*(,[0-9]+)*$/);

type PropertyType =
  | 'int'
  | 'float'
  | 'str'
  | 'select'
  | 'bool'
  | 'tuple'
  | 'NoneType';

const PROPERTY_DESCRIPTORS: Record<
  PropertyType,
  ComponentType<NodePropertyParams>
> = {
  int: NumberProperty,
  float: NumberProperty,
  str: () => null,
  select: SelectProperty,
  bool: BooleanProperty,
  tuple: TupleProperty,
  NoneType: () => null,
};

export function LayerProperties({ node }: DetailsProps): JSX.Element {
  const { changeNodeProperty } = useNetworkMapContext();

  const propertiesDescriptors = useMemo(
    () =>
      node?.name
        ? COMPONENT_DESCRIPTORS_MAP.get(node.name)?.properties.map((prop) => {
            return {
              property: prop,
              Component: PROPERTY_DESCRIPTORS[prop.type as PropertyType],
            };
          })
        : undefined,
    [node?.name],
  );

  const hasProps = !!propertiesDescriptors?.length;

  const handlePropChange = useCallback(
    (key: string, value: NodePropertyType) => {
      if (node?.id) {
        changeNodeProperty({
          nodeId: node.id,
          nodeDataPropsToUpdate: { [key]: value },
        });
      }
    },
    [node?.id, changeNodeProperty],
  );
  const originName = node?.data.origin_name;
  return (
    <NodeDetailsPanel
      key={node.id}
      openByDefault
      title="Properties"
      className={clsx('space-y-6 overflow-auto', hasProps && 'p-4 ')}
      icon={<Settings />}
    >
      {hasProps ? (
        <>
          {propertiesDescriptors?.map(({ property, Component }) => (
            <Component
              key={property.name}
              data={node.data}
              property={property}
              onChange={handlePropChange}
            />
          ))}
          {originName && (
            <Input label="name" value={originName} readOnly={true} />
          )}
        </>
      ) : (
        <Empty title="No Properties" />
      )}
    </NodeDetailsPanel>
  );
}

interface NodePropertyParams {
  data: Record<string, NodePropertyType>;
  property: Property;
  onChange: (key: string, value: NodePropertyType) => void;
}

function NumberProperty({
  data,
  property,
  onChange,
}: NodePropertyParams): JSX.Element {
  const { name, default_val } = property;

  const numberPropertyValue = useMemo(
    () => data[name]?.toString() || default_val?.toString() || '',
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data[name]],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue =
      property.type === 'int'
        ? event.target.value.split('.')[0]
        : event.target.value;
    onChange(property.name, Number(changedValue));
  };

  return (
    <Input
      onChange={handleChange}
      type="number"
      label={name}
      value={numberPropertyValue}
    />
  );
}

function TupleProperty({
  data,
  property,
  onChange,
}: NodePropertyParams): JSX.Element {
  const { name, default_val } = property;
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(data[name]?.toString() || default_val?.toString() || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value.replace(/[^\d,]+/g, '');
    setValue(changedValue);
    if (changedValue && NUMBERS_WITH_COMMA_REGEX.test(changedValue)) {
      const parsedValue = changedValue.includes(',')
        ? changedValue.split(',').map(Number)
        : Number(changedValue);
      onChange(property.name, parsedValue);
    }
  };

  return <Input onChange={handleChange} label={name} value={value} />;
}

function BooleanProperty({
  data,
  property,
  onChange,
}: NodePropertyParams): JSX.Element {
  const { name, default_val } = property;
  const [value, setValue] = useState('False');

  useEffect(() => {
    const isTrue = data[name] != undefined ? data[name] : default_val;
    setValue(isTrue ? 'True' : 'False');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const handleChange = (value = 'False') => {
    setValue(value);
    onChange(property.name, value === 'True');
  };

  const options = useMemo(() => ['True', 'False'], []);

  return (
    <Select
      label={name}
      value={value}
      onChange={handleChange}
      options={options}
    />
  );
}

function SelectProperty({
  data,
  property,
  onChange,
}: NodePropertyParams): JSX.Element {
  const { values, name, default_val } = property;

  const handleChange = (changedValue?: string) => {
    onChange(property.name, changedValue);
  };

  return (
    <Select
      label={name}
      value={(data[name] || default_val || undefined) as string}
      onChange={handleChange}
      options={values || []}
    />
  );
}
