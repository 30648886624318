import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgApplyTestFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M8.306 5.667h13.36v1.442l-.151.152-4.707 5.92v4.328l-.227.19-2.43 1.821-.986.721v-7.06l-4.707-5.92-.152-.152V5.667ZM9.673 6.88l4.403 5.466h1.821L20.3 6.88H9.673Zm4.706 6.68v4.251l1.215-.91v-3.34h-1.215Z"
      fill="currentColor"
    />
    <path
      d="m5.78 15.194 2.24-2.24H1.667v-1.249H8.02l-2.24-2.24.884-.883 3.747 3.748-3.747 3.747-.884-.883Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgApplyTestFilter;
