import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M11.883 6.688v-5h1.68v1.68h3.32v1.64h-3.32v1.68h-1.68Zm5 3.32h-8.32v-1.64h8.32v1.64Zm-11.68-3.32h1.68v5h-1.68v-1.68h-3.32v-1.64h3.32v-1.68Zm5 10h-1.64v-5h1.64v1.68h6.68v1.64h-6.68v1.68Zm-8.32-13.32h8.32v1.64h-8.32v-1.64Zm0 10h5v1.64h-5v-1.64Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettings;
