import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, memo, useCallback } from 'react';
import { Up } from '../../icons';
import { TableColumn } from './table-types';
import { TableRowActions, RowActions } from './TableRowActions';
import { TableExpanderPosition, calcNumColumns } from './Table';
import { BaseItem } from '../types';

export type ExpendRowComp<Item> = ComponentType<{ item: Item }>;

type TableRowProps<Item> = {
  columns: TableColumn<Item>[];
  item: Item;
  onClick?: (item: Item) => void;
  onToggleExtend?: (item: Item) => void;
  expanded?: boolean;
  expanderPosition?: TableExpanderPosition;
  selected?: boolean;
  borderBottomClass?: string;
  hideBottomBorder: boolean;
  bgClass?: string;
  ExpendComp?: ExpendRowComp<Item>;
} & RowActions<Item>;

function _TableRow<Item extends BaseItem>({
  columns,
  item,
  onClick,
  onToggleExtend,
  expanded,
  selected,
  ExpendComp,
  expanderPosition = 'left',
  hideBottomBorder,
  borderBottomClass = 'border-b-gray-800',
  menuActions,
  actionPosition = 'end',
  hoverActionsSnapToClass,
  hoverActions,
  bgClass,
  customActions,
}: TableRowProps<Item>): JSX.Element {
  const isExpandable = !!ExpendComp && expanded !== undefined;
  const hasActions = !!menuActions || !!customActions || !!hoverActions;
  const columnsLength = calcNumColumns({
    columns,
    isExpandable,
    expanderPosition,
    menuActions,
    hoverActions,
  });

  const handleExpandClick = useCallback(() => {
    onToggleExtend?.(item);
  }, [item, onToggleExtend]);
  if (expanderPosition === 'hoverAction' && !!hoverActions) {
    hoverActions = [
      ...hoverActions,
      {
        title: 'Expand',
        icon: (
          <Up className={clsx(!expanded && '-scale-100', 'transition-all')} />
        ),
        onSelect: handleExpandClick,
      },
    ];
  } else if (expanderPosition === 'menuAction' && !!menuActions) {
    menuActions = [
      ...menuActions,
      {
        title: 'Expand',
        icon: (
          <Up className={clsx(!expanded && '-scale-100', 'transition-all')} />
        ),
        onSelect: handleExpandClick,
      },
    ];
  }

  const tdActions = hasActions && (
    <td>
      <TableRowActions
        {...{
          item,
          menuActions,
          hoverActions,
          customActions,
          actionPosition,
          hoverActionsSnapToClass,
        }}
      />
    </td>
  );

  return (
    <>
      <tr
        onClick={() => onClick?.(item)}
        className={clsx(
          'group',
          borderBottomClass,
          'h-14 border-b',
          !bgClass && 'hover:bg-primary-925',
          bgClass ? bgClass : selected && 'bg-primary-950',
          hideBottomBorder && !expanded && 'border-b-0',
          onClick && 'cursor-pointer',
        )}
      >
        {actionPosition === 'start' && tdActions}
        {expanderPosition === 'left' && isExpandable && (
          <td
            onClick={(e) => {
              e.stopPropagation();
              onToggleExtend?.(item);
            }}
            className="text-center"
          >
            <Up className={clsx(!expanded && '-scale-100', 'transition-all')} />
          </td>
        )}
        {columns.map(({ Cell, id }) => (
          <Cell key={id} item={item} />
        ))}
        {actionPosition === 'end' && tdActions}
      </tr>
      {isExpandable && (
        <tr>
          <td colSpan={columnsLength} className="p-0">
            <Collapse in={!!expanded} unmountOnExit>
              <ExpendComp item={item} />
            </Collapse>
          </td>
        </tr>
      )}
    </>
  );
}

export const TableRow = memo(_TableRow) as typeof _TableRow;
