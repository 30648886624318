import {
  FC,
  createContext,
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { LoginParams, UserData } from '@tensorleap/api-client';
import { datadogRum } from '@datadog/browser-rum';

import api, { ClientError } from '../core/api-client';
import useAsyncEffect from '../core/useAsyncEffect';
import { useAuthProvider } from './AuthProvider';

export interface AuthContextInterface {
  user?: UserData;
  login: (_: LoginParams) => Promise<boolean>;
  logout: () => Promise<void>;
  refreshUser: () => Promise<void>;
  error?: ClientError;
  isUserLoggedIn: boolean;
}

export const AuthContext = createContext<AuthContextInterface>({
  login: () => Promise.reject(),
  logout: () => Promise.reject(),
  refreshUser: () => Promise.reject(),
  isUserLoggedIn: false,
});

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<UserData>();
  const [error, setError] = useState<ClientError | undefined>();
  const authProvider = useAuthProvider();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const isUserLoggedIn = useMemo(
    () => !!authProvider.authenticated && isLoggedIn,
    [authProvider.authenticated, isLoggedIn],
  );

  const fetchUser = useCallback(async () => {
    try {
      if (!isUserLoggedIn) return;
      const loggedInUser = await api.whoAmI();
      setUser(loggedInUser);
      setError(undefined);
    } catch (e) {
      console.error(e);
      if (e instanceof ClientError) {
        setError(e);
        if (e.status === 401) {
          setUser(undefined);
          setIsLoggedIn(false);
        }
      }
    }
  }, [isUserLoggedIn]);
  useAsyncEffect(fetchUser);

  useEffect(() => {
    if (!user) {
      datadogRum.clearUser();
      return;
    }
    datadogRum.setUser({
      id: user.cid,
      name: user.local.name,
      email: user.local.email,
      team: user.teamName,
    });
  }, [user]);

  const value = useMemo<AuthContextInterface>(
    () => ({
      user,
      login: async ({ email, firstName, lastName, userName }: LoginParams) => {
        try {
          const loggedInUser = await api.login({
            email,
            firstName,
            lastName,
            userName,
          });
          setUser(loggedInUser);
          setIsLoggedIn(true);
          setError(undefined);
          return true;
        } catch (e) {
          console.error(e);
          if (e instanceof ClientError) {
            setError(e);
            if (e.status === 401) {
              setUser(undefined);
            }
          }
          return false;
        }
      },
      logout: async () => {
        try {
          await api.logout();
          await authProvider.logout();

          setUser(undefined);
          setError(undefined);
          setIsLoggedIn(false);
        } catch (e) {
          console.error(e);
          if (e instanceof ClientError) {
            setError(e);
          }
        }
      },
      refreshUser: async () => {
        await fetchUser();
      },
      isUserLoggedIn,
      error,
    }),
    [user, error, setUser, authProvider, fetchUser, isUserLoggedIn, setError],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextInterface => useContext(AuthContext);
