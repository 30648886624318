import { MessageData } from '@tensorleap/api-client';
import { useCallback, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { getApiHost, getBasePrefixURL } from './api-client';
import { useAuthProvider } from '../auth/AuthProvider';
const SERVER_MESSAGE = 'serverMessage';

const WS_PREFIX = document.location.protocol === 'https:' ? 'wss' : 'ws';
export const WS_URL = `${WS_PREFIX}://${getApiHost()}`;

export function useSocketIO() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [lastMessage, setLastMessage] = useState<MessageData | null>(null);
  const { token } = useAuthProvider();

  useEffect(() => {
    if (!token) {
      return;
    }
    let path = '/socket.io';
    const basePath = getBasePrefixURL();
    if (basePath && basePath !== '/') {
      path = basePath + path;
    }
    const socket = io(WS_URL, {
      path,
      withCredentials: true,
      auth: {
        Authorization: `KBearer ${token}`,
      },
    });
    setSocket(socket);

    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      console.warn('socket io disconnected, reason:', reason);
      // else the socket will automatically try to reconnect
    });

    socket.on('connect_error', (err) => {
      console.error('socket io connect_error', err.message, err);
    });

    socket.on('authentication_error', (err) => {
      console.error('socket io authentication_error', err.message);
    });

    socket.on(SERVER_MESSAGE, (message) => {
      setLastMessage(message);
    });

    return () => {
      socket.close();
    };
  }, [token]);

  const sendMessage = useCallback(
    (message) => {
      if (socket) {
        socket.send(message);
      }
    },
    [socket],
  );

  return { sendMessage, lastMessage };
}
