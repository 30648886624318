// based on https://medium.com/nerd-for-tech/how-to-combine-context-providers-for-cleaner-react-code-9ed24f20225e

/* eslint-disable react/display-name */

import { FC } from 'react';

export function composeComponents(...components: FC[]): FC {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }) => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
    ({ children }) => <>{children}</>,
  );
}
