import { Chip } from '../ui/atoms/Chip';
import { Button } from '../ui/atoms/Button';
import { CloudDownload2, SmallFilterFilled } from '../ui/icons';
import { CopyButton } from '../ui/atoms/CopyButton';
import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { formatBytesSizeUnits } from '../core/formatters/bytes-formatting';
import { ClassNameProp, useStateObject } from '../core/types';
import { ProjectManifest, useHubProjectManifests } from './useProjectManifests';
import { ImportProjectDialog } from '../actions-dialog/ProjectDialogs';
import { Title } from '../ui/atoms/Title';
import { first } from 'lodash';
import { Badge } from '@material-ui/core';
import { useOpenState } from '../ui/atoms/utils/useOpenState';
import { CategoriesSelectorPopup } from '../core/category/CategoriesSelectorPopup';
import { CategoryValue } from '../core/category/types';
import { FILTER_PROJECT_CATEGORIES_META } from '../projects/consts';
import { CategoriesChips } from '../core/category/CategoriesChips';
import { search } from '../filters/search';
import { convertCategoriesValueToFilters } from '../filters/utils';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export type HubGalleryProps = {
  projectMetaList: ProjectManifest[];
  onImport: (projectManifest: ProjectManifest) => void;
};

export function HubGalleryContainer({ className }: ClassNameProp) {
  const demoOpenState = useStateObject(false);
  const [importProjectManifest, setImportProjectManifest] =
    useState<ProjectManifest | null>(null);

  const projectMetaList = useHubProjectManifests();
  const [selectedCategories, setSelectedCategories] = useState<CategoryValue>(
    {},
  );
  const { isOpen, close, open } = useOpenState(false);

  const handleOnImport = useCallback(
    (project: ProjectManifest) => {
      demoOpenState.setState(false);
      setImportProjectManifest(project);
    },
    [demoOpenState],
  );

  const filteredProjectMetaList = useMemo(() => {
    const filters = convertCategoriesValueToFilters(
      selectedCategories,
      'categories',
    );
    return search(projectMetaList, filters);
  }, [selectedCategories, projectMetaList]);

  return (
    <div
      className={clsx('flex flex-col', className)}
      id={TOUR_SELECTORS_ENUM.HUB_GALLERY_ID}
    >
      <div className="flex justify-between">
        <Title className="pb-4" bottomBorderClassName="border-b-primary-500">
          DEMO PROJECTS
        </Title>
        <CategoriesSelectorPopup
          open={isOpen}
          onClose={close}
          categoriesMeta={FILTER_PROJECT_CATEGORIES_META}
          value={selectedCategories}
          onChange={setSelectedCategories}
          defaultOpenCategory={first(FILTER_PROJECT_CATEGORIES_META)?.category}
        >
          <Button variant="action" onClick={open} className="p-2">
            Filter
            <SmallFilterFilled className="h-6 w-6" />
          </Button>
        </CategoriesSelectorPopup>
      </div>
      <CategoriesChips
        className="pb-4"
        onChange={setSelectedCategories}
        categoriesMeta={FILTER_PROJECT_CATEGORIES_META}
        value={selectedCategories}
      >
        <Badge
          className="mr-2"
          badgeContent={Object.keys(selectedCategories).length}
          color="primary"
        >
          <SmallFilterFilled className="h-6 w-6" />
        </Badge>
      </CategoriesChips>
      <HubGallery
        onImport={handleOnImport}
        projectMetaList={filteredProjectMetaList}
      />
      {importProjectManifest && (
        <ImportProjectDialog
          isOpen
          projectManifest={importProjectManifest}
          onClose={() => setImportProjectManifest(null)}
        />
      )}
    </div>
  );
}

export function HubGallery({
  projectMetaList,
  onImport,
}: HubGalleryProps): JSX.Element {
  return (
    <div className="overflow-auto">
      <div className="flex flex-col gap-4">
        {projectMetaList.map((projectMeta) => (
          <HubGalleryItem
            onImport={onImport}
            key={projectMeta.name}
            projectMeta={projectMeta}
          />
        ))}
      </div>
    </div>
  );
}

type HubGalleryItemProps = {
  projectMeta: ProjectManifest;
  onImport: (projectMeta: ProjectManifest) => void;
};

export function HubGalleryItem({
  projectMeta,
  onImport,
}: HubGalleryItemProps): JSX.Element {
  const { name, title, description, size, bgImageSrc, tags, upgradeRequired } =
    projectMeta;

  const handleOnImport = useCallback(() => {
    onImport(projectMeta);
  }, [onImport, projectMeta]);

  const formattedSize = formatBytesSizeUnits(size);

  return (
    <div className="group relative flex h-64 w-full border border-gray-700 overflow-hidden rounded-xl">
      <div className=" bg-gray-800 p-4 max-h-full overflow-hidden flex flex-1 flex-col gap-3">
        <div
          className={clsx(
            'flex flex-1 flex-col max-h-full',
            upgradeRequired && 'opacity-50',
          )}
        >
          <div className="flex font-bold">{title || name}</div>
          <div className="flex-1 overflow-auto">{description}</div>
          <Tags tags={tags} />
        </div>
        {upgradeRequired && <UpdateRequired />}
      </div>
      <div
        style={{ backgroundImage: `url("${bgImageSrc}")` }}
        className="w-36 py-4 flex items-end justify-start bg-center bg-cover bg-no-repeat"
      >
        {!upgradeRequired && (
          <ImportButton
            onImport={handleOnImport}
            formattedSize={formattedSize}
          />
        )}
      </div>
    </div>
  );
}

type TagsProps = { tags: string[] };
function Tags({ tags }: TagsProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {tags.map((tag) => (
        <Chip
          borderClassName=" text-base bg-gray-700 border border-gray-600"
          key={tag}
        >
          {tag}
        </Chip>
      ))}
    </div>
  );
}

type ImportButtonProps = {
  onImport: () => void;
  formattedSize: string;
};
function ImportButton({ onImport, formattedSize }: ImportButtonProps) {
  return (
    <div className="group-hover:flex -ml-4 hidden">
      <Button type="button" onClick={onImport} className="flex h-10">
        <div className="flex flex-col items-start">
          IMPORT
          <span className="font-normal text-xs">{formattedSize}</span>
        </div>
        <CloudDownload2 />
      </Button>
    </div>
  );
}

const UPGRADE_COMMAND = `bash <(curl -s https://helm.tensorflow.ai)`;
function UpdateRequired() {
  return (
    <div className="hidden absolute p-4 group-hover:flex inset-0 bg-black/50 gap-2 flex-col justify-end">
      <span>UPGRADE REQUIRED</span>
      <p>
        Please upgrade your Tensorleap version to use this demo. Click the
        ‘Copy’ button to copy the CMD command, and run it on your terminal.
      </p>
      <div className="border bg-black/50 border-white rounded flex">
        <CopyButton textToCopy={UPGRADE_COMMAND} />
        <code className="py-2 text-gray-200 block truncate">
          {UPGRADE_COMMAND}
        </code>
      </div>
    </div>
  );
}
