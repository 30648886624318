import { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import {
  AnalyticsDashletData,
  AnalyticsDashletType,
} from '@tensorleap/api-client';
import { DashletFormHeader, useGraphMetaMap } from './FormFields';
import { DashletFormLeftMenu } from './LeftMenu';
import { CircularProgress, Divider } from '../../../../ui/mui';
import { DashletFormContent } from '../DashletFormContent';
import { Input } from '../../../../ui/atoms/Input';
import { Controller, useForm } from 'react-hook-form';
import { useDebounce } from '../../../../core/useDebounce';
import { UnifyGraphParams } from '../types';
import { stopPropagation } from '../../../../core/stopPropagation';
import { DashletFields } from '../../../../core/data-fetching/dashlet-fields';

export interface MainDashletFormProps {
  value: AnalyticsDashletData;
  apply: (_: AnalyticsDashletData) => void;
  cancel: () => void;
  formOptions: DashletFields | undefined;
  metricNames?: string[];
  className?: string;
}

export function MainDashletForm({
  value,
  className,
  apply,
  formOptions,
  metricNames,
  cancel,
}: MainDashletFormProps): JSX.Element {
  useEffect(() => {
    handleSubmit(submit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { graphMetaMap } = useGraphMetaMap();

  const submit = useCallback(
    (val: AnalyticsDashletData) => {
      if (!val.type || !val.data) return;
      const { extractor, validator } = graphMetaMap[val.type];
      const data = extractor(val.data as UnifyGraphParams);
      const missingFields = validator(data);
      if (missingFields) {
        console.error(
          'Form validation failed',
          data,
          'Missing fields:',
          missingFields,
        );
        return;
      }
      apply({ ...val, data });
    },
    [apply, graphMetaMap],
  );

  const debounceApply = useDebounce(submit, 200);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const firstValue = useMemo(() => JSON.parse(JSON.stringify(value)), []);

  const form = useForm<AnalyticsDashletData>({
    mode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: value as any,
  });

  const {
    register,
    control,
    watch,
    reset: _reset,
    handleSubmit,
    formState: { errors },
  } = form;

  const reset = useCallback(
    () => _reset(firstValue as AnalyticsDashletData),
    [_reset, firstValue],
  );

  const graphType = watch('type');

  useEffect(() => {
    const subscription = watch(() => handleSubmit(debounceApply)());
    return () => subscription.unsubscribe();
  }, [watch, debounceApply, handleSubmit]);

  return (
    <form
      className={clsx(
        'flex flex-row bg-gray-800 min-w-[24rem] rounded-l-2xl max-w-2xl',
        className,
      )}
      onMouseDown={stopPropagation}
      onSubmit={handleSubmit(submit)}
    >
      <Controller
        control={control}
        name="type"
        defaultValue={AnalyticsDashletType.Line}
        render={({ field: { value, onChange } }) => (
          <DashletFormLeftMenu value={value} onChange={onChange} />
        )}
      />

      <div className="flex overflow-hidden flex-1 flex-col py-1 px-6 border-l border-l-gray-700">
        <div className="flex flex-col gap-4">
          <DashletFormHeader
            graphType={graphType}
            reset={reset}
            cancel={cancel}
          />

          <Input
            label="DASHLET NAME"
            {...register('name')}
            error={errors.name?.message}
          />

          <Divider />
        </div>
        <div className="flex-1 py-4 w-full flex flex-col space-y-4 overflow-auto">
          {formOptions && metricNames ? (
            <DashletFormContent
              form={form}
              graphType={graphType}
              fields={formOptions}
              metricNames={metricNames}
            />
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </form>
  );
}
