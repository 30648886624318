import {
  GridColumnVisibilityModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridSortModel,
  GridToolbarExport,
  DataGridProProps,
  DataGridPro,
  GridPinnedColumns,
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Reset } from '../icons';

const enum LocalStorageKeyTypes {
  COLUMN_VISIBILITY = 'columnVisibility',
  SORT = 'sort',
  PINNING_MODEL = 'pinningModel',
}

function calcLocalStorageKey(
  localStorageKey: string,
  keyType: LocalStorageKeyTypes,
): string {
  return `${keyType}-${localStorageKey}`;
}

export interface ProTableProps extends DataGridProProps {
  localStorageKey: string;
}

export function ProTable(props: ProTableProps): JSX.Element {
  const { localStorageKey } = props;

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();

  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [pinningModel, setPinningModel] = useState<GridPinnedColumns>();

  useEffect(() => {
    const visibleColumnsLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.COLUMN_VISIBILITY,
    );
    const visibleColumns = localStorage.getItem(visibleColumnsLocalStorageKey);
    if (visibleColumns) {
      setColumnVisibilityModel(JSON.parse(visibleColumns));
    }

    const sortLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.SORT,
    );
    const savedSort = localStorage.getItem(sortLocalStorageKey);
    if (savedSort) {
      setSortModel(JSON.parse(savedSort));
    }

    const pinningLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.PINNING_MODEL,
    );
    const savedPinning = localStorage.getItem(pinningLocalStorageKey);
    if (savedPinning) {
      setPinningModel(JSON.parse(savedPinning));
    }
  }, [localStorageKey]);

  const handleColumnVisibilityChange = (
    newModel: GridColumnVisibilityModel,
  ) => {
    setColumnVisibilityModel(newModel);
    const visibleColumnsLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.COLUMN_VISIBILITY,
    );
    localStorage.setItem(
      visibleColumnsLocalStorageKey,
      JSON.stringify(newModel),
    );
  };

  const handleSortChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
    const sortLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.SORT,
    );
    localStorage.setItem(sortLocalStorageKey, JSON.stringify(newModel));
  };

  const handlePinningChange = (newModel: GridPinnedColumns) => {
    setPinningModel(newModel);
    const pinningLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.PINNING_MODEL,
    );
    localStorage.setItem(pinningLocalStorageKey, JSON.stringify(newModel));
  };

  const handleReset = () => {
    setColumnVisibilityModel({});
    setSortModel([]);
    setPinningModel({});
    const visibleColumnsLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.COLUMN_VISIBILITY,
    );
    localStorage.removeItem(visibleColumnsLocalStorageKey);
    const sortLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.SORT,
    );
    localStorage.removeItem(sortLocalStorageKey);
    const pinningLocalStorageKey = calcLocalStorageKey(
      localStorageKey,
      LocalStorageKeyTypes.PINNING_MODEL,
    );
    localStorage.removeItem(pinningLocalStorageKey);
  };

  if (!props?.rows?.length || !props?.columns?.length) {
    return <span>No metadata available</span>;
  }

  return (
    <DataGridPro
      {...props}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityChange}
      sortModel={sortModel}
      onSortModelChange={handleSortChange}
      pinnedColumns={pinningModel}
      onPinnedColumnsChange={handlePinningChange}
      slots={{ toolbar: CustomToolbar }}
      slotProps={{ toolbar: { onReset: handleReset } }}
      disableColumnFilter
      disableRowSelectionOnClick
      hideFooter
      density="compact"
    />
  );
}

function CustomToolbar({ onReset }: { onReset: () => void }) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        style={{ color: 'lightgray', fontSize: 'x-small' }}
      />
      <Button
        onClick={onReset}
        style={{ color: 'lightgray', fontSize: 'x-small' }}
        className="h-6"
      >
        <div className="flex flex-row gap-2">
          <Reset className="h-4 w-4" />
          <span>Reset</span>
        </div>
      </Button>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        style={{ color: 'lightgray', fontSize: 'x-small' }}
      />
    </GridToolbarContainer>
  );
}
