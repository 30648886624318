import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgMagnetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M15 1h-4l1 9a3 3 0 0 1-6 0l1-9H3l-1 9a7 7 0 1 0 14 0l-1-9Zm-1.846 13.154A5.836 5.836 0 0 1 9 15.875a5.834 5.834 0 0 1-4.154-1.721 5.834 5.834 0 0 1-1.72-4.095l.564-5.075h1.736l-.55 4.953v.062c0 1.102.429 2.138 1.208 2.917a4.098 4.098 0 0 0 2.917 1.208 4.098 4.098 0 0 0 2.917-1.208 4.1 4.1 0 0 0 1.208-2.917v-.062l-.007-.062-.543-4.891h1.736l.564 5.075a5.838 5.838 0 0 1-1.72 4.095h-.002Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMagnetIcon;
