import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSocialShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M13 11.717c-.507 0-.96.2-1.307.514L6.94 9.465c.033-.154.06-.307.06-.467 0-.16-.027-.313-.06-.467l4.7-2.739A1.997 1.997 0 0 0 15 4.332c0-1.106-.893-1.999-2-1.999s-2 .893-2 2c0 .16.027.313.06.466l-4.7 2.74A1.997 1.997 0 0 0 3 8.998a1.997 1.997 0 0 0 3.36 1.459l4.747 2.772c-.034.14-.054.287-.054.434 0 1.073.874 1.946 1.947 1.946a1.949 1.949 0 0 0 1.947-1.946A1.949 1.949 0 0 0 13 11.717Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSocialShare;
