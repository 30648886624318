function interpolateLinear(
  value1: number,
  value2: number,
  fraction: number,
): number {
  return value1 + (value2 - value1) * fraction;
}

export function resizeMatrix(
  inputMatrix: Array<number[]>,
  newRows: number,
  newCols: number,
): Array<number[]> {
  const rowScale = (inputMatrix.length - 1) / (newRows - 1);
  const colScale = (inputMatrix[0].length - 1) / (newCols - 1);
  const isSameSize =
    newRows === inputMatrix.length && newCols === inputMatrix[0].length;

  if (isSameSize) {
    return inputMatrix;
  }

  const output = Array.from({ length: newRows }, (_, i) =>
    Array.from({ length: newCols }, (_, j) => {
      const srcRow = i * rowScale;
      const srcCol = j * colScale;

      const row1 = Math.floor(srcRow);
      const row2 = Math.min(row1 + 1, inputMatrix.length - 1);
      const col1 = Math.floor(srcCol);
      const col2 = Math.min(col1 + 1, inputMatrix[0].length - 1);

      const rowFraction = srcRow - row1;
      const colFraction = srcCol - col1;

      const topInterpolation = interpolateLinear(
        inputMatrix[row1][col1],
        inputMatrix[row1][col2],
        colFraction,
      );
      const bottomInterpolation = interpolateLinear(
        inputMatrix[row2][col1],
        inputMatrix[row2][col2],
        colFraction,
      );

      return interpolateLinear(
        topInterpolation,
        bottomInterpolation,
        rowFraction,
      );
    }),
  );

  return output;
}
