import Editor from '@monaco-editor/react';
import { loader } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { useDatasets } from '../../core/DatasetsContext';
import { useDebounce } from '../../core/useDebounce';
import { CodeIntegrationMessageDrawer } from './code-integration-message-pane/CodeIntegrationMessageDrawer';

/**
 * DO NOT DELETE!!!!! REQUIRED FOR OFFLINE MACHINES!!!
 * Monaco by default attempts to fetch https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs/loader.js on
 * load and will fail if the machine is offline. This is a workaround to prevent that.
 */
loader.config({ monaco });

const DEBOUNCE_HANDLE_EDITOR_CHANGE_TIME = 500;

export function CodeEditor(): JSX.Element {
  const {
    virtualFs: { fileMap, currentFileName },
    fontSize,
    handleEditorChange,
  } = useDatasets();

  const debounceHandleEditorChange = useDebounce(
    handleEditorChange,
    DEBOUNCE_HANDLE_EDITOR_CHANGE_TIME,
  );

  const currentFileContent = fileMap[currentFileName];

  return (
    <div className="flex h-full w-full relative overflow-hidden">
      <div className="w-full relative">
        <Editor
          className="absolute top-0 left-0 right-0 bottom-0"
          options={{ fontSize }}
          language="python"
          value={currentFileContent || ''}
          onChange={debounceHandleEditorChange}
          theme="vs-dark"
        />
      </div>
      <CodeIntegrationMessageDrawer />
    </div>
  );
}
