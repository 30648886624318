import useSWR, { KeyedMutator } from 'swr';
import {
  GetMachineTypesResponse,
  MachineTypeOption,
} from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseMachineTypes {
  options: MachineTypeOption[];
  defaultCpuType: string | undefined;
  defaultGpuType: string | undefined;
  refetch: KeyedMutator<GetMachineTypesResponse>;
  error?: Error;
}

export function useFetchMachineTypes(): UseMachineTypes {
  const { data, error, mutate } = useSWR<GetMachineTypesResponse, Error>(
    `${CacheKey.MACHINE_TYPES}`,
    async () => {
      return await api.getMachineTypes();
    },
  );

  return useMergedObject({
    options: data?.options || [],
    defaultCpuType: data?.defaultCpuType,
    defaultGpuType: data?.defaultGpuType,
    refetch: mutate,
    error,
  });
}
