import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M15.363 2.637C13.605.879 11.484 0 9 0 6.516 0 4.395.879 2.637 2.637S0 6.516 0 9c0 2.484.879 4.605 2.637 6.363S6.516 18 9 18c2.484 0 4.605-.879 6.363-2.637S18 11.484 18 9c0-2.484-.879-4.605-2.637-6.363Zm-1.898 4.36c.023.093.035.187.035.28 0 1.594-.574 3.047-1.723 4.36-1.148 1.289-2.683 1.933-4.605 1.933a6.164 6.164 0 0 1-3.375-.984c.164.023.34.035.527.035 1.008 0 1.922-.316 2.742-.95a2.299 2.299 0 0 1-1.3-.456 2.134 2.134 0 0 1-.774-1.09 2.566 2.566 0 0 0 1.02-.035 2.405 2.405 0 0 1-1.301-.774 2.144 2.144 0 0 1-.492-1.406v-.035c.304.188.644.281 1.02.281-.68-.445-1.02-1.054-1.02-1.828 0-.398.105-.773.316-1.125 1.172 1.453 2.695 2.227 4.57 2.32a1.912 1.912 0 0 1-.07-.527c0-.61.211-1.125.633-1.547.445-.445.973-.668 1.582-.668.656 0 1.195.235 1.617.703.516-.093.985-.27 1.406-.527-.164.54-.48.95-.949 1.23a4.267 4.267 0 0 0 1.266-.351 4.8 4.8 0 0 1-1.125 1.16Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTwitterIcon;
