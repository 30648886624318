import { useRef } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '../ui/mui';
import { PushpinOnIcon, PushpinOffIcon, UploadIcon } from '../ui/icons';
import { VersionsTable } from './VersionsTable';
import { VersionControlActions } from './VersionControlActions';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { Truncate } from '../ui/atoms/Truncate';
import { useVersionControlPanelContext } from '../core/VersionControlPanelContext';

export type ShelveAction = 'SaveCommit' | 'ImportModel';

export interface VersionControlPaneProps {
  projectName: string;
  className?: string;
  currentVersionNotes?: string;
  currentVersionId?: string;
  shelveAction?: ShelveAction;
  togglePin: () => void;
  pinned: boolean;
  onActionCancelled: () => void;
}
export function VersionControlPane({
  projectName,
  className,
  currentVersionNotes: currentVersionName,
  currentVersionId,
  shelveAction,
  pinned,
  togglePin,
  onActionCancelled,
}: VersionControlPaneProps): JSX.Element {
  const { handleImportModel } = useVersionControlPanelContext();

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      id={TOUR_SELECTORS_ENUM.VERSION_CONTROL_PANE_ID}
      className={clsx(
        'flex flex-col w-80 overflow-hidden max-h-full',
        className,
      )}
    >
      <div className={clsx('flex justify-between items-center p-2')}>
        <div className="flex flex-col justify-start items-start">
          <Tooltip title={projectName} arrow>
            <h6 className="font-medium text-xl leading-normal whitespace-nowrap w-60 ">
              <Truncate value={projectName} />
            </h6>
          </Tooltip>
          <Tooltip title={currentVersionName || ''} arrow>
            <span className="font-normal text-base tracking-normal whitespace-nowrap w-60">
              <Truncate value={currentVersionName || ''} />
            </span>
          </Tooltip>
        </div>
        <div className="flex flex-row gap-1">
          <Tooltip title="Import Model" arrow>
            <IconButton onClick={handleImportModel} size="small">
              <UploadIcon className="h-5 w-5" />
            </IconButton>
          </Tooltip>
          <Tooltip title={pinned ? 'Unpin' : 'Pin'} arrow>
            <IconButton onClick={togglePin} size="small">
              {pinned ? (
                <PushpinOnIcon className="rounded-full bg-primary-900 border border-gray-700" />
              ) : (
                <PushpinOffIcon />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <VersionsTable
        currentVersionId={currentVersionId}
        subHeader={
          <VersionControlActions
            shelveAction={shelveAction}
            onActionCancelled={onActionCancelled}
          />
        }
      />
    </div>
  );
}
