import { Popover } from '@material-ui/core';
import {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, ButtonProps } from './Button';
import { Down, Up } from '../icons';
import clsx from 'clsx';
import { Setter } from '../../core/types';

type ButtonMenuProps = Omit<ButtonProps, 'openInNewTab' | 'href'> &
  PropsWithChildren<{
    closeOnClick?: boolean;
    menuClassName?: string;
    label: ReactNode;
    open?: boolean;
    setOpen?: Setter<boolean>;
  }>;

export function ButtonMenu({
  children,
  closeOnClick,
  label,
  onClick,
  open,
  setOpen,
  menuClassName,
  tourId,
  ...buttonProps
}: ButtonMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isOpenLocal, setIsOpenLocal] = useState(open ?? false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      setIsOpenLocal((o) => !o);
      setOpen?.((o) => !o);
      onClick?.(event);
    },
    [setOpen, onClick],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setIsOpenLocal(false);
    setOpen?.(false);
  }, [setOpen]);

  useEffect(() => {
    const isOpen = open ?? isOpenLocal;
    setAnchorEl(isOpen ? buttonRef.current : null);
  }, [isOpenLocal, open]);

  return (
    <>
      <Button
        variant="action"
        {...buttonProps}
        onClick={handleClick}
        ref={buttonRef}
        tourId={tourId}
      >
        <div className="flex items-center gap-2">
          {label} {anchorEl ? <Up /> : <Down />}
        </div>
      </Button>
      <Popover
        onClose={handleClose}
        onClick={closeOnClick ? handleClose : undefined}
        classes={{ paper: clsx('flex flex-col', menuClassName) }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {children}
      </Popover>
    </>
  );
}
