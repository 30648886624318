import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSelectionMinusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <g clipPath="url(#selection-minus-icon_svg__a)" fill="currentColor">
      <path d="M8.75 12.5V14H6.5v-1.5h2.25ZM5 12.5V14H2v-3h1.5v1.5H5Zm-3-3V7.25h1.5V9.5H2Zm0-3.75v-3h3v1.5H3.5v1.5H2Zm4.5-3h2.25v1.5H6.5v-1.5Zm4.5 0h3v3h-1.5v-1.5H11v-1.5Zm3 4.5V9.5h-1.5V7.25H14Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.325 16.825a3.882 3.882 0 0 0 1.038-1.796 3.966 3.966 0 0 0 .001-2.061 3.874 3.874 0 0 0-1.037-1.796 3.903 3.903 0 0 0-1.8-1.04 4.033 4.033 0 0 0-2.057.004 3.882 3.882 0 0 0-1.796 1.038 3.91 3.91 0 0 0-1.042 1.8 4.004 4.004 0 0 0 .003 2.05 3.903 3.903 0 0 0 1.04 1.8c.516.514 1.114.86 1.796 1.036a3.94 3.94 0 0 0 2.054.007 3.91 3.91 0 0 0 1.8-1.042Zm-4.821-3.465a.666.666 0 0 0 0 1.332h3.995a.666.666 0 0 0 0-1.332h-3.995Z"
      />
    </g>
    <defs>
      <clipPath id="selection-minus-icon_svg__a">
        <path fill="currentColor" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSelectionMinusIcon;
