import { useMemo } from 'react';
import { useDatasets } from '../../../core/DatasetsContext';
import { CodeIntegrationMessageData } from './CodeIntegrationMessageDrawer';

export interface CodeIntegrationErrorHooks {
  elements: CodeIntegrationMessageData[];
}

const DEFAULT_NO_LOG_MESSAGES: CodeIntegrationMessageData[] = [
  {
    title: 'print log',
    type: 'log',
    message: ['No message logs found in for this code.'],
    index: 0,
  },
];

export function useCodeIntegrationErrors(): CodeIntegrationErrorHooks {
  const { errors } = useDatasets();

  const elements = useMemo((): CodeIntegrationMessageData[] => {
    if (Object.keys(errors).length === 0) {
      return DEFAULT_NO_LOG_MESSAGES;
    }
    return Object.entries(errors).map(([name, message], index) => ({
      title: name,
      type: name === 'print log' ? 'log' : 'error',
      message: message.split('\n'),
      index,
    }));
  }, [errors]);

  return useMemo(
    () => ({
      elements,
    }),
    [elements],
  );
}
