import { OrderType, SessionRunData, SplitValue } from '@tensorleap/api-client';
import { DataDistributionType } from '../../../dashboard/dashlet/Analytics/types';

type SplitInfo = {
  distribution?: DataDistributionType;
  interval?: number;
  order?: OrderType;
} | null;

export function getSplitLabel(
  currentValue: SplitValue,
  selectedSessionRunMap: Map<string, SessionRunData>,
  splitInfo: SplitInfo,
): SplitValue {
  if (!splitInfo) {
    return currentValue;
  }
  const { distribution, interval, order } = splitInfo;

  if (distribution === 'continuous' && interval !== undefined) {
    const valueAsNumber = parseFloat(currentValue as string);
    const [from, to] = [valueAsNumber, valueAsNumber + interval];
    return order === OrderType.Asc ? `${from} - ${to}` : `${to} - ${from}`;
  }

  const sessionRun = selectedSessionRunMap.get(String(currentValue));

  if (sessionRun) {
    return sessionRun.name;
  }

  return currentValue;
}
