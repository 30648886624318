import { Divider, IconButton, Tooltip, Popover } from '../../ui/mui';
import { Button } from '../../ui/atoms/Button';
import {
  ExitFullScreenIcon,
  EnterFullScreenIcon,
  XCloseIcon2,
} from '../../ui/icons';
import { useSecretManagers } from '../SecretManagementContext';
import { Select } from '../../ui/atoms/Select';
import { useCallback, useMemo, useState } from 'react';
import { Input } from '../../ui/atoms/Input';
import {
  DatasetVersionsPopover,
  formatVersionIndex,
} from '../../ui/molecules/DatasetVersionsPopover';
import { Title } from '../../ui/atoms/Title';
import {
  createCodeIntegrationVersionToVersionIndexMap,
  useDatasets,
} from '../../core/DatasetsContext';
import { ShowFileTreeButton } from './ShowFileTreeButton';
import { testIds } from '../../test-ids';
import { HasChanges } from '../../ui/atoms/HasChanges';
import { TextArea } from '../../ui/atoms/TextArea';
import clsx from 'clsx';
import { useEngineSettings } from '../../core/data-fetching/engineSettings';

const DATASET_NAME_IS_MISSING_ERROR = 'Dataset name is missing';
const DATASET_NAME_IS_ALREADY_TAKEN_ERROR = 'Dataset name is already taken';

export interface DatasetEditorTopBarProps {}

export function DatasetEditorTopBar(): JSX.Element {
  const { secretManagers } = useSecretManagers();
  const { data } = useEngineSettings();

  const isBuildDependeciesEnabled = data?.values['BUILD_DEPENDENCIES'];

  const {
    increaseFontSize,
    decreaseFontSize,
    fullScreenMode,
    setFullScreenMode,
    setIsErrorMessageOpen,
    datasetName,
    handleDatasetNameChange,
    editorDatasetVersion,
    datasetVersions,
    datasets,
    dataset,
    virtualFs: {
      secretManagerId,
      setSecretManagerId,
      branch,
      commit,
      setCommit,
      hasUnsavedCode,
      setShowFiletree,
    },
    handleSave,
    handleSaveNewDataset,
    resetChanges,
    handleEditorDatasetVersionSelected,
    handleBranchChange,
    branchOptions,
    codeIntegrationVersionToVersionIndexMap,
    baseImageTypes,
    selectedGenericBaseImageType,
    handleSelectedGenericBaseImageChange,
  } = useDatasets();

  const isNewDataset = !dataset;

  const secretManagersOptions = useMemo(
    () =>
      secretManagers.map((secret) => {
        return { label: secret.name, value: secret.cid };
      }),
    [secretManagers],
  );

  const handleFullScreenClick = useCallback(() => {
    if (fullScreenMode) {
      setFullScreenMode(false);
    } else {
      setShowFiletree(false);
      setIsErrorMessageOpen(false);
      setFullScreenMode(true);
    }
  }, [
    fullScreenMode,
    setFullScreenMode,
    setIsErrorMessageOpen,
    setShowFiletree,
  ]);

  const handleSaveWithParse = useCallback(() => {
    handleSave();
  }, [handleSave]);

  const branchOptionsWithDefault = useMemo(() => {
    const defaultBranch = 'master';
    if (branchOptions.includes(defaultBranch)) {
      return branchOptions;
    }
    return [defaultBranch, ...branchOptions];
  }, [branchOptions]);

  const [datasetNameErrorMsg, setDatasetNameErrorMsg] = useState<string>();

  const handleParseClick = useCallback(() => {
    if (isNewDataset) {
      if (!datasetName) {
        setDatasetNameErrorMsg(DATASET_NAME_IS_MISSING_ERROR);
        return;
      }
      if (datasets.some((d) => d.name === datasetName)) {
        setDatasetNameErrorMsg(DATASET_NAME_IS_ALREADY_TAKEN_ERROR);
        return;
      }

      handleSaveNewDataset();
    } else {
      handleSaveWithParse();
    }
    setDatasetNameErrorMsg(undefined);
  }, [
    datasetName,
    datasets,
    handleSaveNewDataset,
    handleSaveWithParse,
    isNewDataset,
  ]);

  const handleBranchSelect = useCallback(
    (selectedBranch: string) => {
      const latestBranchVersion = dataset?.latestVersions.find(
        ({ branch }) => branch === selectedBranch,
      )?.latest;
      if (latestBranchVersion) {
        handleEditorDatasetVersionSelected(latestBranchVersion.cid);
      }
    },
    [dataset, handleEditorDatasetVersionSelected],
  );

  const isValid = !!branch;

  const versionIndex = useMemo<number>(() => {
    if (!hasUnsavedCode) {
      return editorDatasetVersion
        ? (codeIntegrationVersionToVersionIndexMap.get(
            editorDatasetVersion.cid,
          ) ?? 1)
        : 0;
    }
    const { maxVersionByBranch } =
      createCodeIntegrationVersionToVersionIndexMap(datasetVersions);
    return branch ? (maxVersionByBranch.get(branch) ?? 0) + 1 : 1;
  }, [
    branch,
    codeIntegrationVersionToVersionIndexMap,
    datasetVersions,
    editorDatasetVersion,
    hasUnsavedCode,
  ]);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center content-center justify-between">
        <Title
          className="px-4"
          small
          bottomBorderClassName="border-b-dataset-600"
        >
          SCRIPT EDITOR
        </Title>

        <div className="flex-1 flex flex-row items-center justify-end text-gray-500">
          <div className="flex flex-row gap-2 justify-end max-w-64">
            <Select
              label="SECRET"
              options={secretManagersOptions}
              onChange={setSecretManagerId}
              value={secretManagerId}
              small
              className="flex-1"
            />
            {isBuildDependeciesEnabled && (
              <Select
                label="PYTHON"
                optionID="id"
                value={selectedGenericBaseImageType}
                optionToLabel={(option) => option?.displayName || ''}
                options={baseImageTypes}
                onChange={handleSelectedGenericBaseImageChange}
                small
                className="flex-1"
              />
            )}
          </div>

          <Divider orientation="vertical" className="h-5" />
          <Button
            className="!text-2xl w-14 h-14"
            variant="action-icon"
            onClick={increaseFontSize}
          >
            A+
          </Button>
          <Divider orientation="vertical" className="h-5" />
          <Button
            className="!text-xl w-14 h-14"
            variant="action-icon"
            onClick={decreaseFontSize}
          >
            A-
          </Button>

          <>
            <Divider orientation="vertical" className="h-5" />

            <Button
              variant="action-icon"
              onClick={handleFullScreenClick}
              className="w-14 h-14"
              data-testid="full-screen-button"
            >
              {fullScreenMode ? (
                <ExitFullScreenIcon />
              ) : (
                <EnterFullScreenIcon />
              )}
            </Button>
          </>
        </div>
      </div>
      <div className="flex flex-row items-start content-center justify-between gap-1">
        <div className="mt-2">
          <ShowFileTreeButton />
        </div>
        <div className="w-1/4">
          <Input
            label="DATASET NAME"
            value={isNewDataset ? datasetName : dataset.name || ''}
            onChange={handleDatasetNameChange}
            disabled={!isNewDataset}
            required
            small
            error={datasetNameErrorMsg}
          />
        </div>

        <Select
          label="BRANCH"
          options={branchOptionsWithDefault}
          value={branch}
          required
          onChange={handleBranchChange}
          queryToOption={(query) => query}
          className="w-1/4"
          small
          error={!branch ? 'Branch is required' : undefined}
        />
        <div className="w-1/4">
          <VersionAndCommit
            commit={commit}
            setCommit={setCommit}
            versionIndex={versionIndex}
          />
        </div>

        <div className="w-1/12">
          {!isNewDataset && (
            <DatasetVersionsPopover
              codeIntegrationVersionToVersionIndexMap={
                codeIntegrationVersionToVersionIndexMap
              }
              currentBranch={branch ?? dataset.defaultBranch}
              datasetVersions={datasetVersions}
              currentDatasetVersion={editorDatasetVersion}
              handleDatasetVersionIdSelect={handleEditorDatasetVersionSelected}
              handleBranchSelect={handleBranchSelect}
              dataset={dataset}
            />
          )}
        </div>
        <HasChanges hasChanges={hasUnsavedCode}>
          <Button
            onClick={handleParseClick}
            className="uppercase"
            data-testid={testIds.codeIntegration.parseButton}
            disabled={!isValid}
          >
            PUSH
          </Button>
        </HasChanges>

        {hasUnsavedCode && (
          <Tooltip title="Discard changes">
            <IconButton className="p-1" onClick={resetChanges}>
              <XCloseIcon2 className="h-6 w-6" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

type VersionAndCommitProps = {
  commit: string;
  setCommit: (commit: string) => void;
  versionIndex: number;
};

export function VersionAndCommit({
  commit,
  setCommit,
  versionIndex,
}: VersionAndCommitProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleTextAreaClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'commit-message-popover' : undefined;

  return (
    <div className="flex flex-1 h-9 w-full items-center">
      <Tooltip title="Version index">
        <span
          className={clsx(
            'border border-gray-600 h-full py-[7px] px-2 border-r-0 text-gray-400 rounded-l -mr-1 font-bold',
          )}
        >
          {formatVersionIndex(versionIndex)}
        </span>
      </Tooltip>

      <Tooltip title={commit}>
        <Input
          label="Commit message"
          value={commit}
          className="w-full"
          small
          onClick={handleTextAreaClick}
        />
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { width: '400px', height: '360px' },
        }}
      >
        <TextArea
          className="w-full max-w-full max-h-full h-full overflow-auto"
          containerClassName={'rounded-l-none'}
          value={commit}
          onChange={(e) => setCommit((e.target as HTMLInputElement).value)}
          onBlur={handlePopoverClose}
          autoFocus
        />
      </Popover>
    </div>
  );
}
