import {
  AnalyticsDashletData,
  AnalyticsDashletType,
} from '@tensorleap/api-client';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useGraphMetaMap } from './form/FormFields';
import { DashletFormProps } from './types';
import { DashletFields } from '../../../core/data-fetching/dashlet-fields';

export interface DashletFormContentProps {
  graphType: AnalyticsDashletType;
  metricNames: string[];
  fields: DashletFields;
  form: UseFormReturn<AnalyticsDashletData, object>;
}

export function DashletFormContent({
  graphType,
  metricNames,
  fields,
  form,
}: DashletFormContentProps) {
  const defaultNumericField = useMemo(
    () =>
      fields.numericFields.includes('metrics.loss')
        ? 'metrics.loss'
        : fields.numericFields[0] || '',
    [fields.numericFields],
  );

  const defaultAggregatableField = useMemo(
    () =>
      fields.aggregatableFields.includes('batch')
        ? 'batch'
        : fields.aggregatableFields[0] || '',
    [fields.aggregatableFields],
  );

  const props: Omit<DashletFormProps, 'form'> = useMemo(() => {
    return {
      fields,
      defaultNumericField,
      defaultAggregatableField,
      metricNames,
      graphType,
    };
  }, [
    defaultAggregatableField,
    defaultNumericField,
    fields,
    graphType,
    metricNames,
  ]);

  const { graphMetaMap } = useGraphMetaMap();
  const { Component } = useMemo(
    () => graphMetaMap[graphType],
    [graphMetaMap, graphType],
  );

  return <Component {...props} form={form} />;
}
