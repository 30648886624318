import {
  NumberOrString,
  MultiChartsResponse,
  AnalyticsDashletType,
} from '@tensorleap/api-client';
import { useMemo, useState } from 'react';
import { AxisDomain } from 'recharts/types/util/types';
import { Setter } from '../../../core/types';
import { ChartRequestData, ColorMap, DataPoint } from '../common/interfaces';
import {
  getAllChartPointsFlat,
  getAllLabels,
  getAxisDomain,
  useChartColorMap,
} from './utils';
import { useMergedObject } from '../../../core/useMergedObject';
import { chartFormatIfNumber } from '../chart.utils';

export interface UseMultiChartParams {
  xyChartsResponse: MultiChartsResponse | undefined;
  chartRequestData: ChartRequestData;
  chartType: AnalyticsDashletType;
  autoScaleY?: boolean;
  mapValue: (value: NumberOrString) => NumberOrString | undefined;
  preview?: boolean;
}
export interface UseSplitXYChart {
  splitAxisType: 'number' | 'category';
  splitXAxisDomain: AxisDomain | undefined;
  splitYAxisDomain: AxisDomain | undefined;
  allLabels: string[];
  colorMap: ColorMap;
  hoverLabel: NumberOrString | undefined;
  setHoverLabel: Setter<NumberOrString | undefined>;
  hiddenLabels: NumberOrString[];
  setHiddenLabels: Setter<NumberOrString[]>;
  mapValue: (value: NumberOrString) => NumberOrString;
  preview?: boolean;
}
export function useMultiChart({
  xyChartsResponse,
  chartRequestData,
  chartType,
  autoScaleY,
  mapValue: _mapValue,
  preview,
}: UseMultiChartParams): UseSplitXYChart {
  /**
   * Axis type defines the way recharts handles the data passed to the chart.
   * Catagory refers to catagorical data (might be string or number) while number can only be a numeric data.
   */
  const splitAxisType = useMemo((): 'category' | 'number' => {
    if (
      chartType === AnalyticsDashletType.Table ||
      chartType === AnalyticsDashletType.Heatmap
    ) {
      return 'category';
    }
    return chartRequestData.dataDistribution === 'continuous'
      ? 'number'
      : 'category';
  }, [chartRequestData.dataDistribution, chartType]);

  const allPointsFlat = useMemo(
    (): DataPoint[] =>
      getAllChartPointsFlat({ xyChartsResponse, chartRequestData, chartType }),
    [chartRequestData, chartType, xyChartsResponse],
  );

  const XAxisDomain = useMemo(
    (): AxisDomain | undefined =>
      getAxisDomain({
        points: allPointsFlat,
        chartRequestData,
        axis: 'x',
        axisType: splitAxisType,
        chartType,
      }),
    [allPointsFlat, chartRequestData, chartType, splitAxisType],
  );

  const YAxisDomain = useMemo((): AxisDomain | undefined => {
    const minDomainOverride = autoScaleY ? undefined : 0;
    return getAxisDomain({
      points: allPointsFlat,
      chartRequestData,
      axis: 'y',
      axisType: splitAxisType,
      minDomainOverride,
      chartType,
    });
  }, [allPointsFlat, autoScaleY, chartRequestData, chartType, splitAxisType]);

  const allLabels = useMemo(
    (): string[] =>
      getAllLabels({
        dataPoints: allPointsFlat,
        chartType,
        xField: chartRequestData.xField,
      }),
    [chartType, allPointsFlat, chartRequestData.xField],
  );

  const colorMap = useChartColorMap(allLabels);

  const mapValue = (value: NumberOrString): NumberOrString => {
    const modelName = _mapValue?.(value);
    return modelName !== undefined ? modelName : chartFormatIfNumber(value);
  };

  const [hoverLabel, setHoverLabel] = useState<NumberOrString | undefined>();
  const [hiddenLabels, setHiddenLabels] = useState<NumberOrString[]>([]);

  return useMergedObject({
    splitAxisType,
    splitXAxisDomain: XAxisDomain,
    splitYAxisDomain: YAxisDomain,
    allLabels,
    colorMap,
    hoverLabel,
    setHoverLabel,
    hiddenLabels,
    setHiddenLabels,
    mapValue,
    preview,
  });
}
