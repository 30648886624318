import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Prompt, Redirect, useHistory, useLocation } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import clsx from 'clsx';

import { createStyles, makeStyles } from '../../ui/mui';
import api from '../../core/api-client';
import { DoubleCheck } from '../../ui/icons';
import { useSecretManagers } from '../SecretManagementContext';
import { Button } from '../../ui/atoms/Button';
import { Title } from '../../ui/atoms/Title';
import { SecretEditorBox } from './SecretEditorBox';
import { Input } from '../../ui/atoms/Input';
import {
  SECRET_MANAGER_ID_KEY,
  URLS_ENUM,
  URL_SUB_PATHS_ENUM,
  getStringValueFromQueryParams,
} from '../../url/url-builder';
import { SecretManager } from '@tensorleap/api-client';
import { PageLoader } from '../../ui/molecules/PageLoader';

export interface SecretForm {
  id: string;
  name: string;
  secretKey?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    successBadge: {
      backgroundColor: theme.palette.background.paper,
      background: theme.palette.assets.success,
    },
  }),
);

export function SecretEditorLoader(): JSX.Element {
  const { search } = useLocation();
  const secretIdFromUrl = getStringValueFromQueryParams(
    search,
    SECRET_MANAGER_ID_KEY,
  );
  const { secretManagers, isLoading } = useSecretManagers();

  const secretManager = useMemo(() => {
    if (!secretIdFromUrl) return undefined;
    return secretManagers.find(({ cid }) => cid === secretIdFromUrl);
  }, [secretIdFromUrl, secretManagers]);

  if (
    !isLoading &&
    secretIdFromUrl &&
    !secretManagers.some(({ cid }) => cid === secretIdFromUrl)
  ) {
    console.warn('Secret not found, redirecting to secret list');
    return <Redirect to={URL_SUB_PATHS_ENUM.SECRET} />;
  }

  if (!secretManagers.length && isLoading) {
    return <PageLoader />;
  }

  return <SecretEditor secretManager={secretManager} key={secretIdFromUrl} />;
}

export interface SecretEditorProps {
  secretManager: SecretManager | undefined;
}

function SecretEditor({ secretManager }: SecretEditorProps): JSX.Element {
  const history = useHistory();

  const { secretManagers, fetchSecretManagers } = useSecretManagers();

  const [userErrorMessage, setUserErrorMessage] = useState('');

  const classes = useStyles();
  const methods = useForm<SecretForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: secretManager?.cid || '',
      name: secretManager?.name || '',
      secretKey: '',
    },
  });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: {
      isDirty,
      isValid,
      isValidating,
      isSubmitting,
      isSubmitSuccessful,
    },
    formState: { errors },
  } = methods;
  const { name, secretKey } = watch();

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        await handleSubmit(async ({ id, name, secretKey }) => {
          if (!secretKey) {
            throw Error('No secret was provided');
          }
          if (secretManagers.some((sm) => sm.name === name && sm.cid !== id)) {
            throw Error('Secret name already exists');
          }
          if (id && secretManagers.some((sm) => sm.cid === id)) {
            await api.updateSecretManager({
              cid: id,
              name,
              authData: secretKey,
            });
            await fetchSecretManagers();
          } else {
            const { cid } = await api.addSecretManager({
              name,
              authData: secretKey,
            });
            await fetchSecretManagers();
            history.push(
              `${URLS_ENUM.ASSETS}/${URL_SUB_PATHS_ENUM.SECRET}?${SECRET_MANAGER_ID_KEY}=${cid}`,
            );
          }
        })(event);
      } catch (e) {
        setUserErrorMessage(
          'Failed saving or updating secret: ' + (e as Error).message,
        );
        console.error(e);
      }
    },
    [fetchSecretManagers, handleSubmit, history, secretManagers],
  );

  const setBaseValues = useCallback(() => {
    if (secretManager?.name) {
      setValue('name', secretManager?.name, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: true,
      });
      setValue('id', secretManager?.cid, {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: true,
      });
      setValue('secretKey', '', {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: true,
      });
    }
  }, [secretManager, setValue]);

  useEffect(() => {
    setBaseValues();
    setUserErrorMessage('');
  }, [secretManager, setBaseValues]);

  const isDisabled =
    !isDirty ||
    !isValid ||
    isValidating ||
    isSubmitting ||
    !!errors?.name ||
    !secretKey;

  return (
    <FormProvider {...methods} key={secretManager?.cid}>
      <Prompt
        when={isDirty && !isSubmitting && !!secretKey}
        message="Leaving will discard your unsaved changes"
      />

      <form
        onSubmit={onSubmit}
        onReset={() => reset()}
        className="h-full mx-12 flex flex-col"
      >
        <Title small className="mt-8">
          secret manager
        </Title>
        <fieldset className="px-0 m-0 border-none">
          <div className="w-full mt-6 flex flex-row justify-start">
            <Input
              label="SECRET NAME"
              value={name}
              {...register('name', {
                required: { value: true, message: 'Value is required' },
                pattern: {
                  value: /^[a-z0-9-.]+$/,
                  message: 'Secret name must be lowercase letters, . and -',
                },
                onBlur: (name) => {
                  setValue('name', name, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                  if (userErrorMessage) {
                    setUserErrorMessage('');
                  }
                },
              })}
              error={errors?.name?.message}
            />
          </div>
        </fieldset>
        <fieldset className="grow-[8] shrink basis-0 py-12 px-0 m-0 border-none flex flex-row justify-around items-center">
          <DndProvider backend={HTML5Backend}>
            <SecretEditorBox
              setValue={(secret) =>
                setValue('secretKey', secret || '', {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
              value={secretKey || ''}
            />
          </DndProvider>
        </fieldset>
        <fieldset className="flex-1 flex justify-end items-center px-0 m-0 border-t border-solid border-gray-800">
          {isSubmitSuccessful && (
            <div className="self-center justify-self-end h-full">
              <div
                className={clsx(
                  'flex items-center justify-center mr-2 w-32 border border-solid border-gray-400 box-border rounded-sm',
                  classes.successBadge,
                )}
              >
                <DoubleCheck />
                Success
              </div>
            </div>
          )}
          {isSubmitting && !userErrorMessage && (
            <div className="flex flex-col justify-start">
              <p className="m-0">
                Connecting to your service provider and validating your secret
                key with them...
              </p>
              <p className="m-0">
                Please do not close this window until the process is finished.
              </p>
            </div>
          )}
          {userErrorMessage && (
            <div className="flex flex-col justify-start text-error-500">
              <p className="m-0">{userErrorMessage}</p>
            </div>
          )}
          <div className="flex-1" />
          <Button type="reset" variant="text">
            clear
          </Button>
          <Button className="ml-2" type="submit" disabled={isDisabled}>
            {secretManager ? 'update' : 'save'}
          </Button>
        </fieldset>
      </form>
    </FormProvider>
  );
}
