import { forwardRef, PropsWithChildren, useCallback, useState } from 'react';
import clsx from 'clsx';

import { useRipple } from './utils/useRipple';

export type CheckboxProps = PropsWithChildren<{
  disabled?: boolean;
  defaultChecked?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  onChange: (_: boolean) => void | Promise<void>;
  labelClasses?: string;
  checked?: boolean;
  className?: string;
  containerClasses?: string;
}>;
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      children,
      disabled = false,
      defaultChecked = false,
      uppercase = false,
      capitalize = false,
      onChange,
      labelClasses,
      checked,
      className,
      containerClasses,
    },
    ref,
  ) => {
    const ripple = useRipple();

    const isControlled = typeof checked === 'boolean'; // KMN

    const [isChecked, setIsChecked] = useState(defaultChecked);
    const handleChange = useCallback(() => {
      setIsChecked((c) => {
        onChange(!c);
        return !c;
      });
    }, [onChange]);

    return (
      <label
        className={clsx(
          'group',
          'flex',
          'justify-center',
          'items-center',
          'gap-2',
          'max-w-fit',
          { uppercase, capitalize },
          containerClasses,
        )}
      >
        <input
          ref={ref}
          type="checkbox"
          onMouseUp={ripple}
          checked={isControlled ? checked : isChecked}
          disabled={disabled}
          className={clsx(
            'peer',
            'appearance-none',
            'relative',
            'inline-block',
            'h-4 w-4',
            'text-black',
            'transition-transform',
            'group-hover:scale-125',
            'group-hover:disabled:scale-100',
            'bg-transparent',
            'rounded-sm',
            'border-2 border-primary-500',
            'cursor-pointer',
            'before:absolute before:-top-0.5 before:left-0.5',
            'before:w-2 before:h-3',
            'before:border-r-2 before:border-b-2 before:border-black',
            'before:rotate-45',
            'before:transition-all',
            'before:opacity-0',
            'checked:bg-primary-500',
            'checked:before:opacity-100',
            'disabled:cursor-default',
            'disabled:bg-gray-400',
            'disabled:border-gray-400',
            'disabled:before:hidden',
            className,
          )}
          onChange={handleChange}
        />
        <span
          className={clsx(
            'cursor-pointer',
            'peer-disabled:cursor-default',
            'peer-disabled:text-white/75',
            labelClasses,
          )}
        >
          {children}
        </span>
      </label>
    );
  },
);
Checkbox.displayName = 'Checkbox';
