import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgViDoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3.4H4a.6.6 0 0 0-.6.6v10a.6.6 0 0 0 .6.6h10a.6.6 0 0 0 .6-.6V4a.6.6 0 0 0-.6-.6ZM4 2a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4Z"
      fill="currentColor"
    />
    <path
      d="M8.099 13.556a.887.887 0 0 1-.728-.364l-2.34-3.068c-.217-.338-.226-.676-.026-1.014.199-.347.49-.494.87-.442a.957.957 0 0 1 .612.364l1.534 2.002 3.848-6.162c.13-.2.307-.33.533-.39.225-.061.463-.026.715.104.199.13.329.307.39.533.06.225.026.463-.104.715l-4.55 7.28c-.174.277-.416.416-.728.416l-.026.026Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgViDoneIcon;
