import { useCallback, useMemo } from 'react';
import { UseFetchAllSlimUsers } from '../core/data-fetching/users';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { ModelFields } from '../ui/model-list/types';
import {
  TeamMemberData,
  TeamMemberStatus,
  USER_ROLE_TO_ROLE_NAME,
} from './TeamManagementTypes';
import api from '../core/api-client';
import { useAuth } from '../auth/AuthContext';
import { UserRole } from '@tensorleap/api-client';
import { UseFetchTeams } from '../core/data-fetching/teams';
import { AcceptRejectIconButton } from '../ui/atoms/AcceptRejectIconButton';
import useAsyncEffect from '../core/useAsyncEffect';
import { format } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import { Alert } from '../ui/icons';
import { EditableSelectCell } from '../ui/atoms/EditableSelectCell';

const STATUS_STYLE: Record<TeamMemberStatus, string> = {
  Pending: 'text-warning-400',
  Active: 'text-success-500',
};

const SUPPORTED_USER_ROLE_UPDATE: UserRole[] = [UserRole.Admin, UserRole.User];

const createdAtFormatted = (date: Date) => format(date, 'MMM dd, yyyy');

export function useMemberFields(
  maxActiveUsers: number,
): ModelFields<TeamMemberData> {
  const { user } = useAuth();

  const { teamData, activeUsersCount, refetch } = UseFetchAllSlimUsers();
  const { teams: allTeams, refetch: refetchTeams } = UseFetchTeams();

  const updateUserRole = useCallback(
    async (userData: TeamMemberData, role: UserRole) => {
      if (!SUPPORTED_USER_ROLE_UPDATE.includes(role)) {
        return;
      }
      await api.updateUserRole({
        userId: userData.cid,
        role,
      });
      refetch();
    },
    [refetch],
  );

  const updateUserStatus = useCallback(
    async (userData: TeamMemberData, active: boolean) => {
      await api.updateUserStatus({
        userId: userData.cid,
        active,
      });
      refetch();
    },
    [refetch],
  );

  const updateUserTeam = useCallback(
    async (userData: TeamMemberData, teamId: string) => {
      if (!teamId) {
        return;
      }
      await api.updateUserTeam({
        userId: userData.cid,
        teamId,
      });
      refetch();
    },
    [refetch],
  );

  useAsyncEffect(async () => {
    await refetchTeams();
  }, [teamData]);

  return useMemo(() => {
    const memberFields: ModelFields<TeamMemberData> = [
      {
        accessorKey: 'userName',
        label: 'user name',
        format(userName) {
          return (
            <div className="flex gap-2 items-center">
              <CircledIcon text={userName} dropShadow />
              {userName}
            </div>
          );
        },
      },
      {
        accessorKey: 'teamId',
        label: 'team',
        format: (teamId, teamMember) => (
          <EditableSelectCell
            options={allTeams || []}
            optionToLabel={(o) => o.publicName}
            optionID="cid"
            value={teamId}
            onChange={(x: string) => updateUserTeam(teamMember, x || '')}
          />
        ),
      },
      {
        accessorKey: 'status',
        label: 'status',
        table: { width: 130 },
        format: (status, teamMember) => (
          <div className="flex gap-2 items-center">
            <span className={STATUS_STYLE[status]}>{status.toString()}</span>
            {status === 'Pending' &&
              (activeUsersCount < maxActiveUsers ? (
                <AcceptRejectIconButton
                  className="mr-1"
                  accept
                  onClick={() => {
                    updateUserStatus(teamMember, true);
                  }}
                />
              ) : (
                <Tooltip
                  title={
                    <span className="capitalize text-sm">
                      Max active user limit reached!
                    </span>
                  }
                >
                  <span>
                    <Alert />
                  </span>
                </Tooltip>
              ))}
          </div>
        ),
      },
      {
        accessorKey: 'role',
        label: 'role',
        table: { width: 120 },
        format: (role, teamMember) => (
          <EditableSelectCell
            value={role}
            readonly={teamMember.cid === user?.cid}
            options={SUPPORTED_USER_ROLE_UPDATE}
            optionToLabel={(x) => USER_ROLE_TO_ROLE_NAME[x]}
            onChange={(x) => updateUserRole(teamMember, x as UserRole)}
          />
        ),
      },
      {
        accessorKey: 'joiningDate',
        label: 'joining date',
        table: { width: 140 },
        format: createdAtFormatted,
      },
    ];
    return memberFields;
  }, [
    updateUserRole,
    user,
    allTeams,
    updateUserTeam,
    activeUsersCount,
    maxActiveUsers,
    updateUserStatus,
  ]);
}
