import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export type CircularProgressBar = {
  value: number;
  totalScale: number;
  subtitle: string;
  textColor: string;
};

export type CircularProgressBarProps = {
  dashboardsSummaryData: CircularProgressBar[];
};

export const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  dashboardsSummaryData,
}: CircularProgressBarProps) => {
  return (
    <div className="flex flex-row gap-3 h-32 w-full items-center -my-2">
      {dashboardsSummaryData.map(
        ({ subtitle, textColor, totalScale, value }, index) => {
          const progress = (value / totalScale) * 100;
          return (
            <div
              key={index}
              className="flex flex-1 flex-col justify-center items-center bg-gray-850 p-2 rounded-xl shadow-lg"
            >
              <div className="flex flex-1 px-2 justify-center relative">
                <CircularProgressbar
                  value={progress || 0}
                  text={`${value}`}
                  circleRatio={0.5}
                  strokeWidth={5}
                  className="h-24"
                  styles={buildStyles({
                    rotation: 0.75,
                    strokeLinecap: 'round',
                    trailColor: '#444',
                    pathColor: textColor,
                    textColor,
                    textSize: '1.5rem',
                  })}
                />
              </div>
              <div className="text-center absolute top-[82px] w-36">
                <div className="text-gray-400">{subtitle}</div>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};
