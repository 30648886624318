import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { CircularProgress } from '@material-ui/core';

export interface SwitchProps {
  id?: string;
  labels?: [string, string];
  disabled?: boolean;
  onClick: (_: boolean) => Promise<void> | void;
  /** @warn if this param is not `undefined` then this component is **controlled**
   * and so must manage its own state (using the `onClick` param) */
  value?: boolean;
  defaultValue?: boolean;
  className?: string;
  loading?: boolean;
}
export function Switch({
  labels,
  id: cid,
  onClick,
  value,
  disabled,
  defaultValue,
  className,
  loading,
}: SwitchProps): JSX.Element {
  const [firstLabel, secondLabel] = labels || [];

  const id = useMemo(() => (cid ? cid : `SWITCH_ID_${uniqueId()}`), [cid]);

  const switchEl = useRef<HTMLInputElement>(null);
  const onSwitch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      !loading && onClick(event.target.checked);
    },
    [onClick, loading],
  );

  useEffect(() => {
    if (switchEl.current && typeof defaultValue === 'boolean') {
      switchEl.current.checked = defaultValue;
    }
  }, [defaultValue]);

  return (
    <div className={clsx('flex justify-center items-center group', className)}>
      {!!firstLabel && (
        <label htmlFor={id} className="cursor-pointer">
          {firstLabel}
        </label>
      )}
      <button
        className="relative mx-3 my-2 bg-transparent border-none"
        onClick={() => switchEl.current?.click()}
      >
        <input
          id={id}
          type="checkbox"
          disabled={disabled}
          className="peer sr-only"
          ref={switchEl}
          checked={value}
          onChange={onSwitch}
        />
        <div
          className={clsx(
            'w-10',
            'h-4',
            'rounded-full',
            'shadow-inner',
            'transition-colors',
            'peer-disabled:cursor-not-allowed',
            'bg-gray-500',
            'peer-checked:bg-primary-300',
            'peer-disabled:bg-gray-300',
            'peer-disabled:peer-checked:bg-primary-100',
            'group-focus-within:ring-2 group-focus-within:ring-white/70',
          )}
        />
        <div
          className={clsx(
            'absolute',
            '-left-1',
            '-top-1',
            'w-6',
            'h-6',
            'rounded-full',
            'shadow',
            'bg-white',
            'peer-checked:bg-primary-500',
            'text-primary-500',
            'peer-checked:text-white',
            'peer-disabled:bg-gray-200',
            'peer-disabled:cursor-not-allowed',
            'peer-disabled:peer-checked:bg-primary-300',

            'peer-checked:translate-x-full',
            'transition',
          )}
        >
          {loading && (
            <CircularProgress color="inherit" className="!w-6 !h-6 " />
          )}
        </div>
      </button>
      {!!secondLabel && (
        <label htmlFor={id} className="cursor-pointer">
          {secondLabel}
        </label>
      )}
    </div>
  );
}
