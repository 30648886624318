import { IconButton } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DownSmallIcon, LogIcon, UpSmallIcon } from '../../../ui/icons';
import clsx from 'clsx';
import SvgAlert from '../../../ui/icons/Alert';
import { useCodeIntegrationErrors } from './CodeIntegrationErrorsHooks';

export interface CodeIntegrationMessageData {
  title: string;
  type: CodeIntegrationMessageType;
  message: string[];
  index: number;
}
interface CodeIntegrationMessageState {
  isExpanded: boolean;
  setIsExpanded: (expand: boolean) => void;
  isSelected(index: number): boolean;
  select: (index: number) => void;
}

export type CodeIntegrationMessageType = 'log' | 'error';

const DEFAULT_CONTENT_HEIGHT = 100; //px
const MAX_CONTENT_HEIGHT = 800; //px
const TOP_BAR_HEIGHT = 32; //px

export function CodeIntegrationMessageDrawer(): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(true);
  const [contentHeight, setContentHeight] = useState(DEFAULT_CONTENT_HEIGHT);
  const [selectedElementIndex, setSelectedElementIndex] = useState<number>(0);
  const { elements } = useCodeIntegrationErrors();

  const selectedElement = useMemo(
    () => elements.find((element) => element.index === selectedElementIndex),
    [selectedElementIndex, elements],
  );
  const isSelected = useCallback(
    (index: number) => index === selectedElementIndex,
    [selectedElementIndex],
  );
  const select = useCallback(
    (index: number) => setSelectedElementIndex(index),
    [setSelectedElementIndex],
  );

  const state: CodeIntegrationMessageState = useMemo(
    () => ({
      isExpanded,
      setIsExpanded,
      isSelected,
      select,
    }),
    [isExpanded, isSelected, select],
  );

  return (
    <div className="absolute bottom-0 w-full bg-gray-850 rounded-t-xl border-gray-700 border">
      <CodeIntegrationMessageTopBar
        elements={elements}
        contentHeight={contentHeight}
        setContentHeight={setContentHeight}
        state={state}
      />
      <CodeIntegrationMessageContent
        isExpanded={isExpanded}
        contentHeight={contentHeight}
        selectedElement={selectedElement}
      />
    </div>
  );
}

interface HasMoreScrollElementProps {
  className?: string;
}
function HasMoreScrollElement({
  className,
}: HasMoreScrollElementProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex h-full w-fit justify-end items-center text-gray-500',
        className,
      )}
    >
      ...
    </div>
  );
}

interface CodeIntegrationMessagePaneProps {
  elements: CodeIntegrationMessageData[];
  contentHeight: number;
  setContentHeight: (height: number) => void;
  state: CodeIntegrationMessageState;
}
function CodeIntegrationMessageTopBar({
  elements,
  contentHeight,
  setContentHeight,
  state,
}: CodeIntegrationMessagePaneProps): JSX.Element {
  const [hasOverflowLeft, setHasOverflowLeft] = useState(false);
  const [hasOverflowRight, setHasOverflowRight] = useState(false);

  return (
    <div className="flex flex-col h-8 bg-blue-500 relative">
      <DragBar
        isExpanded={state.isExpanded}
        contentHeight={contentHeight}
        setContentHeight={setContentHeight}
      />
      <div className="flex w-full h-full">
        {hasOverflowLeft && <HasMoreScrollElement className="ml-4" />}
        <CodeIntegrationMessageTopBarOptions
          elements={elements}
          state={state}
          setHasOverflowLeft={setHasOverflowLeft}
          setHasOverflowRight={setHasOverflowRight}
        />
        {hasOverflowRight && <HasMoreScrollElement className="mr-4" />}
        <ExpandButton
          isExpanded={state.isExpanded}
          setIsExpanded={state.setIsExpanded}
        />
      </div>
    </div>
  );
}

interface DragBarProps {
  isExpanded: boolean;
  contentHeight: number;
  setContentHeight: (height: number) => void;
}

function DragBar({
  isExpanded,
  setContentHeight,
  contentHeight,
}: DragBarProps): JSX.Element {
  const [isDragging, setIsDragging] = useState(false);
  const dragBarRef = useRef<HTMLDivElement>(null);

  const updateHeight = useCallback(
    (newHeight: number) => {
      requestAnimationFrame(() => setContentHeight(newHeight));
    },
    [setContentHeight],
  );

  const handleDrag = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        let newHeight = window.innerHeight - e.clientY - TOP_BAR_HEIGHT;
        newHeight = Math.min(newHeight, MAX_CONTENT_HEIGHT);
        if (newHeight > 0 && newHeight !== contentHeight) {
          updateHeight(newHeight);
        }
      }
    },
    [contentHeight, isDragging, updateHeight],
  );

  const handleDragStart = () => {
    setIsDragging(true);
    document.body.classList.add('select-none');
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.classList.remove('select-none');
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('mouseleave', handleDragEnd);
      window.addEventListener('blur', handleDragEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('mouseleave', handleDragEnd);
      window.removeEventListener('blur', handleDragEnd);
    };
  }, [handleDrag, isDragging]);

  return (
    <div
      ref={dragBarRef}
      className={clsx(
        'w-full h-[2px] min-h-[2px] absolute top-0',
        isExpanded && 'cursor-row-resize',
      )}
      onMouseDown={handleDragStart}
    />
  );
}

interface ExpandButtonProps {
  isExpanded: boolean;
  setIsExpanded: (state: boolean) => void;
}
function ExpandButton({
  isExpanded,
  setIsExpanded,
}: ExpandButtonProps): JSX.Element {
  return (
    <div className="flex h-full w-4 mx-4 justify-center items-center">
      {isExpanded ? (
        <IconButton onClick={() => setIsExpanded(false)} className="w-5 h-5">
          <DownSmallIcon className="justify-center items-center" />
        </IconButton>
      ) : (
        <IconButton onClick={() => setIsExpanded(true)} className="w-5 h-5">
          <UpSmallIcon className="" />
        </IconButton>
      )}
    </div>
  );
}

interface CodeIntegrationMessageTopBarOptionElementProps {
  element: CodeIntegrationMessageData;
  state: CodeIntegrationMessageState;
}
function CodeIntegrationMessageTopBarOptionElement({
  element: { title, type, index },
  state: { setIsExpanded, select, isSelected, isExpanded },
}: CodeIntegrationMessageTopBarOptionElementProps): JSX.Element {
  const onClick = useCallback(() => {
    setIsExpanded(true);
    select(index);
  }, [index, select, setIsExpanded]);

  const optionIsSelected = useMemo(
    () => isSelected(index),
    [index, isSelected],
  );

  return (
    <div
      onClick={onClick}
      className={clsx(
        'flex flex-row gap-1 h-full w-fit mx-2 pb-1.5 uppercase items-end justify-center text-gray-500 cursor-pointer',
        optionIsSelected && isExpanded && 'border-t-2 border-primary-600',
      )}
    >
      {type === 'error' ? (
        <SvgAlert className="h-4 w-4 text-error-500" />
      ) : (
        <LogIcon className="h-4 w-4 text-gray-500" />
      )}
      <span className="text-xs font-bold">{title}</span>
    </div>
  );
}

interface CodeIntegrationMessageTopBarOptionsProps {
  elements: CodeIntegrationMessageData[];
  state: CodeIntegrationMessageState;
  setHasOverflowLeft: (hasOverflow: boolean) => void;
  setHasOverflowRight: (hasOverflow: boolean) => void;
}
function CodeIntegrationMessageTopBarOptions({
  elements,
  state,
  setHasOverflowLeft,
  setHasOverflowRight,
}: CodeIntegrationMessageTopBarOptionsProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const checkForOverflow = useCallback(() => {
    const div = ref.current;
    if (div) {
      const isOverflowingLeft = div.scrollLeft > 0;
      const isOverflowingRight =
        div.scrollLeft < div.scrollWidth - div.clientWidth;

      setHasOverflowLeft(isOverflowingLeft);
      setHasOverflowRight(isOverflowingRight);
    }
  }, [setHasOverflowLeft, setHasOverflowRight]);

  useEffect(() => {
    const div = ref.current;
    if (div) {
      const handleWheel = (event: WheelEvent) => {
        event.preventDefault();
        div.scrollLeft += event.deltaY;
        checkForOverflow();
      };

      div.addEventListener('wheel', handleWheel, { passive: false });
      checkForOverflow(); // Initial check for overflow
      window.addEventListener('resize', checkForOverflow);

      return () => {
        div.removeEventListener('wheel', handleWheel);
        window.removeEventListener('resize', checkForOverflow);
      };
    }
  }, [checkForOverflow]);

  useEffect(() => {
    const div = ref.current;
    if (div) {
      const handleScroll = () => {
        checkForOverflow();
      };

      div.addEventListener('scroll', handleScroll);
      return () => div.removeEventListener('scroll', handleScroll);
    }
  }, [checkForOverflow]);

  return (
    <div
      className="flex flex-1 flex-row gap-2 mx-4 overflow-x-scroll overflow-y-hidden scrollbar-hide whitespace-nowrap"
      ref={ref}
    >
      {elements.map((element, index) => (
        <CodeIntegrationMessageTopBarOptionElement
          key={index}
          element={element}
          state={state}
        />
      ))}
    </div>
  );
}

interface CodeIntegrationMessageContentProps {
  isExpanded: boolean;
  contentHeight: number;
  selectedElement?: CodeIntegrationMessageData;
}
function CodeIntegrationMessageContent({
  isExpanded,
  contentHeight,
  selectedElement,
}: CodeIntegrationMessageContentProps): JSX.Element {
  const elementStyle = useMemo(
    () => ({
      height: isExpanded ? contentHeight : 0,
      transitionDuration: '100ms',
      minHeight: isExpanded ? '40px' : 0,
    }),
    [isExpanded, contentHeight],
  );

  if (!selectedElement) return <></>;
  return (
    <div
      className={clsx(
        'overflow-x-hidden overflow-y-auto break-words scrollbar-hide max-h-screen-6rem',
        selectedElement.type === 'error' ? 'text-error-500' : 'text-gray-500',
      )}
      style={elementStyle}
    >
      <div className="px-6 py-2 text-xs">
        {selectedElement.message.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    </div>
  );
}
