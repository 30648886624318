export type NamedObject = { name: string };
export function extractName({ name }: NamedObject) {
  return name;
}
export function findByName<T extends NamedObject>(
  namedList: T[] = [],
  someName?: string,
): T | undefined {
  return namedList?.find(({ name }) => someName === name);
}
