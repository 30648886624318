import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Dataset, SecretManager } from '@tensorleap/api-client';
import { Plus } from '../ui/icons';
import api from '../core/api-client';
import { SecretEditorLoader } from './SecretEditor/SecretEditor';
import {
  useSecretManagers,
  SecretManagementProvider,
} from './SecretManagementContext';
import { MainNavBar } from '../ui/MainNavBar';
import { useQueryParams } from '../core/useQueryParams';
import { useSnackbar } from '../ui/SnackbarContext';
import { Table } from '../ui/model-list/table/Table';
import { ModelFields } from '../ui/model-list/types';
import { IconButton, Tooltip } from '@material-ui/core';
import { Title } from '../ui/atoms/Title';
import { MenuAction } from '../ui/model-list/table/TableRowActions';
import { Divider } from '@material-ui/core';
import { DatasetsProvider, useDatasets } from '../core/DatasetsContext';
import { AssetsCodeView } from './code-integration/AssetsCodeView';
import { testIds } from '../test-ids';
import { URLS_ENUM, URL_SUB_PATHS_ENUM } from '../url/url-builder';
import { Truncate } from '../ui/atoms/Truncate';

const NAME_COLUMN_STYLE = {
  cellClassName: 'pl-6',
  headerClassName: 'pl-6',
};

const DATE_COLUMN_STYLE = {
  align: 'center' as const,
  width: 130,
  headerClassName: 'text-gray-400',
};

const dateFormat = (createdAt: string | Date) => (
  <span className="text-gray-500 text-sm">
    {new Date(createdAt).toLocaleString() || ''}
  </span>
);

const nameFormat = (name: string | null) => (
  <Tooltip title={name || ''}>
    <div className="w-48">
      <Truncate value={name || ''} />
    </div>
  </Tooltip>
);

const SECRET_MANAGER_FIELDS: ModelFields<SecretManager> = [
  {
    accessorKey: 'name',
    table: NAME_COLUMN_STYLE,
    label: 'SECRET NAME',
    format: nameFormat,
  },
  {
    accessorKey: 'createdAt',
    label: 'created at',
    table: DATE_COLUMN_STYLE,
    format: dateFormat,
  },
];

const LEEP_SCRIPT_FIELDS: ModelFields<Dataset> = [
  {
    accessorKey: 'name',
    table: NAME_COLUMN_STYLE,
    label: 'NAME',
    format: nameFormat,
  },
  {
    accessorKey: 'createdAt',
    label: 'created at',
    table: DATE_COLUMN_STYLE,
    format: dateFormat,
  },
];

const EditorSwitchRouter = React.memo(() => {
  return (
    <div className="grow-[2] shrink basis-0 overflow-y-auto">
      <Switch>
        <Route exact path={URLS_ENUM.ASSETS} component={SecretEditorLoader} />
        <Route
          path={`${URLS_ENUM.ASSETS}/${URL_SUB_PATHS_ENUM.SECRET}`}
          component={() => <SecretEditorLoader />}
        />
        <Route
          path={`${URLS_ENUM.ASSETS}/${URL_SUB_PATHS_ENUM.DATASETS}`}
          component={() => <AssetsCodeView />}
        />
      </Switch>
    </div>
  );
});
EditorSwitchRouter.displayName = 'EditorSwitchRouter';

export const AssetsManagementPageView = React.memo(() => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedId] = useQueryParams('id');
  const { secretManagers, fetchSecretManagers } = useSecretManagers();

  const { changeDatasetContext, fetchDatasets, datasets } = useDatasets();

  useEffect(
    () => changeDatasetContext(selectedId, undefined),
    [selectedId, changeDatasetContext],
  );

  const switchToNewDatasetMode = useCallback(() => {
    changeDatasetContext(undefined, undefined);
    history.push(`${URLS_ENUM.ASSETS}/${URL_SUB_PATHS_ENUM.DATASETS}`);
  }, [changeDatasetContext, history]);

  const secretsRowMenu = useMemo<MenuAction<SecretManager>[]>(
    () => [
      {
        title: 'Update Secret',
        onSelect: ({ cid: secretManagerId }) => {
          history.push(`${path}?id=${secretManagerId}`);
        },
      },
      {
        title: 'Delete Secret',
        onSelect: async ({ cid: secretManagerId }) => {
          try {
            await api.trashSecretManager({ secretManagerId });
          } catch (e) {
            if (e instanceof Error) {
              enqueueSnackbar(e.message, {
                variant: 'error',
              });
            }
          }
          await fetchSecretManagers();
        },
      },
    ],
    [enqueueSnackbar, fetchSecretManagers, history, path],
  );

  const datasetRowMenu = useMemo<MenuAction<Dataset>[]>(
    () => [
      {
        title: 'Delete Dataset',
        onSelect: async ({ cid: datasetId }) => {
          try {
            await api.trashDataset({ datasetId });
            await fetchDatasets();

            if (datasetId === selectedId) {
              history.push('/assets/secrets');
            }
          } catch (e) {
            if (e instanceof Error) {
              enqueueSnackbar(e.message, {
                variant: 'error',
              });
            }
          }
        },
      },
    ],
    [fetchDatasets, selectedId, history, enqueueSnackbar],
  );

  const ResourceManagementContainer = (
    <div className="max-h-full w-96 flex flex-col border-r border-r-gray-700">
      <Title className="p-6" bottomBorderClassName="border-b-primary-500" small>
        Resources Management
      </Title>

      <div className="flex flex-col flex-1 max-h-full overflow-hidden">
        <AssetHeader
          title={`SECRETS (${secretManagers.length})`}
          onAdd={() => history.push('/assets/secrets')}
        />

        <Table
          inline
          data={secretManagers}
          fields={SECRET_MANAGER_FIELDS}
          onRowClick={({ cid }) => history.push(`/assets/secrets?id=${cid}`)}
          menuActions={secretsRowMenu}
        />
      </div>
      <Divider />
      <div className="flex flex-col flex-[2] overflow-hidden">
        <AssetHeader
          title={`INTEGRATION SCRIPTS (${datasets.length})`}
          onAdd={switchToNewDatasetMode}
          addTestId={testIds.codeIntegration.addButton}
        />

        <Table
          className="max-h-full"
          inline
          data={datasets}
          fields={LEEP_SCRIPT_FIELDS}
          onRowClick={({ cid }) => history.push(`/assets/datasets?id=${cid}`)}
          menuActions={datasetRowMenu}
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-stretch w-screen h-screen">
      <MainNavBar />

      <div className="flex-1 flex flex-row overflow-auto">
        {ResourceManagementContainer}
        <EditorSwitchRouter />
      </div>
    </div>
  );
});
AssetsManagementPageView.displayName = 'AssetsManagementPageView';

type AssetHeaderProps = {
  title: ReactNode;
  onAdd: () => void;
  addTestId?: string;
};

function AssetHeader({ title, onAdd, addTestId }: AssetHeaderProps) {
  return (
    <div className="flex py-2 mx-4 items-center justify-between">
      <Title small className="px-2">
        {title}
      </Title>
      <IconButton onClick={onAdd} data-testid={addTestId}>
        <Plus className="h-4 w-4" />
      </IconButton>
    </div>
  );
}

export const AssetsManagementPage: FC = () => {
  return (
    <DatasetsProvider>
      <SecretManagementProvider>
        <AssetsManagementPageView />
      </SecretManagementProvider>
    </DatasetsProvider>
  );
};
