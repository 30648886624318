import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSmallFilterFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M13.722 4H4.556v.99l.104.104 3.229 4.062V14l.677-.495 1.667-1.25.156-.13V9.156l3.23-4.062.103-.104V4Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSmallFilterFilled;
