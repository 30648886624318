import { Divider } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { Select } from '../../../../ui/atoms/Select';
import {
  DashletFormParagraph,
  FlipcolorRange,
  InputSizeIntervalField,
  ScaleTypeY,
  scaleTypeYProps,
  SelectAggregationField,
  SelectDistributionField,
  SelectOrderField,
  Split,
} from './FormFields';
import { AggregationMethod, OrderType } from '@tensorleap/api-client';
import { ChartSplit, DashletFormProps, DataDistributionType } from '../types';
import { NO_SPLIT_SELECTED } from '../ElasticVis/utils';

export type HeatmapParams = scaleTypeYProps & {
  aggregation: AggregationMethod;
  color: string;
  xAxisDataDistribution: DataDistributionType;
  xAxisSizeInterval: number;
  xAxisField: string;
  xAxisOrderBy: string;
  xAxisOrder: OrderType;
  yAxisDataDistribution: DataDistributionType;
  yAxisSizeInterval: number;
  yAxisField: string;
  yAxisOrder: OrderType;
  splitSeriesBySubset: boolean;
  splitByPrediction: boolean;
  firstSplit: ChartSplit;
  secondSplit: ChartSplit;
  flipColorRange: boolean;
};

export function HeatmapDashletForm(
  props: DashletFormProps<HeatmapParams>,
): JSX.Element {
  const dashletFields = props.fields;

  const {
    fields,
    form: { control },
  } = props;

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref: _colorRef, ...colorField },
  } = useController({
    control,
    name: 'data.color',
    defaultValue: 'metrics.loss',
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref: _xAxisFieldRef, ...xAxisField },
  } = useController({
    control,
    name: 'data.xAxisField',
    defaultValue: 'epoch',
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref: _yAxisFieldRef, ...yAxisField },
  } = useController({
    control,
    name: 'data.yAxisField',
    defaultValue: 'sample_identity.state.keyword',
  });

  const {
    field: { ref: _firstSplit, ...firstSplitField },
  } = useController({
    control,
    name: 'data.firstSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  const {
    field: { ref: _secondSplit, ...secondSplitField },
  } = useController({
    control,
    name: 'data.secondSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  return (
    <div className="flex flex-col gap-6 overflow-x-hidden pr-2">
      <DashletFormParagraph content="COLOR" className="flex-1" />

      <Select
        label="FIELD"
        options={fields.numericFields}
        inactiveOptions={dashletFields.notActiveFields}
        {...colorField}
        className="flex-1"
      />

      <SelectAggregationField {...props} />

      <FlipcolorRange
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        {...(props as DashletFormProps<any>)}
      />

      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <ScaleTypeY {...(props as DashletFormProps<any>)} label="Color Scale" />

      <Divider orientation="horizontal" />

      <DashletFormParagraph content="X-Axis" />

      <Select
        label="FIELD"
        options={fields.aggregatableFields}
        inactiveOptions={dashletFields.notActiveFields}
        {...xAxisField}
        className="flex-1"
      />

      <SelectDistributionField<HeatmapParams>
        name="xAxisDataDistribution"
        axisField={xAxisField.value}
        {...props}
      />

      <InputSizeIntervalField
        {...props}
        dataDistribution={props.form.getValues()?.data?.xAxisDataDistribution}
        name="xAxisSizeInterval"
      />

      <SelectOrderField<HeatmapParams>
        name="xAxisOrder"
        {...props}
        dataDistribution={props.form.getValues()?.data?.xAxisDataDistribution}
      />

      <Divider orientation="horizontal" />

      <DashletFormParagraph content="Y-Axis" className="flex-1" />

      <Select
        label="FIELD"
        options={fields.aggregatableFields}
        inactiveOptions={dashletFields.notActiveFields}
        {...yAxisField}
        className="flex-1"
      />

      <SelectDistributionField<HeatmapParams>
        name="yAxisDataDistribution"
        axisField={yAxisField.value}
        {...props}
      />

      <InputSizeIntervalField
        {...props}
        dataDistribution={props.form.getValues()?.data?.yAxisDataDistribution}
        name="yAxisSizeInterval"
      />

      <SelectOrderField<HeatmapParams>
        name="yAxisOrder"
        {...props}
        dataDistribution={props.form.getValues()?.data?.yAxisDataDistribution}
      />

      <Divider orientation="horizontal" />

      <Split
        label="vertical split"
        dashletFields={dashletFields}
        {...firstSplitField}
      />

      <Split
        label="horizontal split"
        dashletFields={dashletFields}
        {...secondSplitField}
      />
    </div>
  );
}
