import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgEmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m11.99 9.318 4.1-3.563v7.085l-4.1-3.522Zm-5.284.603 1.44 1.25c.226.192.52.308.842.308H9h-.001.01c.322 0 .617-.117.845-.31l-.002.001 1.44-1.25 4.378 3.76H2.33l4.376-3.76ZM2.324 4.91h13.354l-6.414 5.57a.407.407 0 0 1-.254.089h-.018a.406.406 0 0 1-.254-.089h.001L2.324 4.91Zm-.414.844 4.1 3.563-4.1 3.52V5.754Zm14.4-1.627A1.227 1.227 0 0 0 15.764 4H2.24c-.193 0-.383.045-.556.131l.007-.003A1.237 1.237 0 0 0 1 5.237v8.115a1.24 1.24 0 0 0 1.239 1.238H15.76A1.24 1.24 0 0 0 17 13.352V5.237c0-.485-.28-.905-.686-1.107l-.007-.003h.004Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEmailIcon;
