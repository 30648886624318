import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import {
  MouseHandler,
  MousePosition,
  getMousePosition,
} from './useSelectionGroup';
import { useDebounce } from './useDebounce';

export function useMouseHandler(mouseContainerRef: RefObject<HTMLDivElement>) {
  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0,
  });
  const [dynamicCorsurIsVisible, setDynamicCorsurIsVisible] = useState(false);

  const debounceSetCursorIsNotVisible = useDebounce(() => {
    setDynamicCorsurIsVisible(false);
  }, 100);

  const showCursor = useCallback(() => {
    setDynamicCorsurIsVisible(true);
    debounceSetCursorIsNotVisible();
  }, [debounceSetCursorIsNotVisible]);

  const mouseHandler: MouseHandler = useMemo(() => {
    return {
      mousePosition,
      showCursor,
    };
  }, [showCursor, mousePosition]);

  const handleMouseMove = useCallback((e) => {
    setMousePosition(getMousePosition(e));
  }, []);

  useEffect(() => {
    const container = mouseContainerRef.current;
    if (container && dynamicCorsurIsVisible) {
      container.addEventListener('mousemove', handleMouseMove);
    } else if (container) {
      container.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [dynamicCorsurIsVisible, handleMouseMove, mouseContainerRef]);

  return { mouseHandler };
}
