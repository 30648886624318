import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M14.99 1c.546 0 1.018.198 1.415.595.397.385.595.844.595 1.377v12.019c0 .545-.205 1.017-.614 1.414-.397.397-.862.595-1.395.595h-3.014v-5.767h2.12V8.74h-2.12V7.437c0-.372.173-.558.52-.558h1.6V4.051h-1.785c-.918 0-1.687.341-2.307 1.023-.62.67-.93 1.483-.93 2.438V8.74H7.14v2.493h1.934V17H3.01c-.533 0-1.004-.198-1.414-.595A1.932 1.932 0 0 1 1 14.99V2.972c0-.533.198-.992.595-1.377A1.932 1.932 0 0 1 3.01 1h11.982Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFacebookIcon;
