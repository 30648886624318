import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCollectionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.91 4.636a.545.545 0 0 0-.546-.545H9a.545.545 0 0 0-.545.545v.546h2.727a1.636 1.636 0 0 1 1.636 1.636v2.727h.546A.545.545 0 0 0 13.909 9V4.636Zm-1.092 6h.546A1.636 1.636 0 0 0 15 9V4.636A1.636 1.636 0 0 0 13.364 3H9a1.636 1.636 0 0 0-1.636 1.636v.546h-.546a1.636 1.636 0 0 0-1.636 1.636v.546h-.546A1.636 1.636 0 0 0 3 9v4.364A1.636 1.636 0 0 0 4.636 15H9a1.636 1.636 0 0 0 1.636-1.636v-.546h.546a1.636 1.636 0 0 0 1.636-1.636v-.546Zm-1.09-3.818a.545.545 0 0 0-.546-.545H6.818a.545.545 0 0 0-.545.545v.546H9A1.636 1.636 0 0 1 10.636 9v2.727h.546a.545.545 0 0 0 .545-.545V6.818ZM9.544 9A.545.545 0 0 0 9 8.455H4.636A.545.545 0 0 0 4.091 9v4.364a.545.545 0 0 0 .545.545H9a.545.545 0 0 0 .545-.545V9Z"
      fill="currentColor"
    />
    <circle cx={6.801} cy={11.2} r={1} fill="currentColor" />
  </svg>
);

export default SvgCollectionIcon;
