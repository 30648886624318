import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgVi1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m16 6-9 9-4.5-4.5 1.688-1.688L7 11.626l7.313-7.313L16 6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVi1;
