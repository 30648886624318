import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPlusMini = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M8.834 2.97a.629.629 0 0 1 .461-.192c.179 0 .33.064.456.192a.612.612 0 0 1 .192.456V8.61h5.185c.18 0 .331.064.456.193a.612.612 0 0 1 .192.455.629.629 0 0 1-.192.461.62.62 0 0 1-.456.187H9.943v5.186c0 .178-.064.33-.192.455a.612.612 0 0 1-.456.193.629.629 0 0 1-.46-.193.62.62 0 0 1-.188-.455V9.907H3.462a.637.637 0 0 1-.649-.648.62.62 0 0 1 .188-.455.629.629 0 0 1 .46-.193h5.186V3.426a.62.62 0 0 1 .187-.456Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlusMini;
