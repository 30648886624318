import { ReactNode, useCallback, useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { useAuth } from '../auth/AuthContext';
import { ContactTrialDialog } from '../ui/support/ContactTrialDialog';
import { StateObject, useStateObject } from '../core/types';

export type FreemiumData = {
  isSingleUser: boolean;
  daysLeft: number;
  runOrOpenUpgrade: (func: () => void) => void;
  upgradeDialogState: StateObject;
  upgradeDialog: ReactNode;
  freemiumUserData: FreemiumUserData;
};

export type FreemiumUserData = {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  role: string;
};

export function useFreemium(): FreemiumData {
  const { user } = useAuth();
  const upgradeDialogState = useStateObject(false);

  const isSingleUser = useMemo(
    () => !!user?.singleUserMode,
    [user?.singleUserMode],
  );

  const daysLeft = useMemo(() => {
    if (!isSingleUser) {
      return Math.max();
    }
    const expirationDate = new Date(
      user?.singleUserMode?.expirationDate || new Date(),
    );
    const daysLeft = differenceInDays(expirationDate, new Date());

    return daysLeft >= 0 ? daysLeft : 0;
  }, [isSingleUser, user?.singleUserMode?.expirationDate]);

  const canPerformJobActions = useMemo(
    () => !isSingleUser || daysLeft > 0,
    [daysLeft, isSingleUser],
  );

  const runOrOpenUpgrade = useCallback(
    (func: () => void) => {
      if (canPerformJobActions) {
        func();
      } else {
        upgradeDialogState.setState(true);
      }
    },
    [canPerformJobActions, upgradeDialogState],
  );

  const upgradeDialog = useMemo(() => {
    return upgradeDialogState.state ? (
      <ContactTrialDialog openState={upgradeDialogState} />
    ) : undefined;
  }, [upgradeDialogState]);

  const freemiumUserData: FreemiumUserData = useMemo(() => {
    let fullName = '';
    let firstName = '';
    let lastName = '';
    const email = user?.local?.email || user?.singleUserMode?.email || '';
    const phone = user?.singleUserMode?.phoneNumber || '';
    const company =
      user?.metadata?.company || user?.singleUserMode?.company || '';
    const role = user?.singleUserMode?.firstName || '';

    if (
      user?.singleUserMode?.trialStarted &&
      isSingleUser &&
      (user?.singleUserMode?.firstName || user?.singleUserMode?.lastName)
    ) {
      firstName = user?.singleUserMode?.firstName;
      lastName = user?.singleUserMode?.lastName;
    } else if (user?.singleUserMode?.trialStarted && user?.local.name) {
      const splitName = user?.local.name.split(' ');
      if (splitName.length > 1 && splitName[0] && splitName[1]) {
        firstName = splitName[0];
        lastName = splitName.slice(1).join(' ');
      } else {
        firstName = user?.local.name;
        lastName = '';
      }
    }
    if (firstName && lastName) {
      fullName = `${firstName} ${lastName}`;
    } else if (firstName) {
      fullName = firstName;
    } else if (lastName) {
      fullName = lastName;
    }

    return {
      fullName,
      firstName,
      lastName,
      email,
      phone,
      company,
      role,
    };
  }, [isSingleUser, user]);

  return {
    isSingleUser,
    daysLeft,
    runOrOpenUpgrade,
    upgradeDialogState,
    upgradeDialog,
    freemiumUserData,
  };
}
