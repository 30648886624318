import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgRemDb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m13.441 11.638 3.797-8.315c.235-.526.117-.92-.351-1.183-.492-.263-.89-.155-1.196.322L10.734 10.6c-.375.55-.398 1.11-.07 1.684.281.478.691.717 1.23.717.704 0 1.22-.454 1.547-1.362ZM17.45 5.688c.398-.382.773-.37 1.124.036C20.191 7.492 21 9.62 21 12.104a.796.796 0 0 1-.246.573.708.708 0 0 1-.527.216.877.877 0 0 1-.598-.216.866.866 0 0 1-.211-.573c0-2.031-.668-3.787-2.004-5.269-.352-.43-.34-.812.035-1.147ZM13.195 4.219a.702.702 0 0 1-.562.179l-.317-.036c-.21 0-.316.012-.316.036-2.063 0-3.82.753-5.273 2.258-1.43 1.505-2.145 3.333-2.145 5.484a.797.797 0 0 1-.246.573c-.14.144-.328.215-.563.215a.83.83 0 0 1-.562-.215A.866.866 0 0 1 3 12.14c0-2.58.879-4.78 2.637-6.595 1.758-1.84 3.879-2.76 6.363-2.76.07 0 .188.012.352.036h.421c.211.024.387.12.528.287.14.167.199.358.176.573a.807.807 0 0 1-.282.538ZM16.675 15.325a1.069 1.069 0 0 0-.8-.325c-.316 0-.583.108-.8.325L12.5 17.91l-2.575-2.584a1.087 1.087 0 0 0-.8-.325A1.098 1.098 0 0 0 8 16.125c0 .316.108.583.325.8L10.91 19.5l-2.584 2.575a1.087 1.087 0 0 0-.325.8c0 .322.105.592.316.809.217.21.487.316.809.316.316 0 .583-.108.8-.325L12.5 21.09l2.575 2.584c.217.217.484.325.8.325A1.098 1.098 0 0 0 17 22.875c0-.316-.108-.583-.325-.8L14.09 19.5l2.584-2.575c.217-.217.325-.484.325-.8 0-.322-.108-.589-.325-.8Z"
      fill="currentColor"
    />
    <path
      d="M2 1.1h20a.9.9 0 0 1 .9.9v12a.9.9 0 0 1-.9.9h-3V16h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h4v-1.1H2a.9.9 0 0 1-.9-.9V2a.9.9 0 0 1 .9-.9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRemDb;
