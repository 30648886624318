import { AllSessionsTestResults } from '@tensorleap/api-client';
import clsx from 'clsx';
import { useMemo } from 'react';
import { compactNumberFormatter } from '../core/formatters/number-formatting';
import { TEXT_COLOR_TEMPLATES } from '../ui/molecules/colorTemplates';
import {
  SelectedSessionRun,
  useModelFilter,
} from '../ui/molecules/useModelFilter';
import { Tooltip } from '../ui/mui';
import { isSessionTestResultSuccess } from './useModelTestSummaryResult';

type TestResultRangeElement = {
  value: number;
  selectedModel: SelectedSessionRun | undefined;
};

export interface ModelTestResultrangeProps {
  modelTestsResults?: AllSessionsTestResults;
  className?: string;
}

export function ModelTestResultrange({
  modelTestsResults,
  className,
}: ModelTestResultrangeProps): JSX.Element {
  const { selected: selectedModels } = useModelFilter();

  const ranModelsResults = useMemo(
    () => modelTestsResults?.sessionsResults.filter(isSessionTestResultSuccess),
    [modelTestsResults?.sessionsResults],
  );

  const [minRangeElement, maxRangeElement] = useMemo<
    [TestResultRangeElement | undefined, TestResultRangeElement | undefined]
  >(() => {
    if (!ranModelsResults?.length) {
      return [undefined, undefined];
    }

    const metricValueMap = ranModelsResults.map(
      (testResult) => testResult.aggregation,
    );

    const max = Math.max(...metricValueMap);
    const min = Math.min(...metricValueMap);

    const maxModelTestResult = ranModelsResults.find(
      (modelResult) => modelResult.aggregation === max,
    );

    const maxSelectedModelElement = selectedModels.find(
      (selectedModel) => selectedModel.id === maxModelTestResult?.sessionRunId,
    );
    const maxRangeElement = {
      value: max,
      selectedModel: maxSelectedModelElement,
    };

    const minSessionTestResult = ranModelsResults.find(
      (modelResult) => modelResult.aggregation === min,
    );
    const minSelectedModelElement = selectedModels.find(
      (selectedModel) =>
        selectedModel.id === minSessionTestResult?.sessionRunId,
    );
    const minRangeElement = {
      value: min,
      selectedModel: minSelectedModelElement,
    };
    return [minRangeElement, maxRangeElement];
  }, [ranModelsResults, selectedModels]);

  const maxElement = useMemo(
    () =>
      maxRangeElement?.selectedModel && (
        <Tooltip title={maxRangeElement.selectedModel.name}>
          <span
            className={
              TEXT_COLOR_TEMPLATES[maxRangeElement.selectedModel.modelUniqueKey]
            }
          >
            {compactNumberFormatter.format(maxRangeElement.value)}
          </span>
        </Tooltip>
      ),
    [maxRangeElement],
  );

  const minElement = useMemo(
    () =>
      minRangeElement?.selectedModel && (
        <Tooltip title={minRangeElement.selectedModel.name || ''}>
          <span
            className={
              TEXT_COLOR_TEMPLATES[minRangeElement.selectedModel.modelUniqueKey]
            }
          >
            {compactNumberFormatter.format(minRangeElement.value)}
          </span>
        </Tooltip>
      ),
    [minRangeElement],
  );

  const displayMaxRangeElement =
    maxRangeElement?.value !== minRangeElement?.value &&
    minElement !== undefined;
  const displayRangeDivider =
    maxElement !== undefined &&
    minElement !== undefined &&
    maxRangeElement?.value !== minRangeElement?.value;

  return (
    <div className={clsx('capitalize flex flex-row gap-1', className)}>
      {ranModelsResults ? (
        <>
          <span className="text-gray-500 pr-1 uppercase">avg</span>
          {displayMaxRangeElement && minElement}
          {displayRangeDivider && <span>-</span>}
          {maxElement}
        </>
      ) : (
        <span className="text-gray-600 uppercase">no data available</span>
      )}
    </div>
  );
}
