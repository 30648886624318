import { useDatasets } from '../../../core/DatasetsContext';
import SvgClosePanel from '../../../ui/icons/ClosePanel';
import SvgOpenPanel from '../../../ui/icons/OpenPanel';
import { useCallback, useMemo } from 'react';
import {
  NetworkWizardData,
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardErrorSeverity,
} from '../types';
import { useMappingUtils } from '../../../core/mappingUtils';

export const DATASET_MAPPING_MISSMATCH_MESSAGE =
  'The dataset mapping code is inconsistent with the network mapping';
export const NO_DATASET_MAPPING_MESSAGE =
  'No dataset mapping file found for network. Create a new dataset mapping file based on the existing map';

export const DATASET_MAPPING_MISSMATCH_TITLE = 'inconsistent mapping';
export const NO_DATASET_MAPPING_TITLE = 'No Dataset mapping';

const CREATE_BUTTON_TEXT = 'Create';
const CREATE_BUTTON_TOOLTIP =
  'Create a new code integration based on the existing map';

const UPDATE_BUTTON_TEXT = 'Update';
const UPDATE_BUTTON_TOOLTIP =
  'Update code integration based on the existing map';

export const APPLY_BUTTON_TEXT = 'Apply';
export const APPLY_BUTTON_TOOLTIP =
  'Apply the existing mapping code to the map';

export function useMappingCreateOrUpdateText() {
  const { datasetMappingFileExists, networkAndDatasetMappingsAreEqual } =
    useDatasets();
  return useMemo(
    () =>
      datasetMappingFileExists && !networkAndDatasetMappingsAreEqual
        ? UPDATE_BUTTON_TEXT
        : CREATE_BUTTON_TEXT,
    [datasetMappingFileExists, networkAndDatasetMappingsAreEqual],
  );
}
export function useMappingCreateOrUpdateTooltip() {
  const { datasetMappingFileExists, networkAndDatasetMappingsAreEqual } =
    useDatasets();
  return useMemo(
    () =>
      datasetMappingFileExists && !networkAndDatasetMappingsAreEqual
        ? UPDATE_BUTTON_TOOLTIP
        : CREATE_BUTTON_TOOLTIP,
    [datasetMappingFileExists, networkAndDatasetMappingsAreEqual],
  );
}

export function useDatasetMappingCreateUpdateApplyData(): NetworkWizardData[] {
  const { addOrUpdateNetworkMapping, applyDatasetMapping } = useMappingUtils();
  const { datasetMappingFileExists, networkAndDatasetMappingsAreEqual } =
    useDatasets();
  const createOrUpdateText = useMappingCreateOrUpdateText();
  const createOrUpdateTooltip = useMappingCreateOrUpdateTooltip();

  const quickFixes = useMemo(() => {
    const fixes = [
      {
        title: createOrUpdateText,
        tooltipMsg: createOrUpdateTooltip,
        onSelect: addOrUpdateNetworkMapping,
        disableQuickFix: false,
        icon: <SvgOpenPanel />,
      },
    ];
    if (datasetMappingFileExists && !networkAndDatasetMappingsAreEqual) {
      fixes.unshift({
        title: APPLY_BUTTON_TEXT,
        tooltipMsg: APPLY_BUTTON_TOOLTIP,
        onSelect: applyDatasetMapping,
        disableQuickFix: false,
        icon: <SvgClosePanel className="w-5" />,
      });
    }
    return fixes;
  }, [
    createOrUpdateText,
    createOrUpdateTooltip,
    addOrUpdateNetworkMapping,
    datasetMappingFileExists,
    networkAndDatasetMappingsAreEqual,
    applyDatasetMapping,
  ]);

  const message = datasetMappingFileExists
    ? DATASET_MAPPING_MISSMATCH_MESSAGE
    : NO_DATASET_MAPPING_MESSAGE;

  const calculateKey = useCallback(
    () =>
      message + datasetMappingFileExists
        ? DATASET_MAPPING_MISSMATCH_TITLE
        : NO_DATASET_MAPPING_TITLE,
    [datasetMappingFileExists, message],
  );

  return useMemo(
    () => [
      {
        errorType: GraphErrorKind.datasetMappingCreateUpdateApply,
        category: NetworkWizardCategory.CODE,
        title: datasetMappingFileExists
          ? DATASET_MAPPING_MISSMATCH_TITLE
          : NO_DATASET_MAPPING_TITLE,
        message,
        calculateKey,
        showNodeFooter: true,
        quickFixes: quickFixes,
        errorSeverity: datasetMappingFileExists
          ? NetworkWizardErrorSeverity.WARNING
          : NetworkWizardErrorSeverity.ERROR,
        key: calculateKey(),
      },
    ],
    [datasetMappingFileExists, message, calculateKey, quickFixes],
  );
}
