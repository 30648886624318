import clsx from 'clsx';
import { forwardRef, Ref } from 'react';
import {
  CommandAndBoundingBox,
  normalizeAndBBoxPoints,
  transformPath,
  normalizeAndBBoxPath,
  PointsAndBoundingBox,
  transformPolygon,
} from './svgPathUtlis';
import _ from 'lodash';

type AllShapeData =
  | {
      type: 'circle';
    }
  | {
      type: 'rect';
      scaleX?: number; // 0 - 1
      scaleY?: number; // 0 - 1
    }
  | {
      type: 'ellipse';
      scaleX?: number; // 0 - 1
      scaleY?: number; // 0 - 1
    }
  | {
      type: 'path';
      d: CommandAndBoundingBox;
    }
  | {
      type: 'polygon';
      points: PointsAndBoundingBox;
    };

export const SHAPES = [
  'circle',
  'rectangle',
  'triangle',
  'hexagon',
  'star',
  'plus',
  'isosceles-triangle',
  'vertical-ellipse',
  'diamond',
] as const;

export type ShapeType = (typeof SHAPES)[number];

export const SHAPE_MAP: Record<ShapeType, AllShapeData> = {
  circle: { type: 'circle' },
  rectangle: { type: 'rect' },
  'vertical-ellipse': { type: 'ellipse', scaleX: 1, scaleY: 0.5 },

  triangle: {
    type: 'polygon',
    points: normalizeAndBBoxPoints([
      [0.5, 0], // Top
      [1, 1], // Bottom-right
      [0, 1], // Bottom-left
    ]),
  },

  'isosceles-triangle': {
    type: 'path',
    d: normalizeAndBBoxPath([
      { type: 'M', points: [50, 250] },
      { type: 'L', points: [150, 250] },
      { type: 'L', points: [100, 350] },
      { type: 'Z', points: [] },
    ]),
  },

  diamond: {
    type: 'polygon',
    points: normalizeAndBBoxPoints([
      [0.5, 0], // Top
      [1, 0.5], // Right
      [0.5, 1], // Bottom
      [0, 0.5], // Left
    ]),
  },
  star: {
    type: 'polygon',
    points: normalizeAndBBoxPoints([
      [0.5, 0], // Top
      [0.6, 0.35], // Top-right
      [1, 0.35], // Right
      [0.7, 0.6], // Bottom-right
      [0.8, 1], // Bottom
      [0.5, 0.75], // Center-bottom
      [0.2, 1], // Bottom
      [0.3, 0.6], // Bottom-left
      [0, 0.35], // Left
      [0.4, 0.35], // Top-left
    ]),
  },
  plus: {
    type: 'path',
    d: normalizeAndBBoxPath([
      { type: 'M', points: [0.4, 0] },
      { type: 'L', points: [0.6, 0] },
      { type: 'L', points: [0.6, 0.4] },
      { type: 'L', points: [1, 0.4] },
      { type: 'L', points: [1, 0.6] },
      { type: 'L', points: [0.6, 0.6] },
      { type: 'L', points: [0.6, 1] },
      { type: 'L', points: [0.4, 1] },
      { type: 'L', points: [0.4, 0.6] },
      { type: 'L', points: [0, 0.6] },
      { type: 'L', points: [0, 0.4] },
      { type: 'L', points: [0.4, 0.4] },
      { type: 'Z', points: [] },
    ]),
  },
  hexagon: {
    type: 'polygon',
    points: normalizeAndBBoxPoints([
      [0.5, 0], // Top
      [1, 0.25], // Top-right
      [1, 0.75], // Bottom-right
      [0.5, 1], // Bottom
      [0, 0.75], // Bottom-left
      [0, 0.25], // Top-left
    ]),
  },
};

type ScatterShapeProps = {
  id?: string;
  className?: string;
  isSelected?: boolean;
  style?: React.CSSProperties;
  x: number;
  y: number;
  shapeType: ShapeType;
  size: number;
  isHighlighted?: boolean;
  isMarked?: boolean;
  onClick?: (e: React.SyntheticEvent<SVGElement, Event>) => void;
  onMouseEnter?: (e: React.SyntheticEvent<SVGElement, Event>) => void;
  onMouseLeave?: (e: React.SyntheticEvent<SVGElement, Event>) => void;
  onMouseDown?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
};
const _ScatterShape = (
  {
    id,
    className,
    isSelected,
    x,
    y,
    size,
    style,
    onClick,
    onMouseDown,
    isHighlighted,
    shapeType,
    isMarked,
    ...rest
  }: ScatterShapeProps,
  ref: React.Ref<SVGElement>,
) => {
  const shape = SHAPE_MAP[shapeType];
  if (isHighlighted) {
    size = size * 1.1;
  }
  style = {
    ...style,
    transition:
      'height 0.1s ease-out, width 0.1s ease-out, stroke 0.1s ease-out, stroke-width 0.1s ease-out, stroke-opacity 0.1s ease-out',
  };

  const commonProps = {
    id,
    style,
    className: clsx(
      className,
      isSelected && 'selected-class',
      (isHighlighted || isMarked) && 'hovered-class',
    ),
    onClick,
    onMouseDown,
    ...rest,
  };

  switch (shape.type) {
    case 'circle':
      return (
        <circle
          ref={ref as Ref<SVGCircleElement>}
          cx={x}
          cy={y}
          r={size / 2}
          {...commonProps}
        />
      );
    case 'rect': {
      const xSize = size * (shape.scaleX ?? 1);
      const ySize = size * (shape.scaleY ?? 1);
      return (
        <rect
          x={x - xSize / 2}
          y={y - ySize / 2}
          height={ySize}
          width={xSize}
          {...commonProps}
          ref={ref as Ref<SVGRectElement>}
        />
      );
    }
    case 'ellipse':
      return (
        <ellipse
          cx={x}
          cy={y}
          rx={(size / 2) * (shape.scaleY ?? 1)}
          ry={(size / 2) * (shape.scaleX ?? 1)}
          {...commonProps}
          ref={ref as Ref<SVGEllipseElement>}
        />
      );

    case 'polygon':
      return (
        <polygon
          points={transformPolygon(shape.points, x, y, size)}
          {...commonProps}
          ref={ref as Ref<SVGPolygonElement>}
        />
      );
    case 'path':
      // Use transform to scale the path
      return (
        <path
          d={transformPath(shape.d, x, y, size)}
          {...commonProps}
          ref={ref as Ref<SVGPathElement>}
        />
      );
    default:
      return null;
  }
};

export const ScatterShape = forwardRef<SVGElement, ScatterShapeProps>(
  _ScatterShape,
);
ScatterShape.displayName = 'ScatterShape';
