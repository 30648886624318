import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFullScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.414 3.586A2 2 0 0 0 21 3H3a2 2 0 0 0-2 2v3a1 1 0 0 0 2 0V5h18v14h-5a1 1 0 1 0 0 2h5a2 2 0 0 0 2-2V5a2 2 0 0 0-.586-1.414Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 13a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-6ZM3 13h8v6H3v-6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFullScreenIcon;
