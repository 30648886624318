import useSWR, { KeyedMutator } from 'swr';
import { JobSubType, RunProcess } from '@tensorleap/api-client';

import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';
import { useMemo } from 'react';

export interface UseFetchJobList {
  processesList: RunProcess[] | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<RunProcess[]>;
}

const ignoreJobSubTypesSet = new Set([
  JobSubType.ExportProject,
  JobSubType.CopyProject,
]);

const hiddenJobTypes = [JobSubType.ExportProject, JobSubType.CopyProject];

export const JOB_SUB_TYPE_TO_DISPLAY = Object.values(JobSubType).filter(
  (subType) => !ignoreJobSubTypesSet.has(subType),
);

export const DEFAULT_JOB_SUB_TYPES = JOB_SUB_TYPE_TO_DISPLAY.filter(
  (subType) => subType !== JobSubType.PopulationExploration,
);

export function useFetchJobList(
  jobSubTypes = DEFAULT_JOB_SUB_TYPES,
): UseFetchJobList {
  const expandedJobSubTypes = useMemo(() => {
    if (jobSubTypes.includes(JobSubType.ImportProject)) {
      return [...jobSubTypes, ...hiddenJobTypes];
    }
    return jobSubTypes;
  }, [jobSubTypes]);

  const {
    data: processesList,
    error,
    mutate,
  } = useSWR<RunProcess[], Error>(
    CacheKey.JOB_LIST + String(jobSubTypes),
    async () => {
      const { processes } = await api.getTeamJobs({
        subTypes: expandedJobSubTypes,
      });
      return processes;
    },
  );

  return useMergedObject({
    processesList,
    error,
    isLoading: !error && !processesList,
    refetch: mutate,
  });
}
