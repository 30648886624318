import { TooltipProps } from 'recharts';
import { useMemo } from 'react';
import { CustomTooltip } from '../../common/CustomTooltip';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { ChartRequestData, ColorMap } from '../../common/interfaces';
import { GenericDataResponse, NumberOrString } from '@tensorleap/api-client';

export interface TooltipParams {
  graphData: GenericDataResponse;
  colorMap: ColorMap;
  chartRequestData: ChartRequestData;
  mapValue: (value: NumberOrString) => NumberOrString;
}

export function useTooltipProps({
  graphData,
  colorMap,
  chartRequestData,
  mapValue,
}: TooltipParams): TooltipProps<ValueType, NameType> {
  return useMemo(
    (): TooltipProps<ValueType, NameType> => ({
      wrapperClassName: '!bg-gray-800 rounded !border-none shadow',
      content: (
        <CustomTooltip
          graphData={graphData}
          colorMap={colorMap}
          chartRequestData={chartRequestData}
          mapValue={mapValue}
        />
      ),
    }),
    [chartRequestData, colorMap, graphData, mapValue],
  );
}
