import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgGitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M6.252 9.496c.072.544.294 1.203.666 1.977.387.773 1.11 1.36 2.17 1.761 1.074.401 1.697.767 1.869 1.096.186.315.28.58.28.795v.15c-.66.2-1.204.58-1.633 1.14a3.052 3.052 0 0 0-.645 1.911c0 .888.308 1.647.924 2.277.63.63 1.39.946 2.277.946.888 0 1.64-.315 2.256-.945.63-.63.945-1.39.945-2.278 0-.716-.215-1.353-.644-1.912a3.19 3.19 0 0 0-1.633-1.139v-.15c0-.215.086-.48.258-.795.186-.315.816-.68 1.89-1.096 1.06-.4 1.777-.988 2.149-1.761.387-.774.616-1.433.687-1.977a3.176 3.176 0 0 0 1.676-1.117 3.106 3.106 0 0 0 .666-1.955c0-.888-.315-1.647-.945-2.278-.63-.63-1.382-.945-2.256-.945-.888 0-1.647.315-2.277.945-.63.63-.946 1.39-.946 2.278 0 .702.208 1.332.623 1.89.43.545.967.924 1.612 1.14-.072.343-.222.722-.451 1.138-.23.4-.623.709-1.182.924a11.07 11.07 0 0 0-1.418.666c-.401.229-.738.48-1.01.752a4.757 4.757 0 0 0-1.01-.752c-.4-.23-.873-.452-1.418-.666-.558-.215-.952-.523-1.181-.924-.23-.416-.38-.795-.451-1.139a3.252 3.252 0 0 0 1.59-1.139c.43-.558.644-1.188.644-1.89 0-.888-.315-1.647-.945-2.278a3.104 3.104 0 0 0-2.278-.945c-.873 0-1.625.315-2.256.945-.63.63-.945 1.39-.945 2.278 0 .73.222 1.382.666 1.955a3.176 3.176 0 0 0 1.676 1.117ZM17.209 5.05c.372 0 .695.136.967.408.272.258.408.58.408.967 0 .372-.136.694-.408.967a1.321 1.321 0 0 1-.967.408 1.358 1.358 0 0 1-1.375-1.375c0-.387.129-.71.387-.967.272-.272.601-.408.988-.408Zm-3.674 13.277a1.359 1.359 0 0 1-1.375 1.375 1.358 1.358 0 0 1-1.375-1.375c0-.372.136-.695.408-.967.273-.272.595-.408.967-.408.373 0 .695.136.967.408.272.272.408.595.408.967ZM7.111 5.05c.387 0 .71.136.967.408.272.258.408.58.408.967 0 .372-.136.694-.408.967-.258.272-.58.408-.967.408-.372 0-.694-.136-.966-.408a1.321 1.321 0 0 1-.409-.967c0-.387.136-.71.409-.967.272-.272.594-.408.966-.408Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGitIcon;
