import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgXClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12.675 5.325a1.069 1.069 0 0 0-.8-.325c-.316 0-.583.108-.8.325L8.5 7.91 5.925 5.325a1.087 1.087 0 0 0-.8-.325A1.098 1.098 0 0 0 4 6.125c0 .316.108.583.325.8L6.91 9.5l-2.584 2.575a1.087 1.087 0 0 0-.325.8c0 .322.105.592.316.809.217.21.487.316.809.316.316 0 .583-.108.8-.325L8.5 11.09l2.575 2.584c.217.217.484.325.8.325A1.098 1.098 0 0 0 13 12.875c0-.316-.108-.583-.325-.8L10.09 9.5l2.584-2.575c.217-.217.325-.484.325-.8 0-.322-.108-.589-.325-.8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgXClose;
