import { useCallback, useMemo } from 'react';
import { useVersionControl } from '../core/VersionControlContext';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';

export function DeleteSessionRunDialog() {
  const {
    sessionRunToDelete,
    setSessionRunToDelete,
    handleConfirmDeleteSessionRun,
  } = useVersionControl();

  const handleClose = useCallback(() => {
    setSessionRunToDelete(undefined);
  }, [setSessionRunToDelete]);

  const title = useMemo(
    () =>
      `Are you sure you want to delete ${
        sessionRunToDelete?.name || 'this'
      } run?`,
    [sessionRunToDelete?.name],
  );

  return (
    <ConfirmDialog
      title={title}
      isOpen={!!sessionRunToDelete}
      onClose={handleClose}
      onConfirm={handleConfirmDeleteSessionRun}
    />
  );
}
