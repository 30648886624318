import {
  ResponsiveContainer,
  AreaChart as ReAreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
} from 'recharts';
import { NoDataChart } from '../common/NoDataChart';
import { GRAPH_STYLE } from '../common/constants';
import { useXYChart } from '../hooks/useXYChart';
import { useXYChartDragFilter } from '../hooks/useXYChartDragFilter';
import { XYChartProps } from '../common/interfaces';
import { AnalyticsDashletType } from '@tensorleap/api-client';
import { useXAxisProps } from './ChartBlocks/XAxisProps';
import { useYAxisProps } from './ChartBlocks/YAxisProps';
import { useTooltipProps } from './ChartBlocks/TooltipProps';

export function AreaChart({
  graphData,
  chartRequestData,
  className,
  onFiltersChange,
  localFilters,
  xAxisDomain,
  yAxisDomain,
  showXAxisLine = true,
  showYLabel = true,
  hiddenLabels,
  hoverLabel,
  colorMap,
  axisType,
  scaleY,
  mapValue,
  height,
  width,
}: XYChartProps): JSX.Element {
  const {
    mergedData,
    strokeOpacity,
    fillOpacity,
    isHidden,
    allLabels,
    setIsMouseDown,
    graphKey,
  } = useXYChart({
    graphData,
    chartRequestData,
    chartType: AnalyticsDashletType.Area,
    hiddenLabels,
    hoverLabel,
  });

  const lines = mergedData();

  const {
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    referenceAreaProps,
  } = useXYChartDragFilter({
    graphData,
    chartRequestData,
    axisType,
    localFilters,
    onFiltersChange,
    setIsMouseDown,
  });

  const xAxisProps = useXAxisProps({
    displayName: chartRequestData.xField,
    displayAxis: showXAxisLine,
    domain: xAxisDomain,
    axisType,
  });
  const yAxisProps = useYAxisProps({
    displayName: chartRequestData.yField,
    showLabel: showYLabel,
    domain: yAxisDomain,
    scale: scaleY,
  });
  const tooltipProps = useTooltipProps({
    graphData,
    colorMap,
    chartRequestData,
    mapValue,
  });

  if (!graphData.data?.length || !lines) {
    return <NoDataChart />;
  }

  return (
    <ResponsiveContainer
      height={height}
      width={width}
      className={className}
      key={graphKey}
    >
      <ReAreaChart
        data={lines}
        margin={GRAPH_STYLE.margin}
        className="select-none"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
        <Tooltip {...tooltipProps} />
        {referenceAreaProps && <ReferenceArea {...referenceAreaProps} />}
        {allLabels.map((datakey, index) => (
          <Area
            key={index}
            dot={{
              stroke: colorMap[datakey],
              strokeWidth: 1,
              r: 1,
            }}
            connectNulls={true}
            dataKey={datakey}
            fill={colorMap[datakey]}
            fillOpacity={fillOpacity(datakey)}
            stroke={colorMap[datakey]}
            strokeWidth={GRAPH_STYLE.line.strokeWidth}
            type="linear"
            hide={isHidden(datakey)}
            isAnimationActive={false}
            activeDot={{
              cursor: 'pointer',
            }}
            strokeOpacity={strokeOpacity(datakey)}
          />
        ))}
      </ReAreaChart>
    </ResponsiveContainer>
  );
}
