import { useCallback, useMemo } from 'react';
import { UseFetchAllSlimUsers } from '../core/data-fetching/users';
import { Table } from '../ui/model-list/table/Table';
import { TeamMemberData } from './TeamManagementTypes';
import api from '../core/api-client';
import { useAuth } from '../auth/AuthContext';
import { MenuAction } from '../ui/model-list/table/TableRowActions';
import { useMemberFields } from './useMemberFields';
export interface MemberManagementProps {
  maxActiveUsers: number;
}

export function MemberManagement({
  maxActiveUsers,
}: MemberManagementProps): JSX.Element {
  const { teamData, refetch } = UseFetchAllSlimUsers();
  const { user } = useAuth();
  const memberFields = useMemberFields(maxActiveUsers);

  const updateUserStatus = useCallback(
    async (userId: string, active: boolean) => {
      await api.updateUserStatus({
        userId,
        active,
      });
      refetch();
    },
    [refetch],
  );

  const menuActions = useMemo<MenuAction<TeamMemberData>[]>(
    () => [
      {
        title: 'Deactivate User',
        filter: (item) => item.cid !== user?.cid,
        onSelect: ({ cid }) => updateUserStatus(cid, false),
      },
    ],
    [updateUserStatus, user?.cid],
  );

  return (
    <div className="flex flex-col gap-2 w-full max-h-full overflow-hidden">
      <span className="uppercase text-xl ml-3">members</span>
      <Table menuActions={menuActions} fields={memberFields} data={teamData} />
    </div>
  );
}
