import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgZoomOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M11.724 11A5.428 5.428 0 0 0 13 7.5 5.5 5.5 0 1 0 7.5 13c1.28 0 2.52-.451 3.5-1.276l3.793 3.776.707-.707L11.724 11ZM7.5 12a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Z"
      fill="currentColor"
    />
    <rect
      x={4.95}
      y={6.95}
      width={5.1}
      height={1.1}
      rx={0.25}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
    />
  </svg>
);

export default SvgZoomOutIcon;
