import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgThreeDotsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M9.688 7.227a.954.954 0 0 1-.704.28c-.265 0-.5-.093-.703-.28A1.034 1.034 0 0 1 8 6.5c0-.281.094-.516.281-.703a.966.966 0 0 1 .703-.305c.282 0 .516.102.704.305a.917.917 0 0 1 .304.703.992.992 0 0 1-.305.727ZM8.28 8.797a.966.966 0 0 1 .703-.305c.282 0 .516.102.704.305a.917.917 0 0 1 .304.703.992.992 0 0 1-.305.727.954.954 0 0 1-.703.28c-.265 0-.5-.093-.703-.28A1.035 1.035 0 0 1 8 9.5c0-.281.094-.516.281-.703Zm0 3a.966.966 0 0 1 .703-.305c.282 0 .516.102.704.305a.917.917 0 0 1 .304.703.992.992 0 0 1-.305.727.954.954 0 0 1-.703.28c-.265 0-.5-.093-.703-.28A1.035 1.035 0 0 1 8 12.5c0-.281.094-.516.281-.703Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgThreeDotsIcon;
