import { FilterOperatorType, NumberOrString } from '@tensorleap/api-client';
import { TestCondition } from './conditions';
import {
  CategoryValue,
  CategoryMeta,
  StringCategoryTypes,
} from '../core/category/types';
import {
  EQVisualizationFilter,
  INVisualizationFilter,
  VisualizationFilter,
} from '../core/types/filters';

export function invertCondition<T>(
  testCondition: TestCondition<T>,
): TestCondition<T> {
  return (item: T) => !testCondition(item);
}

export type FieldValueTypes =
  | boolean
  | NumberOrString
  | NumberOrString[]
  | undefined;
export type FieldValueExtractor<T> = (
  item: T,
  field: string,
) => FieldValueTypes;
type FieldValueGetter<Item, ValueTypes extends FieldValueTypes> = (
  item: Item,
) => ValueTypes;

export function buildGetAndValidateValue<
  ValueTypes extends FieldValueTypes,
  Item,
>(
  path: string,
  expectedValue: (_: unknown) => boolean,
  extractFieldValue?: FieldValueExtractor<Item>,
): FieldValueGetter<Item, ValueTypes | undefined> {
  if (extractFieldValue)
    return (item: Item) => {
      const value = extractFieldValue(item, path);
      return expectedValue(value) ? (value as ValueTypes) : undefined;
    };

  const keys = path.split('.');
  return (obj: Item) => {
    let lastValue = obj as unknown;
    for (const key of keys) {
      lastValue = (lastValue as Record<string, unknown>)?.[key];
    }
    return expectedValue(lastValue) ? (lastValue as ValueTypes) : undefined;
  };
}

export function isStringOrNumber(value: unknown): value is NumberOrString {
  return (typeof value) in { string: '', number: '' };
}

export function isStringOrNumberOrArray(
  value: unknown,
): value is NumberOrString | Array<NumberOrString> {
  return (
    isStringOrNumber(value) ||
    (Array.isArray(value) && value.every(isStringOrNumber))
  );
}

export function convertCategoriesMetaToFilterCategoriesMeta<
  TCategory extends string,
>(categoriesMeta: CategoryMeta<TCategory>[]): CategoryMeta<TCategory>[] {
  return categoriesMeta.map((categoryMeta) => ({
    ...categoryMeta,
    type: StringCategoryTypes.StringArray,
  }));
}

export function convertCategoriesValueToFilters(
  categories: CategoryValue,
  fieldPrefix = '',
): VisualizationFilter[] {
  const fieldPrefixWithPoint = fieldPrefix ? `${fieldPrefix}.` : '';

  const filters = Object.entries(categories).flatMap(([key, value]) => {
    if (!value) return [];
    const field = `${fieldPrefixWithPoint}${key}`;
    if (typeof value === 'string') {
      const equalFilter: EQVisualizationFilter = {
        field,
        operator: FilterOperatorType.Equal,
        value,
      };
      return equalFilter;
    }
    const inFilter: INVisualizationFilter = {
      field,
      operator: FilterOperatorType.In,
      value,
    };
    return inFilter;
  });
  return filters;
}
