import {
  createContext,
  useContext,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { ShelveAction } from '../version-control/VersionControlPane';
import { useMergedObject } from './useMergedObject';
import { StateObject, useStateObject } from './types';

export interface VersionControlPanelContext {
  versionControlAction: ShelveAction | undefined;
  handleSaveNewVersion: () => void;
  handleImportModel: () => void;
  handleVersionActionCancelled: () => void;
  versionControlOpenState: StateObject<boolean>;
}

const versionControlPanelContextDefault: VersionControlPanelContext = {
  versionControlAction: undefined,
  handleSaveNewVersion: () => undefined,
  handleImportModel: () => undefined,
  handleVersionActionCancelled: () => undefined,
  versionControlOpenState: {} as StateObject,
};

export const VersionControlPanelContext =
  createContext<VersionControlPanelContext>(versionControlPanelContextDefault);

export const VersionControlPanelProvider: FC = ({ children }) => {
  const versionControlOpenState = useStateObject(false);
  useEffect(() => {
    setTimeout(() => versionControlOpenState.setState(true), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openVC = useCallback(
    () => versionControlOpenState.setState(true),
    [versionControlOpenState],
  );

  const [versionControlAction, setVersionControlAction] =
    useState<ShelveAction>();

  const handleSaveNewVersion = useCallback(() => {
    setVersionControlAction('SaveCommit');
    openVC();
  }, [openVC]);

  const handleImportModel = useCallback(() => {
    setVersionControlAction('ImportModel');
    openVC();
  }, [openVC]);

  const handleVersionActionCancelled = useCallback(() => {
    setVersionControlAction(undefined);
  }, []);

  const value = useMergedObject({
    versionControlAction,
    handleSaveNewVersion,
    handleImportModel,
    handleVersionActionCancelled,
    versionControlOpenState,
  });

  return (
    <VersionControlPanelContext.Provider value={value}>
      {children}
    </VersionControlPanelContext.Provider>
  );
};

export const useVersionControlPanelContext: () => VersionControlPanelContext =
  () => useContext(VersionControlPanelContext);
