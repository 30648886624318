import React, { useEffect, useMemo } from 'react';
import { createStyles, makeStyles } from '../ui/mui';
import clsx from 'clsx';
import { useDrag, useDrop, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useMergedRefs } from '../core/mergeRefs';
import {
  ConnectionDragData,
  ConnectionDropResult,
} from './interfaces/drag-and-drop';
import { ConnectionWithType } from './interfaces/drag-and-drop';
import { Connection } from './interfaces/Connection';

const useStyles = makeStyles((theme) =>
  createStyles({
    socket: {
      background: theme.palette.networkEditor.socket,
    },
    draggedSocket: {
      backgroundColor: 'pink',
    },
    socketBeforeDrop: {
      backgroundColor: 'blue',
    },
  }),
);

export interface NodeOutputSocketProps {
  nodeId: string;
  name: string;
  readOnly?: boolean;
  type: string;
  onNewConnection?: (data: ConnectionDropResult) => void;
  title?: string | JSX.Element;
}

export interface NodeInputSocketProps {
  nodeId: string;
  name: string;
  isDynamicInput: boolean;
  namePattern?: string;
  readOnly?: boolean;
  connectionInfo?: ConnectionWithType;
  allowedConnectionTypes: string[];
  onNewConnection?: (data: ConnectionDropResult) => void;
  onConnectionRemoved?: (
    connection: Connection,
    isDynamicInput: boolean,
  ) => void;
}

export const NodeInputSocket = React.forwardRef<
  HTMLDivElement,
  NodeInputSocketProps
>(
  (
    {
      nodeId,
      name,
      isDynamicInput,
      namePattern,
      readOnly,
      connectionInfo,
      allowedConnectionTypes,
      onNewConnection,
      onConnectionRemoved,
    },
    ref,
  ) => {
    const classes = useStyles();

    const [{ isDragging }, dragRef, preview] = useDrag(
      () => ({
        type: connectionInfo?.type || '',
        item: () =>
          ({
            outputNodeId: connectionInfo?.connection.outputNodeId,
            outputName: connectionInfo?.connection.outputName,
            existingConnection: connectionInfo?.connection,
          }) as ConnectionDragData,
        canDrag: connectionInfo && !readOnly,
        end: (
          item,
          monitor: DragSourceMonitor<ConnectionDragData, ConnectionDropResult>,
        ) => {
          const dropResult = monitor.getDropResult();
          if (dropResult) {
            onNewConnection && onNewConnection(dropResult);
          } else if (item.existingConnection) {
            onConnectionRemoved &&
              onConnectionRemoved(item.existingConnection, !!isDynamicInput);
          }
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }),
      [
        nodeId,
        name,
        connectionInfo,
        readOnly,
        onNewConnection,
        onConnectionRemoved,
        isDynamicInput,
      ],
    );

    useEffect(() => {
      preview(getEmptyImage());
    }, [preview]);

    const [{ canDrop, isOver }, dropRef] = useDrop(
      () => ({
        accept: allowedConnectionTypes,
        drop: ({
          outputNodeId,
          outputName,
          existingConnection,
        }: ConnectionDragData): ConnectionDropResult => {
          const existingConnections: Connection[] = [];
          if (connectionInfo) {
            existingConnections.push(connectionInfo.connection);
          }
          if (existingConnection) {
            existingConnections.push(existingConnection);
          }
          return {
            outputNodeId,
            outputName,
            inputNodeId: nodeId,
            inputName: name,
            isDynamicInput: !!isDynamicInput,
            existingConnections,
          };
        },
        canDrop: ({ outputNodeId, existingConnection }) =>
          !readOnly &&
          outputNodeId !== nodeId &&
          (!existingConnection ||
            !isDynamicInput ||
            existingConnection.inputNodeId !== nodeId ||
            existingConnection.inputName === name),
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      }),
      [nodeId, name, connectionInfo, readOnly, isDynamicInput],
    );

    const displayName = useMemo(
      () => (isDynamicInput ? namePattern?.replace('${id}', name) : name),
      [name, namePattern, isDynamicInput],
    );

    const mergedRef = useMergedRefs(dragRef, dropRef, ref);

    return (
      <div className="flex h-12 items-center">
        <div
          ref={mergedRef}
          className={clsx(
            'w-6 h-6 rounded-xl inline-block m-1.5 -ml-3.5',
            classes.socket,
            isDragging && classes.draggedSocket,
            canDrop && isOver && classes.socketBeforeDrop,
          )}
        />
        <span className="inline-block font-normal text-base tracking-normal">
          {displayName}
        </span>
      </div>
    );
  },
);
NodeInputSocket.displayName = 'NodeInputSocket';

export const NodeOutputSocket = React.forwardRef<
  HTMLDivElement,
  NodeOutputSocketProps
>(({ nodeId, name, type, onNewConnection, title }, ref) => {
  const classes = useStyles();

  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type,
      item: { outputNodeId: nodeId, outputName: name } as ConnectionDragData,
      end: (
        _item,
        monitor: DragSourceMonitor<ConnectionDragData, ConnectionDropResult>,
      ) => {
        const dropResult = monitor.getDropResult();
        if (dropResult && onNewConnection) {
          onNewConnection(dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [nodeId, name, onNewConnection],
  );

  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  const mergedRef = useMergedRefs(dragRef, ref);

  return (
    <div className="h-12 flex items-center justify-end">
      <span className="inline-block font-normal text-base tracking-normal">
        {title}
      </span>
      <div
        ref={mergedRef}
        className={clsx(
          'w-6 h-6 rounded-xl inline-block m-1.5 -mr-3.5',
          classes.socket,
          isDragging && classes.draggedSocket,
        )}
      />
    </div>
  );
});
NodeOutputSocket.displayName = 'NodeOutputSocket';
