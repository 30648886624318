import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSortBigSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.778 15c.182 0 .353-.09.457-.24l2.222-3.21A.556.556 0 0 0 11 10.68H6.556a.556.556 0 0 0-.457.872l2.222 3.21c.104.15.274.239.457.239ZM8.732 4.203a.056.056 0 0 1 .092 0l2.222 3.21A.056.056 0 0 1 11 7.5H6.556a.056.056 0 0 1-.046-.087l2.222-3.21Zm-.41-.285a.556.556 0 0 1 .913 0l2.222 3.21A.556.556 0 0 1 11 8H6.556a.556.556 0 0 1-.457-.872l2.222-3.21Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSortBigSmallIcon;
