import { useCallback, useState } from 'react';
import { Setter } from './types';

type ValueType = string | undefined;
export type ToggleString = (_: string, override?: boolean) => void;
export function useStringToggle(
  initialValue?: string,
): [ValueType, ToggleString, Setter<ValueType>] {
  const [value, setValue] = useState<ValueType>(initialValue);
  const toggleValue = useCallback(
    (updateVal: string, override?: boolean) =>
      override
        ? setValue(updateVal)
        : setValue((val) => (val === updateVal ? undefined : updateVal)),
    [],
  );
  return [value, toggleValue, setValue];
}
