import useSWR, { KeyedMutator } from 'swr';
import {
  GenericBaseImage,
  GetGenericBaseImageTypesResponse,
} from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseGenericBaseImageTypes {
  baseImageTypes: GenericBaseImage[];
  defaultBaseImageType: string;
  refetch: KeyedMutator<GetGenericBaseImageTypesResponse>;
  error?: Error;
}

export function useFetchGenericBaseImageTypes(): UseGenericBaseImageTypes {
  const { data, error, mutate } = useSWR<
    GetGenericBaseImageTypesResponse,
    Error
  >(`${CacheKey.GENERIC_BASE_IMAGE_TYPES}`, async () => {
    return await api.getGenericBaseImageTypes();
  });

  return useMergedObject({
    baseImageTypes: data?.baseImageTypes || [],
    defaultBaseImageType: data?.defaultBaseImageType || '',
    refetch: mutate,
    error,
  });
}
