import clsx from 'clsx';
import { ChangeEventHandler, forwardRef, PropsWithChildren } from 'react';
import { InputContainer } from './utils/InputContainer';
import { InputNote } from './utils/InputNote';
import { useInputHasValueTracker } from './utils/useInputHasValueTracker';
export type HTMLInputProps = React.HTMLProps<HTMLInputElement>;

type InputElementProps = {
  name?: string;
  type?: HTMLInputProps['type'];
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: HTMLInputProps['onKeyDown'];
  onBlur?: HTMLInputProps['onBlur'];
  onFocus?: HTMLInputProps['onFocus'];
  value?: HTMLInputProps['value'];
  className?: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

export type InputProps = InputElementProps & {
  label: string;
  small?: boolean;
  error?: string;
  clean?: boolean;
  autoFocus?: boolean;
  info?: string;
  containerProps?: { className?: string };
  icon?: JSX.Element;
  step?: string | number | undefined;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const INPUT_CLASSES = [
  'peer',
  'w-full',
  'h-full',
  'bg-transparent',
  'leading-normal',
  'shadow-none',
  'outline-none',
  'focus:outline-none',
  'focus:ring-0',
  'text-base',
  'disabled:text-gray-500 text-gray-200',
];

const CLASSES_BY_SIZE = {
  sm: {
    input: clsx(...INPUT_CLASSES, 'px-3', 'pt-1.5 pb-0.5', 'text-sm'),
    outline: 'h-9',
  },
  regular: {
    input: clsx(...INPUT_CLASSES, 'px-3', 'pt-2.5 pb-1.5'),
    outline: 'h-11',
  },
};

export const Input = forwardRef<
  HTMLInputElement,
  PropsWithChildren<InputProps>
>(
  (
    {
      label,
      small,
      clean = false,
      autoFocus = false,
      error,
      info,
      icon,
      className,
      containerProps = {},
      onChange,
      onKeyDown,
      step,
      onClick,
      ...inputProps
    },
    ref,
  ) => {
    const { onChangeHandler, inputHasValue, wrappedRef } =
      useInputHasValueTracker(inputProps.value, onChange, ref);

    const { input: inputClasses, outline: outlineClasses } =
      CLASSES_BY_SIZE[small ? 'sm' : 'regular'];

    return (
      <div
        {...containerProps}
        className={clsx('flex flex-col', containerProps.className)}
      >
        <InputContainer
          clean={clean}
          disabled={inputProps.disabled}
          onClick={onClick}
          error={!!error}
          label={label}
          small={small}
          className={clsx('flex flex-row items-center', outlineClasses)}
          inputHasValue={inputHasValue}
        >
          <input
            step={step}
            {...inputProps}
            onChange={onChangeHandler}
            onKeyDown={onKeyDown}
            ref={wrappedRef}
            placeholder={clean ? label : ''}
            className={clsx(inputClasses, className)}
            autoFocus={autoFocus}
          />
          {icon}
        </InputContainer>
        <InputNote error>{error}</InputNote>
        <InputNote info>{info}</InputNote>
      </div>
    );
  },
);

Input.displayName = 'Input';
