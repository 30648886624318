import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPushpinOnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M10.6 4.8v3.25c0 .728.24 1.404.65 1.95h-3.9c.423-.559.65-1.235.65-1.95V4.8h2.6Zm1.95-1.3h-6.5a.652.652 0 0 0-.65.65c0 .357.292.65.65.65h.65v3.25c0 .855-.547 1.58-1.312 1.844-.339.117-.638.397-.638.756s.291.65.65.65h3.23v4.55l.65.65.65-.65V11.3h3.27a.65.65 0 0 0 .65-.65c0-.359-.299-.64-.638-.756A1.948 1.948 0 0 1 11.9 8.05V4.8h.65c.357 0 .65-.292.65-.65a.652.652 0 0 0-.65-.65Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPushpinOnIcon;
