import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { SelectedSessionRun } from '../../ui/molecules/useModelFilter';
import { FullScreenEnabler } from '../../ui/atoms/FullScreenDialogEnabler';
import { VisualizationFilter } from '../../core/types/filters';

export type BaseVisualizationProps = {
  projectId: string;
  cid: string;
  remove: () => void;
  pinFilters?: VisualizationFilter[];
  updatePinFilters: (f: VisualizationFilter[]) => Promise<void>;
  duplicate: () => void;
  editMode: boolean;
  toggleEditMode: () => void;
  selectedSessionRuns: SelectedSessionRun[];
  className?: string;
};

export interface DashletCardProps {
  fullScreenMode?: boolean;
  toggleFullScreenMode?: () => void;
  className?: string;
}

export function DashletCard({
  children,
  fullScreenMode = false,
  toggleFullScreenMode = () => undefined,
  className,
}: PropsWithChildren<DashletCardProps>) {
  return (
    <div
      className={clsx(
        className,
        'h-full w-full border rounded-2xl border-gray-700 solid',
      )}
    >
      <FullScreenEnabler
        fullScreenMode={fullScreenMode}
        exitFullScreenMode={toggleFullScreenMode}
      >
        {children}
      </FullScreenEnabler>
    </div>
  );
}
