import { useCallback, useMemo, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import clsx from 'clsx';
import { CategoryView, CategoryViewProps } from './CategoryView';
import {
  CategoryValue,
  NormalizedOption,
  CategoryMeta,
  StringCategoryTypes,
} from './types';
import { first, omit } from 'lodash';
import { normalizeOption } from './utils';
import { Chip } from '../../ui/atoms/Chip';

export type CategoriesSelectorProps = {
  value: CategoryValue;
  onChange: (value: CategoryValue) => void;
  className?: string;
  categoriesMeta: CategoryMeta[];
  defaultOpenCategory?: string;
};

export function CategoriesSelector({
  value,
  onChange,
  categoriesMeta,
  className,
  defaultOpenCategory,
}: CategoriesSelectorProps) {
  const [openCategory, setOpenCategory] = useState(defaultOpenCategory);

  const handleCategoryToggle = (category: string) => {
    setOpenCategory((prevOpenCategory) =>
      prevOpenCategory === category ? undefined : category,
    );
  };

  const handleOptionSelect = useCallback(
    (category: string, option?: string | string[]) => {
      if (!option) {
        onChange(omit(value, [category]));
        return;
      }
      onChange({
        ...value,
        [category]: option,
      });
    },
    [value, onChange],
  );

  return (
    <div
      className={clsx('overflow-hidden max-h-full flex flex-col', className)}
    >
      {categoriesMeta.map((category, index) => (
        <Category
          key={category.category}
          category={category}
          openCategory={openCategory}
          onCategoryToggle={handleCategoryToggle}
          value={value[category.category]}
          onChange={handleOptionSelect}
          noBottomBorder={index === categoriesMeta.length - 1}
        />
      ))}
    </div>
  );
}

type SingleCategoryProps = CategoryViewProps & SingleOptionsProps;
function SingleCategory({
  open,
  value,
  title,
  onOpenToggle,
  options,
  onChange,
  noBottomBorder,
}: SingleCategoryProps) {
  return (
    <CategoryView
      open={open}
      noBottomBorder={noBottomBorder}
      onOpenToggle={onOpenToggle}
      title={
        <CategoryView.Title title={title}>
          <DisplaySelectedString
            onRemove={onChange}
            options={options}
            value={value}
          />
        </CategoryView.Title>
      }
    >
      <SingleOptions options={options} value={value} onChange={onChange} />
    </CategoryView>
  );
}

type SingleOptionsProps = {
  options: NormalizedOption[];
  value?: string;
  onChange: (value?: string) => void;
};

const SingleOptions = ({ options, value, onChange }: SingleOptionsProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <RadioGroup value={value || ''} onChange={handleChange}>
      {options.map(({ value: optionValue, name: optionName }) => {
        return (
          <FormControlLabel
            key={optionValue}
            value={optionValue}
            control={<Radio color="primary" />}
            label={optionName}
          />
        );
      })}
    </RadioGroup>
  );
};

type MultiCategoryProps = CategoryViewProps & MultiOptionsProps;
function MultiCategory({
  open,
  value,
  title,
  onOpenToggle,
  options,
  onChange,
  noBottomBorder,
}: MultiCategoryProps) {
  return (
    <CategoryView
      open={open}
      onOpenToggle={onOpenToggle}
      noBottomBorder={noBottomBorder}
      title={
        <CategoryView.Title title={title}>
          <DisplaySelectedArray
            onRemove={onChange}
            options={options}
            value={value}
          />
        </CategoryView.Title>
      }
    >
      <MultiOptions options={options} value={value} onChange={onChange} />
    </CategoryView>
  );
}

type MultiOptionsProps = {
  options: NormalizedOption[];
  value: string[];
  onChange: (value?: string[]) => void;
};

const MultiOptions = ({ options, value = [], onChange }: MultiOptionsProps) => {
  const handleChange = (optionValue: string) => {
    if (value.includes(optionValue)) {
      // Remove the value if already selected
      const newArray = value.filter((val) => val !== optionValue);
      onChange(newArray.length ? newArray : undefined);
    } else {
      // Add the value if not selected
      onChange([...value, optionValue]);
    }
  };

  return (
    <div className="flex flex-col">
      {options.map(({ name: optionName, value: optionValue }) => {
        return (
          <CheckboxOption
            key={optionValue}
            value={optionValue}
            name={optionName}
            selected={value}
            onToggle={handleChange}
          />
        );
      })}
    </div>
  );
};

type CheckboxOptionProps = {
  value: string;
  name: string;
  selected: string[];
  onToggle: (value: string) => void;
};

function CheckboxOption({
  value,
  name,
  selected,
  onToggle,
}: CheckboxOptionProps) {
  const isSelected = useMemo(() => selected.includes(value), [selected, value]);
  const handleChange = () => {
    onToggle(value);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isSelected}
          onChange={handleChange}
          value={value}
          color="primary"
        />
      }
      label={name}
    />
  );
}

type CategoryProps = {
  category: CategoryMeta;
  openCategory?: string;
  onCategoryToggle: (category: string) => void;
  value?: string | string[];
  noBottomBorder?: boolean;
  onChange: (category: string, value?: string | string[]) => void;
};

const Category = ({
  category,
  openCategory,
  onCategoryToggle,
  value,
  onChange,
  noBottomBorder,
}: CategoryProps) => {
  const isCategoryOpen = openCategory === category.category;
  const categoryName = category.name || category.category;
  const isSingle = category.type === StringCategoryTypes.String;
  const { enum: options } = category;
  const normalizedOptions = useMemo(
    () => options.map(normalizeOption),
    [options],
  );

  const handleOptionSelect = useCallback(
    (newValue?: string | string[]) => {
      onChange(category.category, newValue);
    },
    [category.category, onChange],
  );

  const handleCategoryToggle = useCallback(() => {
    onCategoryToggle(category.category);
  }, [category.category, onCategoryToggle]);

  return isSingle ? (
    <SingleCategory
      title={categoryName}
      open={isCategoryOpen}
      noBottomBorder={noBottomBorder}
      onOpenToggle={handleCategoryToggle}
      options={normalizedOptions}
      value={convertCategoryValueToString(value)}
      onChange={handleOptionSelect}
    />
  ) : (
    <MultiCategory
      title={categoryName}
      open={isCategoryOpen}
      noBottomBorder={noBottomBorder}
      onOpenToggle={handleCategoryToggle}
      options={normalizedOptions}
      value={convertCategoryValueToStringArray(value)}
      onChange={handleOptionSelect}
    />
  );
};

type DisplaySelectedStringProps = {
  onRemove: () => void;
  options: NormalizedOption[];
  value?: string;
};

function DisplaySelectedString({
  options,
  value,
  onRemove,
}: DisplaySelectedStringProps) {
  const formatted = useMemo(() => {
    return options.find(({ value: optionValue }) => value == optionValue)?.name;
  }, [options, value]);

  return formatted ? <Chip onRemove={onRemove}>{formatted}</Chip> : null;
}

type DisplaySelectedArrayProps = {
  onRemove: () => void;
  options: NormalizedOption[];
  value: string[];
};

function DisplaySelectedArray({
  options,
  value,
  onRemove,
}: DisplaySelectedArrayProps) {
  const formatted = useMemo(() => {
    if (!value || !value.length) return;
    if (value?.length === 1) {
      const v = first(value) as string;
      return options.find(({ value }) => value == v)?.name;
    }
    return `${value.length} Selected`;
  }, [options, value]);

  return formatted ? (
    <Chip className="bg-gray-700 border-gray-600" onRemove={onRemove}>
      {formatted}
    </Chip>
  ) : null;
}

function convertCategoryValueToStringArray(
  value?: string | string[],
): string[] {
  if (Array.isArray(value)) return value;
  return value ? [value] : [];
}

function convertCategoryValueToString(
  value?: string | string[],
): string | undefined {
  if (Array.isArray(value)) return first(value);
  return value;
}
