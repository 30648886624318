import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgGridMagnet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M3.63 2.855c0-.169.058-.312.175-.43a.577.577 0 0 1 .45-.195h4.374c.183 0 .332.066.45.196.117.117.175.26.175.43v10.312c0 .99.267 1.81.801 2.46.521.639 1.237.958 2.149.958.937 0 1.725-.332 2.363-.996.625-.664.937-1.472.937-2.422V2.855c0-.169.059-.312.176-.43a.577.577 0 0 1 .45-.195h4.374c.183 0 .332.066.45.196.117.117.175.26.175.43V13.46c0 .794-.104 1.575-.312 2.344a9.43 9.43 0 0 1-.86 2.11 9.374 9.374 0 0 1-1.367 1.776 9.37 9.37 0 0 1-1.777 1.368 8.538 8.538 0 0 1-2.09.878 8.287 8.287 0 0 1-2.344.332 8.45 8.45 0 0 1-3.398-.703c-1.094-.468-2.031-1.093-2.813-1.875a8.658 8.658 0 0 1-1.855-2.793 8.85 8.85 0 0 1-.684-3.437V2.855Zm1.25.625V6h3.124V3.48H4.88Zm11.874 0V6h3.125V3.48h-3.125ZM12.38 21.02c1.368 0 2.63-.339 3.79-1.016a7.444 7.444 0 0 0 2.714-2.754 7.49 7.49 0 0 0 .996-3.79V7.25h-3.125v5.918c0 1.302-.436 2.409-1.308 3.32-.886.899-1.966 1.348-3.242 1.348-.834 0-1.57-.208-2.207-.625a4.03 4.03 0 0 1-1.485-1.68c-.338-.703-.508-1.49-.508-2.363V7.25H4.88v6.21c0 1.03.196 2.013.586 2.95.391.925.925 1.725 1.602 2.402a7.445 7.445 0 0 0 2.402 1.622c.925.39 1.895.585 2.91.585Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGridMagnet;
