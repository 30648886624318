import clsx from 'clsx';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { compactNumberFormatter } from '../../core/formatters/number-formatting';

export type TestResult = {
  failed: number;
  succeeded: number;
  errors: number;
  notFound: number;
};

const TEST_PIE_CHART_VARIANT = {
  lg: {
    containerClassName: 'w-28 h-28',
    strokeWidth: 10,
    textContainerClassName: '',
  },
  sm: {
    containerClassName: 'w-9 h-9',
    strokeWidth: 12,
    textContainerClassName: 'hidden',
  },
};

export type TestPieChartProps = TestResult & {
  variant: keyof typeof TEST_PIE_CHART_VARIANT;
  className?: string;
};

export function TestPieChart({
  failed,
  succeeded,
  errors,
  notFound,
  variant,
  className,
}: TestPieChartProps) {
  const { containerClassName, textContainerClassName, strokeWidth } =
    TEST_PIE_CHART_VARIANT[variant];

  const isAllHaveErrorsOrNotFound =
    !failed && !succeeded && (errors || notFound);
  const sum = failed + succeeded + errors + notFound;
  const notSucceded = sum - succeeded;

  const count = isAllHaveErrorsOrNotFound ? 1 : succeeded;
  const all = isAllHaveErrorsOrNotFound ? 1 : sum;
  const pathColor = isAllHaveErrorsOrNotFound ? '#555' : '#22C55E';
  const trailColor = isAllHaveErrorsOrNotFound ? '#555' : '#EF4444';

  return (
    <div className={clsx(containerClassName, className)}>
      <CircularProgressbarWithChildren
        value={count}
        maxValue={all}
        minValue={0}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          trailColor,
          pathColor,
        })}
      >
        <div
          className={clsx(
            'flex w-full h-full justify-center items-center tracking-lg',
            textContainerClassName,
          )}
        >
          {isAllHaveErrorsOrNotFound ? (
            <span className="text-gray-500 px-1">NA</span>
          ) : (
            <>
              {!!notSucceded && (
                <>
                  <span className="text-error-500">
                    {compactNumberFormatter.format(notSucceded)}
                  </span>
                  <span className="text-gray-500 px-1">/</span>
                </>
              )}
              <span className=" text-gray-300">
                {compactNumberFormatter.format(all)}
              </span>
            </>
          )}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
