import clsx from 'clsx';
import { CopyButton } from './CopyButton';

export interface CodeTextAreaProps {
  text: string;
  containerClassName?: string;
  className?: string;
}

export function CodeTextArea({
  text,
  containerClassName,
  className,
}: CodeTextAreaProps) {
  return (
    <code
      className={clsx(
        'relative text-2xs border rounded-lg border-gray-600 bg-gray-700 text-gray-200  break-words group',
        containerClassName,
      )}
    >
      <div
        className={clsx(
          'overflow-auto min-h-[3rem] px-2 py-1 flex flex-col gap-0',
          className,
        )}
      >
        {text.split('\n').map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <CopyButton
        textToCopy={text}
        className="absolute top-1 right-1 bg-gray-500 opacity-0 bg-opacity-40 group-hover:opacity-100 "
        afterClickedClassName="opacity-100"
      />
    </code>
  );
}
