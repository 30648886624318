import {
  CSSProperties,
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';

import { Setter } from '../../core/types';
import { FileMap } from './utils/dataset-editor-content';
import {
  FieldIcon,
  MagnifyingGlass,
  NewFileIcon,
  PlayIcon,
  Trash,
} from '../../ui/icons';
import { Tooltip } from '../../ui/mui';
import { IS_MAC, NEW_FILE_NAME, NEW_FILE_REGEX } from './utils/consts';
import { useOutsideClick } from '../../core/useOutsideClick';
import { toFileHierarchy as fileMapToHierarchy } from './utils/helper-functions';
import { FileHierarchy } from './utils/types';
import { useEscKeypress } from '../../core/useEscKeypress';
import { useDatasets } from '../../core/DatasetsContext';
import { testIds } from '../../test-ids';

type DetailsWrapperProps = PropsWithChildren<{
  dirname: string;
  parentDirs: string[];
  currentFileName: string;
  setFileMap: Setter<FileMap>;
  resetCurrentFileName: () => void;
}>;
function DetailsWrapper({
  children,
  dirname,
  parentDirs,
  currentFileName,
  setFileMap,
  resetCurrentFileName,
}: DetailsWrapperProps): JSX.Element {
  const level = parentDirs.length;
  const selectedFilepath = useMemo(
    () => [...parentDirs, dirname].join('/'),
    [dirname, parentDirs],
  );
  const isSelected = currentFileName === selectedFilepath;
  const indentation = useMemo<Pick<CSSProperties, 'paddingLeft'>>(
    () => ({ paddingLeft: `${level - 0.5}rem` }),
    [level],
  );

  const deleteDir = useCallback(() => {
    setFileMap((current) => {
      const fullDirname = parentDirs.join('/');
      if (isSelected) {
        resetCurrentFileName();
      }

      return Object.entries(current).reduce<FileMap>(
        (accu, [filename, content]) => ({
          ...accu,
          ...(!filename.startsWith(fullDirname) && { [filename]: content }),
        }),
        {},
      );
    });
  }, [isSelected, parentDirs, resetCurrentFileName, setFileMap]);

  return (
    <details open>
      <summary
        className={clsx(
          'relative group',
          'leading-8 align-middle truncate',
          'border-b border-solid border-gray-800',
          'cursor-pointer',
          isSelected && 'bg-primary-950',
        )}
        style={indentation}
      >
        <div
          className={clsx(
            'absolute inset-0 pr-2',
            'bg-gradient-to-r from-transparent via-gray-600/30 to-gray-600/50',
            'hidden group-hover:flex flex-1 flex-row justify-end items-center gap-2',
          )}
        >
          <Tooltip
            arrow
            title={<span className="capitalize text-error-500">delete</span>}
            placement="bottom"
          >
            <button
              className="transition-transform hover:text-error-500"
              onClick={deleteDir}
            >
              <Trash />
            </button>
          </Tooltip>
        </div>
        <span>{dirname + '/'}</span>
      </summary>
      {children}
    </details>
  );
}

type SearchBarProps = {
  fileMap: FileMap;
  setCurrentFileName: Setter<string>;
  closeSearch: () => void;
};
function SearchBar({
  fileMap,
  setCurrentFileName,
  closeSearch,
}: SearchBarProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  useOutsideClick({ refs: [inputRef], func: closeSearch });
  useEscKeypress(closeSearch);

  const saveCloseOnEnter = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    ({ key, currentTarget: { value } }) => {
      if (key === 'Enter') {
        if (typeof fileMap[value] === 'string') {
          setCurrentFileName(value);
        } else {
          console.warn(`"${value}" is not a valid file name`);
        }
        closeSearch();
      }
    },
    [closeSearch, fileMap, setCurrentFileName],
  );

  return (
    <>
      <datalist id="file-name-options">
        {Object.keys(fileMap)
          .sort()
          .map((file) => (
            <option key={file} value={file} />
          ))}
      </datalist>
      <input
        type="search"
        ref={inputRef}
        list="file-name-options"
        className={clsx(
          'w-full h-9 px-2 bg-transparent',
          'border-2 border-white/80 rounded',
        )}
        onKeyPress={saveCloseOnEnter}
      />
    </>
  );
}

type RenameInputProps = {
  name: string;
  setFileMap: Setter<FileMap>;
  setCurrentFileName: Setter<string>;
  exitRenameMode: () => void;
};
function RenameInput({
  name,
  setFileMap,
  setCurrentFileName,
  exitRenameMode,
}: RenameInputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  useOutsideClick({ refs: [inputRef], func: exitRenameMode });
  useEscKeypress(exitRenameMode);

  const saveOnEnter = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    ({ key, currentTarget: { value } }) => {
      if (key === 'Enter') {
        setFileMap(({ [name]: changedFileContent, ...rest }) => ({
          ...rest,
          [value]: changedFileContent,
        }));
        setCurrentFileName(value);
        exitRenameMode();
      }
    },
    [exitRenameMode, name, setCurrentFileName, setFileMap],
  );

  return (
    <input
      data-testid={testIds.editor.renameFileInput}
      type="text"
      className={clsx(
        'h-full w-[calc(100%-8px)] mx-1',
        'leading-8 align-middle',
        'bg-transparent',
      )}
      ref={inputRef}
      defaultValue={name}
      onKeyPress={saveOnEnter}
    />
  );
}

type FileBranchDisplayProps = {
  dirname: string;
  fileHierarchy: FileHierarchy;
  setFileMap: Setter<FileMap>;
  currentFileName: string;
  setCurrentFileName: Setter<string>;
  parentDirs: string[];
  resetCurrentFileName: () => void;
  entryFile: string;
  setEntryFile: Setter<string>;
};
function FileBranchDisplay({
  dirname,
  setFileMap,
  currentFileName,
  setCurrentFileName,
  parentDirs,
  entryFile,
  setEntryFile,
}: FileBranchDisplayProps): JSX.Element {
  const level = parentDirs.length;
  const [isRenameMode, setIsRenameMode] = useState(false);

  const selectedFilepath = useMemo(
    () => [...parentDirs, dirname].join('/'),
    [dirname, parentDirs],
  );
  const indentation = useMemo<Pick<CSSProperties, 'paddingLeft'>>(
    () => ({ paddingLeft: `${level + 0.5}rem` }),
    [level],
  );

  const toRenameFileMode = useCallback(() => {
    setIsRenameMode(true);
  }, []);

  return (
    <li
      className={clsx(
        'h-9 relative',
        'group',
        'border-b border-solid border-gray-800',
      )}
      onDoubleClick={toRenameFileMode}
      data-testid={testIds.editor.fileNameRow}
    >
      {isRenameMode ? (
        <RenameInput
          name={selectedFilepath}
          setFileMap={setFileMap}
          setCurrentFileName={setCurrentFileName}
          exitRenameMode={() => {
            setIsRenameMode(false);
          }}
        />
      ) : (
        <>
          <p
            className={clsx(
              'h-full px-2',
              'leading-8 align-middle truncate',
              currentFileName === selectedFilepath && 'bg-primary-950',
              entryFile === selectedFilepath && 'border-r-4 border-success-600',
            )}
            style={indentation}
          >
            {dirname}
          </p>
          <div
            className={clsx(
              'absolute inset-0 pr-2 cursor-pointer',
              'bg-gradient-to-r from-transparent via-gray-600/30 to-gray-600/50',
              'hidden group-hover:flex flex-1 flex-row justify-end items-center gap-2',
            )}
            onClick={() => {
              setCurrentFileName(selectedFilepath);
            }}
          >
            <Tooltip arrow title="Set as entry file" placement="bottom">
              <button
                className="transition-transform hover:text-success-500"
                onClick={() => {
                  setEntryFile(selectedFilepath);
                }}
              >
                <PlayIcon className="w-5 h-5" />
              </button>
            </Tooltip>
            <Tooltip arrow title="Rename" placement="bottom">
              <button
                className="transition-transform hover:text-success-500"
                onClick={toRenameFileMode}
              >
                <FieldIcon />
              </button>
            </Tooltip>
            <Tooltip
              arrow
              title={<span className="capitalize text-error-500">delete</span>}
              placement="bottom"
            >
              <button
                className="transition-transform hover:text-error-500"
                onClick={() => {
                  setFileMap(({ [selectedFilepath]: _, ...rest }) => rest);
                }}
              >
                <Trash />
              </button>
            </Tooltip>
          </div>
        </>
      )}
    </li>
  );
}

type FileBranchProps = {
  fileHierarchy: FileHierarchy;
  setFileMap: Setter<FileMap>;
  currentFileName: string;
  setCurrentFileName: Setter<string>;
  parentDirs?: string[];
  resetCurrentFileName: () => void;
  entryFile: string;
  setEntryFile: Setter<string>;
};
function FileBranch({
  fileHierarchy,
  setFileMap,
  currentFileName,
  setCurrentFileName,
  parentDirs = [],
  resetCurrentFileName,
  entryFile,
  setEntryFile,
}: FileBranchProps): JSX.Element {
  return (
    <ul>
      {Object.entries(fileHierarchy)
        .sort()
        .map(([dirname, content]) => {
          const key = dirname + JSON.stringify(content);

          if (typeof content === 'string') {
            return (
              <FileBranchDisplay
                key={key}
                dirname={dirname}
                setFileMap={setFileMap}
                currentFileName={currentFileName}
                setCurrentFileName={setCurrentFileName}
                parentDirs={parentDirs}
                fileHierarchy={fileHierarchy}
                resetCurrentFileName={resetCurrentFileName}
                entryFile={entryFile}
                setEntryFile={setEntryFile}
              />
            );
          } else {
            return (
              <li key={key}>
                <DetailsWrapper
                  dirname={dirname}
                  currentFileName={currentFileName}
                  setFileMap={setFileMap}
                  parentDirs={parentDirs.concat(dirname)}
                  resetCurrentFileName={resetCurrentFileName}
                >
                  <FileBranch
                    fileHierarchy={content}
                    setFileMap={setFileMap}
                    currentFileName={currentFileName}
                    setCurrentFileName={setCurrentFileName}
                    parentDirs={parentDirs.concat(dirname)}
                    resetCurrentFileName={resetCurrentFileName}
                    entryFile={entryFile}
                    setEntryFile={setEntryFile}
                  />
                </DetailsWrapper>
              </li>
            );
          }
        })}
    </ul>
  );
}

export type FileTreeProps = {
  className?: string;
};
export function FileTree({ className }: FileTreeProps): JSX.Element {
  const {
    virtualFs: {
      fileMap,
      setFileMap,
      currentFileName,
      setCurrentFileName,
      entryFile,
      setEntryFile,
      resetCurrentFileName,
      showFiletree,
    },
  } = useDatasets();

  const fileHierarchy = useMemo(() => fileMapToHierarchy(fileMap), [fileMap]);
  const createNewFile = useCallback(() => {
    setFileMap((current) => {
      const newFileCount = Object.keys(current).reduce<number>(
        (accu, curr) => accu + (NEW_FILE_REGEX.test(curr) ? 1 : 0),
        0,
      );
      const newFileName = `${NEW_FILE_NAME}${
        newFileCount ? `_${newFileCount + 1}` : ''
      }.py`;

      setCurrentFileName(newFileName);
      return { [newFileName]: '', ...current };
    });
  }, [setCurrentFileName, setFileMap]);

  const [showSearch, setShowSearch] = useState(false);
  useEffect(() => {
    const openSearchKeypress = ({
      key,
      metaKey,
      ctrlKey,
    }: KeyboardEvent): void => {
      if (key === 'k' && ((IS_MAC && metaKey) || (!IS_MAC && ctrlKey))) {
        setShowSearch(true);
      }
    };
    document.addEventListener('keydown', openSearchKeypress);
    return () => {
      document.removeEventListener('keydown', openSearchKeypress);
    };
  }, []);
  const closeSearch = useCallback(() => {
    setShowSearch(false);
  }, []);

  const elementStyle = useMemo(
    () => ({
      width: showFiletree ? '300px' : '0',
      transition: 'width 200ms',
    }),
    [showFiletree],
  );

  return (
    <div
      className={clsx(
        'flex h-full flex-col justify-center gap-1 align-top overflow-hidden',
        className,
      )}
      style={elementStyle}
    >
      <div className="flex justify-end items-center px-2">
        <h4 className="uppercase text-gray-600 text-sm">project files</h4>
        <span className="flex-1" />

        <Tooltip placement="left" arrow title="Search">
          <button
            className="transition-transform hover:text-success-500"
            onClick={() => setShowSearch(true)}
          >
            <MagnifyingGlass className="w-7 h-7" />
          </button>
        </Tooltip>

        <Tooltip
          placement="left"
          arrow
          title="New file (to create a directory just rename the file with a `/` character inside)"
        >
          <button
            data-testid={testIds.editor.addFileButton}
            className="transition-transform hover:text-success-500"
            onClick={createNewFile}
          >
            <NewFileIcon />
          </button>
        </Tooltip>
      </div>
      <div
        className={clsx(
          'flex-1',
          'font-mono text-sm',
          'border border-solid border-gray-800',
          'overflow-y-auto',
        )}
      >
        {showSearch && (
          <SearchBar
            fileMap={fileMap}
            setCurrentFileName={setCurrentFileName}
            closeSearch={closeSearch}
          />
        )}
        <FileBranch
          key={Math.random()}
          fileHierarchy={fileHierarchy}
          setFileMap={setFileMap}
          currentFileName={currentFileName}
          setCurrentFileName={setCurrentFileName}
          resetCurrentFileName={resetCurrentFileName}
          entryFile={entryFile}
          setEntryFile={setEntryFile}
        />
      </div>
    </div>
  );
}
