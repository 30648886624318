import { IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

export type ToggleIconProp = PropsWithChildren<{
  className?: string;
  value: boolean;
  tooltips?: [
    tooltipOnTrue: NonNullable<ReactNode>,
    tooltipOnFalse: NonNullable<ReactNode>,
  ];
  onToggle: () => void;
}>;

export function ToggleIcon({
  value,
  onToggle,
  tooltips: [yesText, noText] = ['', ''],
  children,
  className,
}: ToggleIconProp) {
  return (
    <Tooltip title={value ? yesText : noText}>
      <IconButton
        onClick={onToggle}
        className={clsx(
          value && 'bg-primary-500 hover:bg-primary-400 text-white',
          className,
        )}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}
