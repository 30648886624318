import { useCodeIntegrationErrorData } from './cards/CodeIntegrationErrorData';
import { useDatasetMappingCreateUpdateApplyData } from './cards/DatasetMappingCreateUpdateApplyData';
import { useDatasetMappingGeneralIssueMessageData } from './cards/DatasetMappingGeneralIssueMessageData';
import { useGraphErrorData } from './cards/GraphErrorData';
import { useGraphNodeAttrErrorData } from './cards/GraphNodeAttrErrorData';
import { useGraphNodeErrorData } from './cards/GraphNodeErrorData';
import { useGraphNodeInputErrorData } from './cards/GraphNodeInputErrorData';
import { useGraphNodesErrorData } from './cards/GraphNodesErrorData';
import { GetValidateAssetsErrorData } from './cards/ValidateAssetsErrorData';
import {
  GraphWarningKind,
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardData,
} from './types';

export enum GraphErrorMsg {
  ManyOptimizers = 'There is more than one Optimizer, delete them all except one',
  InvalidDataset = `Failed parsing the selected dataset. Please validate the dataset's binding script`,
  ValidateAssets = 'Validate your assets before execute any action on Tensorleap',
  NoInputNode = 'Inputs node not found. Please add an Inputs node',
  UpdateCodeIntegration = 'The existing code integration is not up to date. Consider updating it to the latest version',
}

export enum NodeErrorMsg {
  InputHasntVisualizer = 'The Input node must be connected to at least one Visualizer node',
  InputIsNotSelected = "Input isn't selected, please select an input",
  CustomLayerIsNotSelected = "Custom Layer isn't selected, please select a Custom Layer",
  MetricIsNotSelected = "Metric isn't selected, please select a Metric",
  GTHasntLoss = 'GroundTruth node must be connected to a Loss node',
  NoIntegrationScript = 'Please select a script to integrate with the model',
  NoOutputNode = 'Output node not found. Please add a Loss or Layer node',
}

export const GraphWarningMsg: Record<GraphWarningKind, string> = {
  predictionNeedsVisualization:
    'It is recommended to connect a Prediction node to a Visualizer',
  predictionNeedsMetric:
    'It is recommended to connect a Prediction node to a Metric',
  gtNeedsVisualization:
    'It is recommended to connect a Ground Truth node to a Visualizer',
  inputNeedsVisualization:
    'It is recommended to connect an input node to a ground truth node',
};

export type BaseGraphErrorType = {
  title?: string;
  msg: string;
  isValidateAssetsError?: boolean;
  category?: NetworkWizardCategory;
  nodeId?: string;
  nodesIds?: string[];
  type?: GraphErrorKind | GraphWarningKind;
};

export type GraphErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.graph;
};

export type ValidateAssetsErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.validateAssets;
};

export type CodeIntegrationErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.codeIntegration;
};

export type GraphNodeErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.node;
};

export type GraphNodesErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.nodes;
  nodesIds?: string[];
};

export type GraphNodeInputErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.nodeInput;
  nodeId: string;
  inputName?: string;
  inputHasError?: boolean;
};
export type GraphNodeAttrErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.nodeAttr;
  nodeId: string;
  attrName: string;
};

export type GraphNodeErrorFromValidateAssetsType = GraphNodeErrorType & {
  isValidateAssetsError: boolean;
};

export type DatasetMappingErrorType = BaseGraphErrorType & {
  type: GraphErrorKind.datasetMappingCreateUpdateApply;
};

export type DatasetMappingApplyError = BaseGraphErrorType & {
  type: GraphErrorKind.datasetMappingApplyError;
};

export type DatasetMappingApplyWarning = BaseGraphErrorType & {
  type: GraphErrorKind.datasetMappingApplyWarning;
};

export type OneOfGraphErrorTypes =
  | GraphErrorType
  | GraphNodeErrorType
  | GraphNodesErrorType
  | GraphNodeAttrErrorType
  | GraphNodeInputErrorType
  | GraphNodeErrorFromValidateAssetsType
  | CodeIntegrationErrorType
  | ValidateAssetsErrorType
  | DatasetMappingErrorType
  | DatasetMappingApplyError
  | DatasetMappingApplyWarning;

export const ERROR_TYPE_TO_COMP_MAP: Readonly<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<GraphErrorKind, (props: any) => NetworkWizardData[]>
> = {
  [GraphErrorKind.graph]: useGraphErrorData,
  [GraphErrorKind.validateAssets]: GetValidateAssetsErrorData,
  [GraphErrorKind.node]: useGraphNodeErrorData,
  [GraphErrorKind.nodes]: useGraphNodesErrorData,
  [GraphErrorKind.nodeInput]: useGraphNodeInputErrorData,
  [GraphErrorKind.nodeAttr]: useGraphNodeAttrErrorData,
  [GraphErrorKind.codeIntegration]: useCodeIntegrationErrorData,
  [GraphErrorKind.datasetMappingCreateUpdateApply]:
    useDatasetMappingCreateUpdateApplyData,
  [GraphErrorKind.datasetMappingApplyError]:
    useDatasetMappingGeneralIssueMessageData,
  [GraphErrorKind.datasetMappingApplyWarning]:
    useDatasetMappingGeneralIssueMessageData,
};
