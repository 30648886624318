// most of the code is generated and copied from the internet so don't worry about the complexity, just focus on the types and the function names - the result of these function tested by storybook snapshot

export type PathCommand = {
  type: 'M' | 'L' | 'C' | 'Q' | 'Z';
  points: number[];
};

type MinMaxBBox = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};

export type CommandAndBoundingBox = {
  command: PathCommand[];
  boundingBox: MinMaxBBox;
};

const EPSILON = 0.00001;

type BBox = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export function transformPolygon(
  { points, boundingBox: { minX, minY, maxX, maxY } }: PointsAndBoundingBox,
  x: number,
  y: number,
  size: number,
): string {
  // Calculate the center of the bounding box
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  // Apply translation to center the shape at (x, y)
  return points
    .map(([px, py]) => {
      // Apply translation and scaling to all points in the polygon
      const transformedX = (px - centerX) * size + x; // Adjust X to center
      const transformedY = (py - centerY) * size + y; // Adjust Y to center
      return `${transformedX},${transformedY}`;
    })
    .join(' '); // Join all points with commas to form the final points string
}

export function bboxToMinMax(bbox: BBox): MinMaxBBox {
  return {
    minX: bbox.x,
    minY: bbox.y,
    maxX: bbox.x + bbox.width,
    maxY: bbox.y + bbox.height,
  };
}

// Avoid division by zero
function avoidDivisionByZero(value: number): number {
  return Math.abs(value) < EPSILON ? EPSILON : value;
}

// Check if the Bezier interval is within 0 to 1
function checkBezierInterval(t: number): number {
  return Math.max(0, Math.min(t, 1));
}

// Calculate quadratic Bezier value
function getQuadraticValue(
  t: number,
  p0: number,
  p1: number,
  p2: number,
): number {
  const oneMinusT = 1 - t;
  return oneMinusT * oneMinusT * p0 + 2 * oneMinusT * t * p1 + t * t * p2;
}

// Calculate cubic Bezier value
function getRoots(a: number, b: number, c: number): number[] {
  const discriminant = b * b - 4 * a * c;
  if (discriminant < 0) return []; // No real roots

  const sqrtDiscriminant = Math.sqrt(discriminant);
  const t1 = (-b + sqrtDiscriminant) / (2 * a);
  const t2 = (-b - sqrtDiscriminant) / (2 * a);

  return [t1, t2].filter((t) => t >= 0 && t <= 1); // Only return roots in [0, 1]
}

function getCubicValue(
  t: number,
  p0: number,
  p1: number,
  p2: number,
  p3: number,
): number {
  const oneMinusT = 1 - t;
  return (
    oneMinusT * oneMinusT * oneMinusT * p0 +
    3 * oneMinusT * oneMinusT * t * p1 +
    3 * oneMinusT * t * t * p2 +
    t * t * t * p3
  );
}

// Quadratic Bezier Bounding Box
export function quadraticBBox(
  p0x: number,
  p0y: number,
  p1x: number,
  p1y: number,
  p2x: number,
  p2y: number,
): BBox {
  const devx = avoidDivisionByZero(p0x + p2x - 2 * p1x);
  const devy = avoidDivisionByZero(p0y + p2y - 2 * p1y);

  const tx = checkBezierInterval((p0x - p1x) / devx);
  const ty = checkBezierInterval((p0y - p1y) / devy);

  const txByTX = getQuadraticValue(tx, p0x, p1x, p2x);
  const tyByTX = getQuadraticValue(tx, p0y, p1y, p2y);
  const txByTY = getQuadraticValue(ty, p0x, p1x, p2x);
  const tyByTY = getQuadraticValue(ty, p0y, p1y, p2y);

  const x1 = Math.min(txByTX, p0x, p2x, txByTY);
  const y1 = Math.min(tyByTX, p0y, p2y, tyByTY);
  const x2 = Math.max(txByTX, p0x, p2x, txByTY);
  const y2 = Math.max(tyByTX, p0y, p2y, tyByTY);

  return {
    width: x2 - x1,
    height: y2 - y1,
    x: x1,
    y: y1,
  };
}
export function cubicBBox(
  p0x: number,
  p0y: number,
  p1x: number,
  p1y: number,
  p2x: number,
  p2y: number,
  p3x: number,
  p3y: number,
): BBox {
  const ax = p3x - p0x + 3 * (p1x - p2x);
  const bx = 2 * (p0x - 2 * p1x + p2x);
  const cx = p1x - p0x;

  const ay = p3y - p0y + 3 * (p1y - p2y);
  const by = 2 * (p0y - 2 * p1y + p2y);
  const cy = p1y - p0y;

  const txRoots = getRoots(ax, bx, cx);
  const tyRoots = getRoots(ay, by, cy);

  // Safeguard: Check if roots exist before using them
  const tv0x = getCubicValue(0, p0x, p1x, p2x, p3x);
  const tv1x =
    txRoots[0] !== undefined
      ? getCubicValue(txRoots[0], p0x, p1x, p2x, p3x)
      : tv0x;
  const tv2x =
    txRoots[1] !== undefined
      ? getCubicValue(txRoots[1], p0x, p1x, p2x, p3x)
      : tv0x;
  const tv3x = getCubicValue(1, p0x, p1x, p2x, p3x);

  const tv0y = getCubicValue(0, p0y, p1y, p2y, p3y);
  const tv1y =
    tyRoots[0] !== undefined
      ? getCubicValue(tyRoots[0], p0y, p1y, p2y, p3y)
      : tv0y;
  const tv2y =
    tyRoots[1] !== undefined
      ? getCubicValue(tyRoots[1], p0y, p1y, p2y, p3y)
      : tv0y;
  const tv3y = getCubicValue(1, p0y, p1y, p2y, p3y);

  // Calculate the minimum and maximum x and y values
  const x1 = Math.min(tv0x, tv1x, tv2x, tv3x, p0x, p3x);
  const y1 = Math.min(tv0y, tv1y, tv2y, tv3y, p0y, p3y);
  const x2 = Math.max(tv0x, tv1x, tv2x, tv3x, p0x, p3x);
  const y2 = Math.max(tv0y, tv1y, tv2y, tv3y, p0y, p3y);

  return {
    width: x2 - x1,
    height: y2 - y1,
    x: x1,
    y: y1,
  };
}

export function boundingBoxFromPath(pathData: PathCommand[]): MinMaxBBox {
  let minX = Infinity,
    minY = Infinity;
  let maxX = -Infinity,
    maxY = -Infinity;

  let prevX = 0;
  let prevY = 0;

  pathData.forEach((command) => {
    const points = command.points;
    switch (command.type) {
      case 'M':
      case 'L':
        points.forEach((point, index) => {
          if (index % 2 === 0) {
            // X-coordinate
            prevX = point;
            minX = Math.min(minX, point);
            maxX = Math.max(maxX, point);
          } else {
            // Y-coordinate
            prevY = point;
            minY = Math.min(minY, point);
            maxY = Math.max(maxY, point);
          }
        });
        break;

      case 'Q': {
        // Quadratic Bézier: p0 = previous point, p1 = control point, p2 = end point
        const [p1x, p1y, p2x, p2y] = points;
        const quadBox = quadraticBBox(prevX, prevY, p1x, p1y, p2x, p2y);
        const {
          minX: qMinX,
          minY: qMinY,
          maxX: qMaxX,
          maxY: qMaxY,
        } = bboxToMinMax(quadBox);
        minX = Math.min(minX, qMinX);
        maxX = Math.max(maxX, qMaxX);
        minY = Math.min(minY, qMinY);
        maxY = Math.max(maxY, qMaxY);

        // Update previous point to the end of the quadratic Bézier
        prevX = p2x;
        prevY = p2y;
        break;
      }

      case 'C': {
        // Cubic Bézier: p0 = previous point, p1 = control point 1, p2 = control point 2, p3 = end point
        const [p1x, p1y, p2x, p2y, p3x, p3y] = points;
        const cubicBox = cubicBBox(prevX, prevY, p1x, p1y, p2x, p2y, p3x, p3y);
        const {
          minX: cMinX,
          minY: cMinY,
          maxX: cMaxX,
          maxY: cMaxY,
        } = bboxToMinMax(cubicBox);
        minX = Math.min(minX, cMinX);
        maxX = Math.max(maxX, cMaxX);
        minY = Math.min(minY, cMinY);
        maxY = Math.max(maxY, cMaxY);

        // Update previous point to the end of the cubic Bézier
        prevX = p3x;
        prevY = p3y;
        break;
      }

      default:
        break;
    }
  });

  return { minX, minY, maxX, maxY };
}

export type Point = [x: number, y: number];
export function normalizePoints(points: Point[]): Point[] {
  // Find the minimum and maximum values for both x and y coordinates
  let minX = Infinity,
    minY = Infinity;
  let maxX = -Infinity,
    maxY = -Infinity;

  // Loop through all points to find the extents
  points.forEach(([x, y]) => {
    minX = Math.min(minX, x);
    maxX = Math.max(maxX, x);
    minY = Math.min(minY, y);
    maxY = Math.max(maxY, y);
  });

  // Calculate the range for normalization
  const rangeX = maxX - minX || 1; // Prevent division by zero
  const rangeY = maxY - minY || 1;

  // Normalize all points so they fit within [0, 1]
  return points.map(([x, y]) => [
    (x - minX) / rangeX, // Normalize X-coordinate
    (y - minY) / rangeY, // Normalize Y-coordinate
  ]);
}

export function bboxFromPoints(points: Point[]): MinMaxBBox {
  let minX = Infinity,
    minY = Infinity;
  let maxX = -Infinity,
    maxY = -Infinity;

  points.forEach(([x, y]) => {
    minX = Math.min(minX, x);
    maxX = Math.max(maxX, x);
    minY = Math.min(minY, y);
    maxY = Math.max(maxY, y);
  });

  return {
    minX,
    minY,
    maxX,
    maxY,
  };
}

export type PointsAndBoundingBox = {
  points: Point[];
  boundingBox: MinMaxBBox;
};

export function normalizeAndBBoxPoints(points: Point[]): PointsAndBoundingBox {
  const normalizedPoints = normalizePoints(points);
  const boundingBox = bboxFromPoints(normalizedPoints);

  return { points: normalizedPoints, boundingBox };
}

export function normalizeAndBBoxPath(
  pathData: PathCommand[],
): CommandAndBoundingBox {
  const normalizedPath = normalizePath(pathData);
  const boundingBox = boundingBoxFromPath(normalizedPath);

  return { command: normalizedPath, boundingBox };
}

export function normalizePath(pathData: PathCommand[]): PathCommand[] {
  // Find the minimum and maximum values for both x and y coordinates
  let minX = Infinity,
    minY = Infinity;
  let maxX = -Infinity,
    maxY = -Infinity;

  // Loop through all commands and points to find the extents
  pathData.forEach((command) => {
    command.points.forEach((point, index) => {
      if (index % 2 === 0) {
        // X-coordinate
        minX = Math.min(minX, point);
        maxX = Math.max(maxX, point);
      } else {
        // Y-coordinate
        minY = Math.min(minY, point);
        maxY = Math.max(maxY, point);
      }
    });
  });

  // Calculate the range for normalization
  const rangeX = maxX - minX || 1; // Prevent division by zero
  const rangeY = maxY - minY || 1;

  // Normalize all points so they fit within [0, 1]
  const normalizedPath = pathData.map((command) => ({
    type: command.type,
    points: command.points.map(
      (point, index) =>
        index % 2 === 0
          ? (point - minX) / rangeX // Normalize X-coordinate
          : (point - minY) / rangeY, // Normalize Y-coordinate
    ),
  }));

  return normalizedPath;
}

export function transformPath(
  {
    command: pathData,
    boundingBox: { minX, minY, maxX, maxY },
  }: CommandAndBoundingBox,
  x: number,
  y: number,
  size: number,
): string {
  // Calculate the center of the bounding box
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  // Apply translation to center the shape at (x, y)
  return pathData
    .map((command) => {
      // Apply translation and scaling to all points in each command
      const transformedPoints = command.points.map(
        (point, index) =>
          index % 2 === 0
            ? (point - centerX) * size + x // Adjust X to center
            : (point - centerY) * size + y, // Adjust Y to center
      );
      // Convert the command back to string format
      return `${command.type} ${transformedPoints.join(' ')}`;
    })
    .join(' '); // Join all commands with spaces to form the final 'd' attribute string
}
