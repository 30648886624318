import { useMemo, useCallback } from 'react';
import { DatasetMask, parseDatasetVersion } from './dataset-helper';
import { DetailsProps } from './NodeDetails';
import { NodeDetailsPanel, NoPreview } from './NodeDetalisPanel';
import { Settings, Visible } from '../ui/icons';
import { Input } from '../ui/atoms/Input';
import { CustomLossInstance } from '@tensorleap/api-client';
import { Select } from '../ui/atoms/Select';
import { UserUniqueName, USER_UNIQUE_NAME } from './UserUniqueName';
import { useNetworkMapContext } from '../core/NetworkMapContext';

export type CustomLossNodeData = Record<string, number | undefined> & {
  name: string;
  selected: string;
  arg_names: string[];
};

export function CustomLossDetails({ node }: DetailsProps): JSX.Element {
  const { changeNodeProperty, updateConnection, currentDatasetSetup } =
    useNetworkMapContext();

  const customLossName = node.data.name || '';
  const customLosses = currentDatasetSetup?.custom_losses || [];

  const customLossDetails = useMemo(
    () =>
      parseDatasetVersion(DatasetMask.CustomLoss, currentDatasetSetup).find(
        ({ title }) => title.endsWith(customLossName),
      ),
    [currentDatasetSetup, customLossName],
  );

  const handleCustomLossChange = useCallback(
    (name: string | undefined, customLossItem: CustomLossInstance | null) => {
      const { arg_names = [] } = customLossItem || {};

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: {
          name,
          selected: name,
          [USER_UNIQUE_NAME]: name,
          arg_names,
        },
      });
      updateConnection(node.id, arg_names);
    },
    [changeNodeProperty, updateConnection, node.id],
  );

  return (
    <div className="flex flex-col overflow-auto">
      <NodeDetailsPanel
        title="Properties"
        className="p-4 gap-8"
        icon={<Settings />}
        openByDefault
      >
        <Select
          label="SELECTED CUSTOM LOSS"
          optionID="name"
          options={customLosses}
          onChange={handleCustomLossChange}
          value={customLossName}
        />
        <UserUniqueName node={node} />
      </NodeDetailsPanel>

      <NodeDetailsPanel
        title="Preview"
        className="p-4 gap-8"
        icon={<Visible />}
        openByDefault
      >
        {customLossDetails ? (
          customLossDetails.subItems.map(({ title, value }) => (
            <Input key={title} label={title} value={value} readOnly />
          ))
        ) : (
          <NoPreview />
        )}
      </NodeDetailsPanel>
    </div>
  );
}
