import { useCallback } from 'react';
import { useNotificationSubscriber } from './PushNotificationsContext';
import { CommandNotification } from '@tensorleap/api-client';
import { useSnackbar } from 'notistack';

export function useCommandNotificationHandler(logout: () => Promise<void>) {
  const { enqueueSnackbar } = useSnackbar();
  const handleCommand = useCallback(
    async (notification: unknown) => {
      if (!isCommandNotification(notification)) {
        return;
      }
      if (notification.command === 'log-out') {
        const message = notification.params?.message;
        if (message) {
          enqueueSnackbar(message, { variant: 'info' });
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        await logout();
      }
    },
    [logout, enqueueSnackbar],
  );
  useNotificationSubscriber(handleCommand);
}

function isCommandNotification(
  notification: unknown,
): notification is CommandNotification {
  return !!(notification as CommandNotification)?.command;
}
