import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPushpinOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12.808 7.04 10.51 9.338a3.24 3.24 0 0 0-.92 1.839L6.834 8.419a3.213 3.213 0 0 0 1.839-.92l2.298-2.297 1.838 1.838Zm2.298.46L10.51 2.904a.652.652 0 0 0-.92 0 .652.652 0 0 0 0 .919l.46.46L7.753 6.58a1.948 1.948 0 0 1-2.23.376c-.323-.157-.733-.17-.987.083a.65.65 0 0 0 0 .92l2.284 2.284-3.217 3.217v.92h.92l3.217-3.218 2.312 2.312a.65.65 0 0 0 .919 0c.254-.254.24-.664.083-.986a1.948 1.948 0 0 1 .376-2.232l2.298-2.298.46.46a.652.652 0 0 0 .92 0 .652.652 0 0 0 0-.92Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPushpinOffIcon;
