import {
  SessionTestResult,
  SessionTestResultError,
  SessionTestResultNotFound,
  SessionTestResultSuccess,
} from '@tensorleap/api-client';
import { useMemo } from 'react';
import { compactNumberFormatter } from '../core/formatters/number-formatting';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { Divider, Tooltip } from '../ui/mui';
import { ModelTestStatusIcon } from './ModelTestStatusIcon';
import { isSessionTestResultSuccess } from './useModelTestSummaryResult';
import { truncateLongtail } from '../core/formatters/string-formatting';

export interface ModelTestsCardContentTestResultsRowProps {
  showDivider: boolean;
  sessionResult: SessionTestResult;
}

export function ModelTestsCardContentTestResultsRow({
  showDivider,
  sessionResult,
}: ModelTestsCardContentTestResultsRowProps): JSX.Element {
  return (
    <>
      {isSessionTestResultSuccess(sessionResult) ? (
        <SessionTestsCardContentTestRunResultsRow
          sessionResult={sessionResult}
        />
      ) : (
        <ModelTestsCardContentTestNotAvailableResultsRow
          modelResult={sessionResult}
        />
      )}
      {showDivider && <Divider className="col-span-10 bg-gray-700" />}
    </>
  );
}

export interface ModelTestsCardContentTestRunResultsRowProps {
  sessionResult: SessionTestResultSuccess;
}

export function SessionTestsCardContentTestRunResultsRow({
  sessionResult,
}: ModelTestsCardContentTestRunResultsRowProps): JSX.Element {
  const { selected: selectedModels } = useModelFilter();

  const selectedModelName = useMemo(
    () =>
      selectedModels.find(
        (selectedModel) => selectedModel.id === sessionResult.sessionRunId,
      )?.name || '',
    [sessionResult.sessionRunId, selectedModels],
  );

  const modelUniqueKey = useMemo(
    () =>
      selectedModels.find(
        (selectedModel) => selectedModel.id === sessionResult.sessionRunId,
      )?.modelUniqueKey,
    [sessionResult.sessionRunId, selectedModels],
  );
  return (
    <>
      <div className="my-2">
        <ModelTestStatusIcon
          name={selectedModelName}
          modelUniqueKey={modelUniqueKey}
          status={sessionResult.testSucceeded ? 'success' : 'failed'}
        />
      </div>
      <Tooltip title={selectedModelName}>
        <div className="col-span-5 my-2 inline-flex justify-start items-center">
          {truncateLongtail({
            value: selectedModelName,
            startSubsetLength: 15,
            endSubsetLength: 5,
          })}
        </div>
      </Tooltip>
      <div className="col-span-2 my-2 inline-flex justify-start items-center">
        {typeof sessionResult.aggregation === 'number' ? (
          Number(compactNumberFormatter.format(sessionResult.aggregation))
        ) : (
          <span className="text-gray-500">NA</span>
        )}
      </div>
      <div className="col-span-2 my-2 inline-flex justify-end items-center">
        <span>
          <span className="text-secondary-500 mr-1">
            {compactNumberFormatter.format(sessionResult.succefullSamples)}
          </span>
          /
          <span className="ml-1">
            {compactNumberFormatter.format(sessionResult.allSamples)}
          </span>
        </span>
      </div>
    </>
  );
}

export interface ModelTestsCardContentTestNotAvailableResultsProps {
  modelResult: SessionTestResultError | SessionTestResultNotFound;
}

export function ModelTestsCardContentTestNotAvailableResultsRow({
  modelResult,
}: ModelTestsCardContentTestNotAvailableResultsProps): JSX.Element {
  const { selected: selectedModels } = useModelFilter();

  const selectedModelName = useMemo(
    () =>
      selectedModels.find(
        (selectedModel) => selectedModel.id === modelResult.sessionRunId,
      )?.name || '',
    [modelResult.sessionRunId, selectedModels],
  );
  const modelUniqueKey = useMemo(
    () =>
      selectedModels.find(
        (selectedModel) => selectedModel.id === modelResult.sessionRunId,
      )?.modelUniqueKey,
    [modelResult.sessionRunId, selectedModels],
  );

  return (
    <>
      <div className="my-2">
        <ModelTestStatusIcon
          name={selectedModelName}
          modelUniqueKey={modelUniqueKey}
          status="na"
        />
      </div>
      <div className="col-span-5 my-2 inline-flex justify-start items-center">
        {truncateLongtail({
          value: selectedModelName,
          startSubsetLength: 10,
          endSubsetLength: 10,
        })}
      </div>
      <div className="col-span-2 my-2 inline-flex justify-start items-center">
        <span className="text-gray-500">NA</span>
      </div>
      <div className="col-span-2 my-2 inline-flex justify-end items-center">
        <span className="text-gray-500">NA</span>
      </div>
    </>
  );
}
