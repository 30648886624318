import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgContentCopy1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M20 21H9V7h11v14Zm0-16H9a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2Zm-3-4H5a2 2 0 0 0-2 2v14h2V3h12V1Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgContentCopy1;
