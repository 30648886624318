import {
  ResponsiveContainer,
  PieChart as RePieChart,
  Pie,
  Tooltip,
} from 'recharts';
import { GRAPH_STYLE } from '../common/constants';
import { useXYChart } from '../hooks/useXYChart';
import { XYChartProps } from '../common/interfaces';
import { NoDataChart } from '../common/NoDataChart';
import { AnalyticsDashletType } from '@tensorleap/api-client';
import { useTooltipProps } from './ChartBlocks/TooltipProps';
import { ActiveShape } from './ChartBlocks/PieChartActiveShape';
import { usePieChart } from '../hooks/usePieChart';

export function PieChart({
  graphData,
  chartRequestData,
  className,
  hiddenLabels,
  hoverLabel,
  colorMap,
  onFiltersChange,
  localFilters,
  mapValue,
  height,
  width,
}: XYChartProps): JSX.Element {
  const {
    splitData,
    isHidden,
    allLabels,
    graphKey,
    fillOpacity,
    setIsMouseHover,
    setIsMouseHoverPortion,
  } = useXYChart({
    graphData,
    chartRequestData,
    chartType: AnalyticsDashletType.Donut,
    hiddenLabels,
    hoverLabel,
  });

  const lines = splitData();

  const {
    onClick,
    activeIndex,
    handleMouseOver,
    handleMouseLeave,
    handleMouseLeaveChart,
    getInnerRadius,
    getOuterRadius,
  } = usePieChart({
    chartRequestData,
    localFilters,
    onFiltersChange,
    setIsMouseHover,
    setIsMouseHoverPortion,
    allLabels,
  });

  const tooltipProps = useTooltipProps({
    graphData,
    colorMap,
    chartRequestData,
    mapValue,
  });

  if (!graphData.data?.length || !lines) {
    return <NoDataChart />;
  }

  return (
    <ResponsiveContainer
      height={height}
      width={width}
      className={className}
      key={graphKey}
    >
      <RePieChart
        margin={GRAPH_STYLE.margin}
        className="select-none"
        onMouseLeave={handleMouseLeaveChart}
      >
        <Tooltip {...tooltipProps} />
        {Object.entries(lines).map(([datakey, line], index) => (
          <Pie
            labelLine={false}
            key={index}
            data={line}
            dataKey={datakey}
            isAnimationActive={false}
            cx={GRAPH_STYLE.pie.cx}
            cy={GRAPH_STYLE.pie.cy}
            fill={colorMap[datakey]}
            fillOpacity={fillOpacity(datakey)}
            innerRadius={getInnerRadius(index)}
            outerRadius={getOuterRadius(index)}
            hide={isHidden(datakey)}
            stroke={GRAPH_STYLE.pie.strokeColor}
            activeShape={ActiveShape}
            activeIndex={activeIndex}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
          />
        ))}
      </RePieChart>
    </ResponsiveContainer>
  );
}
