export const MOUSE_DOWN_RENDER_KEY = 'mouseDownRenderKey';

export const CHART_META_FIELDS_KEY = '__META__KEY__';

export const DEFAULT_AXIS_SIZE_INTERVAL = 1;

export const LABEL_TRUNCATE_LENGTH = 8;
export const CHART_LABEL_TRUNCATE_LENGTH = 24;

export const GRAPH_STYLE = {
  kibanaBlackBlue: '#1c1e24',
  gray500: '#9E9E9E',
  margin: {
    top: 10,
    right: 20,
    left: 15,
    bottom: 10,
  },
  mediumFontLabelLength: 8,
  largeFontLabelLength: 16,
  xAxis: {
    label: {
      dy: 15,
      fontSizeSmall: 8,
      fontSizeMedium: 10,
      fontSizeLarge: 12,
    },
    tick: {
      fontSize: 11,
    },
  },
  yAxis: {
    label: {
      dx: 35,
      angle: -90,
      fontSizeSmall: 8,
      fontSizeMedium: 10,
      fontSizeLarge: 12,
    },
    tick: {
      fontSize: 10,
    },
  },
  dragStrokeOpacity: 0.3,
  line: {
    strokeWidth: 1.5,
    hoverOpacity: 0.2,
    noHoverOpacity: 1,
    hiddenFillOpacity: 0.1,
    weakShowOpacity: 0.5,
    strongShowOpacity: 0.9,
  },
  pie: {
    strokeColor: '#1c1e24',
    cx: '50%',
    cy: '50%',
    maxRadiusPercent: 100,
    cliceSeperatingPixels: 2,
    innerBlankCircleSize: 4,
  },
  heatmap: {
    theme: {
      axis: {
        ticks: {
          line: {
            stroke: 'white',
            strokeWidth: 1,
          },
          text: {
            stroke: 'white',
            strokeWidth: 0.7,
          },
        },
        legend: {
          text: {
            stroke: 'white',
            strokeWidth: 0.7,
          },
        },
      },
      legends: {
        ticks: {
          text: {
            stroke: 'white',
            strokeWidth: 0.7,
          },
        },
      },
    },
    borderWidth: 1,
    legends: {
      anchor: 'right',
      translateX: 30,
      translateY: 0,
      thickness: 8,
      direction: 'column',
      tickPosition: 'after',
      tickSize: 3,
      tickSpacing: 4,
      tickOverlap: false,
      titleAlign: 'start',
      titleOffset: 4,
    },
    colorSchemeRedYellowGreen: ['#A60126', '#FEEB9F', '#06743D'] as [
      string,
      string,
      string,
    ],
    colorSchemeGreenYellowRed: ['#06743D', '#FEEB9F', '#A60126'] as [
      string,
      string,
      string,
    ],
    colors: {
      type: 'diverging',
      divergeAt: 0.5,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendPosition: 'middle',
      legendOffset: -72,
    },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendPosition: 'middle',
      legendOffset: 46,
    },
    margin: { top: 60, right: 90, bottom: 60, left: 90 },
  },
} as const;
