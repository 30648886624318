import { Tooltip } from '@material-ui/core';
import { FormEvent, useCallback, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';
import { FacebookIcon, FwArrowIcon, LinkedinIcon, TwitterIcon } from '../icons';
import api from '../../core/api-client';
import { Select } from '../atoms/Select';
import { useFreemium } from '../../trial/useFreemium';

const contactReasonOptions: string[] = [
  'Upgrade to Pro Version',
  'Request Trial Extension',
];

export interface ReasonSelectionContactWidgetInputs {
  firstName: string;
  lastName: string;
  role: string;
  company: string;
  email: string;
  telephone: string;
  reason: string;
}
export function ReasonSelectionContactWidget() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<ReasonSelectionContactWidgetInputs>({
    mode: 'onBlur',
  });
  const [messageSent, setMessageSent] = useState(false);
  const isFormValid = isValid && isDirty && !isSubmitting;
  const { freemiumUserData } = useFreemium();

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        await handleSubmit(
          async ({
            firstName,
            lastName,
            role,
            company,
            email,
            telephone,
            reason,
          }) => {
            await api.sendUserMessage({
              firstName,
              lastName,
              role,
              company,
              email,
              telephone,
              content: { reason },
            });
            setMessageSent(true);
          },
        )(event);
      } catch (e) {
        console.error(e);
      }
    },
    [handleSubmit],
  );

  const { field: reasonField } = useController({
    control,
    name: 'reason',
    defaultValue: contactReasonOptions[0] || '',
  });

  return messageSent ? (
    <div className="flex flex-col h-full w-full text-primary-400">
      <span className="text-xl">Thank you.</span>
      <span className="text-lg">
        The form was sent successfully, and we will be in touch soon. On the
        meantime, it will mean the world to us, if you’ll share how awesome
        Tensorleap is...
      </span>
      <div className="h-fit w-full flex flex-row text-gray-500 gap-6 pt-8">
        <Tooltip title="Find us on LinkdIn" arrow placement="top">
          <button
            className="hover:text-gray-400"
            onClick={() =>
              openInNewTab('https://www.linkedin.com/company/tensorleap')
            }
          >
            <LinkedinIcon />
          </button>
        </Tooltip>
        <Tooltip title="Find us on Facebook" arrow placement="top">
          <button
            className="hover:text-gray-400"
            onClick={() => openInNewTab('https://www.facebook.com/Tensorleap/')}
          >
            <FacebookIcon />
          </button>
        </Tooltip>
        <Tooltip title="Find us on Twitter" arrow placement="top">
          <button
            className="hover:text-gray-400"
            onClick={() => openInNewTab('https://twitter.com/tensorleap')}
          >
            <TwitterIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  ) : (
    <form onSubmit={onSubmit} className="flex flex-col w-full h-full">
      <div className="uppercase w-full grid grid-flow-row grid-cols-2 gap-x-2 gap-y-4">
        <Input
          label="first name"
          {...register('firstName', {
            value: freemiumUserData.firstName,
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.firstName && errors?.firstName?.message}
        />
        <Input
          label="last name"
          {...register('lastName', {
            value: freemiumUserData.lastName,
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.lastName && errors?.lastName?.message}
        />
        <Input
          label="role"
          {...register('role', {
            value: freemiumUserData.role,
          })}
          error={errors.role && errors?.role?.message}
        />
        <Input
          label="company"
          {...register('company', {
            value: freemiumUserData.company,
          })}
          error={errors.company && errors?.company?.message}
        />
        <Input
          label="email"
          type="email"
          {...register('email', {
            value: freemiumUserData.email,
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.email && errors?.email?.message}
        />
        <Input
          label="telephone"
          type="tel"
          {...register('telephone', {
            value: freemiumUserData.phone,
          })}
          error={errors.telephone && errors?.telephone?.message}
        />
        <Select
          label="contact reason"
          {...register('reason', {
            required: { value: true, message: 'Value is required' },
          })}
          options={contactReasonOptions}
          {...reasonField}
          className="col-span-2"
          error={errors.reason && errors?.reason?.message}
        />
      </div>
      <footer className="justify-end col-start-2 flex gap-2 mt-auto">
        <Button type="submit" className="uppercase" disabled={!isFormValid}>
          send <FwArrowIcon className="pl-2" />
        </Button>
      </footer>
    </form>
  );
}

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener');
};
