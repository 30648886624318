import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgShapeInfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 16.917c0 .443-.245.828-.618 1.026l-9.217 5.18c-.187.14-.42.21-.665.21-.245 0-.478-.07-.665-.21l-9.217-5.18A1.156 1.156 0 0 1 2 16.917v-10.5c0-.444.245-.829.618-1.027L11.835.21c.187-.14.42-.21.665-.21.245 0 .478.07.665.21l9.217 5.18c.373.198.618.583.618 1.027v10.5ZM12.5 2.508 4.333 7.105v9.123l8.167 4.597 8.167-4.597V7.105L12.5 2.508Zm0 15.124c-.365 0-.669-.08-.912-.24-.23-.17-.345-.41-.345-.72v-6.176c0-.31.115-.544.345-.704.243-.16.547-.24.912-.24s.669.08.912.24c.243.16.365.395.365.704v6.176c0 .31-.122.55-.365.72-.243.16-.547.24-.912.24Zm0-9.132a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgShapeInfoIcon;
