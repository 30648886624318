import {
  DatasetParsePushMessage,
  VisualizationPushMessage,
  InsightsPushMessage,
  PushNotificationResponse,
  JobStatus,
  JobMessageParams,
  CustomMessageDataParams,
  GraphAnalyzerMessage,
  ExportModelPushMessage,
  ScatterPushMessage,
  UpdateScatterCSVPushMessage,
  JobEventMessage,
  JobType,
} from '@tensorleap/api-client';

export function isPushNotificationMessage(
  msg: unknown,
): msg is PushNotificationResponse {
  return (msg as PushNotificationResponse)?.source === 'push-notification';
}

export interface DatasetUpdateNotificationMessage {
  data: { source: 'update-notification' };
  job: string;
  status: string;
}
export function isDatasetUpdateNotificationMessage(
  msg: unknown,
): msg is DatasetUpdateNotificationMessage {
  return (
    (msg as DatasetUpdateNotificationMessage)?.data?.source ===
    'update-notification'
  );
}

export function isDatasetParsePushMessage(
  msg: unknown,
): msg is DatasetParsePushMessage {
  return (msg as DatasetParsePushMessage)?.source === 'dataset_parse';
}

export function isScatterPushMessage(msg: unknown): msg is ScatterPushMessage {
  return (
    (msg as VisualizationPushMessage)?.data?.source === 'visualization' &&
    !!(msg as ScatterPushMessage)?.data?.blob
  );
}

export function isUpdateScatterCSVPushMessage(
  msg: unknown,
): msg is UpdateScatterCSVPushMessage {
  return (
    (msg as VisualizationPushMessage)?.data?.source === 'visualization' &&
    !!(msg as UpdateScatterCSVPushMessage)?.data?.csvBlob
  );
}

export function isInsightsPushMessage(
  msg: unknown,
): msg is InsightsPushMessage {
  return !!(msg as InsightsPushMessage)?.insights;
}

export function isPushNotificationsMsg(
  msg: unknown,
): msg is PushNotificationResponse {
  const message = msg as PushNotificationResponse | undefined;
  return message?.source === 'push-notification';
}

export function isJobStatusMessageParams(
  msgData: CustomMessageDataParams | undefined,
): msgData is JobMessageParams {
  return (msgData as JobMessageParams)?.module === 'JobStatus';
}

export function isJobEventMessage(msg: unknown): msg is JobEventMessage {
  return (msg as JobEventMessage)?.source === 'job-event-message';
}

export function isImportModelSuccessMsg(msg: unknown): boolean {
  return (
    isPushNotificationsMsg(msg) &&
    msg.data.context?.jobType === JobType.ImportModel &&
    isJobStatusMessageParams(msg.data.messageData.params) &&
    msg.data.messageData.params.jobStatus === JobStatus.Finished
  );
}

export function isTrainingStartedMsg(msg: unknown): boolean {
  return (
    isPushNotificationsMsg(msg) &&
    msg.data?.context?.jobType === JobType.Training &&
    isJobStatusMessageParams(msg.data.messageData.params) &&
    msg.data.messageData.params.jobStatus === JobStatus.Started
  );
}

export function isGraphAnalyzerMessage(
  msg: unknown,
): msg is GraphAnalyzerMessage {
  return (msg as GraphAnalyzerMessage)?.source === 'graph-analyzer';
}

export function isProcessUpdatedMsg(msg: unknown): boolean {
  return (
    isPushNotificationsMsg(msg) &&
    isJobStatusMessageParams(msg.data.messageData.params)
  );
}

export function isExportModelMsg(msg: unknown): boolean {
  return (msg as ExportModelPushMessage)?.data?.source === 'export_model';
}

export function isValidateAssetsUpdatelMsg(msg: unknown): boolean {
  return (
    isProcessUpdatedMsg(msg) &&
    (msg as PushNotificationResponse).data.title === 'ValidateGraph'
  );
}

export function isSampleAnalysisMsg(msg: unknown): boolean {
  return (
    isProcessUpdatedMsg(msg) &&
    (msg as PushNotificationResponse).data.title === 'sample_analysis'
  );
}
