import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RunModelDialog } from './RunModel';
import { RunningToolbarDialog } from './RunningDialog';
import { Down, Up, Vi1 } from '../ui/icons';
import { useVersionControl } from '../core/VersionControlContext';
import { NotificationPanel } from '../actions-dialog/NotificationPanel';
import { useFreemium } from '../trial/useFreemium';
import { ActionButton } from '../ui/molecules/ActionButton';
import { Button } from '../ui/atoms/Button';
import { useStateObject } from '../core/types';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { useAuth } from '../auth/AuthContext';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { URLS_ENUM } from '../url/url-builder';
import { ToursDialog } from '../tour/ToursDialog';
import { Drawer } from '../ui/atoms/Drawer';
import { RightMenu } from '../menu/RightMenu';
import { UserManagementDialog } from '../team-management/UserManagementDialog';

export function ToolbarActions(): JSX.Element {
  const { user } = useAuth();
  const rightMenuDrawerState = useStateObject(false);
  const userManagerDialogState = useStateObject(false);

  const {
    currentVersion,
    initialEvaluateDialogVersionId,
    isEvaluateDialogOpen,
    openEvaluateDialog,
    closeEvaluateDialog,
  } = useCurrentProject();
  const { versions } = useVersionControl();
  const { upgradeDialog } = useFreemium();

  const match = useRouteMatch({ path: URLS_ENUM.PROJECT });
  const isMainPage = !!match?.path;

  const initialSlimVersion = useMemo(
    () =>
      initialEvaluateDialogVersionId
        ? versions.find(({ cid }) => cid === initialEvaluateDialogVersionId)
        : versions.find(({ cid }) => cid === currentVersion?.cid) ||
          versions[0],
    [initialEvaluateDialogVersionId, versions, currentVersion?.cid],
  );

  return (
    <>
      {upgradeDialog}
      <UserManagementDialog openState={userManagerDialogState} />
      {isMainPage && (
        <ActionButton icon={<Vi1 />} onRun={openEvaluateDialog}>
          EVALUATE
        </ActionButton>
      )}

      <ToursDialog />

      <RunningToolbarDialog />

      <NotificationPanel />

      {user && (
        <Button
          variant="action-icon"
          onClick={() => rightMenuDrawerState.setState(true)}
        >
          <CircledIcon text={user.local.name} borderStyle="border-2" />
          {rightMenuDrawerState.state ? <Up /> : <Down />}
        </Button>
      )}

      {initialSlimVersion && isEvaluateDialogOpen && (
        <RunModelDialog
          isOpen={isEvaluateDialogOpen}
          onClose={closeEvaluateDialog}
          initialVersion={initialSlimVersion}
        />
      )}
      {rightMenuDrawerState.state && (
        <Drawer
          openState={rightMenuDrawerState}
          drawerClassName="absolute top-16 right-0 h-[calc(100%_-_4.1rem)] w-80 bg-gray-800"
          position="top"
        >
          <RightMenu userManagerDialogState={userManagerDialogState} />
        </Drawer>
      )}
    </>
  );
}
