import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useVersionControl } from '../core/VersionControlContext';
import { groupBy, map } from 'lodash';
import { useMergedObject } from '../core/useMergedObject';
import { OptionDefaultObj } from '../ui/atoms/utils/select';
import { useFetchDatasets } from '../core/data-fetching/datasets';
import { createFilterOptions, FilterOptionsState } from '@mui/material';

interface BranchOption {
  title: string;
  inputValue?: string;
}

export interface SaveVersionParams {
  revisionName: string;
  allBranches: BranchOption[];
  branch: BranchOption | null;
  modelName: string;
  datasetId?: string;
  datasetOptions: OptionDefaultObj[];
  handleDatasetChange: (value?: string) => void;
  handleRevisionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBranchChange: (
    event: ChangeEvent<unknown>,
    newValue: BranchOption | string | null,
  ) => void;
  handleBranchFilterOptions: (
    options: BranchOption[],
    params: FilterOptionsState<BranchOption>,
  ) => BranchOption[];
  handleOptionalBranch: (option: BranchOption) => string;
  handleModelNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fetchVersions: () => Promise<void>;
}

const filter = createFilterOptions<BranchOption>();

export const useSaveVersionState = (): SaveVersionParams => {
  const { versions, fetchVersions } = useVersionControl();
  const { currentVersion } = useCurrentProject();
  const [revisionName, setRevisionName] = useState('');
  const [allBranches, setAllBranches] = useState<BranchOption[]>([]);
  const [branch, setBranch] = useState<BranchOption | null>(null);
  const [modelName, setModelName] = useState<string>('');
  const [datasetId, setDatasetId] = useState<string | undefined>();
  const { datasets } = useFetchDatasets();
  const datasetOptions = useMemo<OptionDefaultObj[]>(
    () =>
      datasets.map(({ name, cid }) => ({
        label: name,
        value: cid,
      })),
    [datasets],
  );

  useEffect(() => {
    setAllBranches(
      map(
        groupBy(versions, (v) => v.branchName),
        ([version]) => ({
          title: version.branchName,
          versionId: version.cid,
          branchId: version.branchName,
        }),
      ),
    );
  }, [versions]);

  useEffect(() => {
    const version = versions.find((v) => v.cid === currentVersion?.cid);
    const defaultBranch = version
      ? {
          title: version.branchName,
          versionId: version.cid,
          branchId: version.branchName,
        }
      : null;

    setBranch(defaultBranch);
  }, [versions, currentVersion]);

  const handleDatasetChange = useCallback((datasetId?: string) => {
    setDatasetId(datasetId);
  }, []);

  const handleRevisionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setRevisionName(event.target.value?.trim() || '');
    },
    [],
  );

  const handleModelNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setModelName(event.target.value?.trim() || '');
    },
    [],
  );

  const handleBranchChange = useCallback(
    (event: ChangeEvent<unknown>, newValue: BranchOption | string | null) => {
      event.preventDefault();
      if (typeof newValue === 'string') {
        setBranch({
          title: newValue,
        });
      } else if (newValue && newValue.inputValue) {
        setBranch({
          title: newValue.inputValue,
        });
      } else {
        setBranch(newValue);
      }
    },
    [],
  );

  const handleBranchFilterOptions = useCallback(
    (options: BranchOption[], params: FilterOptionsState<BranchOption>) => {
      const filtered = filter(options, params);

      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          title: `Add "${params.inputValue}"`,
        });
      }

      return filtered;
    },
    [],
  );

  const handleOptionalBranch = useCallback((option: BranchOption) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.inputValue) {
      return option.inputValue;
    }
    return option.title;
  }, []);

  return useMergedObject({
    revisionName,
    allBranches,
    branch,
    modelName,
    datasetId,
    datasetOptions,
    handleDatasetChange,
    handleRevisionChange,
    handleBranchChange,
    handleBranchFilterOptions,
    handleOptionalBranch,
    handleModelNameChange,
    fetchVersions,
  });
};

export function getBranchTitle({ title }: BranchOption): string {
  return title;
}
