import { Tooltip } from '@material-ui/core';
import { BoundingBox } from '@tensorleap/api-client';
import clsx from 'clsx';
import { clamp } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { labelsColorSupplier } from '../../core/color-helper';
import { useToggle } from '../../core/useToggle';
import {
  FILTER_LABEL_ICON,
  LabelOption,
  MultiSelectIconMenu,
  renderLabelOption,
} from '../atoms/MultiSelectIconMenu';
import { Slider, Range } from '../atoms/Slider';
import { ToggleIcon } from '../atoms/ToggleIcon';
import { OptionValue } from '../atoms/utils/select';
import { IssueIcon } from '../icons';
import { useMapStoredProjectImgUrl } from '../../core/useProjectStorage';
import { TransformComponent } from 'react-zoom-pan-pinch';

export type ImageWithBboxProps = {
  boundingBox: BoundingBox[];
  className?: string;
  imageContainerClassName?: string;
  src: string;
};

export function ImageWithBboxAndMapUrl({ src, ...props }: ImageWithBboxProps) {
  const mappedSrc = useMapStoredProjectImgUrl(src);
  return <ImageWithBbox src={mappedSrc} {...props} />;
}

export function ImageWithBbox({
  src,
  boundingBox,
  className,
  imageContainerClassName,
}: ImageWithBboxProps) {
  const [showBboxInfo, toggleShowBBoxInfo] = useToggle();
  const [confidenceFilter, setConfidenceFilter] = useState<Range>([0, 1]);
  const setConfidenceRange = useCallback(
    (_: React.ChangeEvent<object>, newValue: number | number[]) => {
      setConfidenceFilter(newValue as Range);
    },
    [],
  );

  const [ignoredLabels, setIgnoredLabels] = useState<OptionValue[]>([]);

  const labelOptions = useMemo<LabelOption[]>(
    () =>
      Array.from(new Set(boundingBox.map(({ label }) => label))).map(
        (label) => ({
          value: label,
          color: labelsColorSupplier.get(label),
        }),
      ),
    [boundingBox],
  );
  const filteredBoundingBox = useMemo(
    () =>
      boundingBox.filter(
        ({ confidence, label }) =>
          confidence >= confidenceFilter[0] &&
          confidence <= confidenceFilter[1] &&
          !ignoredLabels.includes(label),
      ),
    [confidenceFilter, ignoredLabels, boundingBox],
  );

  const [fullHeightOrWidthClass, setFullHeightOrWidthClass] =
    useState('h-full');

  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const updateClass = useCallback(() => {
    const img = imageRef.current;
    const container = containerRef.current;
    if (!img || !container) return;

    const imgRadio = img.naturalWidth / img.naturalHeight;
    const containerRadio = container.clientWidth / container.clientHeight;
    if (imgRadio > containerRadio) {
      setFullHeightOrWidthClass('w-full');
    } else {
      setFullHeightOrWidthClass('h-full');
    }
  }, []);

  useEffect(() => {
    const img = imageRef.current;
    const container = containerRef.current;
    if (!img || !container) return;

    if (img.complete) {
      updateClass();
    } else {
      img.addEventListener('load', updateClass);
      return () => {
        img.removeEventListener('load', updateClass);
      };
    }
  }, [
    updateClass,
    imageRef.current?.naturalWidth,
    containerRef.current?.clientWidth,
    containerRef.current?.clientHeight,
  ]);

  return (
    <div className={clsx(className, 'flex flex-col self-center items-center ')}>
      <TransformComponent
        contentStyle={{
          height: '100%',
          width: '100%',
        }}
        wrapperStyle={{
          height: '100%',
          width: '100%',
        }}
      >
        <div
          ref={containerRef}
          className={clsx(
            'h-full w-full items-center flex flex-row justify-center flex-1 self-center min-h-0',
            imageContainerClassName,
          )}
        >
          <div className={clsx('relative', fullHeightOrWidthClass)}>
            <img
              src={src}
              alt="img"
              className={clsx('object-contain', fullHeightOrWidthClass)}
              ref={imageRef}
            />
            {filteredBoundingBox.map((bbox, i) => (
              <BBox
                {...bbox}
                key={bbox.label + bbox.confidence + i}
                showInfo={showBboxInfo}
              />
            ))}
          </div>
        </div>
      </TransformComponent>
      <div className="flex justify-center gap-6">
        <MultiSelectIconMenu
          icon={FILTER_LABEL_ICON}
          iconWrapperClassName="m-2 h-8 w-8"
          invertedSelection
          options={labelOptions}
          value={ignoredLabels}
          onChange={setIgnoredLabels}
          renderOption={renderLabelOption}
        />
        <div className="flex flex-col gap-3 mt-3">
          <Slider
            min={0}
            max={1}
            step={0.05}
            value={confidenceFilter}
            onChange={setConfidenceRange}
          />
          <span className="text-xs font-bold text-gray-500">
            CONFIDENCE THRESHOLD
          </span>
        </div>

        <ToggleIcon
          className="m-2 h-8 w-8"
          value={showBboxInfo}
          tooltips={['Hide bounding box info', 'Show bounding box info']}
          onToggle={toggleShowBBoxInfo}
        >
          <IssueIcon />
        </ToggleIcon>
      </div>
    </div>
  );
}

export type BboxProps = BoundingBox & {
  showInfo: boolean;
};
const BBOX_BG_OPACITY = '10';
export function BBox({
  x,
  y,
  height,
  width,
  confidence,
  label,
  showInfo,
  metadata,
  rotation = 0,
}: BboxProps) {
  const color = useMemo(() => labelsColorSupplier.get(label), [label]);
  const metadataString = useMemo(() => {
    const info = { confidence, label };
    return Object.entries({ ...info, ...(metadata || {}) })
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }, [confidence, label, metadata]);

  return (
    <Tooltip arrow title={`${confidence}, ${label}`}>
      <div
        className="absolute border border-solid flex items-start"
        style={{
          ...calcImageLocation({ x, y, height, width }),
          background: color + BBOX_BG_OPACITY,
          borderColor: color,
          transform: `rotate(${rotation}deg)`,
          transformOrigin: 'center',
        }}
      >
        {showInfo && (
          <div
            style={{ color }}
            className="px-1 text-ellipsis whitespace-pre text-xs bg-white/80"
          >
            <p>{metadataString}</p>
          </div>
        )}
      </div>
    </Tooltip>
  );
}

function calcImageLocation({
  x,
  y,
  height,
  width,
}: {
  x: number;
  y: number;
  height: number;
  width: number;
}) {
  const halfHeight = 0.5 * height;
  const halfWidth = 0.5 * width;
  return {
    left: toPercents(x - halfWidth),
    top: toPercents(y - halfHeight),
    bottom: toPercents(1 - (y + halfHeight)),
    right: toPercents(1 - (x + halfWidth)),
  };
}

function toPercents(num: number) {
  return `${clamp(num, 0, 1) * 100}%`;
}
