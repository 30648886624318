import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCheck1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m7.29 11.203 7.54-7.541a.782.782 0 1 1 1.106 1.107l-7.94 7.91a1 1 0 0 1-1.413-.002L3.561 9.655a.78.78 0 1 1 1.107-1.102l2.621 2.65Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheck1;
