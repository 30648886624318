import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { ClassNameProp } from '../core/types';
import {
  Dashlet,
  Layout,
  SampleAnalysisAlgo,
  SizedLayout,
} from '@tensorleap/api-client';
import {
  DEFAULT_HEIGHT,
  LG_WIDTH,
  MD_WIDTH,
  SM_WIDTH,
  XS_WIDTH,
} from './DashboardLayout';

export function TextAction({
  children,
  onClick,
  tourId,
}: PropsWithChildren<{ onClick: () => void; tourId?: string }>) {
  return (
    <span
      onClick={onClick}
      className="p-2 hover:text-primary-300 cursor-pointer rounded hover:bg-white/5 underline"
      id={tourId}
    >
      {children}
    </span>
  );
}

export function DashboardPlaceholder({
  children,
  className,
}: PropsWithChildren<ClassNameProp>) {
  return (
    <div
      className={clsx(
        className,
        'w-full flex-1 flex items-center justify-center text-lg font-bold text-gray-500',
      )}
    >
      {children}
    </div>
  );
}

const DEFAULT_WIDTH = 12;

interface CalcLayoutSizeProps {
  accY: number;
  y: number;
  width: number;
  height: number;
}

const calcLayoutSize = ({ accY, y, width, height }: CalcLayoutSizeProps) => {
  return {
    x: 0,
    y: Math.max(accY, y + height),
    w: width,
    h: height,
  };
};

export function calcNewDashletLayout(items: Dashlet[]): SizedLayout {
  const defaultLayout = { x: 0, y: 0, w: DEFAULT_WIDTH, h: DEFAULT_HEIGHT };
  return items.reduce(
    (acc, item) => {
      const { lg, md, sm, xs } = item.layout;
      return {
        lg: calcLayoutSize({
          accY: acc.lg.y,
          y: lg.y,
          width: LG_WIDTH,
          height: DEFAULT_HEIGHT,
        }),
        md: calcLayoutSize({
          accY: acc.md.y,
          y: md.y,
          width: MD_WIDTH,
          height: DEFAULT_HEIGHT,
        }),
        sm: calcLayoutSize({
          accY: acc.sm.y,
          y: sm.y,
          width: SM_WIDTH,
          height: DEFAULT_HEIGHT,
        }),
        xs: calcLayoutSize({
          accY: acc.xs.y,
          y: xs.y,
          width: XS_WIDTH,
          height: DEFAULT_HEIGHT,
        }),
      };
    },
    {
      lg: defaultLayout,
      md: defaultLayout,
      sm: defaultLayout,
      xs: defaultLayout,
    },
  );
}

function calcItemLayout(
  index: number,
  itemsInARow: number,
  windowWidth: number,
  itemHeight: number,
  fullWidth: boolean = true,
  totalFullWidthItemsInDashboard: number = 0,
): Layout {
  const itemWidth = fullWidth ? windowWidth : windowWidth / itemsInARow;
  const x = fullWidth
    ? 0
    : ((index - totalFullWidthItemsInDashboard) % itemsInARow) * itemWidth;
  const y = fullWidth
    ? index * itemHeight
    : totalFullWidthItemsInDashboard * itemHeight + // Full width items
      Math.floor((index - totalFullWidthItemsInDashboard) / itemsInARow) *
        itemHeight;
  return {
    x,
    y,
    w: itemWidth,
    h: itemHeight,
  };
}

const DASHLET_TYPES_ORDER = [
  'PopulationExploration',
  'SampleAnalysis',
  'Analytics',
];

const FULL_WIDTH_TYPES = ['PopulationExploration', 'SampleAnalysis'];

export function organizeDashboardItems(items: Dashlet[]): Dashlet[] {
  const totalFullWidthItemsInDashboard = items.filter((item) =>
    FULL_WIDTH_TYPES.includes(item.type),
  ).length;

  const sortedItemsByType = items.sort(
    (a, b) =>
      DASHLET_TYPES_ORDER.indexOf(a.type) - DASHLET_TYPES_ORDER.indexOf(b.type),
  );

  return sortedItemsByType.map((item, index) => {
    return {
      ...item,
      layout: {
        xs: calcItemLayout(index, 1, XS_WIDTH, DEFAULT_HEIGHT),
        sm: calcItemLayout(index, 1, SM_WIDTH, DEFAULT_HEIGHT),
        md: calcItemLayout(
          index,
          2,
          MD_WIDTH,
          DEFAULT_HEIGHT,
          FULL_WIDTH_TYPES.includes(item.type),
          totalFullWidthItemsInDashboard,
        ),
        lg: calcItemLayout(
          index,
          3,
          LG_WIDTH,
          DEFAULT_HEIGHT,
          FULL_WIDTH_TYPES.includes(item.type),
          totalFullWidthItemsInDashboard,
        ),
      },
    };
  });
}

export function SampleAnalysisAlgoToString(algo: SampleAnalysisAlgo): string {
  return algo
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}
