import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTeamOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M19.317 16.404a7.067 7.067 0 0 0-2.025-1.416 4.677 4.677 0 0 0 1.74-3.644c0-2.597-2.166-4.728-4.763-4.688a4.69 4.69 0 0 0-2.878 8.332 7.052 7.052 0 0 0-2.025 1.416 6.986 6.986 0 0 0-2.053 4.779.188.188 0 0 0 .187.192h1.313c.1 0 .185-.08.187-.18a5.298 5.298 0 0 1 1.563-3.598 5.312 5.312 0 0 1 3.78-1.566c1.428 0 2.771.556 3.781 1.566a5.32 5.32 0 0 1 1.564 3.598c.002.1.086.18.187.18h1.313a.188.188 0 0 0 .187-.192 6.97 6.97 0 0 0-2.058-4.78Zm-4.973-2.06a2.974 2.974 0 0 1-2.121-.88 2.973 2.973 0 0 1-.88-2.15 3.004 3.004 0 0 1 2.97-2.97 3.017 3.017 0 0 1 2.133.858c.581.57.9 1.33.9 2.142 0 .801-.312 1.554-.879 2.12a2.982 2.982 0 0 1-2.123.88Zm-5.871-2.381a5.966 5.966 0 0 1 .068-1.709.189.189 0 0 0-.106-.206 2.961 2.961 0 0 1-.865-.589 2.99 2.99 0 0 1-.907-2.236 2.985 2.985 0 0 1 .851-2.006 2.984 2.984 0 0 1 2.184-.907 2.996 2.996 0 0 1 2.494 1.378.187.187 0 0 0 .218.075 5.816 5.816 0 0 1 1.296-.29.188.188 0 0 0 .148-.272 4.696 4.696 0 0 0-4.118-2.576c-2.6-.04-4.765 2.09-4.765 4.685 0 1.472.678 2.785 1.74 3.645a7.046 7.046 0 0 0-2.028 1.415 6.97 6.97 0 0 0-2.058 4.781.188.188 0 0 0 .188.193h1.314c.101 0 .186-.08.188-.18a5.297 5.297 0 0 1 1.563-3.598 5.29 5.29 0 0 1 2.454-1.4.187.187 0 0 0 .14-.204Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTeamOutlined;
