import { useCallback, useMemo } from 'react';
import { Plus } from '../../../ui/icons';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import {
  NetworkWizardData,
  QuickFixProps,
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardErrorSeverity,
} from '../types';
import { GraphNodeAttrErrorType } from '../errors';

const MISSING_PREDICTION_TYPE_MESSAGE = 'PredictionType was not selected';

export function useGraphNodeAttrErrorData({
  title,
  msg,
  type,
  nodeId,
  attrName,
  category,
}: GraphNodeAttrErrorType): NetworkWizardData[] {
  const {
    selectNode,
    currentDatasetSetup,
    changeNodeProperty,
    onFitNodeToScreen,
  } = useNetworkMapContext();

  const showNodeProp = useCallback(() => {
    onFitNodeToScreen(nodeId);
    selectNode(nodeId);
  }, [nodeId, onFitNodeToScreen, selectNode]);

  const quickFix = useMemo<QuickFixProps | undefined>(() => {
    if (
      type === GraphErrorKind.nodeAttr &&
      msg === MISSING_PREDICTION_TYPE_MESSAGE
    ) {
      return {
        onSelect: () => {
          changeNodeProperty({
            nodeId,
            nodeDataPropsToUpdate: {
              prediction_type: currentDatasetSetup?.prediction_types?.[0]?.name,
            },
          });
          selectNode(nodeId);
          onFitNodeToScreen(nodeId);
        },
        title: 'Add',
        tooltipMsg: 'Add Prediction Type',
        icon: <Plus className="h-5 w-5" />,
      };
    }
  }, [
    type,
    msg,
    changeNodeProperty,
    nodeId,
    currentDatasetSetup?.prediction_types,
    selectNode,
    onFitNodeToScreen,
  ]);

  const calculateKey = useCallback(
    () => msg + nodeId + attrName,
    [attrName, msg, nodeId],
  );
  return useMemo(
    () => [
      {
        errorType: GraphErrorKind.nodeAttr,
        category: category || NetworkWizardCategory.MODEL,
        title: title || 'INVALID NODE PROPERTY',
        message: msg,
        calculateKey,
        showNodeFooter: true,
        showNode: showNodeProp,
        quickFixes: quickFix ? [quickFix] : [],
        errorSeverity: NetworkWizardErrorSeverity.ERROR,
        key: calculateKey(),
      },
    ],
    [calculateKey, category, msg, quickFix, showNodeProp, title],
  );
}
