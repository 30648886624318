import { FilterOperatorType } from '@tensorleap/api-client';
import { BTWVisualizationFilter } from '../core/types/filters';
import { useScatterData } from './ScatterDataContext';
import { useDashletScatterContext } from './dashlet/PopulationExploration/DashletScatterContext';
import { minMaxValues } from './useScatterMapData';

export function useRangeLegendData() {
  const {
    scatterData: { metadata = {}, scatter_data: scatterData },
    legendData,
  } = useScatterData();
  const {
    filters: { updateDashletFilters, dashletFilters },
  } = useDashletScatterContext();

  const dotColor = legendData?.colorField;

  if (!dotColor) {
    console.error(
      'calcRangeLegendData called with no dotColor, we should use should not get here',
    );
    return {
      filteredMinColorValue: 0,
      filteredMaxColorValue: 0,
    };
  }

  const addRangeFilter = (min: number, max: number) => {
    if (min === max) return;
    const newFilter: BTWVisualizationFilter = {
      field: dotColor,
      operator: FilterOperatorType.Between,
      value: { gte: min, lt: max },
      pin: false,
    };

    updateDashletFilters([...dashletFilters, newFilter]);
  };

  const metadataKeys = Object.keys(metadata);

  const dotColorSubjectMetadata = metadata[dotColor || metadataKeys[0]] || {
    body: [],
  };

  if (dotColorSubjectMetadata.type !== 'range') {
    throw new Error('calcRangeLegendData called with non-range data');
  }
  const colorValues = dotColorSubjectMetadata.body;
  const { min: minColorValue = 0, max: maxColorValue = 0 } = minMaxValues(
    colorValues as number[],
  );

  const filteredData = Array.from(
    { length: scatterData.length },
    (_, i) => i,
  ).map((sampleIndex) => metadata[dotColor].body[sampleIndex]);

  const {
    min: filteredMinColorValue = minColorValue,
    max: filteredMaxColorValue = maxColorValue,
  } = minMaxValues(filteredData as number[]);

  return {
    filteredMinColorValue,
    filteredMaxColorValue,
    addRangeFilter,
  };
}
