import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgOpticalFlowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M8.103 5.469a.256.256 0 0 1 .18-.314l1.814-.485a.256.256 0 0 1 .314.18l.486 1.814a.256.256 0 1 1-.495.132l-.326-1.216-1.501 2.6a.256.256 0 1 1-.443-.256l1.501-2.6-1.217.326a.256.256 0 0 1-.313-.181ZM3.532 5.069a.256.256 0 0 1 .18-.314l1.814-.485a.256.256 0 0 1 .314.18l.486 1.814a.256.256 0 0 1-.495.132L5.505 5.18l-1.501 2.6a.256.256 0 1 1-.443-.256l1.501-2.6-1.217.326a.256.256 0 0 1-.313-.181ZM12.762 6.069a.257.257 0 0 1 .18-.314l1.814-.485a.256.256 0 0 1 .314.18l.486 1.814a.257.257 0 0 1-.495.132l-.326-1.216-1.501 2.6a.256.256 0 1 1-.443-.256l1.5-2.6-1.216.326a.256.256 0 0 1-.313-.181ZM6.643 11.237a.256.256 0 0 1-.043-.36l1.162-1.474a.256.256 0 0 1 .36-.043l1.474 1.162a.256.256 0 1 1-.317.402l-.99-.78.354 2.982a.256.256 0 1 1-.508.06l-.353-2.98-.78.988a.256.256 0 0 1-.36.043ZM2.468 10.694a.256.256 0 0 1 0-.362l1.328-1.328a.256.256 0 0 1 .362 0l1.327 1.328a.256.256 0 1 1-.362.362l-.89-.89v3.002a.256.256 0 1 1-.512 0V9.803l-.89.89a.256.256 0 0 1-.363 0ZM11.305 11.961a.256.256 0 0 1-.063-.356l1.076-1.539a.256.256 0 0 1 .356-.063l1.538 1.076a.256.256 0 0 1-.293.42l-1.032-.722.523 2.957a.256.256 0 1 1-.504.089l-.523-2.957-.722 1.032a.256.256 0 0 1-.356.063Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.2}
    />
    <rect
      x={1.5}
      y={2.5}
      width={15}
      height={13}
      rx={1.5}
      stroke="currentColor"
    />
  </svg>
);

export default SvgOpticalFlowIcon;
