import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPushFiltersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39 1.702c.368-.37.87-.577 1.391-.577h8.438a1.969 1.969 0 0 1 1.969 1.969v7.312a1.969 1.969 0 0 1-1.97 1.969h-1.405a.562.562 0 1 1 0-1.125h1.406a.844.844 0 0 0 .844-.844V3.094a.844.844 0 0 0-.844-.844H4.78a.844.844 0 0 0-.843.844v7.312a.844.844 0 0 0 .843.844h1.407a.562.562 0 1 1 0 1.125H4.78a1.969 1.969 0 0 1-1.968-1.969V3.094c0-.522.207-1.023.576-1.392Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.602 5.227c.22-.22.576-.22.796 0L12.21 8.04a.562.562 0 1 1-.795.795L9 6.42 6.585 8.835a.563.563 0 0 1-.795-.795l2.812-2.813Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5.625c.31 0 .563.252.563.563v10.125a.562.562 0 1 1-1.126 0V6.188c0-.311.252-.563.563-.563Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPushFiltersIcon;
