import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNonVisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M20.8 12.907c-.262.436-.627.921-1.096 1.457a10.627 10.627 0 0 1-3.495 2.682A9.608 9.608 0 0 1 12 18l.743-1.326a8.362 8.362 0 0 0 3.938-1.376 10.3 10.3 0 0 0 1.667-1.366c.502-.516.958-1.088 1.366-1.718a10.648 10.648 0 0 0-2.832-2.953l.633-1.125c.642.442 1.252.954 1.828 1.537.301.301.569.606.803.914.241.302.459.613.653.934.275.462.275.924 0 1.386Zm-4.3-1.978c0 .93-.265 1.78-.793 2.551a4.416 4.416 0 0 1-2.1 1.647l2.813-5.042c.026.154.046.301.06.442.013.134.02.268.02.402Zm-.372-4.49c0 .053-.003.083-.01.09l-3.164 5.685c-.71 1.273-1.329 2.38-1.858 3.325a711.62 711.62 0 0 0-1.316 2.37l-.492.885a.3.3 0 0 1-.281.16c-.087 0-.536-.234-1.346-.703-.107-.067-.161-.157-.161-.271 0-.074.147-.365.442-.874a10.606 10.606 0 0 1-2.652-1.738 11.724 11.724 0 0 1-2.09-2.46 1.251 1.251 0 0 1-.2-.694c0-.254.067-.485.2-.693C4.22 9.961 5.492 8.72 7.019 7.795 8.53 6.884 10.192 6.429 12 6.429c.576 0 1.179.057 1.808.17l.542-.974a.3.3 0 0 1 .282-.16.54.54 0 0 1 .18.06 5.437 5.437 0 0 1 .553.301c.087.047.17.094.251.14.08.047.15.091.211.131l.14.07c.108.067.161.158.161.272ZM12 7.875c-.837 0-1.557.301-2.16.904a2.93 2.93 0 0 0-.894 2.15c0 .134.047.247.141.341.094.094.208.14.342.14a.465.465 0 0 0 .341-.14.465.465 0 0 0 .14-.341c0-.576.205-1.068.613-1.477.409-.408.901-.613 1.477-.613a.465.465 0 0 0 .341-.14.465.465 0 0 0 .141-.342.465.465 0 0 0-.14-.341.465.465 0 0 0-.342-.14Zm-3.425 8.106.783-1.416a4.19 4.19 0 0 1-.783-.713 4.959 4.959 0 0 1-.583-.874 4.722 4.722 0 0 1-.371-.985A4.72 4.72 0 0 1 7.5 10.93c0-.817.204-1.57.613-2.26-1.534.783-2.81 1.965-3.827 3.545 1.111 1.721 2.541 2.977 4.289 3.767Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNonVisible;
