import { ViDoneIcon } from '../../../ui/icons';
import {
  ValidateAssetsButtonState,
  ValidateAssetsStatus,
} from '../../interfaces/ValidateGraphStatus';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import { useMemo } from 'react';
import {
  NetworkWizardData,
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardErrorSeverity,
  QuickFixProps,
} from '../types';
import { ValidateAssetsErrorType } from '../errors';

export function GetValidateAssetsErrorData(
  validationAssetErrorProps: ValidateAssetsErrorType[],
): NetworkWizardData[] {
  const {
    validateAssetsStatus,
    validateAssetsButtonState,
    handleValidateAssetsClicked,
  } = useNetworkMapContext();

  const quickFix = useMemo(
    () =>
      generateValidateAssetsQuickFix(
        validateAssetsButtonState,
        handleValidateAssetsClicked,
      ),
    [handleValidateAssetsClicked, validateAssetsButtonState],
  );

  return useMemo(
    () =>
      validationAssetErrorProps.map(({ title, msg, category }) => ({
        errorType: GraphErrorKind.validateAssets,
        category: category || NetworkWizardCategory.VIS,
        title: title || 'VALIDATE ASSETS',
        message: msg || '',
        calculateKey: () => msg || '',
        showNodeFooter: true,
        quickFixes: [quickFix],
        isLoading: validateAssetsStatus === ValidateAssetsStatus.Calculating,
        headerIcon: <ViDoneIcon />,
        errorSeverity: NetworkWizardErrorSeverity.INFO,
        key: msg || '',
      })),
    [quickFix, validateAssetsStatus, validationAssetErrorProps],
  );
}

export function generateValidateAssetsQuickFix(
  validateAssetsButtonState: ValidateAssetsButtonState,
  handleValidateAssetsClicked: (force?: boolean | undefined) => Promise<void>,
): QuickFixProps {
  return {
    onSelect: () => {
      handleValidateAssetsClicked();
    },
    title: 'Validate',
    tooltipMsg: validateAssetsButtonState.tooltipTitle,
    icon: <ViDoneIcon className="h-5 w-5" />,
    disableQuickFix: validateAssetsButtonState.isDisabled,
  };
}
