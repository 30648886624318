import useSWR, { KeyedMutator } from 'swr';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';
import { useState, useEffect, useMemo } from 'react';

export interface FetchJson<T> {
  data: T | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<T>;
}

export interface FetchJsonBaseProps<T> {
  fetchOptions?: RequestInit;
  key?: string;
  postProcess?: (data: string) => T;
  refreshIntervalMs?: number;
  maxIsLoadingTimeoutMs?: number;
  refreshIntervalAfterSuccessMS?: number;
}

export type FetchJsonProps<T> = FetchJsonBaseProps<T> & {
  url?: RequestInfo | URL;
};

export const REQUEST_INIT_FORCE_CACHE: RequestInit = {
  method: 'GET',
  cache: 'force-cache',
  credentials: 'include',
};

export const REQUEST_INIT_NO_CACHE: RequestInit = {
  method: 'GET',
  cache: 'no-cache',
  credentials: 'include',
};

export function useFetchJson<T>({
  url,
  fetchOptions: init = REQUEST_INIT_FORCE_CACHE,
  key,
  postProcess,
  refreshIntervalMs,
  maxIsLoadingTimeoutMs,
  refreshIntervalAfterSuccessMS,
}: FetchJsonProps<T>): FetchJson<T | undefined> {
  const swrKey = useMemo(() => `${CacheKey.FETCH}-${key || url}`, [key, url]);

  const [foundResult, setFoundResult] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    if (swrKey) {
      setFoundResult(false);
      setHasTimedOut(false);
    }
  }, [swrKey]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (maxIsLoadingTimeoutMs && !hasTimedOut && !foundResult) {
      timer = setTimeout(() => {
        setHasTimedOut(true);
      }, maxIsLoadingTimeoutMs);
    }

    if (foundResult && timer) {
      clearTimeout(timer);
      timer = null;
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [maxIsLoadingTimeoutMs, hasTimedOut, swrKey, foundResult]);

  const adjustedRefreshInterval = useMemo(() => {
    if (foundResult && refreshIntervalAfterSuccessMS) {
      if (refreshIntervalAfterSuccessMS === 0) {
        return undefined;
      }
      return refreshIntervalAfterSuccessMS;
    }
    return hasTimedOut ? undefined : refreshIntervalMs;
  }, [
    foundResult,
    hasTimedOut,
    refreshIntervalAfterSuccessMS,
    refreshIntervalMs,
  ]);

  const { data, error, mutate } = useSWR<T | undefined, Error>(
    `${CacheKey.FETCH}-${key || url}`,
    async () => {
      if (!url) {
        return undefined;
      }
      const response = await fetch(url, init);
      if (response.status === 404) {
        throw new Error('File not found');
      }

      const responseData = await response.json();
      setFoundResult(true);
      return postProcess ? postProcess(responseData) : responseData;
    },
    { refreshInterval: adjustedRefreshInterval },
  );

  const isLoading = useMemo(() => {
    if (maxIsLoadingTimeoutMs && hasTimedOut) {
      return false;
    }
    if (error && error.message.includes('File not found')) {
      return true;
    }
    return !error && !data;
  }, [data, hasTimedOut, maxIsLoadingTimeoutMs, error]);

  return useMergedObject({
    data,
    error,
    isLoading,
    refetch: mutate,
  });
}
