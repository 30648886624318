import { Dialog, DialogActions, DialogContent, DialogTitle } from '../ui/mui';
import { useForm } from 'react-hook-form';
import { Button } from '../ui/atoms/Button';
import { Input } from '../ui/atoms/Input';
import { SessionRunData } from '@tensorleap/api-client';
import { useCallback } from 'react';
import api from '../core/api-client';
import { useVersionControl } from '../core/VersionControlContext';
import { TextArea } from '../ui/atoms/TextArea';

interface EditSessionRunDialogProps {
  sessionRun: SessionRunData;
}

export function EditSessionRunDialog({
  sessionRun,
}: EditSessionRunDialogProps) {
  const { refetch, setEditSessionRun } = useVersionControl();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: sessionRun.name,
      description: sessionRun.description,
    },
  });
  const handleEditDialogClose = useCallback(() => {
    setEditSessionRun(undefined);
  }, [setEditSessionRun]);

  const onSubmit = useCallback(
    async (data: { name: string; description: string }) => {
      await api.updateSessionRun({
        cid: sessionRun.cid,
        name: data.name,
        projectId: sessionRun.projectId,
        description: data.description,
      });
      await refetch();
      handleEditDialogClose();
    },
    [sessionRun, refetch, handleEditDialogClose],
  );

  return (
    <Dialog
      open={sessionRun !== undefined}
      onClose={handleEditDialogClose}
      aria-labelledby="edit-session-run-dialog-title"
    >
      <DialogTitle id="edit-session-run-dialog-title">Edit Run</DialogTitle>
      <DialogContent>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-120 px-2 flex flex-col space-y-4"
        >
          <Input
            label="NAME"
            {...register('name', { required: 'Name is required' })}
          />
          <TextArea
            label="DESCRIPTION"
            {...register('description')}
            className="h-56"
          />
          <DialogActions>
            <Button onClick={handleEditDialogClose} variant="text">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
