import { VisualizationFilter } from '../core/types/filters';
import { FilterFormPopup } from '../filters/FilterFormPopup';
import { FilterElements } from '../filters/FilterElements';
import { ApplyTestFilter, Plus } from '../ui/icons';
import { IconButton, Tooltip } from '../ui/mui';
import { Setter } from '../core/types';
import clsx from 'clsx';
import { PlaceholderChip } from '../ui/atoms/PlaceholderChip';
import { useFetchDashboardFilterFieldsMeta } from '../core/data-fetching/dashlet-fields';
import { useOpenState } from '../ui/atoms/utils/useOpenState';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useDashboardContext } from '../dashboard/DashboardContext';
import { MouseEventHandler, useCallback } from 'react';
import { useModelFilter } from '../ui/molecules/useModelFilter';

export interface ModelTestsCardContentDatasetFiltersProps {
  testFilters: VisualizationFilter[];
  onFilterChange: Setter<VisualizationFilter[]>;
  onGlobalFilterChange: (newFilter: VisualizationFilter) => Promise<void>;
  editable: boolean;
  className?: string;
}

export function ModelTestsCardContentDatasetFilters({
  testFilters,
  onFilterChange,
  onGlobalFilterChange,
  className,
  editable,
}: ModelTestsCardContentDatasetFiltersProps): JSX.Element {
  const { isOpen, close, open } = useOpenState(false);
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const { handleGlobalFiltersChange, globalFilters } = useDashboardContext();
  const { selected } = useModelFilter();
  const sessionRunIds = selected.map((s) => s.id);
  const fieldsMeta = useFetchDashboardFilterFieldsMeta({
    projectId,
    filters: globalFilters,
    sessionRunIds: sessionRunIds,
  });

  const applyFiltersToDashboard = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      handleGlobalFiltersChange(testFilters);
    },
    [handleGlobalFiltersChange, testFilters],
  );

  return (
    <div className={clsx('w-full px-4', className)}>
      <div className="flex flex-col w-full justify-center items-start my-4">
        <div className="flex flex-row w-full">
          <span className="flex-1 capitalize text-xl tracking-widest text-gray-500">
            dataset filters
          </span>

          {editable && (
            <FilterFormPopup
              onApply={onGlobalFilterChange}
              onClose={close}
              fields={fieldsMeta}
              open={isOpen}
            >
              <IconButton className="w-8 h-8" onClick={open}>
                <Plus className="w-4 h-4" />
              </IconButton>
            </FilterFormPopup>
          )}
          <Tooltip title="Apply to dashboard filters">
            <span>
              <IconButton
                className="w-8 h-8"
                disabled={!testFilters.length}
                onClick={applyFiltersToDashboard}
              >
                <ApplyTestFilter />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        <div className="flex flex-row w-full py-4">
          {testFilters.length > 0 ? (
            <FilterElements
              filterFieldsMeta={fieldsMeta}
              readOnly={!editable}
              filters={testFilters}
              onFiltersChange={onFilterChange}
              listClassName="flex-1 overflow-x-hidden flex-wrap"
              allowDisable={false}
              allowPin={false}
            />
          ) : (
            <PlaceholderChip>NO FILTERS</PlaceholderChip>
          )}
        </div>
      </div>
    </div>
  );
}
