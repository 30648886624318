import { PopupPane } from '../molecules/PopupPane';
import { UpgradeFreemiumDialogContent } from './UpgradeFreemiumDialogContent';
import { ExtensionTokenDialogContent } from './ExtensionTokenDialogContent';
import { StateObject, useStateObject } from '../../core/types';

export interface ContactTrailDialogProps {
  openState: StateObject;
  selectedIndex?: number;
  className?: string;
  extensionTokenIsOpenOveride?: boolean;
}
export function ContactTrialDialog({
  openState,
  selectedIndex = 0,
  className = '',
  extensionTokenIsOpenOveride,
}: ContactTrailDialogProps) {
  const { state: extensionTokenIsOpen, setState: setExtensionTokenIsOpen } =
    useStateObject(false);
  return (
    <PopupPane openState={openState} className={className} showClose>
      {!extensionTokenIsOpen && !extensionTokenIsOpenOveride ? (
        <UpgradeFreemiumDialogContent
          selectedIndex={selectedIndex}
          openState={openState}
          className={className}
          setExtensionTokenIsOpen={setExtensionTokenIsOpen}
        />
      ) : (
        <ExtensionTokenDialogContent
          setExtensionTokenIsOpen={setExtensionTokenIsOpen}
          openState={openState}
          backClosesDialog={extensionTokenIsOpenOveride}
        />
      )}
    </PopupPane>
  );
}
