import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTimeType = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M6.508 4.984h3.984V4H11.5v.984h.492c.281 0 .516.102.703.305a.917.917 0 0 1 .305.703V13a.917.917 0 0 1-.305.703.954.954 0 0 1-.703.281H5.008c-.281 0-.524-.093-.727-.28A.954.954 0 0 1 4 13V5.992c0-.281.094-.515.281-.703a.992.992 0 0 1 .727-.305H5.5V4h1.008v.984ZM5.008 7v6h6.984V7H5.008Zm.984 1.5H7v.984H5.992V8.5Zm2.016 0h.984v.984h-.984V8.5ZM10 8.5h1.008v.984H10V8.5Zm0 1.992h1.008V11.5H10v-1.008Zm-1.992 0h.984V11.5h-.984v-1.008Zm-2.016 0H7V11.5H5.992v-1.008Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTimeType;
