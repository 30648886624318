import { clamp } from 'lodash';
import {
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMounted } from './useMounted';

export type UseResizeProps = {
  initWidth?: number;
  initHeight?: number;
  containerRef: React.RefObject<HTMLElement>;
  minWidth?: number;
  minHeight?: number;
  widthOffset?: number;
  heightOffset?: number;
  onResize?: (width: number, height: number) => void;
};

export type UseResize = {
  width: number;
  height: number;
  isResizing: boolean;
  onMouseDown: MouseEventHandler<HTMLElement>;
};

type CalcSizeProps = {
  size: number;
  diff: number;
  max: number;
  min: number;
  offset: number;
};

function calcSize({ size, diff, max, min, offset }: CalcSizeProps): number {
  const maxWithOffset = Math.max(max - offset, 0);

  const clampedSize = clamp(
    size - diff,
    Math.min(min, maxWithOffset),
    maxWithOffset,
  );

  return clampedSize;
}

export function useResize({
  initWidth = 200,
  initHeight = 200,
  containerRef,
  minWidth = 0,
  minHeight = 0,
  widthOffset = 0,
  heightOffset = 0,
  onResize,
}: UseResizeProps): UseResize {
  const [state, setState] = useState(() => ({
    width: initWidth,
    height: initHeight,
    diffWidth: 0,
    diffHeight: 0,
  }));

  const onResizeRef = useRef(onResize);
  onResizeRef.current = onResize;

  const clamped = useMemo(() => {
    const width = calcSize({
      size: state.width,
      diff: state.diffWidth,
      max: containerRef.current?.clientWidth ?? 0,
      min: minWidth,
      offset: widthOffset,
    });
    const height = calcSize({
      size: state.height,
      diff: state.diffHeight,
      max: containerRef.current?.clientHeight ?? 0,
      min: minHeight,
      offset: heightOffset,
    });
    return { width, height };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, containerRef.current]);

  const [isResizing, setIsResizing] = useState(false);
  // for reduce re-calculate onMouseDown
  const originWidth = state.width;
  const originHeight = state.height;

  const mounted = useMounted();

  const onMouseDown = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      let initialX = e.clientX;
      let initialY = e.clientY;
      let totalXDiff = 0;
      let totalYDiff = 0;
      let newWidth = 0;
      let newHeight = 0;
      let moveEnd = false;
      setIsResizing(true);

      const updateSize = () => {
        newWidth = calcSize({
          size: originWidth,
          diff: totalXDiff,
          max: containerRef.current?.clientWidth ?? 0,
          min: minWidth,
          offset: widthOffset,
        });
        newHeight = calcSize({
          size: originHeight,
          diff: totalYDiff,
          max: containerRef.current?.clientHeight ?? 0,
          min: minHeight,
          offset: heightOffset,
        });

        setState({
          width: newWidth,
          height: newHeight,
          diffWidth: 0,
          diffHeight: 0,
        });
      };
      updateSize();

      const updateDiff = () => {
        if (!mounted.current || moveEnd) return;

        const currentXDiff = totalXDiff,
          currentYDiff = totalYDiff;

        setState((state) => {
          const isNotChanged =
            state.diffWidth === currentXDiff &&
            state.diffHeight === currentYDiff;
          if (isNotChanged) return state;

          return {
            ...state,
            diffWidth: currentXDiff,
            diffHeight: currentYDiff,
          };
        });
        window.requestAnimationFrame(updateDiff);
      };

      const onMouseMove = (e: MouseEvent) => {
        const diffX = e.clientX - initialX;
        const diffY = e.clientY - initialY;
        totalXDiff += diffX;
        totalYDiff += diffY;
        initialX = e.clientX;
        initialY = e.clientY;
      };
      const onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
        setTimeout(() => {
          moveEnd = true;
          if (!mounted.current) return;
          setIsResizing(false);
          updateSize();
          onResizeRef.current?.(newWidth, newHeight);
        }, 100);
      };
      window.requestAnimationFrame(updateDiff);
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    },
    [
      mounted,
      containerRef,
      heightOffset,
      minWidth,
      minHeight,
      widthOffset,
      originWidth,
      originHeight,
    ],
  );

  return {
    ...clamped,
    onMouseDown,
    isResizing,
  };
}
