import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDownSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4a5 5 0 1 1 0 10A5 5 0 0 1 9 4Zm0-1a6 6 0 1 1 0 12A6 6 0 0 1 9 3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12a.59.59 0 0 0 .493-.277l2.4-3.714a.682.682 0 0 0 .038-.665A.596.596 0 0 0 11.4 7H6.6a.596.596 0 0 0-.531.345.682.682 0 0 0 .038.664l2.4 3.714A.59.59 0 0 0 9 12Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDownSelectedIcon;
