import { ReactNode, useCallback, useMemo } from 'react';
import { mapCategoriesMeta } from './utils';
import { CategoryValue, CategoryMeta } from './types';
import { omit } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { Chip } from '../../ui/atoms/Chip';
import clsx from 'clsx';

type CategoriesChipsProp = {
  className?: string;
  chipClassName?: string;
  categoriesMeta: CategoryMeta[];
  value: CategoryValue;
  onChange: (value: CategoryValue) => void;
  children?: ReactNode;
};

export function CategoriesChips({
  className,
  categoriesMeta,
  chipClassName,
  value,
  onChange,
  children,
}: CategoriesChipsProp) {
  const handleRemove = useCallback(
    (key: string, valueToDelete: string) => {
      const categoryValue = value[key];
      if (!categoryValue) return;
      if (Array.isArray(categoryValue)) {
        const newArr = categoryValue.filter((v) => v !== valueToDelete);
        if (newArr.length) {
          onChange({ ...value, [key]: newArr });
          return;
        }
      }
      onChange(omit(value, [key]));
    },
    [onChange, value],
  );

  const mappedCategoriesMeta = useMemo(
    () => mapCategoriesMeta(categoriesMeta),
    [categoriesMeta],
  );
  const chipsData = useMemo(() => {
    return Object.entries(value).flatMap(([key, categoryValue]) => {
      if (categoryValue === undefined) return [];
      const categoryName = mappedCategoriesMeta[key]?.name || key;
      if (Array.isArray(categoryValue)) {
        return categoryValue.map((value) => ({
          categoryName,
          key,
          value,
          name: mappedCategoriesMeta[key]?.options[value] || value,
        }));
      }
      const value = categoryValue;
      const name = mappedCategoriesMeta[key]?.options[value] || value;
      return { key, value, categoryName, name };
    });
  }, [value, mappedCategoriesMeta]);

  if (!chipsData.length) return null;

  return (
    <div className={clsx('flex flex-wrap items-center gap-2', className)}>
      {children}
      {chipsData.map(({ key, value, name, categoryName }) => (
        <CategoryChip
          key={`${key}-${value}`}
          className={chipClassName}
          categoryName={categoryName}
          categoryKey={key}
          value={value}
          name={name}
          onRemove={handleRemove}
        />
      ))}
    </div>
  );
}

type CategoryChipProps = {
  className?: string;
  value: string;
  name: string;
  categoryName: string;
  categoryKey: string;
  onRemove?: (key: string, value: string) => void;
};

export function CategoryChip({
  className,
  name,
  value,
  categoryKey,
  categoryName,
  onRemove,
}: CategoryChipProps) {
  return (
    <Chip
      className={className}
      onRemove={onRemove ? () => onRemove(categoryKey, value) : undefined}
    >
      <Tooltip
        arrow
        title={<span className="text-xs p-2">{categoryName}</span>}
      >
        <span>{name}</span>
      </Tooltip>
    </Chip>
  );
}
