import { useEffect, useRef } from 'react';
import { useMounted } from './useMounted';

export type IntervalContext = {
  mounted: boolean;
  stop: boolean;
};

export function useAsyncInterval(
  callback: (ctx: IntervalContext) => Promise<void>,
  stopInterval = false,
  interval = 3000,
) {
  const stopIntervalRef = useRef(stopInterval);
  stopIntervalRef.current = stopInterval;

  const isRunningRef = useRef(false);

  const functionRef = useRef(callback);
  functionRef.current = callback;

  const isMounted = useMounted();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let isClosed = false;

    const invoke = async () => {
      const ctx = {
        get mounted() {
          return isMounted.current;
        },
        get stop() {
          return stopIntervalRef.current || isClosed;
        },
      };
      if (ctx.stop || !ctx.mounted) {
        return;
      }
      if (!isRunningRef.current) {
        isRunningRef.current = true;
        await functionRef.current(ctx).catch(console.error);
        isRunningRef.current = false;

        if (!ctx.mounted || stopIntervalRef.current || isClosed) {
          return;
        }
      }

      timer = setTimeout(invoke, interval);
    };
    invoke();
    return () => {
      isClosed = true;
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, interval, stopIntervalRef.current]);
}
