import { useCallback, useEffect, useRef, useState } from 'react';
import { useFeatureFlags } from './FeatureFlagsContext';
import { useMounted } from './useMounted';

export function useSmoothTransition(
  toValue: number,
  speed = 3,
  epsilon = 1,
): number {
  const [currentValue, setCurrentValue] = useState(toValue);
  const isMounted = useMounted();
  const {
    featureFlags: { noAnimation },
  } = useFeatureFlags();
  const ref = useRef({
    toValue,
    currentValue,
    speed,
    epsilon,
    isRunning: false,
  });
  ref.current = {
    epsilon,
    toValue,
    currentValue,
    speed,
    isRunning: ref.current.isRunning,
  };

  const startMotion = useCallback(() => {
    if (ref.current.isRunning) {
      return;
    }
    ref.current.isRunning = true;
    motion();

    function motion() {
      requestAnimationFrame(() => {
        const { toValue, currentValue, speed, epsilon } = ref.current;
        const delta = toValue - currentValue;
        if (delta === 0 || !isMounted.current) {
          ref.current.isRunning = false;
          return;
        }
        const advance = delta / speed;
        const nextValue =
          Math.abs(advance) <= epsilon
            ? ref.current.toValue
            : ref.current.currentValue + advance;

        setCurrentValue(nextValue);

        if (nextValue === ref.current.toValue) {
          ref.current.isRunning = false;
        } else {
          motion();
        }
      });
    }
  }, [isMounted]);

  useEffect(() => {
    !noAnimation && startMotion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current.toValue, noAnimation]);

  return noAnimation ? ref.current.toValue : currentValue;
}
