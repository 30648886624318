import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { Notification } from '@tensorleap/api-client';

import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';
import { groupBy } from 'lodash';

export interface UseFetchNotifications {
  notifications: Notification[] | undefined;
  groupedNotifications: Notification[][];
  numOfRead: number | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<Notification[]>;
}
export function useFetchNotifications(): UseFetchNotifications {
  const {
    data: notifications,
    error,
    mutate,
  } = useSWR<Notification[], Error>(CacheKey.NOTIFICATIONS, async () => {
    const { notifications } = await api.getNotifications();
    return notifications.slice(0, 100);
  });
  const numOfRead = useMemo(
    () =>
      notifications?.reduce((accu, notice) => accu + Number(!notice.isRead), 0),
    [notifications],
  );

  const groupedNotifications = useMemo(() => {
    return Object.values(groupBy(notifications, 'identifier'));
  }, [notifications]);

  return useMergedObject({
    notifications,
    groupedNotifications,
    numOfRead,
    error,
    isLoading: !error && !notifications,
    refetch: mutate,
  });
}
