import { useCallback, useEffect, useMemo } from 'react';

import { useMergedObject } from './useMergedObject';

export interface UseFileSelectionInput {
  accept?: string;
  multiple?: boolean;
  onFileChange?: (_: File[]) => Promise<void> | void;
}
export interface UseFileSelectionOutput {
  openFileSelection: () => void;
}
export function useFileSelection({
  accept,
  multiple,
  onFileChange,
}: UseFileSelectionInput = {}): UseFileSelectionOutput {
  const inputEl = useMemo(() => {
    const input = document.createElement('input');
    input.type = 'file';
    if (accept) {
      input.setAttribute('accept', accept);
    }
    if (multiple) {
      input.setAttribute('multiple', 'multiple');
    }

    return input;
  }, [accept, multiple]);

  useEffect(() => {
    function getFiles(event: Event): void {
      const fileList = Array.from(
        (event?.target as HTMLInputElement)?.files as FileList,
      );
      onFileChange?.(fileList);
    }

    inputEl.addEventListener('change', getFiles);
    return () => {
      inputEl.removeEventListener('change', getFiles);
    };
  }, [inputEl, onFileChange]);

  const openFileSelection = useCallback(() => {
    inputEl.click();
  }, [inputEl]);

  return useMergedObject({ openFileSelection });
}
