import useAsyncEffect from './useAsyncEffect';
import useSWR from 'swr';
import { useMemo, useState } from 'react';

type FileType = 'application/x-gtar';
export async function fetchFile(
  url: string,
  fileName: string,
  fileType: FileType,
): Promise<File> {
  const response = await fetch(url, {
    method: 'GET',
    cache: 'force-cache',
    credentials: 'include',
  });
  if (isFailedResponse(response)) {
    console.error(
      `There was an error in fetching ${response.url} (${response.statusText})`,
    );
    throw new Error(`Fetch file failed: ${response.status}`);
  }
  const buffer = await response.blob();
  const sourceFile = new File([buffer], fileName, {
    type: fileType,
  });

  return sourceFile;
}

export async function uploadJsonFile(
  fileUrl: string,
  data: object,
): Promise<Response> {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/octet-stream' },
    body: JSON.stringify(data),
  };

  const response = await fetch(fileUrl, requestOptions);
  if (isFailedResponse(response)) {
    console.error('Upload file failed with response:', response);
    throw new Error(`Upload file failed: ${response.status}`);
  }
  return response;
}

export async function uploadFile(
  fileUrl: string,
  file: File,
): Promise<Response> {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/octet-stream' },
    body: file,
  };

  const response = await fetch(fileUrl, requestOptions);
  if (isFailedResponse(response)) {
    console.error('Upload file failed with response:', response);
    throw new Error(`Upload file failed: ${response.status}`);
  }
  return response;
}

export function isFailedResponse(r: Response): boolean {
  return !r.ok || r.status < 200 || r.status > 300;
}

export function useWaitAndFetchJsonFile<T>(url: string): T | undefined {
  const { data: file } = useSWR(
    `wait-and-fetch-${url}`,
    async () => {
      const res = await waitForFile(url);
      return await res.json();
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  return file;
}

export async function fetchJsonFile<T>(url: string): Promise<T> {
  const res = await fetch(url, {
    cache: 'force-cache',
    credentials: 'include',
  });
  if (isFailedResponse(res)) {
    console.error(
      `There was an error in fetching ${res.url} (${res.statusText})`,
    );
    throw new Error(`Fetch file failed: ${res.status}`);
  }
  return await res.json();
}

export function useWaitForFile(url?: string): boolean | undefined {
  const urlOrRandom = useMemo(() => url ?? Date.now().toString(), [url]);
  const [existedUrl, setExistedUrl] = useState<string | undefined>(undefined);
  const { data: isReady = undefined } = useSWR(
    urlOrRandom,
    async () => {
      if (!url) {
        return false;
      }
      await waitForFile(url);
      return true;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  useAsyncEffect(async () => {
    setExistedUrl(undefined);
    if (!url) {
      return;
    }
    if (await isFileExists(url)) {
      setExistedUrl(url);
    }
  }, [url]);
  const isAlreadyExisted = existedUrl === url && existedUrl !== undefined;
  // undefined means that we don't know if the file is ready or not
  return isReady ?? isAlreadyExisted;
}

const WAIT_FOR_FILE_TIMEOUT = 10 * 60 * 1000;
const WAIT_FOR_FILE_INTERVAL = 5 * 1000;
export function waitForFile(
  url: string,
  interval = WAIT_FOR_FILE_INTERVAL,
  timeout = WAIT_FOR_FILE_TIMEOUT,
): Promise<Response> {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    check();
    async function check() {
      const res = await fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'include',
      }).catch(reject);
      if (res?.ok) {
        resolve(res);
      } else {
        const timePassed = Date.now() - startTime;
        if (timePassed > timeout) {
          reject(new Error('Timeout'));
        } else {
          runWithTimeout();
        }
      }
    }
    function runWithTimeout() {
      setTimeout(check, interval);
    }
  });
}

export async function isFileExists(url: string): Promise<boolean> {
  const res = await fetch(url, {
    method: 'GET',
    cache: 'no-cache',
    credentials: 'include',
  });
  const isExisted = res.ok;
  return isExisted;
}
