import { Dashlet } from '@tensorleap/api-client';
import { useCallback, useMemo } from 'react';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { useDashboardContext } from './DashboardContext';

export function useDashlet(id: string) {
  const {
    selected,
    removeDashlet,
    duplicateDashlet,
    updateDashlet: _updateDashlet,
    dashletsInEditMode,
    setDashletsInEditMode,
  } = useDashboardContext();
  const { selected: _selectedModels } = useModelFilter();

  const selectedSessionRuns = useMemo(
    () => _selectedModels.filter(({ visible }) => visible),
    [_selectedModels],
  );

  const toggleEditMode = useCallback(
    () =>
      setDashletsInEditMode((prev) =>
        prev?.includes(id)
          ? prev.filter((i) => i !== id)
          : [...(prev ?? []), id],
      ),
    [id, setDashletsInEditMode],
  );

  const removeFromDashboard = useCallback(() => {
    return selected && removeDashlet(selected, id);
  }, [selected, removeDashlet, id]);

  const duplicateInDashboard = useCallback(() => {
    return selected && duplicateDashlet(selected, id);
  }, [selected, duplicateDashlet, id]);

  const updateDashlet = useCallback(
    (dashlet: Dashlet) => {
      return selected && _updateDashlet(selected, dashlet);
    },
    [selected, _updateDashlet],
  );

  return useMemo(
    () => ({
      toggleEditMode,
      removeFromDashboard,
      duplicateInDashboard,
      updateDashlet,
      editMode: !!dashletsInEditMode?.includes(id),
      selectedSessionRuns,
    }),
    [
      updateDashlet,
      toggleEditMode,
      removeFromDashboard,
      duplicateInDashboard,
      id,
      selectedSessionRuns,
      dashletsInEditMode,
    ],
  );
}
