import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDuplicate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M14.88 3.667H6.545c-.69 0-1.278.245-1.764.736a2.393 2.393 0 0 0-.736 1.764V14.5c0 .69.245 1.28.736 1.771.486.486 1.074.73 1.764.73h8.333c.69 0 1.28-.244 1.771-.73.486-.49.73-1.08.73-1.77V6.166c0-.69-.244-1.278-.73-1.764a2.414 2.414 0 0 0-1.77-.736Zm4.166 3.477v10.69c0 .23-.08.425-.241.586a.808.808 0 0 1-.592.247H7.523c.173.49.475.89.904 1.198.43.312.914.469 1.452.469h8.334c.69 0 1.28-.246 1.77-.736.486-.486.73-1.074.73-1.764V9.5a2.43 2.43 0 0 0-.463-1.451 2.486 2.486 0 0 0-1.204-.905Zm-12.5-1.81h8.333c.23 0 .428.082.593.247.16.16.24.356.24.586V14.5a.82.82 0 0 1-.833.834H6.546a.798.798 0 0 1-.586-.241.808.808 0 0 1-.247-.593V6.167c0-.23.082-.425.247-.586a.787.787 0 0 1 .586-.247Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDuplicate;
