import { useCallback, useMemo } from 'react';
import { Slider } from '../../ui/atoms/Slider';
import { Setter } from '../../core/types';

export interface ClippingSlideSelectorProps {
  value?: number[];
  setValue: Setter<number[] | undefined>;
  className?: string;
}
export function ClippingSlideSelector({
  value = [0, 1],
  setValue,
  className,
}: ClippingSlideSelectorProps): JSX.Element {
  const onChange = useCallback(
    (_: React.ChangeEvent<object>, newValue: number | number[]) => {
      setValue((newValue as number[]).map((v) => v / 100));
    },
    [setValue],
  );
  const actualValue = useMemo(() => value.map((v) => v * 100), [value]);

  return (
    <Slider
      labelPrefix="Clipping (%): "
      value={actualValue}
      sliderColor="GradientRainbow"
      onChange={onChange}
      min={0}
      max={100}
      className={className}
    />
  );
}
