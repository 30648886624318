import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBboxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.667 1.333H3.333a2 2 0 0 0-2 2v21.334a2 2 0 0 0 2 2h21.334a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2ZM3.333 0A3.333 3.333 0 0 0 0 3.333v21.334A3.333 3.333 0 0 0 3.333 28h21.334A3.333 3.333 0 0 0 28 24.667V3.333A3.333 3.333 0 0 0 24.667 0H3.333Z"
      fill="currentColor"
    />
    <g filter="url(#bbox-icon_svg__a)">
      <path
        d="M3.398 4.065v2.037h.51v7.13h-.51v2.037h2.037v-.51h7.13v.51h2.037v-2.037h-.51v-7.13h.51V4.065h-2.037v.51h-7.13v-.51H3.398Zm2.037 1.528h7.13v.509h.509v7.13h-.51v.509H5.436v-.51h-.51v-7.13h.51v-.508Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M9 12.333A2.667 2.667 0 1 0 9 7a2.667 2.667 0 0 0 0 5.334Zm0-4.089a1.422 1.422 0 1 1 .071 2.844A1.422 1.422 0 0 1 9 8.244ZM5.022 23.516l5.333-5.333 2.827 2.827-2.507 2.506h1.778l6.631-6.63 5.583 5.537v-1.778l-5.156-5.129a.631.631 0 0 0-.889 0l-4.586 4.587-3.263-3.253a.631.631 0 0 0-.889 0l-6.622 6.666h1.76Z"
      fill="currentColor"
    />
    <defs>
      <filter
        id="bbox-icon_svg__a"
        x={1.667}
        y={2.333}
        width={14.667}
        height={14.667}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          in="SourceAlpha"
          result="effect1_innerShadow_17607_222449"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_17607_222449" />
      </filter>
    </defs>
  </svg>
);

export default SvgBboxIcon;
