import { Divider } from '@material-ui/core';
import {
  SelectDistributionField,
  SelectOrderField,
  SelectOrderByField,
  InputSizeIntervalField,
  SelectAggregationMethodField,
  AutoScaleY,
  Split,
  ScaleTypeY,
} from './FormFields';
import { ChartSplit, DashletFormProps, DataDistributionType } from '../types';
import { useController } from 'react-hook-form';
import { Select } from '../../../../ui/atoms/Select';
import {
  AggregationMethod,
  AnalyticsDashletType,
  OrderType,
} from '@tensorleap/api-client';
import {
  SPLIT_POSITIONS_OPTIONS,
  SplitPosition,
  splitPositions,
} from './utils';
import { useMemo } from 'react';
import { ToggleButtonGroup } from '../../../../ui/atoms/ToggleButtonGroup';
import { NO_SPLIT_SELECTED } from '../ElasticVis/utils';
import { ScaleType } from '../../../../ui/charts/visualizers/ChartBlocks/scale';

export type XYChartParams = {
  yAxis: string;
  xAxis: string;
  aggregation: AggregationMethod;
  dataDistribution: DataDistributionType;
  orderBy: string;
  order: OrderType;
  xAxisSizeInterval: number;
  modelIdPosition: SplitPosition;
  firstSplit: ChartSplit;
  secondSplit: ChartSplit;
  autoScaleY?: boolean;
  scaleTypeY?: ScaleType;
};

export function XYChartDashletForm(
  props: DashletFormProps<XYChartParams>,
): JSX.Element {
  const {
    form: { control },
    graphType,
  } = props;

  const dashletFields = props.fields;

  const {
    field: { ref: _yAxisRef, ...yAxisField },
  } = useController({
    control,
    name: 'data.yAxis',
    defaultValue: 'metrics.loss',
  });

  const {
    field: { ref: _xAxisRef, ...xAxisField },
  } = useController({
    control,
    name: 'data.xAxis',
    defaultValue: 'epoch',
  });

  const {
    field: { ref: _modelIdPosition, ...modelIdPositionField },
  } = useController({
    control,
    name: 'data.modelIdPosition',
    defaultValue: splitPositions[0],
  });

  const {
    field: { ...firstSplitField },
  } = useController({
    control,
    name: 'data.firstSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  const {
    field: { ...secondSplitField },
  } = useController({
    control,
    name: 'data.secondSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  const firstSplitPosition = useMemo(
    () =>
      modelIdPositionField.value === 'vertical' ? 'horizontal' : 'vertical',
    [modelIdPositionField.value],
  );
  const secondSplitPosition = useMemo(
    () => `${modelIdPositionField.value === 'inner' ? 'horizontal' : 'inner'}`,
    [modelIdPositionField.value],
  );

  const showAutoScaleYToggle = useMemo(
    () =>
      graphType === AnalyticsDashletType.Line ||
      graphType === AnalyticsDashletType.Area ||
      graphType === AnalyticsDashletType.Bar,
    [graphType],
  );

  const showScaleTypeY = useMemo(
    () => graphType !== AnalyticsDashletType.Donut,
    [graphType],
  );

  return (
    <div className="flex flex-col gap-6 overflow-x-hidden p-2">
      <Select
        label="Y-AXIS"
        options={dashletFields?.numericFields || []}
        inactiveOptions={dashletFields.notActiveFields}
        className="flex-1 pt-2"
        {...yAxisField}
      />

      <SelectAggregationMethodField {...props} />

      {showScaleTypeY && (
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        <ScaleTypeY {...(props as DashletFormProps<any>)} />
      )}

      {showAutoScaleYToggle && (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <AutoScaleY {...(props as DashletFormProps<any>)} />
      )}

      <Divider orientation="horizontal" />

      <Select
        label="X-AXIS"
        options={dashletFields?.aggregatableFields || []}
        inactiveOptions={dashletFields.notActiveFields}
        className="flex-1"
        {...xAxisField}
      />

      <SelectDistributionField {...props} axisField={xAxisField.value} />

      <SelectOrderByField
        {...props}
        fieldName={yAxisField.value}
        aggregation={props.form.getValues()?.data?.aggregation}
        dataDistribution={props.form.getValues()?.data?.dataDistribution}
      />

      <SelectOrderField
        {...props}
        dataDistribution={props.form.getValues()?.data?.dataDistribution}
      />

      <InputSizeIntervalField
        {...props}
        dataDistribution={props.form.getValues()?.data?.dataDistribution}
        name="xAxisSizeInterval"
      />

      <Divider orientation="horizontal" />

      <span>
        Split by Model:
        <ToggleButtonGroup
          className="pl-2"
          options={SPLIT_POSITIONS_OPTIONS}
          {...modelIdPositionField}
        />
      </span>

      <Split
        label={`${firstSplitPosition} split`}
        dashletFields={dashletFields}
        value={firstSplitField.value}
        onChange={firstSplitField.onChange}
      />

      <Split
        label={`${secondSplitPosition} split`}
        dashletFields={dashletFields}
        {...secondSplitField}
      />
    </div>
  );
}
