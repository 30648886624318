import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUpSelectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 14A5 5 0 1 0 9 4a5 5 0 0 0 0 10Zm0 1A6 6 0 1 0 9 3a6 6 0 0 0 0 12Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6a.59.59 0 0 1 .493.277l2.4 3.714a.682.682 0 0 1 .038.665.596.596 0 0 1-.531.344H6.6a.596.596 0 0 1-.531-.345.682.682 0 0 1 .038-.664l2.4-3.714A.59.59 0 0 1 9 6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUpSelectedIcon;
