import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.765 3.832C2.94 2.611 4.555 2 6.605 2h10.79c2.084 0 3.706.585 4.866 1.756C23.42 4.926 24 6.563 24 8.666v6.667c0 2.138-.555 3.783-1.664 4.937-1.092 1.153-2.74 1.73-4.941 1.73H6.605c-2.134 0-3.773-.585-4.916-1.756C.563 19.091 0 17.454 0 15.334V8.666c0-2.002.588-3.614 1.765-4.835Zm16.235 0H6c-1.345 0-2.387.365-3.126 1.094-.723.713-1.084 1.73-1.084 3.054v7.964c0 1.374.361 2.426 1.084 3.155.723.73 1.765 1.094 3.126 1.094h12c1.378 0 2.42-.356 3.126-1.068.723-.73 1.084-1.79 1.084-3.181V8.056c0-1.357-.361-2.4-1.084-3.13-.706-.73-1.748-1.094-3.126-1.094ZM18.4 6H5.6c-.442 0-.82.16-1.133.482A1.591 1.591 0 0 0 4 7.636v8.728c0 .451.156.836.467 1.154.314.322.691.482 1.133.482h12.8c.442 0 .818-.16 1.13-.482.313-.318.47-.703.47-1.154V7.636c0-.451-.157-.836-.47-1.154A1.515 1.515 0 0 0 18.4 6Zm-6.933 6.546v4.363H5.6a.512.512 0 0 1-.38-.162.528.528 0 0 1-.153-.383v-3.819H5.1v-1.09h-.033V7.635c0-.15.051-.278.154-.383A.512.512 0 0 1 5.6 7.09h5.867v4.363h-.017v1.091h.017Zm7.466 0v3.818c0 .15-.052.278-.158.383a.499.499 0 0 1-.375.162h-5.867v-4.363h.017v-1.091h-.017V7.09H18.4c.147 0 .272.054.375.162a.52.52 0 0 1 .158.383v3.818H18.9v1.091h.033Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTableIcon;
