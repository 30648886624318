import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLightBulbOffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 7.63a5.372 5.372 0 0 0-3.799 9.169l.692.69c.27.27.507.566.709.883h4.796c.203-.318.44-.614.708-.882l.693-.691A5.371 5.371 0 0 0 13 7.629Zm1.66 12.637h-3.32c.053.298.08.602.08.91v.67a1.58 1.58 0 1 0 3.16 0v-.67c0-.306.027-.61.08-.91ZM8.963 6.958A7.267 7.267 0 0 1 18.14 18.14l-.693.692a3.317 3.317 0 0 0-.971 2.345v.671a3.476 3.476 0 0 1-6.952 0v-.67a3.319 3.319 0 0 0-.97-2.346l-.693-.691a7.27 7.27 0 0 1-1.575-7.92 7.267 7.267 0 0 1 2.677-3.262Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLightBulbOffIcon;
