import api from '../api-client';
import useSWR, { KeyedMutator } from 'swr';
import { CacheKey } from './consts';
import { SessionTest } from '@tensorleap/api-client';
import { useMergedObject } from '../useMergedObject';
import { useCallback, useState } from 'react';

export interface useFetchAllModelTests {
  modelTests: SessionTest[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<SessionTest[]>;
}

export interface fetchAllModelTestsProps {
  projectId: string | undefined;
}

export function useFetchAllModelTests({
  projectId,
}: fetchAllModelTestsProps): useFetchAllModelTests {
  const [refetching, setRefetching] = useState(false);
  const {
    data: modelTests,
    error,
    mutate,
  } = useSWR<SessionTest[], Error>(
    `${CacheKey.MODEL_TESTS}-${projectId}`,
    async () => {
      if (!projectId) {
        return [];
      }
      const modelTests = await api.getAllProjectSessionTests({
        projectId,
      });
      return modelTests;
    },
  );

  const refetch = useCallback(() => {
    setRefetching(true);
    return mutate().finally(() => setRefetching(false));
  }, [mutate]);

  return useMergedObject({
    modelTests: modelTests || [],
    error,
    isLoading: refetching || (!error && !modelTests),
    refetch,
  });
}
