import { useCallback, useEffect, useRef, useState } from 'react';
import {
  PopulationExplorationDigest,
  calcPopulationExplorationDigest,
} from './calcPopulationExplorationDigest';
import {
  JobStatus,
  PopulationExplorationResponse,
} from '@tensorleap/api-client';
import api from '../../../core/api-client';
import { useEnvironmentInfo } from '../../../core/EnvironmentInfoContext';
import { useAsyncInterval } from '../../../core/useAsyncInterval';
import { useMounted } from '../../../core/useMounted';
import { isEqual } from 'lodash';

export enum DataStatus {
  NoData = 'NoData',
  Ready = 'Ready',
  Updating = 'Updating',
}

type Props = {
  dataStatus: DataStatus;
  populationParams: PopulationExplorationDigest['populationParams'];
  projectId: string;
};

export type PeRequestState = {
  dataStatus: DataStatus;
  digest: string;
  baseDigest: string;
  populationParams: PopulationExplorationDigest['populationParams'];
  jobResult?: PopulationExplorationResponse;
  reRunAfterFail: boolean;
};

export type PopulationExplorationStatus = {
  ready: PeRequestState | undefined;
  inProgress: PeRequestState | undefined;
};

const MIN_INTERVAL_BETWEEN_PULLING = 5 * 60 * 1000; // 5 minutes
const CHECK_STATUS_INTERVAL = 3 * 1000; // 3 seconds
export function useTriggerAndCheckPopulationExplorationStatus({
  populationParams,
  dataStatus,
  projectId,
}: Props): { status: PopulationExplorationStatus; retry: () => void } {
  const [populationParamsState, setPopulationParamsState] =
    useState(populationParams);
  const [requestsStatus, setRequestsStatus] =
    useState<PopulationExplorationStatus>({
      ready: undefined,
      inProgress: undefined,
    });
  const requestsStatusRef = useRef(requestsStatus);
  requestsStatusRef.current = requestsStatus;
  const [nextRequest, setNextRequest] = useState<PeRequestState | undefined>(
    undefined,
  );
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const dataStatusRef = useRef<DataStatus>(dataStatus);
  dataStatusRef.current = dataStatus;
  const isMounted = useMounted();

  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();

  const stopInterval = !nextRequest && !requestsStatus.inProgress;

  const triggerStatusUpdate = useCallback(async () => {
    let status = { ...requestsStatusRef.current };

    if (nextRequest) {
      setNextRequest(undefined);
      status = {
        ...status,
        inProgress: nextRequest,
      };
    } else if (!status.inProgress) {
      return;
    }

    status.inProgress = await updateRequestsStatusQueue(
      status.inProgress!,
      projectId,
      clientStoragePrefixUrl,
    );

    if (isUpdated(status.inProgress)) {
      status.ready = status.inProgress;
      status.inProgress = undefined;
    }
    setRequestsStatus(status);
  }, [nextRequest, projectId, clientStoragePrefixUrl]);

  useAsyncInterval(triggerStatusUpdate, stopInterval, CHECK_STATUS_INTERVAL);

  useEffect(() => {
    function scheduleNewRequestInCaseOfUpdatingData() {
      const ready = requestsStatusRef.current.ready;
      const isScheduleNewRequest =
        isMounted.current &&
        dataStatusRef.current === DataStatus.Updating &&
        ready?.jobResult &&
        !requestsStatusRef.current.inProgress;

      if (!isScheduleNewRequest) {
        return;
      }

      if (timeoutIdRef.current !== undefined) {
        clearTimeout(timeoutIdRef.current);
      }

      const newState: PeRequestState = {
        ...ready,
        digest: createDigestOnPulling(
          ready.baseDigest,
          MIN_INTERVAL_BETWEEN_PULLING,
        ),
      };

      if (newState.digest === ready.digest) {
        const previousRoundTime = Number(ready.digest.split('-')[1]);
        const timeToWait = calcTimeToWaitForUpdatingDigest(
          previousRoundTime,
          MIN_INTERVAL_BETWEEN_PULLING,
        );
        timeoutIdRef.current = setTimeout(
          scheduleNewRequestInCaseOfUpdatingData,
          timeToWait,
        );
        return;
      }

      setNextRequest((prev) => (prev ? prev : newState));
    }
    scheduleNewRequestInCaseOfUpdatingData();
  }, [dataStatus, isMounted]);

  const scheduleNextRequest = useCallback(
    (reRunAfterFail = false) => {
      if (dataStatus === DataStatus.NoData) {
        return;
      }
      const baseDigest = calcPopulationExplorationDigest({
        populationParams: populationParamsState,
      });
      const digest =
        dataStatus === DataStatus.Updating
          ? createDigestOnPulling(baseDigest, MIN_INTERVAL_BETWEEN_PULLING)
          : baseDigest;
      const requestState = {
        dataStatus,
        digest,
        baseDigest,
        populationParams: populationParamsState,
        status: undefined,
        reRunAfterFail,
      };
      const isSameDigestAndNotFailed =
        requestsStatusRef.current.ready?.digest === digest &&
        requestsStatusRef.current.ready?.jobResult?.status !== JobStatus.Failed;

      if (isSameDigestAndNotFailed) {
        setRequestsStatus((prev) => ({
          inProgress: undefined,
          ready: {
            ...prev.ready!,
            dataStatus,
          },
        }));
        return;
      }

      setNextRequest(requestState);
    },
    [populationParamsState, dataStatus],
  );

  useEffect(() => {
    scheduleNextRequest();
  }, [scheduleNextRequest]);

  useEffect(() => {
    if (!isEqual(populationParams, populationParamsState)) {
      setPopulationParamsState(populationParams);
    }
  }, [populationParams, populationParamsState]);

  const retry = useCallback(() => {
    scheduleNextRequest(true);
  }, [scheduleNextRequest]);

  return { status: requestsStatus, retry };
}

async function updateRequestsStatusQueue(
  state: PeRequestState,
  projectId: string,
  clientStoragePrefixUrl: string,
): Promise<PeRequestState> {
  if (state.jobResult?.status === JobStatus.Finished) {
    return state;
  }
  if (state.jobResult?.status === JobStatus.Failed) {
    return state;
  }

  const params = {
    ...state.populationParams,
    projectId,
    digest: state.digest,
    reRunAfterFail: state.reRunAfterFail,
  };
  const isFirstTime = !state.jobResult;
  const jobResult = isFirstTime
    ? await api.populationExploration(params)
    : await api.getPopulationExplorationStatus(params);

  jobResult.readyArtifacts = mapUrl(
    jobResult.readyArtifacts,
    clientStoragePrefixUrl,
  );

  return {
    ...state,
    jobResult,
  };
}

function isUpdated(peState: PeRequestState | undefined): boolean {
  const status = peState?.jobResult?.status;
  return !!status && status !== 'NOT_FOUND' && isEndStatus(status);
}

export function isEndStatus(status: JobStatus): boolean {
  return status !== JobStatus.Started && status !== JobStatus.Pending;
}

export function createDigestOnPulling(
  digest: string,
  pullingMinInterval: number,
): string {
  const time = roundTimestamp(pullingMinInterval);
  return `${digest}-${time}`;
}

function mapUrl(
  readyArtifacts: PopulationExplorationResponse['readyArtifacts'],
  clientStoragePrefixUrl: string,
): PopulationExplorationResponse['readyArtifacts'] {
  const result = { ...readyArtifacts };
  for (const [key, url] of Object.entries(readyArtifacts)) {
    if (!url) {
      continue;
    }
    result[key as keyof typeof readyArtifacts] =
      `${clientStoragePrefixUrl}/${url}`;
  }
  return result;
}

function calcTimeToWaitForUpdatingDigest(
  previousRoundTime: number,
  pullingMinInterval: number,
): number {
  const roundTime = roundTimestamp(pullingMinInterval);
  if (previousRoundTime !== roundTime) {
    return 0;
  }
  const now = Date.now();
  return pullingMinInterval - (now - roundTime);
}

function roundTimestamp(pullingMinInterval: number): number {
  const now = Date.now();
  const roundTime = Math.floor(now / pullingMinInterval) * pullingMinInterval;

  return roundTime;
}
