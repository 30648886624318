import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLastIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12 5h1v8h-1V5ZM7.293 11.293 9.086 9.5H4v-1h5.086L7.293 6.707 8 6l3 3-3 3-.707-.707Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLastIcon;
