import { MultiChartsResponse } from '@tensorleap/api-client';
import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { REFRESH_INTERVALS } from './consts';

export interface UseGetChart {
  multiChartsResponse: MultiChartsResponse | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<MultiChartsResponse>;
}
export interface UseGetChartParams<T> {
  params: T;
  func: (params: T) => Promise<MultiChartsResponse>;
}

export function useGetChart<T>({
  params,
  func,
}: UseGetChartParams<T>): UseGetChart {
  const {
    data: multiChartsResponse,
    error,
    mutate,
  } = useSWR(
    `${JSON.stringify(params)}`,
    async () => {
      const result = await func(params);
      return result;
    },
    { refreshInterval: REFRESH_INTERVALS.charts },
  );

  return useMemo(
    () => ({
      multiChartsResponse,
      error,
      isLoading: !error && !multiChartsResponse,
      refetch: mutate,
    }),
    [error, multiChartsResponse, mutate],
  );
}
