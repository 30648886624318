import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSelect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M17.38 2.833h1.666c.69 0 1.278.246 1.765.736.49.486.735 1.074.735 1.764V7c0 .23-.082.428-.247.593a.798.798 0 0 1-.586.24A.82.82 0 0 1 19.88 7V5.333a.787.787 0 0 0-.248-.585.787.787 0 0 0-.586-.248H17.38a.82.82 0 0 1-.834-.833c0-.23.08-.426.241-.586a.808.808 0 0 1 .593-.248ZM3.454 16.415a.808.808 0 0 1 .592-.247c.23 0 .426.082.586.247.165.16.248.356.248.586v1.667c0 .23.08.425.24.586a.808.808 0 0 0 .593.247H7.38c.23 0 .425.08.585.241a.809.809 0 0 1 .248.593c0 .23-.083.425-.248.585a.787.787 0 0 1-.585.248H5.713c-.69 0-1.28-.245-1.77-.736a2.404 2.404 0 0 1-.73-1.764V17c0-.23.08-.425.24-.586Zm0-6.666a.808.808 0 0 1 .592-.248c.23 0 .426.083.586.248.165.16.248.356.248.586v3.333c0 .23-.083.427-.248.592a.798.798 0 0 1-.586.241.82.82 0 0 1-.833-.833v-3.333c0-.23.08-.426.24-.586Zm7.259 9.752h3.333c.23 0 .426.08.586.241a.809.809 0 0 1 .248.593c0 .23-.083.425-.248.585a.787.787 0 0 1-.586.248h-3.333a.808.808 0 0 1-.593-.248.798.798 0 0 1-.24-.585.82.82 0 0 1 .833-.834Zm-5-16.666H7.38c.23 0 .425.082.585.247.165.16.248.356.248.586 0 .23-.083.427-.248.592a.798.798 0 0 1-.585.241H5.713a.808.808 0 0 0-.593.248.798.798 0 0 0-.24.585V7c0 .23-.083.428-.248.593a.798.798 0 0 1-.586.24A.82.82 0 0 1 3.213 7V5.333c0-.69.243-1.278.73-1.764.49-.49 1.08-.736 1.77-.736Zm14.407 13.58a.809.809 0 0 1 .593-.247c.23 0 .425.082.586.247.165.16.247.356.247.586v1.667c0 .69-.245 1.278-.735 1.764a2.393 2.393 0 0 1-1.765.736H17.38a.808.808 0 0 1-.593-.248.798.798 0 0 1-.24-.585.82.82 0 0 1 .833-.834h1.666c.23 0 .426-.082.586-.247a.787.787 0 0 0 .248-.586V17c0-.23.08-.425.24-.586Zm0-6.666a.809.809 0 0 1 .593-.248c.23 0 .425.083.586.248.165.16.247.356.247.586v3.333c0 .23-.082.427-.247.592a.798.798 0 0 1-.586.241.82.82 0 0 1-.833-.833v-3.333c0-.23.08-.426.24-.586Zm-9.407-6.914h3.333c.23 0 .426.082.586.247.165.16.248.356.248.586 0 .23-.083.427-.248.592a.798.798 0 0 1-.586.241h-3.333a.82.82 0 0 1-.833-.833c0-.23.08-.426.24-.586a.809.809 0 0 1 .593-.248Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSelect;
