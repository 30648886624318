import {
  LogIcon,
  PauseCircledIcon,
  PlayIcon,
  XClose,
  CloudDownload2,
} from '../ui/icons';
import { useFetchJobLogs } from '../core/data-fetching/job-logs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton, Tabs, Tab, Tooltip } from '@material-ui/core';
import { truncateLongtail } from '../core/formatters/string-formatting';
import { gzip } from '../core/compression/gzip';
import download from 'downloadjs';

export interface LiveLogsProps {
  title: string;
  onClose: () => void;
  jobId: string;
}

export function LiveLogs({
  jobId,
  onClose,
  title,
}: LiveLogsProps): JSX.Element {
  const [isRunning, setIsRunning] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const logContainerRef = useRef<HTMLDivElement | null>(null);

  const { jobResponse } = useFetchJobLogs({
    jobId,
    suspense: !isRunning,
  });

  const handlePause = useCallback(() => {
    setIsRunning(false);
  }, []);

  const handleResume = useCallback(() => {
    setIsRunning(true);
  }, []);

  const handleClose = () => {
    setIsRunning(false);
    onClose();
  };

  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    newValue: number,
  ) => {
    setActiveTabIndex(newValue);
  };

  const podsLogs = useMemo(
    () => jobResponse?.podsLogs || [],
    [jobResponse?.podsLogs],
  );

  useEffect(() => {
    if (logContainerRef.current) {
      const lastLog = logContainerRef.current.lastElementChild;
      if (lastLog) {
        lastLog.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [activeTabIndex, podsLogs]);

  const isDescribeFile = useMemo(
    () => podsLogs[activeTabIndex]?.name?.toLowerCase().startsWith('describe'),
    [activeTabIndex, podsLogs],
  );

  const contentLogs = useMemo(() => {
    if (isDescribeFile) {
      return [podsLogs[activeTabIndex]?.logs];
    }

    return podsLogs[activeTabIndex]?.logs.split('\n');
  }, [activeTabIndex, isDescribeFile, podsLogs]);

  const handleDownloadLogs = useCallback(async () => {
    if (!podsLogs.length) return;

    const files = podsLogs.map(({ logs, name }) => {
      return new File([logs], `${name}.log`, {
        type: 'text/plain',
      });
    });

    const fileName = `${jobId}-logs.tar.gz`;
    const tarGzFile = await gzip(files, fileName);
    download(tarGzFile, fileName, 'application/gzip');
  }, [podsLogs, jobId]);

  const ActionButtons = () => (
    <div className="flex flex-row gap-1 h-8">
      <IconButton onClick={isRunning ? handlePause : handleResume} size="small">
        {isRunning ? (
          <PauseCircledIcon className="mt-1" />
        ) : (
          <PlayIcon className="h-4" />
        )}
      </IconButton>
      <IconButton onClick={handleDownloadLogs} size="small">
        <CloudDownload2 />
      </IconButton>
      <IconButton onClick={handleClose} size="small">
        <XClose />
      </IconButton>
    </div>
  );

  return (
    <div className="flex flex-col w-full h-full p-3">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-1 text-gray-500 text-lg items-center">
          <LogIcon />
          <span className="font-bold uppercase">{title}</span>
        </div>
        <ActionButtons />
      </div>

      <div className="flex-grow flex flex-col overflow-hidden">
        <Tabs
          value={activeTabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {podsLogs.map(({ name }, index) => (
            <Tab
              key={index}
              label={
                <Tooltip title={name}>
                  <span>
                    {truncateLongtail({
                      value: name,
                      startSubsetLength: 20,
                      endSubsetLength: 5,
                    })}
                  </span>
                </Tooltip>
              }
            />
          ))}
        </Tabs>

        <div
          ref={logContainerRef}
          className="flex-grow overflow-x-hidden overflow-y-auto break-words scrollbar-hide bg-gray-850 mt-2"
        >
          {contentLogs?.map((line, index) => (
            <p key={index} className="px-4 py-1 text-xs whitespace-pre-wrap">
              {line}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
