import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgMiniDocIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12.9 14.35H5.7c-.6 0-1.2-.4-1.2-1.2 0-.8.6-1.2 1.2-1.2h7.2v-7.6H5.7c-.6 0-1.2.6-1.2 1.2v7.6m7.2-.8v1.6-1.6Z"
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgMiniDocIcon;
