import { useMemo } from 'react';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import { GraphErrorMsg } from '../errors';
import { useDatasets } from '../../../core/DatasetsContext';
import { ValidateAssetsStatus } from '../../interfaces/ValidateGraphStatus';

export interface AssetsValidationCalcParams {
  showValidateAssetButton: boolean;
  showCreateMappingMappingButton: boolean;
  showDownloadMappingButton: boolean;
  showEvalTrainButton: boolean;
  showUpdateApplyMappingButtons: boolean;
}
export function useWizardActions(): AssetsValidationCalcParams {
  const { datasetMappingFileExists, networkAndDatasetMappingsAreEqual } =
    useDatasets();
  const { validateAssetsErrors, validateAssetsStatus } = useNetworkMapContext();

  const validateStatusGreen = useMemo(
    () => validateAssetsStatus === ValidateAssetsStatus.Passed,
    [validateAssetsStatus],
  );

  const validateAssetErrorExists = useMemo(
    () =>
      validateAssetsErrors.some(
        ({ msg }) => msg === GraphErrorMsg.ValidateAssets,
      ),
    [validateAssetsErrors],
  );

  const showEvalTrainButton = useMemo(
    () =>
      datasetMappingFileExists &&
      validateStatusGreen &&
      !validateAssetErrorExists,
    [datasetMappingFileExists, validateStatusGreen, validateAssetErrorExists],
  );

  const showUpdateApplyMappingButtons = useMemo(
    () => datasetMappingFileExists && !networkAndDatasetMappingsAreEqual,
    [datasetMappingFileExists, networkAndDatasetMappingsAreEqual],
  );

  const showDownloadMappingButton = useMemo(
    () =>
      validateStatusGreen &&
      datasetMappingFileExists &&
      !showUpdateApplyMappingButtons,
    [
      datasetMappingFileExists,
      showUpdateApplyMappingButtons,
      validateStatusGreen,
    ],
  );

  return useMemo(
    (): AssetsValidationCalcParams => ({
      showValidateAssetButton: datasetMappingFileExists,
      showEvalTrainButton,
      showDownloadMappingButton,
      showCreateMappingMappingButton: !datasetMappingFileExists,
      showUpdateApplyMappingButtons,
    }),
    [
      datasetMappingFileExists,
      showDownloadMappingButton,
      showEvalTrainButton,
      showUpdateApplyMappingButtons,
    ],
  );
}
