import { useDashlet } from '../useDashlet';
import {
  AnalyticsDashletData,
  AnalyticsDashlet,
  Dashlet as DashletType,
  PopulationExplorationDashletData,
  PopulationExplorationDashlet as PopulationExplorationDashletType,
} from '@tensorleap/api-client';
import { ClassNameProp } from '../../core/types';
import { useCallback, useMemo } from 'react';
import { AnalyticsDashlet as AnalyticsDashletComp } from './Analytics/Dashlet';
import { DashletCard } from './DashletCard';
import { useCurrentProject } from '../../core/CurrentProjectContext';
import { SampleAnalysisDashlet } from './SampleAnalysis/Dashlet';
import { PopulationExplorationDashlet } from './PopulationExploration/Dashlet';

import {
  mapToEsFilter,
  mapToVisualizationFilters,
} from '../../model-tests/modelTestHelpers';
import { VisualizationFilter } from '../../core/types/filters';
import { useDashletFields } from '../../core/data-fetching/DashletFieldsContext';

export type DashboardItemProps = DashletType & ClassNameProp;

export function Dashlet({
  className,
  cid,
  type,
  layout,
  pinFilters,
  ...rest
}: DashboardItemProps) {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const {
    removeFromDashboard: remove,
    duplicateInDashboard: duplicate,
    selectedSessionRuns,
    editMode,
    toggleEditMode,
    updateDashlet,
  } = useDashlet(cid);

  const updateAnalyticsDashlet = useCallback(
    async (data: AnalyticsDashletData) => {
      await updateDashlet({
        cid,
        type,
        data,
        layout,
        pinFilters,
      } as AnalyticsDashlet);
    },
    [updateDashlet, cid, type, layout, pinFilters],
  );

  const updatePopExpDashlet = useCallback(
    async (data: PopulationExplorationDashletData) => {
      const updatedDashlet: PopulationExplorationDashletType = {
        cid,
        type: 'PopulationExploration',
        data,
        layout,
        pinFilters,
        name: '',
      };
      await updateDashlet(updatedDashlet);
    },
    [updateDashlet, cid, layout, pinFilters],
  );

  const updatePinFilters = useCallback(
    async (filters: VisualizationFilter[]) => {
      const pinFilters = filters.map(mapToEsFilter);
      await updateDashlet({
        ...rest,
        cid,
        type,
        layout,
        pinFilters,
      } as DashletType);
    },
    [cid, type, layout, rest, updateDashlet],
  );

  const mappedPinFilter = useMemo(
    () =>
      (pinFilters || []).map(mapToVisualizationFilters).map((filter) => {
        filter.pin = true;
        return filter;
      }),
    [pinFilters],
  );

  const { dashletFields, error, isLoading } = useDashletFields();
  if (error) {
    return (
      <DashletCard className="w-full flex-1 flex items-center justify-center text-lg font-bold text-gray-500">
        {"Sorry, there was an error fetching the visualization's config"}
      </DashletCard>
    );
  }

  if (!dashletFields || isLoading) {
    return (
      <DashletCard className="w-full flex-1 flex items-center justify-center text-lg font-bold text-gray-500">
        Loading...
      </DashletCard>
    );
  }

  if (
    !dashletFields.aggregatableFields.length ||
    !dashletFields.numericFields.length
  ) {
    return (
      <DashletCard className="w-full flex-1 flex items-center justify-center text-lg font-bold text-gray-500">
        Training/Evaluation process is required to visualize data
      </DashletCard>
    );
  }

  const baseProps = {
    projectId,
    className,
    remove,
    duplicate,
    cid,
    selectedSessionRuns,
    updateDashlet,
    pinFilters: mappedPinFilter,
    updatePinFilters,
    editMode,
    toggleEditMode,
  };
  return type === 'Analytics' ? (
    <AnalyticsDashletComp
      {...baseProps}
      update={updateAnalyticsDashlet}
      {...(rest as AnalyticsDashlet).data}
    />
  ) : type === 'SampleAnalysis' ? (
    <SampleAnalysisDashlet {...baseProps} />
  ) : (
    <PopulationExplorationDashlet
      {...baseProps}
      update={updatePopExpDashlet}
      data={
        (rest as PopulationExplorationDashletType)
          .data as PopulationExplorationDashletData
      }
    />
  );
}
