import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useVersionControl } from '../core/VersionControlContext';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { SaveVersionStateEnum } from '../core/SaveCommitLoading';

const SHOW_SAVE_RESULT_TIME = 3000;

export interface SaveVersionControl {
  overrideSaveDialogIsOpen: boolean;
  setOverrideSaveDialogIsOpen: Dispatch<SetStateAction<boolean>>;
  lockOverrideSaveDialogMsg: string;
  suggestSavingDialog: boolean;
  saveResultMsg?: string;
  saveButtonState: SaveVersionStateEnum;
  saveIconTooltipTitle: string;
  handleSaveClicked: (doOnSave?: () => void) => Promise<void>;
  handleOverrideSaveClicked: () => Promise<void>;
}

export interface useSaveVersionProps {
  saveVersion: () => Promise<void>;
  isVersionChanged: boolean;
  modelGraphHash?: string;
  isLoadingShapes: boolean;
}

export function useSaveVersion({
  saveVersion,
  isVersionChanged,
  modelGraphHash,
  isLoadingShapes,
}: useSaveVersionProps): SaveVersionControl {
  const { currentVersion, refetchCurrentVersion } = useCurrentProject();
  const { versions: slimVersions, fetchVersions } = useVersionControl();

  const currentSlimVersion = useMemo(
    () => slimVersions.find(({ cid }) => cid === currentVersion?.cid),
    [currentVersion?.cid, slimVersions],
  );

  const [overrideSaveDialogMsg, setOverrideSaveDialogMsg] =
    useState<string>('');

  const [doOnOverrideSave, setDoOnOverrideSave] = useState<() => void>();

  const [lockOverrideSaveDialogMsg, setLockOverrideSaveDialogMsg] =
    useState<string>('');

  const [suggestSavingDialog, setSuggestSavingDialog] =
    useState<boolean>(false);

  const [saveResultMsg, setSaveResultMsg] = useState<string>();

  const [saveButtonState, setSaveButtonState] = useState(
    SaveVersionStateEnum.LOADING_SHAPES,
  );

  const [saveIconTooltipTitle, setSaveIconTooltipTitle] = useState('Save');

  const [overrideSaveDialogIsOpen, setOverrideSaveDialogIsOpen] =
    useState(false);

  const slimVersion = useMemo(
    () =>
      slimVersions.find(
        (slimVersion) => slimVersion.cid === currentVersion?.cid,
      ),
    [currentVersion?.cid, slimVersions],
  );

  const saveVersionState = useMemo(
    () =>
      calcSaveVersionState({
        slimVersionHash: slimVersion?.hash,
        slimVersionSessionLength: slimVersion?.sessions.length,
        networkMapHash: modelGraphHash,
        isLoadingShapes,
        isVersionChanged,
      }),

    [
      isLoadingShapes,
      isVersionChanged,
      modelGraphHash,
      slimVersion?.hash,
      slimVersion?.sessions.length,
    ],
  );

  const handleSaveClicked = useCallback(
    async (doOnSave?: () => void) => {
      if (suggestSavingDialog) {
        setLockOverrideSaveDialogMsg(
          `${overrideSaveDialogMsg}. Are you sure you want to save it anyway?`,
        );
        setOverrideSaveDialogIsOpen(true);
        setDoOnOverrideSave(() => doOnSave);
      } else {
        try {
          await saveVersion();
          await fetchVersions();
          refetchCurrentVersion();
          setSaveResultMsg('Saved Successfully!');
        } catch (e) {
          console.error(e);
          setSaveResultMsg('Save Failed');
        }

        setTimeout(() => {
          setSaveResultMsg(undefined);
        }, SHOW_SAVE_RESULT_TIME);

        doOnSave !== undefined && doOnSave();
      }
    },
    [
      suggestSavingDialog,
      overrideSaveDialogMsg,
      setOverrideSaveDialogIsOpen,
      saveVersion,
      fetchVersions,
      refetchCurrentVersion,
    ],
  );

  const handleOverrideSaveClicked = useCallback(async () => {
    try {
      await saveVersion();
      await fetchVersions();
      refetchCurrentVersion();
      setOverrideSaveDialogIsOpen(false);
      doOnOverrideSave !== undefined && doOnOverrideSave();
    } catch (e) {
      console.error('Something went wrong. failed to override save a version');
    }
  }, [doOnOverrideSave, fetchVersions, refetchCurrentVersion, saveVersion]);

  useEffect(() => {
    if (!currentSlimVersion) {
      setSaveIconTooltipTitle('Save');
      setOverrideSaveDialogMsg('Something went wrong, version not found');
      setSuggestSavingDialog(true);
    } else if (saveVersionState === SaveVersionStateEnum.LOADING_SHAPES) {
      setSaveButtonState(saveVersionState);
      setSaveIconTooltipTitle('Save - Calculating Shapes...');
      setOverrideSaveDialogMsg(
        "Can't compare versions while calculating shapes",
      );
      setSuggestSavingDialog(true);
    } else if (saveVersionState === SaveVersionStateEnum.HAS_NO_CHANGES) {
      setSaveButtonState(saveVersionState);
      setSaveIconTooltipTitle('Save - There is nothing to save');
      setOverrideSaveDialogMsg('');
      setSuggestSavingDialog(false);
    } else if (saveVersionState === SaveVersionStateEnum.SAVE_IS_ALLOWED) {
      setSaveButtonState(saveVersionState);
      setSaveIconTooltipTitle('Save');
      setOverrideSaveDialogMsg('');
      setSuggestSavingDialog(false);
    } else {
      setSaveButtonState(SaveVersionStateEnum.SAVE_IS_NOT_ALLOWED);
      setSaveIconTooltipTitle(
        "Can't fit this version strucutre to the saved version weights",
      );
      setOverrideSaveDialogMsg(
        'The saved version structre is different from this version',
      );
      setSuggestSavingDialog(true);
    }
  }, [
    currentSlimVersion,
    modelGraphHash,
    isLoadingShapes,
    isVersionChanged,
    saveVersionState,
  ]);

  const value = useMemo<SaveVersionControl>(() => {
    return {
      overrideSaveDialogIsOpen,
      setOverrideSaveDialogIsOpen,
      lockOverrideSaveDialogMsg,
      suggestSavingDialog,
      saveResultMsg,
      saveButtonState,
      saveIconTooltipTitle,
      handleSaveClicked,
      handleOverrideSaveClicked,
    };
  }, [
    handleOverrideSaveClicked,
    handleSaveClicked,
    lockOverrideSaveDialogMsg,
    overrideSaveDialogIsOpen,
    saveButtonState,
    saveIconTooltipTitle,
    saveResultMsg,
    suggestSavingDialog,
  ]);

  return value;
}

export interface CalcSaveVersionStateParams {
  slimVersionHash?: string | null;
  slimVersionSessionLength?: number;
  networkMapHash?: string;
  isLoadingShapes: boolean;
  isVersionChanged: boolean;
}

export function calcSaveVersionState({
  slimVersionHash,
  slimVersionSessionLength,
  networkMapHash,
  isLoadingShapes,
  isVersionChanged,
}: CalcSaveVersionStateParams): SaveVersionStateEnum {
  const currentVersionHasntHash = !slimVersionHash;
  const areHashEqual = slimVersionHash === networkMapHash;
  const currentVersionHasntSessions = slimVersionSessionLength === 0;

  if (isLoadingShapes) {
    return SaveVersionStateEnum.LOADING_SHAPES;
  } else if (!isVersionChanged) {
    return SaveVersionStateEnum.HAS_NO_CHANGES;
  } else if (
    currentVersionHasntHash ||
    areHashEqual ||
    currentVersionHasntSessions
  ) {
    return SaveVersionStateEnum.SAVE_IS_ALLOWED;
  }
  return SaveVersionStateEnum.SAVE_IS_NOT_ALLOWED;
}
