import { createContext, FC, useCallback, useMemo, useState } from 'react';
import { useContext } from 'react';
import { TourData, ToursData, ToursTypesEnum } from './ToursConfig';
import { TourPopover } from './TourPopover';
import { useLocalStorage } from '../core/useLocalStorage';
import { useLocation } from 'react-router-dom';

const TOURS_STATE_KEY = 'toursState-V1';

export interface SequenceState {
  index: number;
  completed: boolean;
  description: string;
  totalSteps: number;
}
export interface ToursState {
  tour: ToursTypesEnum;
  sequenceStates: SequenceState[];
}

export interface ToursContextProps {
  closeTour: () => void;
  selectTour: (tour: ToursTypesEnum, sequenceIndex?: number) => void;
  Tour?: JSX.Element;
  toursState: ToursState[];
  getTourState: (tour: ToursTypesEnum) => ToursState;
  getSequenceState: (
    tour: ToursTypesEnum,
    sequenceIndex: number,
  ) => SequenceState;
  updateSequenceState: (
    tour: ToursTypesEnum,
    sequenceIndex: number,
    completed: boolean,
  ) => void;
  updateTourState: (tour: ToursTypesEnum, completed: boolean) => void;
  pageTours: Record<string, TourData>;
  toggleCategoryIsExpanded: (categoryName: string) => void;
  openCategories: Record<string, boolean>;
}

export const ToursContext = createContext<ToursContextProps>({
  closeTour: () => {},
  selectTour: () => {},
  toursState: [],
  getTourState: () => ({
    tour: ToursTypesEnum.EXPLORE_PROJECT,
    sequenceStates: [],
    completed: false,
  }),
  getSequenceState: () => ({
    index: 0,
    completed: false,
    description: '',
    totalSteps: 0,
  }),
  updateSequenceState: () => undefined,
  updateTourState: () => undefined,
  pageTours: {},
  toggleCategoryIsExpanded: () => {},
  openCategories: {},
});

export const ToursProvider: FC = ({ children }) => {
  const [selectedTour, setSelectedTour] = useState<
    ToursTypesEnum | undefined
  >();

  const [initialSequenceIndex, setInitialSequenceIndex] = useState<number>();

  const selectTour = useCallback(
    (tour: ToursTypesEnum, sequenceIndex: number = 0) => {
      setInitialSequenceIndex(sequenceIndex);
      setSelectedTour(tour);
    },
    [],
  );

  const closeTour = useCallback(() => {
    setInitialSequenceIndex(undefined);
    setSelectedTour(undefined);
  }, []);

  const isOpen =
    selectedTour !== undefined && initialSequenceIndex !== undefined;

  const [toursMemoryState, setToursMemoryState] = useLocalStorage<ToursState[]>(
    TOURS_STATE_KEY,
    [],
  );
  const [toursInMemoryState, setToursInMemoryState] =
    useState<ToursState[]>(toursMemoryState);

  const { pathname } = useLocation();
  const pageTours = useMemo(() => {
    return Object.entries(ToursData)
      .filter(([_, tour]) => {
        return tour.pages.some((page) => pathname.includes(page));
      })
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, TourData>,
      );
  }, [pathname]);

  const updateToursState = useCallback(
    (state: ToursState[]) => {
      setToursMemoryState(state);
      setToursInMemoryState(state);
    },
    [setToursMemoryState],
  );

  const toursState = useMemo(
    () =>
      Object.entries(pageTours).map(([tour, { sequences }]) => {
        const tourState: ToursState = toursInMemoryState.find(
          (s) => s.tour === tour,
        ) ?? {
          tour: tour as ToursTypesEnum,
          sequenceStates: sequences.map((_, index) => ({
            index,
            completed: false,
            description: sequences[index].description,
            totalSteps: sequences[index].steps.length,
          })),
        };
        return tourState;
      }),
    [pageTours, toursInMemoryState],
  );

  const getTourState = useCallback(
    (tour: ToursTypesEnum) => {
      return (
        toursState.find((s) => s.tour === tour) ?? {
          tour,
          sequenceStates: pageTours[tour].sequences.map(() => ({
            index: 0,
            completed: false,
            description: '',
            totalSteps: 0,
          })),
        }
      );
    },
    [pageTours, toursState],
  );

  const getSequenceState = useCallback(
    (tour: ToursTypesEnum, sequenceIndex: number) => {
      const tourState = getTourState(tour);
      return (
        tourState.sequenceStates[sequenceIndex] ?? {
          index: sequenceIndex,
          completed: false,
        }
      );
    },
    [getTourState],
  );

  const updateSequenceState = useCallback(
    (tour: ToursTypesEnum, sequenceIndex: number, completed: boolean) => {
      const updatedState = [...toursInMemoryState];
      let tourState = updatedState.find((s) => s.tour === tour);
      if (!tourState) {
        tourState = {
          tour,
          sequenceStates: pageTours[tour].sequences.map((_, index) => ({
            index,
            completed: false,
            description: pageTours[tour].sequences[index].description,
            totalSteps: pageTours[tour].sequences[index].steps.length,
          })),
        };
        updatedState.push(tourState);
      }

      const sequenceState = tourState.sequenceStates[sequenceIndex];
      if (sequenceState) {
        sequenceState.completed = completed;
      } else {
        tourState.sequenceStates[sequenceIndex] = {
          index: sequenceIndex,
          completed: completed,
          description: pageTours[tour].sequences[sequenceIndex].description,
          totalSteps: pageTours[tour].sequences[sequenceIndex].steps.length,
        };
      }

      updateToursState(updatedState);
    },
    [pageTours, toursInMemoryState, updateToursState],
  );

  const updateTourState = useCallback(
    (tour: ToursTypesEnum, completed: boolean) => {
      const updatedState = [...toursInMemoryState];
      const tourState = updatedState.find((s) => s.tour === tour);
      if (tourState) {
        tourState.sequenceStates.forEach((sequenceState) => {
          sequenceState.completed = completed;
        });
      } else {
        updatedState.push({
          tour,
          sequenceStates: pageTours[tour].sequences.map((_, index) => ({
            index,
            completed,
            description: pageTours[tour].sequences[index].description,
            totalSteps: pageTours[tour].sequences[index].steps.length,
          })),
        });
      }

      updateToursState(updatedState);
    },
    [pageTours, toursInMemoryState, updateToursState],
  );

  const [openCategoriesStorage, setOpenCategoriesStorage] = useLocalStorage<
    Record<string, boolean>
  >('tourOpenCategories', {});
  const [openCategories, setOpenCategories] = useState(openCategoriesStorage);

  const updateCategoryState = useCallback(
    (state: Record<string, boolean>) => {
      setOpenCategoriesStorage(state);
      setOpenCategories(state);
    },
    [setOpenCategoriesStorage],
  );

  const toggleCategoryIsExpanded = useCallback(
    (categoryName: string) => {
      const updatedCategories = {
        ...openCategories,
        [categoryName]: !(openCategories[categoryName] ?? true),
      };
      updateCategoryState(updatedCategories);
    },
    [openCategories, updateCategoryState],
  );

  const value = {
    selectedTour,
    selectTour,
    closeTour,
    Tour: isOpen ? (
      <TourPopover
        selectedTour={selectedTour}
        selectTour={selectTour}
        sequencesStates={getTourState(selectedTour).sequenceStates}
        initialSequenceIndex={initialSequenceIndex}
        updateSequenceState={updateSequenceState}
        closeTour={closeTour}
        key={initialSequenceIndex}
      />
    ) : undefined,
    toursState,
    getTourState,
    getSequenceState,
    updateSequenceState,
    updateTourState,
    pageTours,
    toggleCategoryIsExpanded,
    openCategories,
  };

  return (
    <ToursContext.Provider value={value}>{children}</ToursContext.Provider>
  );
};

export const useTours = (): ToursContextProps => useContext(ToursContext);
