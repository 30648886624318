import { useCallback, useEffect, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Badge, Drawer, IconButton, Tooltip } from '../ui/mui';
import api from '../core/api-client';
import { Alerts, Down, NonVisible, Up } from '../ui/icons';
import { usePushNotifications } from '../core/PushNotificationsContext';
import { useFetchNotifications } from '../core/data-fetching';
import { Button } from '../ui/atoms/Button';
import { GroupedNotificationsCard } from '../ui/molecules/GroupedNotificationCard';
import { Title } from '../ui/atoms/Title';
import clsx from 'clsx';

export function NotificationPanel(): JSX.Element {
  const { notifications, groupedNotifications, numOfRead, isLoading, refetch } =
    useFetchNotifications();
  const {
    lastServerMessage,
    browserNotificationsState,
    setBrowserNotificationsState,
  } = usePushNotifications();

  const [isOpen, setIsOpen] = useState(false);
  const onClose = useCallback(async () => {
    setIsOpen(false);

    if (notifications?.length) {
      const isFirstUnread = !notifications[0]?.isRead;
      if (isFirstUnread) {
        await refetch();
      }
    }
  }, [notifications, refetch]);

  const handleClearHistory = useCallback(async () => {
    await api.clearUserNotifications();
    await refetch();
  }, [refetch]);

  useEffect(() => {
    if (lastServerMessage) {
      refetch();
    }
  }, [lastServerMessage, refetch]);
  useEffect(() => {
    const isFirstUnread = !notifications?.[0]?.isRead;

    if (isOpen && !isLoading && isFirstUnread) {
      api.setUserNotificationsAsRead();
    }
  }, [isLoading, isOpen, notifications]);

  const notificationPermissionGranted = useMemo(
    () => Notification.permission === 'granted',
    [],
  );

  const handleClickBrowserNotification = useCallback(() => {
    if (!notificationPermissionGranted) {
      return;
    }
    setBrowserNotificationsState(!browserNotificationsState);
  }, [
    browserNotificationsState,
    notificationPermissionGranted,
    setBrowserNotificationsState,
  ]);

  const browserNotificationsTooltipMessage = useMemo(() => {
    if (!notificationPermissionGranted)
      return 'Please allow browser notifications in your browser settings';
    if (browserNotificationsState) return 'Disable browser notifications';
    return 'Allow browser notifications';
  }, [browserNotificationsState, notificationPermissionGranted]);

  return (
    <>
      <Tooltip title="Notifications">
        <Button variant="action-icon" onClick={() => setIsOpen((o) => !o)}>
          <Badge badgeContent={numOfRead} color="error">
            <Alerts />
          </Badge>
          {isOpen ? <Up /> : <Down />}
        </Button>
      </Tooltip>

      <Drawer
        anchor="right"
        elevation={0}
        open={isOpen}
        classes={{
          paper:
            'top-16 w-[392px] py-4 h-[calc(100vh-4rem)] bottom-0 bg-gray-900 border-l border-gray-700',
        }}
        onClose={onClose}
        BackdropProps={{ invisible: true }}
      >
        <div className="flex flex-row justify-between items-center">
          <Title className="px-6 py-4">NOTIFICATIONS</Title>
          <div className="flex flex-row items-center">
            <Tooltip title={browserNotificationsTooltipMessage}>
              <IconButton
                className="mr-2"
                onClick={handleClickBrowserNotification}
              >
                <Alerts
                  className={clsx(
                    browserNotificationsState && notificationPermissionGranted
                      ? 'text-white'
                      : 'text-gray-700',
                  )}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear Notifications">
              <IconButton className="mr-2" onClick={handleClearHistory}>
                <NonVisible />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {isLoading && (
          <span className="font-normal text-base tracking-normal">
            Loading...
          </span>
        )}
        <div className="flex flex-col gap-5 overflow-auto">
          {groupedNotifications.map((group, index) => (
            <InView key={index} initialInView={index < 10}>
              <GroupedNotificationsCard
                key={group[0].identifier}
                notifications={group}
              />
            </InView>
          ))}
        </div>
      </Drawer>
    </>
  );
}
