import { SequenceState } from './ToursContext';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../core/useDebounce';
import { ToursTypesEnum } from './ToursConfig';
import { Button } from '../ui/atoms/Button';
import { StateToggleVi } from '../ui/StateToggleVi';

export interface TourProgressBarProps {
  sequencesStates: SequenceState[];
  sequenceIndex: number;
  stepIndex: number;
  selectTour: (tour: ToursTypesEnum, sequenceIndex?: number) => void;
  selectedTour: ToursTypesEnum;
}

export function TourProgressBar({
  sequencesStates,
  sequenceIndex,
  stepIndex,
  selectTour,
  selectedTour,
}: TourProgressBarProps): JSX.Element {
  const [realTimeFirstStepIndex, setRealTimeFirstStepIndex] = useState(0);
  const [realTimeFirstStepSequenceIndex, setRealTimeFirstStepSequenceIndex] =
    useState<number>();
  const setRealTimeStepIndex = useCallback((step: number, sequence: number) => {
    setRealTimeFirstStepIndex(step);
    setRealTimeFirstStepSequenceIndex(sequence);
  }, []);
  const debounceSetRealTimeStepIndex = useDebounce(setRealTimeStepIndex, 100);

  const [askGoToSequenceIndex, setAskGoToSequenceIndex] = useState<number>();
  const goToSequence = useCallback(
    (sequenceIndex: number) => {
      selectTour(selectedTour, sequenceIndex);
    },
    [selectTour, selectedTour],
  );
  const askGoToSequence = useCallback((sequenceIndex: number) => {
    setAskGoToSequenceIndex(sequenceIndex);
  }, []);
  const closeAskGoToSequenceDialog = useCallback(() => {
    setAskGoToSequenceIndex(undefined);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => closeAskGoToSequenceDialog, [stepIndex]);

  useEffect(() => {
    if (sequenceIndex !== realTimeFirstStepSequenceIndex) {
      debounceSetRealTimeStepIndex(stepIndex, sequenceIndex);
    }
  }, [
    debounceSetRealTimeStepIndex,
    realTimeFirstStepSequenceIndex,
    sequenceIndex,
    stepIndex,
  ]);

  const realTimeTotalSteps =
    sequencesStates[sequenceIndex].totalSteps - realTimeFirstStepIndex;

  const currentSequenceCompletedSoFar =
    realTimeTotalSteps === 0
      ? 0
      : (stepIndex - realTimeFirstStepIndex) / realTimeTotalSteps;

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-center w-full px-4">
        {sequencesStates.map((_, index) => (
          <div key={index} className="flex items-center flex-1">
            {sequencesStates[index].index === sequenceIndex ? (
              <div className="flex flex-1 h-1 relative">
                <div
                  className={clsx('h-1 relative', 'bg-secondary-400')}
                  style={{
                    width: `${currentSequenceCompletedSoFar * 100}%`,
                  }}
                />
                <div
                  className={clsx('h-1 relative', 'bg-secondary-900')}
                  style={{
                    width: `${(1 - currentSequenceCompletedSoFar) * 100}%`,
                  }}
                />
              </div>
            ) : (
              <div
                className={clsx(
                  'flex-1 h-1 relative',
                  sequencesStates[index].completed
                    ? 'bg-secondary-400'
                    : 'bg-gray-400',
                )}
              />
            )}

            <StateToggleVi
              onClick={() => askGoToSequence(index)}
              completed={sequencesStates[index].completed}
              tooltipTitle={`Go to ${sequencesStates[index].description}`}
              dislayVOnHover={false}
            >
              <span
                className={clsx(
                  'text-xs align-middle text-center justify-center items-center select-none text-gray-350',
                  index === sequenceIndex &&
                    'h-4 w-4 bg-secondary-900 rounded-xl',
                )}
              >
                {index + 1}
              </span>
            </StateToggleVi>
          </div>
        ))}
      </div>
      {askGoToSequenceIndex !== undefined && (
        <div className="flex flex-row gap-2 w-fit px-4">
          <Button
            onClick={() => goToSequence(askGoToSequenceIndex)}
            className="h-8"
          >
            <span className="text-xs">
              {`Go to ${sequencesStates[askGoToSequenceIndex].description} steps`}
            </span>
          </Button>
          <Button
            variant="inverted-gray"
            onClick={closeAskGoToSequenceDialog}
            className="h-8"
          >
            <span className="text-xs">Cancel</span>
          </Button>
        </div>
      )}
    </div>
  );
}
