import { useCallback, useMemo } from 'react';
import ReactJson, { InteractionProps } from 'react-json-view';

import { DetailsProps } from './NodeDetails';
import { Visible } from '../ui/icons';
import { NodeDetailsPanel } from './NodeDetalisPanel';
import {
  getLastInboundNode,
  setLastInboundNode,
} from '../network-editor/descriptor/tfoplambdaUtils';
import { useNetworkMapContext } from '../core/NetworkMapContext';

export function TFOpLambdaJsonPreviewDetails({
  node,
}: DetailsProps): JSX.Element {
  const { changeNodeProperty } = useNetworkMapContext();

  const handleChange = useCallback(
    ({ updated_src: newData }: InteractionProps) => {
      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: setLastInboundNode(node, newData),
      });
    },
    [changeNodeProperty, node],
  );
  const data = useMemo(() => getLastInboundNode(node), [node]);

  return (
    <NodeDetailsPanel
      className="bg-transparent"
      title="Preview"
      icon={<Visible />}
      openByDefault
    >
      <ReactJson
        name={null}
        theme="chalk"
        displayDataTypes={false}
        indentWidth={2}
        src={data}
        onEdit={handleChange}
        onAdd={handleChange}
        onDelete={handleChange}
      />
    </NodeDetailsPanel>
  );
}
