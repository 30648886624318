import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDatatypeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.602 4.138v8.012c0 .41.186.798.505 1.055l4.23 3.402V8.594c0-.41-.186-.797-.505-1.054l-4.23-3.402Zm-.168-1.293a.451.451 0 0 0-.734.351v8.954c0 .683.31 1.33.842 1.757L9.505 17.9a.451.451 0 0 0 .734-.352V8.594c0-.683-.31-1.329-.842-1.757L4.434 2.845Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.631 5.45V2.784l4.23 3.402c.32.257.505.644.505 1.054v8.013l-1.127-.907v1.158l1.296 1.042a.451.451 0 0 0 .733-.352V7.241c0-.683-.31-1.329-.841-1.757L6.463 1.492a.451.451 0 0 0-.734.351v2.855l.902.751Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66 4.058V1.393l4.23 3.402c.32.257.506.645.506 1.055v8.012l-1.128-.906v1.157l1.296 1.042a.451.451 0 0 0 .734-.351V5.85c0-.683-.31-1.33-.842-1.757L8.492.1a.451.451 0 0 0-.733.352v2.854l.901.752Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDatatypeIcon;
