import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { IconButton } from '../ui/mui';
import { XClose } from '../ui/icons';
import { getBranchTitle, useSaveVersionState } from './useSaveVersionState';
import { buildRenderInputText } from '../ui/atoms/utils/buildRenderInputText';
import { Input } from '../ui/atoms/Input';
import { Unreachable } from '../core/Errors';
import { Version } from '@tensorleap/api-client';
import { useVersionControl } from '../core/VersionControlContext';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import SvgSaveCommit from '../ui/icons/SaveCommit';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { SaveVersionStateEnum } from '../core/SaveCommitLoading';
import { removeVersionUrl } from '../url/url-builder';
import { useHistory, useLocation } from 'react-router-dom';
import { ProcessButton, ProcessButtonState } from '../ui/atoms/ProcessButton';
import { Autocomplete } from '@mui/material';

export interface SaveCommitProps {
  onClose: () => void;
  currentVersion: Version;
}
export function SaveCommit({
  onClose,
  currentVersion,
}: SaveCommitProps): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const { versions } = useVersionControl();
  const { saveCurrentVersion, currentDatasetSetup, saveButtonState } =
    useNetworkMapContext();
  const {
    revisionName,
    allBranches,
    branch,
    handleRevisionChange,
    handleBranchChange,
    handleBranchFilterOptions,
    handleOptionalBranch,
    fetchVersions,
  } = useSaveVersionState();
  const inputBgClass = 'bg-gray-850';

  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

  const saveButtonTooltipTitle =
    !!revisionName && !!branch && savingInProgress
      ? 'Saving...'
      : !!revisionName && !!branch
        ? ''
        : !revisionName && !branch
          ? 'Please supply a Revision Name and choose an Experiment'
          : !revisionName && !!branch
            ? 'Please supply a Revision Name'
            : 'Please choose an Experiment';

  const disabledSaveButton = !branch || !revisionName || savingInProgress;

  const processButtonState: ProcessButtonState = savingInProgress
    ? ProcessButtonState.Processing
    : ProcessButtonState.Unstarted;

  const handleSave = useCallback(async () => {
    if (!currentDatasetSetup) throw new Unreachable();

    if (!branch || !revisionName) {
      console.error(
        'Somehow save version was submitted without a branch or revision name',
        { branch, revisionName },
      );
      return;
    }

    const slimVersion = versions.find(
      (version) => version.cid === currentVersion.cid,
    );
    const sessionsToCopy = slimVersion?.sessions || [];

    try {
      setSavingInProgress(true);
      await saveCurrentVersion(revisionName, branch.title, sessionsToCopy);

      fetchVersions();
      onClose();

      history.push({
        pathname: removeVersionUrl(location.pathname),
        search: location.search,
      });
    } catch (error) {
      console.error('Error saving version', error);
    } finally {
      setSavingInProgress(false);
    }
  }, [
    currentDatasetSetup,
    branch,
    revisionName,
    versions,
    currentVersion.cid,
    saveCurrentVersion,
    fetchVersions,
    onClose,
    history,
    location.pathname,
    location.search,
  ]);

  useEffect(() => {
    firstInputRef.current?.focus();
  }, []);

  const renderExperimentInputText = useMemo(
    () => buildRenderInputText({ label: 'Experiment' }),
    [],
  );

  const copySessionsErrorMsg = useMemo<string>(
    () =>
      saveButtonState === SaveVersionStateEnum.LOADING_SHAPES
        ? "Can't compare versions while calculating shapes, saving copy an incompatible session run. Are you sure you want to save it anyway?"
        : saveButtonState === SaveVersionStateEnum.SAVE_IS_ALLOWED ||
            saveButtonState === SaveVersionStateEnum.HAS_NO_CHANGES
          ? ''
          : 'The saved version structre is different from this version, saving copy an incompatible session run. Are you sure you want to save it anyway?',

    [saveButtonState],
  );
  const [overrideSaveDialogIsOpen, setOverrideSaveDialogIsOpen] =
    useState<boolean>(false);

  return (
    <div className={clsx('flex flex-col p-4 gap-4 bg-success-900')}>
      <ConfirmDialog
        title={copySessionsErrorMsg}
        isOpen={overrideSaveDialogIsOpen}
        onClose={() => {
          setOverrideSaveDialogIsOpen(false);
        }}
        onConfirm={handleSave}
        confirmButtonText="Save"
        confirmButtonIcon={<SvgSaveCommit />}
      />

      <div className="flex flex-row justify-between items-center">
        <span className="font-normal text-xs leading-relaxed">
          Save Commit:
        </span>
        <IconButton onClick={onClose} size="small">
          <XClose />
        </IconButton>
      </div>
      <Input
        label="Revision Name"
        value={revisionName}
        onChange={handleRevisionChange}
        ref={firstInputRef}
        containerProps={{ className: inputBgClass }}
      />

      <Autocomplete
        className={inputBgClass}
        value={branch}
        onChange={handleBranchChange}
        filterOptions={handleBranchFilterOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        getOptionLabel={handleOptionalBranch}
        renderOption={(props, option) => (
          <li {...props}>{getBranchTitle(option)}</li>
        )}
        options={allBranches}
        renderInput={renderExperimentInputText}
      />

      <div className="self-center">
        <ProcessButton
          disabled={disabledSaveButton}
          onClick={handleSave}
          processState={processButtonState}
          tooltipTitle={saveButtonTooltipTitle}
        >
          <div className="flex flex-row gap-2 items-center">
            <SvgSaveCommit />
            <p>SAVE AS A NEW VERSION</p>
          </div>
        </ProcessButton>
      </div>
    </div>
  );
}
