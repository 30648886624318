import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgResetSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M8.5 1a8.509 8.509 0 0 0-5.965 2.445V1H1.4v4.538h4.538V4.404H3.183A7.374 7.374 0 0 1 8.5 2.134c4.061 0 7.366 3.305 7.366 7.366S12.56 16.866 8.5 16.866 1.135 13.56 1.135 9.5H0A8.5 8.5 0 1 0 8.5 1Z"
      fill="currentColor"
    />
    <path
      d="M10.262 8.587V5.531h1.026v1.027h2.03V7.56h-2.03v1.026h-1.026Zm3.055 2.03H8.233V9.612h5.084v1.003ZM6.18 8.586h1.026v3.056H6.18v-1.027H4.15V9.613h2.03V8.587Zm3.055 6.111H8.233v-3.056h1.002v1.027h4.082v1.003H9.235v1.026Zm-5.084-8.14h5.084V7.56H4.151V6.558Zm0 6.111h3.055v1.003H4.151v-1.003Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgResetSettingsIcon;
