import { useCallback, useState } from 'react';
import { useMergedObject } from '../../core/useMergedObject';
import { ItemId } from './types';

export type Selector<Item> = {
  toggle: (item: Item) => void;
  set: (item: Item) => void;
  isSelected: (item: Item) => boolean;
};

export type SingleSelector<Item> = Selector<Item> & {
  selectedId?: ItemId;
};

export function useSingleSelector<Item>({
  defaultValue,
  itemIdKey,
}: {
  itemIdKey: keyof Item;
  defaultValue?: ItemId;
}): SingleSelector<Item> {
  const [value, setValue] = useState<ItemId | undefined>(defaultValue);

  const toggle = useCallback(
    (item: Item) =>
      setValue((previous) =>
        previous === item[itemIdKey] ? undefined : (item[itemIdKey] as ItemId),
      ),
    [itemIdKey],
  );

  const isSelected = useCallback(
    (item: Item) => item[itemIdKey] === value && value !== undefined,
    [itemIdKey, value],
  );
  const set = useCallback(
    (item: Item) => setValue(item[itemIdKey] as ItemId),
    [itemIdKey],
  );

  return useMergedObject({
    selectedId: value,
    toggle,
    isSelected,
    set,
  });
}

export type MultiSelector<Item> = Selector<Item> & {
  selectedIds?: Set<ItemId>;
  setMulti: (items: Item[]) => void;
};

export function useMultiSelector<Item>({
  defaultValue = [],
  itemIdKey,
}: {
  itemIdKey: keyof Item;
  defaultValue?: ItemId[];
}): MultiSelector<Item> {
  const [value, setValue] = useState<Set<ItemId>>(() => new Set(defaultValue));

  const toggle = useCallback(
    (item: Item) =>
      setValue((previous) => {
        const newValue = new Set(previous);
        const toggleItemId = item[itemIdKey] as ItemId;
        newValue.has(toggleItemId)
          ? newValue.delete(toggleItemId)
          : newValue.add(toggleItemId);
        return newValue;
      }),
    [itemIdKey],
  );

  const isSelected = useCallback(
    (item: Item) => value.has(item[itemIdKey] as ItemId),
    [itemIdKey, value],
  );
  const set = useCallback(
    (item: Item) => {
      const newValue = new Set(value);
      const toggleItemId = item[itemIdKey] as ItemId;
      newValue.add(toggleItemId);
      setValue(newValue);
    },
    [itemIdKey, value],
  );

  const setMulti = useCallback(
    (items: Item[]) => {
      const ids = items.map((item) => item[itemIdKey] as ItemId);
      setValue(new Set(ids));
    },
    [itemIdKey],
  );

  return useMergedObject({
    value,
    toggle,
    isSelected,
    set,
    setMulti,
  });
}
