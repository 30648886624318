import {
  ClippingSlideSelectorProps,
  ClippingSlideSelector,
} from './ClippingSlideSelector';
import {
  OpacitySlideSelectorProps,
  OpacitySlideSelector,
} from './OpacitySlideSelector';
import { ScissorsIcon, Settings, TransparencyIcon } from '../../ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';

export interface HeatmapSlidersProps {
  opacitySlideSelectorProps?: OpacitySlideSelectorProps;
  clippingSlideSelectorProps?: ClippingSlideSelectorProps;
  isOpen: boolean;
  toggleIsOpen: () => void;
}

export function HeatmapSliders({
  opacitySlideSelectorProps,
  clippingSlideSelectorProps,
  isOpen,
  toggleIsOpen,
}: HeatmapSlidersProps): JSX.Element {
  return (
    <div
      className="flex w-full h-fit relative"
      id={TOUR_SELECTORS_ENUM.HEAT_MAP_SLIDERS_ID}
    >
      <div className="w-10" />
      <div
        className={clsx(
          'w-full bg-black rounded-b-2xl transition-all duration-300 ease-in-out opacity-80',
          isOpen
            ? 'opacity-100 h-auto pl-2 py-2'
            : 'opacity-0 h-0 overflow-hidden',
        )}
      >
        {isOpen && (
          <div className="flex w-full flex-col gap-1 pr-4 z-[50000]">
            {opacitySlideSelectorProps && (
              <div className="flex flex-row w-full h-fit gap-3 justify-center">
                <Tooltip title="Opacity">
                  <div className="flex p-0 m-0 h-fit">
                    <TransparencyIcon className="h-4 w-4 text-gray-300" />
                  </div>
                </Tooltip>
                <OpacitySlideSelector
                  {...opacitySlideSelectorProps}
                  className="mt-1"
                />
              </div>
            )}
            {clippingSlideSelectorProps && (
              <div className="flex flex-row w-full h-fit gap-3 justify-center">
                <Tooltip title="Clipping">
                  <div className="flex p-0 m-0 h-fit">
                    <ScissorsIcon className="h-4 w-4 text-gray-300" />
                  </div>
                </Tooltip>
                <ClippingSlideSelector
                  {...clippingSlideSelectorProps}
                  className="mt-1"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="w-10" />

      <IconButton
        className={clsx(
          '!h-8 !w-8 mx-2 absolute z-[5000] top-0 right-0',
          isOpen ? 'bg-primary-950' : 'bg-gray-800',
        )}
        onClick={toggleIsOpen}
      >
        <Settings className="h-4 w-4" />
      </IconButton>
    </div>
  );
}
