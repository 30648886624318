import { useCallback, useMemo } from 'react';
import { Button } from '../../../ui/atoms/Button';
import { XYViz } from '../Analytics/ElasticVis/XYViz';
import { XYChartVizProps } from '../Analytics/ElasticVis/interfaces';
import { UnifiedXYChartParams } from '../Analytics/form/utils';
import {
  AggregationMethod,
  AnalyticsDashlet,
  AnalyticsDashletType,
  GeneratedDashletType,
  JobStatus,
} from '@tensorleap/api-client';
import { XYChartParams } from '../Analytics/form/XYChart';
import { NO_SPLIT_SELECTED } from '../Analytics/ElasticVis/utils';
import {
  AddDashlet,
  DashletType,
  useDashboardContext,
} from '../../DashboardContext';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@material-ui/core';
import { useCurrentProject } from '../../../core/CurrentProjectContext';
import { useSelectedSessionRuns } from '../../add-analysis/panes/useSelectedModels';
import {
  GeneratedDashletGeneralStatus,
  calcGeneratedDashletHash,
  useFetchSuggestedDashlets,
} from '../../../core/data-fetching/suggested-dashlets';
import { last } from 'lodash';
import { useMergedObject } from '../../../core/useMergedObject';
import { XClose } from '../../../ui/icons';
import { useFetchDashboards } from '../../../core/data-fetching/dashboards';
import {
  removeMetadataPrefix,
  removeMetricsPrefix,
} from '../../../actions-dialog/helper-functions';
import { useModelFilter } from '../../../ui/molecules/useModelFilter';
import { Truncate } from '../../../ui/atoms/Truncate';

type SuggestedDashlet = {
  title: string;
  description: string;
  xyChart: UnifiedXYChartParams;
};

export type UseSuggestedDashletResult = {
  suggestionDashletProps: Omit<SuggestedDashletProps, 'onAdd'>[];
  status: GeneratedDashletGeneralStatus;
  resetSuggestions: () => Promise<void>;
  sessionRunIds: string[];
};

export function useSuggestedDashlet(): UseSuggestedDashletResult {
  const { currentProjectId } = useCurrentProject();
  if (!currentProjectId) {
    throw new Error('No project id');
  }

  const {
    selected,
    ignoreSuggestion,
    ignoreSuggestionHashes,
    resetIgnoreSuggestion,
  } = useDashboardContext();

  const { dashboards } = useFetchDashboards({
    projectId: currentProjectId,
  });

  const sessionRunIds = useSelectedSessionRuns()
    .filter(
      (s) => s.jobs.length === 0 || last(s.jobs)?.status === JobStatus.Finished,
    )
    .map((s) => s.cid);
  const { currentProjectId: projectId } = useCurrentProject();

  const { selected: sessionRuns } = useModelFilter();

  const currentHashes = useMemo(() => {
    const selectedDashbord = dashboards?.find(({ cid }) => cid === selected);
    const analyticsDashlets = selectedDashbord?.items.filter(
      (d): d is AnalyticsDashlet => d.type === DashletType.Analytics,
    );
    return (
      analyticsDashlets?.map((d) =>
        calcDashboardHash(d.data.data as UnifiedXYChartParams),
      ) ?? []
    );
  }, [dashboards, selected]);

  const ignoreHashes = useMemo(
    () => new Set<string>([...currentHashes, ...ignoreSuggestionHashes]),
    [currentHashes, ignoreSuggestionHashes],
  );

  const createOnSkip = useCallback(
    (dashletHash: string) => {
      return () => ignoreSuggestion(dashletHash);
    },
    [ignoreSuggestion],
  );

  const {
    data: response,
    reset,
    status,
  } = useFetchSuggestedDashlets({
    sessionRunIds,
    projectId,
  });

  const resetSuggestions = useCallback(async () => {
    await resetIgnoreSuggestion();
    await reset();
  }, [reset, resetIgnoreSuggestion]);

  const suggestionDashletProps: SuggestedDashletProps[] = useMemo(() => {
    if (!response || !projectId) return [];

    const filtered = response
      .filter(({ hash }) => !ignoreHashes.has(hash))
      .sort((a, b) => b.maxPriority - a.maxPriority)
      .slice(0, 5);

    const visProps: XYChartVizProps[] = filtered.map(
      ({ dashlet: d, sessionRunIds }) => {
        const { x, y } = d.data;
        const chartType =
          d.type === GeneratedDashletType.Bar
            ? AnalyticsDashletType.Bar
            : AnalyticsDashletType.Line;

        const xAxisSizeInterval =
          x.distribution === 'distinct' ? x.limit || 10 : x.interval;
        const orderBy = x.orderField ?? '_key';

        const graphParams: XYChartParams = {
          xAxis: x.field,
          yAxis: y.field,
          dataDistribution: x.distribution,
          orderBy,
          aggregation: AggregationMethod.Average,
          xAxisSizeInterval,
          modelIdPosition: 'vertical',
          firstSplit: { field: NO_SPLIT_SELECTED },
          secondSplit: { field: 'dataset_state.keyword' },
          order: x.order,
          autoScaleY: true,
        };

        return {
          chartType,
          projectId,
          sessionRunIds,
          sessionRuns,
          filters: [],
          localFilters: [],
          onFiltersChange: () => {},
          graphParams: graphParams as UnifiedXYChartParams,
        };
      },
    );

    return visProps.map(
      (dashlet) =>
        ({
          onSkip: createOnSkip(calcDashboardHash(dashlet.graphParams)),
          title: defaultName(dashlet.graphParams),
          data: dashlet,
        }) as SuggestedDashletProps,
    );
  }, [response, projectId, ignoreHashes, sessionRuns, createOnSkip]);

  return useMergedObject({
    suggestionDashletProps,
    resetSuggestions,
    status,
    sessionRunIds,
  });
}

type SuggestedDashletProps = {
  title: string;
  onAdd: AddDashlet;
  onSkip: () => void;
  data: XYChartVizProps;
  className?: string;
};

export function SuggestedDashlet({
  onAdd,
  onSkip,
  title,
  data,
  className,
}: SuggestedDashletProps) {
  const handleAdd = useCallback(() => {
    onAdd(DashletType.Analytics, {
      name: defaultName(data.graphParams),
      type: data.chartType,
      data: data.graphParams,
    });
  }, [onAdd, data.graphParams, data.chartType]);

  return (
    <div className={clsx('flex p-2 gap-2 flex-col ', className)}>
      <header className="flex gap-2 items-center">
        <Tooltip title={title}>
          <div className="w-[300px]">
            <Truncate value={title} className="flex text-xs" />
          </div>
        </Tooltip>
        <Button className="h-8" onClick={handleAdd}>
          Add
        </Button>
        <Tooltip title="Skip">
          <IconButton className="h-8 w-8" onClick={onSkip}>
            <XClose />
          </IconButton>
        </Tooltip>
      </header>
      <XYViz
        className="min-h-[200px] h-64 rounded-lg bg-gray-900"
        {...data}
        preview
      />
    </div>
  );
}

function defaultName(graphParams: { xAxis: string; yAxis: string }) {
  const normalize = (fieldName = '') =>
    removeMetadataPrefix(removeMetricsPrefix(fieldName)).toUpperCase();
  return `${normalize(graphParams.xAxis)} vs ${normalize(graphParams.yAxis)}`;
}

function calcDashboardHash(graphParams: UnifiedXYChartParams) {
  const xField = graphParams.xAxis || graphParams.xAxisField;
  const yField = graphParams.yAxis || graphParams.yAxisField;
  return calcGeneratedDashletHash({ xField, yField });
}
