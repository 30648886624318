import { UI_COMPONENTS, UIComponent } from '../../core/types/ui-components';

export type NodeName = UIComponent['name'];
export type NodeType = UIComponent['type'];
const SHAPE_TYPE_FUNC_NAME = [
  'ConcatinateFunc',
  'CropShape',
  'EmbeddingShapeFunc',
  'EmptyDim',
  'FilterChannelMultiplier',
  'FiltersChannels',
  'IdentityFunc',
  'MulAll',
  'PaddingFunction',
  'ReshapeFunc',
  'SpatialShapeCalc',
  'SpatialShapeCalcTranspose',
  'UnitChannel',
  'UpSamplingShapeFunc',
] as const;
type ShapeCalcFuncName = (typeof SHAPE_TYPE_FUNC_NAME)[number];
const RECEPTIVE_FIELDS_FUNC_NAME = [
  'CalculateSpatialReceptiveFields',
  'EmptyReceptiveFieldsFunc',
  'IdentityReceptiveFieldsFunc',
] as const;
type ReceptiveFieldsFuncName = (typeof RECEPTIVE_FIELDS_FUNC_NAME)[number];

type Tuple = Array<number> | Array<Tuple>;

export interface OutputBlock {
  block_node_id: string;
}

interface PropertyBase {
  name: string;
  values?: string[];
}

export type Property = PropertyBase &
  (
    | {
        type: 'bool';
        isdefault: true;
        default_val: boolean;
      }
    | {
        type: 'float';
        isdefault: true;
        default_val: number;
      }
    | {
        type: 'int';
        isdefault: boolean;
        default_val: number | null;
      }
    | {
        type: 'select';
        isdefault: boolean;
        default_val: string;
        values: string[];
      }
    | {
        type: 'str';
        isdefault: true;
        default_val: string;
      }
    | {
        type: 'tuple';
        isdefault: true;
        default_val: Tuple;
      }
  );

/** @deprecated please use the stricter type from `src/core/types/ui-components.ts` */
export interface NodeDescriptor {
  type: NodeType;
  shape_calc?: ShapeCalcFuncName[];
  receptive_fields_func?: ReceptiveFieldsFuncName;
  menu_sections: string[];
  inputs_data: {
    add_input_by_drag?: boolean;
    input_names_by_args?: boolean;
    inputs: Array<{
      name: string;
      approval_connection: string[];
    }>;
  };
  outputs_data: {
    outputs: Array<{
      name: string;
      approval_connection: string[]; // This is ignored!!!
    }>;
  };
  name: string;
  parents: string[];
  properties: Property[];
  options?: {
    representation_block: boolean;
    layer_inspection: boolean;
  };
  shape_rank?: number;
  hash?: {
    fields: string[];
    calcInputShape?: boolean;
  };
}

export function isValidNodeType(type: unknown): type is UIComponent['type'] {
  return typeof type === 'string' && UI_COMPONENTS.some((c) => c.type === type);
}
export function isValidNodeName(name: unknown): name is UIComponent['name'] {
  return typeof name === 'string' && UI_COMPONENTS.some((c) => c.name === name);
}

export const COMPONENT_DESCRIPTORS_MAP = new Map(
  UI_COMPONENTS.map((descriptor) => [
    // TODO: remvoe this cast
    descriptor.name as string,
    // TODO: remvoe this cast
    descriptor as unknown as NodeDescriptor,
  ]),
);
