import useSWR from 'swr';
import api from '../api-client';

export function useEngineSettings() {
  const { data, mutate } = useSWR('engine-settings', async () => {
    const { schema, values } = await api.getEngineSettings();
    return {
      schema: Object.fromEntries(
        schema.map(({ keyName, schema }) => [keyName, schema]),
      ),
      values: Object.fromEntries(
        values.map(({ keyName, value }) => [keyName, value]),
      ),
    };
  });

  return { data, mutate };
}
