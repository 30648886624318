import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCrownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M15.056 6.022a.87.87 0 0 0-.914-.126l-2.766 1.23-1.611-2.9a.875.875 0 0 0-1.53 0l-1.61 2.9-2.767-1.23a.875.875 0 0 0-1.207 1l1.391 5.924a.866.866 0 0 0 .738.666.884.884 0 0 0 .35-.024c2.532-.699 5.205-.699 7.737 0a.883.883 0 0 0 .942-.325.864.864 0 0 0 .146-.317l1.394-5.924a.87.87 0 0 0-.294-.874Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCrownIcon;
