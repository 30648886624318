import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { JobEvent, JobStatus, StatusEnum } from '@tensorleap/api-client';
import { upperFirst } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export interface EventsProgressBarProps {
  jobStatus: JobStatus;
  events?: Array<JobEvent>;
  isAnimationActive?: boolean;
}

export function EventsProgressBar({
  jobStatus,
  events: eventsParam,
  isAnimationActive = true,
}: EventsProgressBarProps): JSX.Element {
  const events = useMemo(
    () =>
      jobStatus === JobStatus.Failed &&
      eventsParam?.length &&
      eventsParam[eventsParam.length - 1]?.status === StatusEnum.Finished
        ? [
            ...(eventsParam || []),
            {
              id: 'Uncaught',
              name: 'Failed',
              status: StatusEnum.Failed,
            },
          ]
        : eventsParam,
    [eventsParam, jobStatus],
  );

  const [selectedEvent, setSelectedEvent] = useState<JobEvent>();

  useEffect(() => {
    setSelectedEvent(
      events?.find(({ status }) => status === 'STARTED' || status === 'FAILED'),
    );
  }, [events]);

  const handleEventClick = (event: JobEvent) => {
    setSelectedEvent(event);
  };

  if (!events?.length) {
    return (
      <span className="h-full flex items-center">
        {upperFirst(jobStatus.toLowerCase())}
      </span>
    );
  }

  const getProgressBarClass = (
    { status: eventStatus }: JobEvent,
    jobStatus: JobStatus,
  ) =>
    clsx(
      'flex-1 h-[0.4rem] min-w-[20px] cursor-pointer',
      eventStatus === StatusEnum.Finished
        ? 'bg-success-400'
        : eventStatus === StatusEnum.Failed
          ? 'bg-error-500'
          : eventStatus === StatusEnum.Unstarted
            ? 'bg-gray-500'
            : eventStatus === StatusEnum.Started
              ? jobStatus === JobStatus.Failed ||
                jobStatus === JobStatus.Terminated
                ? 'bg-error-500'
                : isAnimationActive
                  ? 'animate-pulse bg-colorful3-500'
                  : 'bg-colorful3-500'
              : '',
    );

  return (
    <div className="w-full h-full flex flex-row gap-3">
      <div className="flex-1 h-full flex flex-col gap-1 overflow-hidden">
        <div className="flex-1">
          <span className="text-colorful0-500">
            {selectedEvent?.status === 'FAILED' && 'Failed: '}
          </span>
          <span className="leading-8 truncate">
            {selectedEvent?.name || upperFirst(jobStatus)}
          </span>
        </div>

        <div className="flex-1 flex flex-row gap-1 overflow-x-auto">
          {events.map((jobEvent, index) => (
            <Tooltip key={index} title={jobEvent.name}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleEventClick(jobEvent);
                }}
                className={getProgressBarClass(jobEvent, jobStatus)}
              />
            </Tooltip>
          ))}
        </div>
      </div>
      {selectedEvent?.progress && (
        <div className="flex items-center">
          <span className="text-primary-500 text-2xl">
            {selectedEvent.progress.current}
          </span>
          <span className="text-gray-450 text-sm mt-2">
            {`/${selectedEvent.progress.total}`}
          </span>
        </div>
      )}
    </div>
  );
}
