import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgResourcesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m1.632 7.557 10.05 5.48a.798.798 0 0 0 .487.042c.056-.014.106-.028.148-.042l10.177-5.48c.24-.127.353-.332.34-.614a.667.667 0 0 0-.096-.35.63.63 0 0 0-.265-.242l-10.05-5.1a.672.672 0 0 0-.614 0l-10.156 5.1a.704.704 0 0 0-.275.243.616.616 0 0 0-.106.35c0 .281.12.486.36.613Zm10.473-4.952 8.591 4.38L12 11.661 3.41 6.986l8.695-4.38Zm10.241 8.718-2.01-1.016-1.438.783 1.692.868-8.696 4.676-8.59-4.676 1.798-.91-1.439-.783-2.137 1.058a.657.657 0 0 0-.37.603.616.616 0 0 0 .349.603l10.05 5.48a.75.75 0 0 0 .339.085c.056 0 .11-.007.159-.021a.928.928 0 0 0 .158-.064l10.157-5.48a.636.636 0 0 0 .36-.603c0-.275-.128-.476-.382-.603Zm0 4.74-1.904-.931-1.439.782 1.587.783-8.696 4.676-8.59-4.676 1.671-.761-1.439-.783-2.01.91a.657.657 0 0 0-.37.603.616.616 0 0 0 .349.603l10.05 5.48c.043.014.082.028.117.042a.605.605 0 0 0 .222.042c.056 0 .11-.007.159-.02a.928.928 0 0 0 .158-.064l10.157-5.48a.636.636 0 0 0 .36-.603c0-.276-.128-.477-.382-.604Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgResourcesIcon;
