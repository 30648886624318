import { useCallback } from 'react';
import clsx from 'clsx';
import { Node, SlimVersion } from '@tensorleap/api-client';

import { Button, createStyles, makeStyles, Tooltip } from '../ui/mui';
import { SaveAs, Target1, CollapseDownIcon } from '../ui/icons';
import { useFreemium } from '../trial/useFreemium';
import { NetworkNodeUiRefs } from './hooks';
import SvgSaveCommit from '../ui/icons/SaveCommit';
import { ConfirmDialog as OverrideSaveDialog } from '../ui/atoms/DeleteContentDialog';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useVersionControlPanelContext } from '../core/VersionControlPanelContext';
import { SaveVersionIcon } from '../core/SaveCommitLoading';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuButton: {
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.grey[600]}`,
      },
    },
    verticalButtonGroup: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.grey[600]}`,
      },
    },
  }),
);

type VerticalButtonGroupProps = {
  children: React.ReactNode;
  className?: string;
};

const VerticalButtonGroup = ({
  children,
  className,
}: VerticalButtonGroupProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.verticalButtonGroup, className)}>
      {children}
    </div>
  );
};

export type NetworkEditorToolsProps = {
  fitToScreen: (nodes: Node[], refs: Map<string, NetworkNodeUiRefs>) => void;
  isModelLayersGrouped?: boolean;
  currentSlimVersion?: SlimVersion;
  fetchVersions: () => Promise<void>;
};

export function NetworkEditorTools({
  fitToScreen,
  isModelLayersGrouped,
}: NetworkEditorToolsProps): JSX.Element {
  const classes = useStyles();
  const { runOrOpenUpgrade, upgradeDialog } = useFreemium();

  const {
    nodes,
    nodeRefs,
    handleToggleModelNodesGrouping,
    overrideSaveDialogIsOpen,
    setOverrideSaveDialogIsOpen,
    lockOverrideSaveDialogMsg,
    saveResultMsg,
    saveButtonState,
    saveIconTooltipTitle,
    handleSaveClicked,
    handleOverrideSaveClicked,
  } = useNetworkMapContext();

  const { handleSaveNewVersion } = useVersionControlPanelContext();

  const fitMapToScreen = useCallback(() => {
    fitToScreen(Array.from(nodes.values()), nodeRefs.current);
  }, [fitToScreen, nodeRefs, nodes]);

  const callFuncOrUpgradeDialog = useCallback(
    (func) => {
      runOrOpenUpgrade(() => func(true));
    },
    [runOrOpenUpgrade],
  );

  return (
    <div className="absolute inset-y-1/2 flex items-center">
      {upgradeDialog}
      <VerticalButtonGroup className="z-40">
        <Tooltip title="Center Map" placement="right" arrow>
          <Button
            onClick={fitMapToScreen}
            className={clsx('text-white bg-gray-700', classes.menuButton)}
          >
            <Target1 />
          </Button>
        </Tooltip>
        <Tooltip
          title={saveResultMsg || saveIconTooltipTitle}
          placement="right"
          arrow
        >
          <div className={clsx('text-white bg-gray-700', classes.menuButton)}>
            <Button onClick={() => handleSaveClicked()}>
              <SaveVersionIcon state={saveButtonState} />
            </Button>
          </div>
        </Tooltip>
        <Tooltip title="Save As New" placement="right" arrow>
          <Button
            onClick={() => callFuncOrUpgradeDialog(handleSaveNewVersion)}
            className={clsx('text-white bg-gray-700', classes.menuButton)}
          >
            <SaveAs />
          </Button>
        </Tooltip>
        <Tooltip
          title={`${
            isModelLayersGrouped ? 'Expand' : 'Collapse'
          } Hidden Layers`}
          placement="right"
          arrow
        >
          <Button
            onClick={handleToggleModelNodesGrouping}
            className={clsx(
              'text-white ',
              classes.menuButton,
              isModelLayersGrouped ? 'bg-dataset-500' : 'bg-gray-700',
            )}
          >
            <CollapseDownIcon />
          </Button>
        </Tooltip>
      </VerticalButtonGroup>
      <OverrideSaveDialog
        title={lockOverrideSaveDialogMsg}
        isOpen={overrideSaveDialogIsOpen}
        onClose={() => {
          setOverrideSaveDialogIsOpen(false);
        }}
        onConfirm={handleOverrideSaveClicked}
        confirmButtonText="Save"
        confirmButtonIcon={<SvgSaveCommit />}
      />
    </div>
  );
}
