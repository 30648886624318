import { AllSessionsTestResults, SessionTest } from '@tensorleap/api-client';
import { getFormattedModelTestName } from './modelTestHelpers';
import { isSessionTestResultSuccess } from './useModelTestSummaryResult';

const MODEL_TEST_SORT_DEFAULT_STATE = {
  successCount: 0,
  failedCount: 0,
  withErrorCount: 0,
  name: '',
};

type ModelTestSortState = typeof MODEL_TEST_SORT_DEFAULT_STATE;

export function sortModelTests(
  modelTests: SessionTest[],
  modelTestResult: AllSessionsTestResults[],
): SessionTest[] {
  const modelTestSortStateMap = new Map(
    modelTestResult.map((testResult) => [
      testResult.testId,
      createModelTestSortState(testResult),
    ]),
  );

  const testNamesMap = new Map(
    modelTests.map((modelTest) => [
      modelTest.cid,
      modelTest.name || getFormattedModelTestName(modelTest),
    ]),
  );

  function compare(test1: SessionTest, test2: SessionTest): number {
    const test1SortState = modelTestSortStateMap.get(test1.cid);
    const test2SortState = modelTestSortStateMap.get(test2.cid);

    const testResultComparison = compareModelTestSortState(
      test1,
      test2,
      test1SortState,
      test2SortState,
    );
    if (testResultComparison !== 0) return testResultComparison;

    const testName1 = testNamesMap.get(test1.cid);
    const testName2 = testNamesMap.get(test2.cid);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const namesCompration = testName1!.localeCompare(testName2!);
    if (namesCompration !== 0) return namesCompration;

    return test1.updatedAt.getTime() > test2.updatedAt.getTime() ? -1 : 1;
  }

  return modelTests.sort(compare);
}

function compareModelTestSortState(
  sessionTest1: SessionTest,
  sessionTest2: SessionTest,
  test1?: ModelTestSortState,
  test2?: ModelTestSortState,
): number {
  if (!test1 && !test2) {
    return sessionTest1.updatedAt.getTime() > sessionTest2.updatedAt.getTime()
      ? -1
      : 1;
  }
  if (!test1) {
    return -1;
  }
  if (!test2) {
    return -1;
  }

  const successComparison = test1.successCount - test2.successCount;
  if (successComparison !== 0) return successComparison;
  return test2.failedCount - test1.failedCount;
}

function createModelTestSortState(
  testResult: AllSessionsTestResults,
): ModelTestSortState {
  const state = { ...MODEL_TEST_SORT_DEFAULT_STATE };

  testResult.sessionsResults.forEach((testResultPerSession) => {
    if (isSessionTestResultSuccess(testResultPerSession)) {
      testResultPerSession.testSucceeded
        ? state.successCount++
        : state.failedCount++;
    } else {
      state.withErrorCount++;
    }
  });
  return state;
}
