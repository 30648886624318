import {
  Dialog,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Button } from './Button';
import { NumberOrString } from '@tensorleap/api-client';
import { Down, TimeType } from '../icons';
import { ClassNameProp } from '../../core/types';
import clsx from 'clsx';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';

export enum TimeRangeLabel {
  All = 'All times',
  CUSTOM = 'Select from calendar',
  Past15Mins = 'Past 15 minutes',
  Past1Hour = 'Past 1 hour',
  Past6Hours = 'Past 6 hours',
  Past12Hours = 'Past 12 hours',
  Past1Day = 'Past 1 day',
  Past1Week = 'Past 1 week',
  Past1Year = 'Past 1 year',
  Past1Month = 'Past 1 month',
}

type FilterObject = {
  gte: NumberOrString;
  lte: NumberOrString;
};

type DateTimeFilterOption = {
  label: TimeRangeLabel;
  value: string;
  filterObject?: FilterObject;
};

export type DateTimeValue = {
  label: TimeRangeLabel;
  filterObject?: FilterObject;
};

const TIME_LABEL_TO_TIME_SPAN: Record<TimeRangeLabel, string | undefined> = {
  [TimeRangeLabel.Past15Mins]: '15m',
  [TimeRangeLabel.Past1Hour]: '1h',
  [TimeRangeLabel.Past6Hours]: '6h',
  [TimeRangeLabel.Past12Hours]: '12h',
  [TimeRangeLabel.Past1Day]: '1d',
  [TimeRangeLabel.Past1Week]: '1w',
  [TimeRangeLabel.Past1Month]: '1mo',
  [TimeRangeLabel.Past1Year]: '1y',
  [TimeRangeLabel.All]: undefined,
  [TimeRangeLabel.CUSTOM]: undefined,
};

export const DATETIME_FILTER_OPTIONS: DateTimeFilterOption[] = [
  {
    label: TimeRangeLabel.All,
    value: 'All',
  },
  {
    label: TimeRangeLabel.Past15Mins,
    value: '15m',
    filterObject: {
      gte: 'now-15m/m', // Past 15 minutes
      lte: 'now/m',
    },
  },
  {
    label: TimeRangeLabel.Past1Hour,
    value: '1h',
    filterObject: {
      gte: 'now-1h/h', // Past 1 hour
      lte: 'now/h',
    },
  },
  {
    label: TimeRangeLabel.Past6Hours,
    value: '6h',
    filterObject: {
      gte: 'now-6h/h', // Past 6 hours
      lte: 'now/h',
    },
  },
  {
    label: TimeRangeLabel.Past12Hours,
    value: '12h',
    filterObject: {
      gte: 'now-12h/h', // Past 12 hours
      lte: 'now/h',
    },
  },
  {
    label: TimeRangeLabel.Past1Day,
    value: '1d',
    filterObject: {
      gte: 'now-1d/d', // Past 1 day
      lte: 'now/d',
    },
  },
  {
    label: TimeRangeLabel.Past1Week,
    value: '1w',
    filterObject: {
      gte: 'now-7d/d', // Past 1 week
      lte: 'now/d',
    },
  },
  {
    label: TimeRangeLabel.Past1Month,
    value: '1m',
    filterObject: {
      gte: 'now-1M/M', // Past 1 month
      lte: 'now/M',
    },
  },
  {
    label: TimeRangeLabel.Past1Year,
    value: '1y',
    filterObject: {
      gte: 'now-1y/y', // Past 1 year
      lte: 'now/y',
    },
  },
  {
    label: TimeRangeLabel.CUSTOM,
    value: 'custom',
  },
];

export type DateTimeRangeSelectorProps = {
  value: DateTimeValue;
  onChange: (value: DateTimeValue) => void;
  className?: string;
  label?: string;
  disabled?: boolean;
};

export function DateTimeRangeSelector({
  value,
  onChange,
  className,
  label,
  disabled,
}: DateTimeRangeSelectorProps) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleCustomConfirm = useCallback(
    (value: CustomRange) => {
      onChange({
        label: TimeRangeLabel.CUSTOM,
        filterObject: {
          gte: value.start.toISOString(),
          lte: value.end.toISOString(),
        },
      });
      setOpenDialog(false);
    },
    [onChange],
  );

  const handleSelectChange = useCallback(
    (selected) => {
      if (selected === TimeRangeLabel.CUSTOM) {
        setOpenDialog(true);
      } else {
        const selectedOption = DATETIME_FILTER_OPTIONS.find(
          (option) => option.label === selected,
        );
        if (selectedOption) {
          onChange({
            label: selectedOption.label,
            filterObject: selectedOption.filterObject,
          });
        }
      }
    },
    [onChange],
  );

  const renderSelectedValue = useCallback(
    (selected: unknown) => {
      let label = selected;
      if (selected === TimeRangeLabel.CUSTOM && value.filterObject) {
        const { gte, lte } = value.filterObject;
        const customStart = new Date(gte).toLocaleString();
        const customEnd = new Date(lte).toLocaleString();

        label = `${customStart} - ${customEnd}`;
      }
      return (
        <div className="flex gap-2 items-center">
          <DateTimeShortLabelIcon label={selected as TimeRangeLabel} />
          {label}
        </div>
      );
    },
    [value.filterObject],
  );

  return (
    <>
      <FormControl variant="standard">
        <Select
          value={value.label}
          label={label}
          className={className}
          IconComponent={Down}
          disabled={disabled}
          renderValue={renderSelectedValue}
          disableUnderline
        >
          {DATETIME_FILTER_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              value={option.label}
              onClick={() => handleSelectChange(option.label)}
            >
              <DateTimeShortLabelIcon {...option} className=" mr-2" />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomTimeRangeDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        handleCustomConfirm={handleCustomConfirm}
      />
    </>
  );
}

type CustomRange = {
  start: Date;
  end: Date;
};
interface CustomTimeRangeDialogProps {
  open: boolean;
  onClose: () => void;
  handleCustomConfirm: (value: CustomRange) => void;
}

const FORM_ID = 'range-form-id';

export function CustomTimeRangeDialog({
  open,
  onClose,
  handleCustomConfirm,
}: CustomTimeRangeDialogProps) {
  const { control, watch, handleSubmit } = useForm<CustomRange>();

  const fromValue = watch('start');
  const toValue = watch('end');

  const customError = useMemo(() => {
    if (fromValue && toValue && fromValue > toValue) {
      return 'Start time should be before end time';
    }
    return '';
  }, [fromValue, toValue]);

  const { field: startField } = useController({
    name: 'start',
    control,
    rules: { required: true },
  });
  const { field: endField } = useController({
    name: 'end',
    control,
    rules: { required: true },
  });

  const onSubmit = useCallback(
    (data) => {
      !customError && handleCustomConfirm(data);
    },
    [handleCustomConfirm, customError],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <LocalizationProvider dateAdapter={AdapterDateFns as any}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Select Custom Time Range</DialogTitle>
        <form
          id={FORM_ID}
          className="p-4 flex flex-col gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex gap-2">
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="FROM"
              value={startField.value ?? null}
              onChange={startField.onChange}
            />
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="TO"
              value={endField.value ?? null}
              onChange={endField.onChange}
            />
          </div>
          {customError && (
            <div className="text-error-500 text-sm">{customError}</div>
          )}
        </form>

        <DialogActions>
          <Button onClick={onClose} variant="text">
            Cancel
          </Button>
          <Button
            form={FORM_ID}
            disabled={!!customError}
            type="submit"
            variant="filled"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
}

type NumericRange = { gte: number; lte: number };

export function toNumericRange(value: DateTimeValue): NumericRange {
  if (value.label === TimeRangeLabel.All) {
    throw new Error('Cannot convert All time range to numeric range');
  }
  if (value.label === TimeRangeLabel.CUSTOM && value.filterObject) {
    const { gte, lte } = value.filterObject;
    return {
      gte: new Date(gte).getTime(),
      lte: new Date(lte).getTime(),
    };
  }
  const to = Date.now();
  let from;

  switch (value.label) {
    case TimeRangeLabel.Past15Mins:
      from = to - 15 * 60 * 1000;
      break;
    case TimeRangeLabel.Past1Hour:
      from = to - 60 * 60 * 1000;
      break;
    case TimeRangeLabel.Past6Hours:
      from = to - 6 * 60 * 60 * 1000;
      break;
    case TimeRangeLabel.Past12Hours:
      from = to - 12 * 60 * 60 * 1000;
      break;
    case TimeRangeLabel.Past1Day:
      from = to - 24 * 60 * 60 * 1000;
      break;
    case TimeRangeLabel.Past1Week:
      from = to - 7 * 24 * 60 * 60 * 1000;
      break;
    case TimeRangeLabel.Past1Month:
      from = to - 30 * 24 * 60 * 60 * 1000;
      break;
    case TimeRangeLabel.Past1Year:
      from = to - 365 * 24 * 60 * 60 * 1000;
      break;
    default:
      throw new Error(`Invalid time range label: ${value.label}`);
  }
  return { gte: from, lte: to };
}

function DateTimeShortLabelIcon({
  label,
  className,
}: { label: TimeRangeLabel } & ClassNameProp): JSX.Element {
  const shortLabel = TIME_LABEL_TO_TIME_SPAN[label];
  const icon =
    shortLabel || (label === TimeRangeLabel.CUSTOM ? <TimeType /> : 'All');
  return (
    <span
      className={clsx(
        'flex h-7 font-bold w-10 rounded justify-center items-center bg-gray-850 text-gray-200',
        className,
      )}
    >
      {icon}
    </span>
  );
}
