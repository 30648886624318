import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPullFiltersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.61 16.298c-.368.37-.87.577-1.391.577H4.78a1.969 1.969 0 0 1-1.968-1.969V7.594A1.969 1.969 0 0 1 4.78 5.625h1.407a.563.563 0 0 1 0 1.125H4.78a.844.844 0 0 0-.843.844v7.312a.844.844 0 0 0 .843.844h8.438a.844.844 0 0 0 .844-.844V7.594a.844.844 0 0 0-.844-.844h-1.406a.562.562 0 1 1 0-1.125h1.406a1.969 1.969 0 0 1 1.969 1.969v7.312c0 .522-.208 1.023-.577 1.392Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.398 12.773a.562.562 0 0 1-.796 0L5.79 9.96a.563.563 0 0 1 .795-.795L9 11.58l2.415-2.415a.562.562 0 1 1 .795.795l-2.812 2.813Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12.375a.562.562 0 0 1-.563-.563V1.688a.563.563 0 0 1 1.126 0v10.124c0 .311-.252.563-.563.563Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPullFiltersIcon;
