import clsx from 'clsx';
import { IconButton } from '../../../ui/mui';
import { Settings, PushFiltersIcon } from '../../../ui/icons';
import { VisualizationFilter } from '../../../core/types/filters';
import { Divider } from '../../Divider';
import { FilterList } from '../../../filters/FilterList';
import { FilterFieldMeta } from '../../../filters/helpers';
import { stopPropagation } from '../../../core/stopPropagation';
import { TopBarRightButtons } from '../topbar/TopBarRightButtons';
import { useCallback } from 'react';

type AnalyticsDashletTopBarProps = {
  dashletId: string;
  name: string;
  className?: string;
  editMode: boolean;
  filterFieldsMeta: FilterFieldMeta[];
  dashletFilters: VisualizationFilter[];
  toggleEditMode: () => void;
  remove: () => void;
  duplicate: () => void;
  globalizeFilters: (dashletId: string) => void;
  updateDashletFilters: (f: VisualizationFilter[]) => Promise<void>;
  fullScreenMode: boolean;
  toggleFullScreenMode: () => void;
};

export function AnalyticsDashletTopBar({
  dashletId,
  name,
  editMode,
  className,
  toggleEditMode,
  filterFieldsMeta,
  remove,
  duplicate,
  globalizeFilters,
  dashletFilters,
  updateDashletFilters,
  fullScreenMode,
  toggleFullScreenMode,
}: AnalyticsDashletTopBarProps) {
  const handleGlobalizeFiltersClick = useCallback(() => {
    globalizeFilters(dashletId);
  }, [dashletId, globalizeFilters]);

  return (
    <header
      className={clsx(
        'flex items-center gap-2 h-12 w-full bg-gray-850 draggable-dashlet allow-dragging',
        className,
      )}
    >
      {!editMode && (
        <IconButton
          className="p-1 h-10 w-10"
          onClick={toggleEditMode}
          onMouseDown={stopPropagation}
        >
          <Settings />
        </IconButton>
      )}
      <p className="flex items-center py-2 font-bold text-xs uppercase">
        {!editMode && name}
      </p>
      <div className="flex-1 items-center flex min-w-0">
        {!editMode && (
          <Divider vertical className="my-4 bg-gray-400 opacity-75" />
        )}
        <FilterList
          filterFieldsMeta={filterFieldsMeta}
          filters={dashletFilters}
          onFiltersChange={updateDashletFilters}
          extraContent={
            <IconButton
              title="Push Filters Back to Dashboard"
              className="w-8 h-8 bg-success-600 hover:bg-success-600"
              onClick={handleGlobalizeFiltersClick}
            >
              <PushFiltersIcon className="w-5 h-5 m-0 p-0" />
            </IconButton>
          }
        />
      </div>
      <TopBarRightButtons
        remove={remove}
        duplicate={duplicate}
        fullScreenMode={fullScreenMode}
        toggleFullScreenMode={toggleFullScreenMode}
      />
    </header>
  );
}
