import { GraphValidatorData, ValidatedNode } from '@tensorleap/api-client';
import {
  OneOfGraphErrorTypes,
  GraphNodeErrorFromValidateAssetsType,
  ValidateAssetsErrorType,
} from '../wizard/errors';
import { GraphErrorKind } from '../wizard/types';

const VALIDATE_NODES_KEYS = new Set<string>([
  'inputs',
  'ground_truths',
  'prediction_types',
  'visualizers',
  'losses',
  'metrics',
  'metadata',
]);

export function extractValidateAssetsErrors(
  graphValidatorData: GraphValidatorData | undefined,
): OneOfGraphErrorTypes[] {
  if (graphValidatorData === undefined) return [];

  const res = Object.entries(graphValidatorData)
    .filter(([keyName, _]) => VALIDATE_NODES_KEYS.has(keyName))
    .map(([keyName, data]) => createNodeErrors(data, keyName))
    .flat();

  if (graphValidatorData.general_error) {
    res.push(createGeneralError(graphValidatorData.general_error));
  }

  return res;
}
function createNodeErrors(
  validatedNodesData: ValidatedNode[],
  title: string,
): (GraphNodeErrorFromValidateAssetsType | ValidateAssetsErrorType)[] {
  return validatedNodesData
    .filter(({ error }) => error !== undefined)
    .map(({ error, node_id: nodeId }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const msg = error!; // its safe since we used filter before the mapping

      const isValidateAssetsError = true;

      if (!nodeId) {
        return {
          title,
          msg,
          type: GraphErrorKind.validateAssets,
          isValidateAssetsError,
        };
      }

      return {
        title,
        msg,
        type: GraphErrorKind.node,
        nodeId,
        isValidateAssetsError,
      };
    });
}

function createGeneralError(msg: string): ValidateAssetsErrorType {
  return {
    title: 'general error',
    msg,
    type: GraphErrorKind.validateAssets,
    isValidateAssetsError: true,
  };
}
