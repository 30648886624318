import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgEnterFullScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.727A.727.727 0 0 1 1.727 1h4.364a.727.727 0 1 1 0 1.455H2.455V6.09A.727.727 0 0 1 1 6.09V1.727Zm10.182 0A.727.727 0 0 1 11.909 1h4.364a.727.727 0 0 1 .727.727v4.364a.727.727 0 1 1-1.454 0V2.455h-3.637a.727.727 0 0 1-.727-.728Zm-9.455 9.455a.727.727 0 0 1 .728.727v3.636H6.09a.727.727 0 1 1 0 1.455H1.727A.727.727 0 0 1 1 16.273v-4.364a.727.727 0 0 1 .727-.727Zm14.546 0a.727.727 0 0 1 .727.727v4.364a.727.727 0 0 1-.727.727h-4.364a.727.727 0 1 1 0-1.454h3.636v-3.637a.727.727 0 0 1 .728-.727Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEnterFullScreenIcon;
