import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

const isMac = /Mac|iMac|Macintosh/i.test(navigator.userAgent);

export const CtrlName = isMac ? 'command ⌘' : 'Ctrl';
export const AltName = isMac ? 'option ⌥' : 'Alt';
export const ShiftName = 'shift ⇧';
export const SpaceName = 'Space';

export type PressedKeys = {
  isSpacePressed: boolean;
  isAltPressed: boolean;
  isCtrlPressed: boolean;
  isShiftPressed: boolean;
};

function useKeyComboListener(mouseIn: boolean): PressedKeys {
  const mouseInRef = useRef(mouseIn);
  mouseInRef.current = mouseIn;

  const [pressedKeys, setPressedKeys] = useState<PressedKeys>({
    isSpacePressed: false,
    isAltPressed: false,
    isCtrlPressed: false,
    isShiftPressed: false,
  });

  const update = useCallback((event: KeyboardEvent, isOnKeyDown: boolean) => {
    const isAltPressed = event.altKey;
    const isCtrlPressed = event.metaKey || event.ctrlKey;
    const isShiftPressed = event.shiftKey;

    setPressedKeys((prev) => {
      const newState = isOnKeyDown
        ? {
            isSpacePressed: isSpace(event) ? true : prev.isSpacePressed,
            isAltPressed: isAltPressed,
            isCtrlPressed: isCtrlPressed,
            isShiftPressed: isShiftPressed,
          }
        : {
            isSpacePressed: isSpace(event) ? false : prev.isSpacePressed,
            isAltPressed: isAltPressed ? prev.isAltPressed : false,
            isCtrlPressed: isCtrlPressed ? prev.isCtrlPressed : false,
            isShiftPressed: isShiftPressed ? prev.isShiftPressed : false,
          };

      return isEqual(prev, newState) ? prev : newState;
    });
  }, []);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      update(event, false);
    },
    [update],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isSpace(event)) {
        const elm = event.target as HTMLElement;
        const tagName = elm.tagName.toLowerCase();
        if (
          elm.isContentEditable ||
          tagName === 'input' ||
          tagName === 'textarea'
        ) {
          return; // Allow the space bar in inputs, textareas, and contentEditable elements
        }
        event.preventDefault(); // Prevent scrolling with the space bar
      }
      if (!mouseInRef.current) return;
      update(event, true);
    },
    [update],
  );

  // General keyUp event listener to manage state reset when keys are released
  useEffect(() => {
    // Adding the general keyUp listener to the window/document
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyUp, handleKeyDown]);

  return pressedKeys;
}

export default useKeyComboListener;

function isSpace(event: KeyboardEvent): boolean {
  return event.key === ' ' || event.code === 'Space';
}
