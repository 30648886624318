import { OrderType } from '@tensorleap/api-client';
import { enumToOptions, OptionDefaultObj } from '../../atoms/utils/select';
import { DataDistributionType } from '../../../dashboard/dashlet/Analytics/types';

export const dataDistributionOptions: DataDistributionType[] = [
  'distinct',
  'continuous',
];

export function dataDistributionValByType(
  dataDistributionType: DataDistributionType,
) {
  return dataDistributionType === 'distinct' ? 'terms' : 'histogram';
}

export const orderOptions: OptionDefaultObj[] = [
  { label: 'Descending', value: OrderType.Desc },
  { label: 'Ascending', value: OrderType.Asc },
];

export enum OrientationType {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum SliceSizeByType {
  UniqCount = 'uniq_count',
  Count = 'count',
  Sum = 'sum',
}
export const orientationOptions = enumToOptions(OrientationType);

export const SliceSizeByOptions = enumToOptions(SliceSizeByType);
