// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class HeatmapData {
  bb: flatbuffers.ByteBuffer | null = null;
  bb_pos = 0;
  __init(i: number, bb: flatbuffers.ByteBuffer): HeatmapData {
    this.bb_pos = i;
    this.bb = bb;
    return this;
  }

  static getRootAsHeatmapData(
    bb: flatbuffers.ByteBuffer,
    obj?: HeatmapData,
  ): HeatmapData {
    return (obj || new HeatmapData()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  static getSizePrefixedRootAsHeatmapData(
    bb: flatbuffers.ByteBuffer,
    obj?: HeatmapData,
  ): HeatmapData {
    bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
    return (obj || new HeatmapData()).__init(
      bb.readInt32(bb.position()) + bb.position(),
      bb,
    );
  }

  data(index: number): number | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? this.bb!.readFloat32(
          this.bb!.__vector(this.bb_pos + offset) + index * 4,
        )
      : 0;
  }

  dataLength(): number {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
  }

  dataArray(): Float32Array | null {
    const offset = this.bb!.__offset(this.bb_pos, 4);
    return offset
      ? new Float32Array(
          this.bb!.bytes().buffer,
          this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset),
          this.bb!.__vector_len(this.bb_pos + offset),
        )
      : null;
  }

  width(): number {
    const offset = this.bb!.__offset(this.bb_pos, 6);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  height(): number {
    const offset = this.bb!.__offset(this.bb_pos, 8);
    return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
  }

  static startHeatmapData(builder: flatbuffers.Builder) {
    builder.startObject(3);
  }

  static addData(builder: flatbuffers.Builder, dataOffset: flatbuffers.Offset) {
    builder.addFieldOffset(0, dataOffset, 0);
  }

  static createDataVector(
    builder: flatbuffers.Builder,
    data: number[] | Float32Array,
  ): flatbuffers.Offset;
  /**
   * @deprecated This Uint8Array overload will be removed in the future.
   */
  static createDataVector(
    builder: flatbuffers.Builder,
    data: number[] | Uint8Array,
  ): flatbuffers.Offset;
  static createDataVector(
    builder: flatbuffers.Builder,
    data: number[] | Float32Array | Uint8Array,
  ): flatbuffers.Offset {
    builder.startVector(4, data.length, 4);
    for (let i = data.length - 1; i >= 0; i--) {
      builder.addFloat32(data[i]!);
    }
    return builder.endVector();
  }

  static startDataVector(builder: flatbuffers.Builder, numElems: number) {
    builder.startVector(4, numElems, 4);
  }

  static addWidth(builder: flatbuffers.Builder, width: number) {
    builder.addFieldInt32(1, width, 0);
  }

  static addHeight(builder: flatbuffers.Builder, height: number) {
    builder.addFieldInt32(2, height, 0);
  }

  static endHeatmapData(builder: flatbuffers.Builder): flatbuffers.Offset {
    const offset = builder.endObject();
    return offset;
  }

  static finishHeatmapDataBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset);
  }

  static finishSizePrefixedHeatmapDataBuffer(
    builder: flatbuffers.Builder,
    offset: flatbuffers.Offset,
  ) {
    builder.finish(offset, undefined, true);
  }

  static createHeatmapData(
    builder: flatbuffers.Builder,
    dataOffset: flatbuffers.Offset,
    width: number,
    height: number,
  ): flatbuffers.Offset {
    HeatmapData.startHeatmapData(builder);
    HeatmapData.addData(builder, dataOffset);
    HeatmapData.addWidth(builder, width);
    HeatmapData.addHeight(builder, height);
    return HeatmapData.endHeatmapData(builder);
  }
}
