import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M9 15.667c3.673 0 6.667-2.994 6.667-6.667 0-3.673-2.994-6.667-6.667-6.667-3.673 0-6.667 2.694-6.667 6 0 2.207 1.794 4 4 4h1.18a.33.33 0 0 1 .334.334c0 .08-.034.153-.087.22A1.696 1.696 0 0 0 7.333 14 1.667 1.667 0 0 0 9 15.667Zm0-12A5.34 5.34 0 0 1 14.333 9 5.34 5.34 0 0 1 9 14.333.33.33 0 0 1 8.667 14a.36.36 0 0 1 .093-.233 1.655 1.655 0 0 0-.068-2.279A1.667 1.667 0 0 0 7.513 11h-1.18a2.666 2.666 0 0 1-2.666-2.667C3.667 5.76 6.06 3.667 9 3.667Z"
      fill="currentColor"
    />
    <path
      d="M12.667 9.667a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM10.667 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM7.333 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM5.333 9.667a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgColorIcon;
