import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useCallback, useMemo, useState } from 'react';
import api, { getBaseUrl } from '../core/api-client';
import { CopyButton } from './atoms/CopyButton';
import { useEnvironmentInfo } from '../core/EnvironmentInfoContext';

export function useCliTokenDialog() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openCliTokenDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const cliTokenDialog = useMemo(
    () =>
      isOpen ? (
        <GenerateCliToken isOpen={isOpen} onClose={() => setIsOpen(false)} />
      ) : null,
    [isOpen],
  );

  return {
    cliTokenDialog,
    openCliTokenDialog,
  };
}

export function GenerateCliToken({
  onClose,
  isOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const [hasTokenGenerated, setHasTokenGenerated] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>();

  const suggestGenerateTokenDialogContent = useMemo(() => {
    return (
      <div>
        {
          <div>
            <p>
              This operation will disable your old CLI token if exists, are you
              sure you want to continue?
            </p>

            <div className="flex flex-row justify-between items-center my-1 mx-0">
              <Button
                onClick={async () => {
                  try {
                    setHasTokenGenerated(true);
                    const { apiKey } = await api.keyGen();
                    setApiKey(apiKey);
                  } catch (err) {
                    setHasTokenGenerated(false);
                    console.error(err);
                  }
                }}
                className="bg-error-500"
              >
                Yes, disable my old token and generate a new one
              </Button>

              <Button onClick={onClose} className="bg-gray-800">
                Cancel
              </Button>
            </div>
          </div>
        }
      </div>
    );
  }, [onClose]);

  const copyTokenDialogContent = useMemo(
    () => (apiKey ? <ShowApiToken apiKey={apiKey} /> : <p>loading...</p>),
    [apiKey],
  );

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Generate CLI Token</DialogTitle>
        <DialogContent>
          <div className="flex flex-1 flex-row justify-center items-center">
            {hasTokenGenerated
              ? copyTokenDialogContent
              : suggestGenerateTokenDialogContent}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function ShowApiToken({ apiKey }: { apiKey: string }) {
  const { environmentInfo } = useEnvironmentInfo();

  const showApiWithLocalhost = !!environmentInfo.proxyUrl;

  const url = getBaseUrl();
  const loginCommand = buildLoginCommand(url, apiKey);

  const localhostApiUrl = environmentInfo.gatewayUrl;
  const localhostLoginCommand = buildLoginCommand(localhostApiUrl, apiKey);

  const currentUrlDescription = showApiWithLocalhost
    ? 'If you are running the CLI remotely, use this command'
    : 'Use this command to authenticate the CLI';

  return (
    <div className="flex flex-col gap-4">
      <CopySection command={loginCommand} description={currentUrlDescription} />
      {showApiWithLocalhost && (
        <CopySection
          command={localhostLoginCommand}
          description="If you are running the CLI locally, use this command instead"
        />
      )}
    </div>
  );
}

function buildLoginCommand(apiUrl: string, apiKey: string) {
  return `leap auth login ${apiUrl} -k ${apiKey}`;
}

function CopySection({
  command,
  description,
}: {
  command: string;
  description: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      <p className="px-2">{description}</p>
      <div className="flex flex-row justify-center items-center border p-2 border-gray-600 rounded bg-gray-850">
        <div>{command}</div>
        <CopyButton textToCopy={command} />
      </div>
    </div>
  );
}
