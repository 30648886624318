import clsx from 'clsx';
import { TEXT_ALIGN_CLASSES } from './table-helpers';
import { Align, CellComponentType } from './table-types';
import { BaseItem, ModelFieldUnion } from '../types';

import { isComputedField } from '../utils';
import { ComputedModelField } from '../model-field-types';

const BASE_CELL_CLASS_NAME = 'px-3';

function createComputedFieldCell<Item extends BaseItem>(
  field: ComputedModelField<Item>,
  align: Align,
  cellClassName = BASE_CELL_CLASS_NAME,
): CellComponentType<Item> {
  return function Cell({ item }) {
    return (
      <td className={clsx(cellClassName, TEXT_ALIGN_CLASSES[align])}>
        {field.format(item)}
      </td>
    );
  };
}

export function createCell<Item extends BaseItem>(
  field: ModelFieldUnion<Item>,
  align: Align,
  cellClassName = BASE_CELL_CLASS_NAME,
): CellComponentType<Item> {
  if (isComputedField(field)) {
    return createComputedFieldCell(field, align, cellClassName);
  }

  const { accessorKey, format } = field;
  return function Cell({ item }: { item: Item }) {
    const value = item[accessorKey];
    return (
      <td className={clsx(cellClassName, TEXT_ALIGN_CLASSES[align])}>
        {format ? format(value, item, accessorKey) : value}
      </td>
    );
  };
}
