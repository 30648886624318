import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { stopPropagation } from '../../../core/stopPropagation';
import { DashletFormHeader } from '../Analytics/form/FormFields';
import {
  DEFAULT_NUM_OF_SAMPLES,
  METADATA_PREFIX,
  METRICS_PREFIX,
  useDashletScatterContext,
} from './DashletScatterContext';

import {
  OptionalAnalysis,
  PopulationExplorationDashletData,
  PopulationExplorationDisplayParams,
  ReductionAlgorithm,
} from '@tensorleap/api-client';
import { useController, useForm } from 'react-hook-form';
import { Input } from '../../../ui/atoms/Input';
import {
  parsePositiveInt,
  removeMetadataPrefix,
} from '../../../actions-dialog/helper-functions';
import { SelectMultiple } from '../../../ui/atoms/SelectMultiple';
import { SimpleAutocomplete } from '../../../ui/molecules/SimpleAutocomplete';
import { BboxIcon, Up, Down } from '../../../ui/icons';
import { useDebounce } from '../../../core/useDebounce';
import { Divider } from '../../Divider';
import { Flag } from '../../../actions-dialog/RunModel/Flags';
import { InfoGuide } from '../../../ui/atoms/InfoGuide';
import { Collapse } from '@material-ui/core';

const MIN_NUM_OF_SAMPLES = 1;
const MAX_NUM_OF_SAMPLES = 4000;

interface PopExpSettingsForm {
  name: string;
  numOfSamples: string;
  projectionMetric?: string;
  domainGapMetadata?: string;
  balanceBy: string[];
  additionalAnalysis?: OptionalAnalysis[];
  reductionAlgorithm: string;
  shouldFillRemainingWithUnbalance: boolean;
}

function buildOptions(options: string[]) {
  return [
    { value: undefined, label: 'None' },
    ...options.map((option) => ({
      value: option,
      label: option?.startsWith(METRICS_PREFIX)
        ? option.split(METRICS_PREFIX)[1]
        : option?.startsWith(METADATA_PREFIX)
          ? option.split(METADATA_PREFIX)[1]
          : option,
    })),
  ];
}

const FORM_ID = 'pop-exp-settings-form';
export interface PopExpSettingsProps {
  update: (data: PopulationExplorationDashletData) => Promise<void>;
  cancel: () => void;
  className?: string;
}

export interface PopExpSettingsFields {
  projectionMetric?: string;
  projectionMetricsFieldsNames: string[];
  domainGapMetadata?: string;
  domainGapMetadataFieldsNames: string[];
  displayParams: {
    optional_analysis?: string[];
  } & PopulationExplorationDisplayParams;
  balanceFieldsNames: string[];
  dashletName: string;
}

export function PopExpSettings({
  update,
  cancel,
  className,
}: PopExpSettingsProps): JSX.Element {
  const {
    settings: {
      projectionMetric,
      projectionMetricsFieldsNames,
      domainGapMetadata,
      domainGapMetadataFieldsNames,
      displayParams,
      balanceFieldsNames,
      dashletName,
    },
  } = useDashletScatterContext();

  const {
    register,
    handleSubmit,
    setFocus,
    reset: _reset,
    formState: { isValid, errors },
    control,
    watch,
  } = useForm<PopExpSettingsForm>({
    mode: 'onSubmit',
    defaultValues: {
      name: dashletName,
      numOfSamples: displayParams.population_exploration_n_samples.toString(),
    },
  });

  const {
    field: { ...projectionMetricField },
  } = useController({
    control,
    name: 'projectionMetric',
    defaultValue: projectionMetric,
  });

  const {
    field: { ...domainGapMetadataField },
  } = useController({
    control,
    name: 'domainGapMetadata',
    defaultValue: domainGapMetadata,
  });

  const {
    field: { ...reduceAlgorithmField },
  } = useController({
    control,
    name: 'reductionAlgorithm',
    defaultValue: displayParams.reduction_algorithm,
  });

  const {
    field: { ...balanceField },
  } = useController({
    control,
    name: 'balanceBy',
    rules: {
      validate: (value) =>
        (value.length >= 0 && value.length <= 2) ||
        'Maximum of 2 values allowed',
    },
    defaultValue: displayParams.balance_by,
  });

  const {
    field: { ...shouldFillRemainingWithUnbalanceField },
  } = useController({
    control,
    name: 'shouldFillRemainingWithUnbalance',
    defaultValue: displayParams.should_fill_remaining_with_unbalance,
  });

  const {
    field: { ...additonalAnalysisField },
  } = useController({
    control,
    name: 'additionalAnalysis',
    defaultValue: displayParams.optional_analysis,
  });

  const projectionMetricOptions = useMemo(
    () => buildOptions(projectionMetricsFieldsNames),
    [projectionMetricsFieldsNames],
  );

  const domainGapMetadataFieldValue = watch('domainGapMetadata');

  const domainGapMetadataOptions = useMemo(() => {
    const options =
      domainGapMetadataFieldValue &&
      !domainGapMetadataFieldsNames?.includes(domainGapMetadataFieldValue)
        ? [domainGapMetadataFieldValue, ...domainGapMetadataFieldsNames]
        : domainGapMetadataFieldsNames;
    return buildOptions(options);
  }, [domainGapMetadataFieldsNames, domainGapMetadataFieldValue]);

  const reset = useCallback(() => {
    _reset({
      name: dashletName,
      numOfSamples: DEFAULT_NUM_OF_SAMPLES.toString(),
      projectionMetric: undefined,
      domainGapMetadata: undefined,
      reductionAlgorithm: ReductionAlgorithm.Tsne,
      balanceBy: [],
      shouldFillRemainingWithUnbalance: true,
    });
  }, [_reset, dashletName]);

  const onSubmit = useCallback(
    async (data: PopExpSettingsForm) => {
      if (!isValid) {
        console.error('Invalid form');
        return;
      }

      const parsedNumber = parsePositiveInt(data.numOfSamples);

      if (typeof parsedNumber !== 'number') {
        console.error('Invalid number of samples');
        return;
      }

      const displayParams: PopulationExplorationDisplayParams = {
        balance_by: data.balanceBy.sort(),
        should_fill_remaining_with_unbalance:
          data.shouldFillRemainingWithUnbalance,
        population_exploration_n_samples: parsedNumber,
        reduction_algorithm: data.reductionAlgorithm as ReductionAlgorithm,
        ...(data?.additionalAnalysis?.length
          ? { optional_analysis: data.additionalAnalysis?.sort() }
          : {}),
      };

      await update({
        name: register('name').name,
        type: 'PopulationExploration',
        data: {
          name: data.name,
          projectionMetric: data.projectionMetric,
          domainGapMetadata: data.domainGapMetadata,
          numOfSamples: parsedNumber,
          displayParams,
        },
      });
    },
    [isValid, update, register],
  );

  const debounceApply = useDebounce(onSubmit, 200);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(debounceApply)());
    return () => subscription.unsubscribe();
  }, [watch, debounceApply, handleSubmit]);

  const [advancedOpen, setAdvancedOpen] = useState(false);

  const toggleAdvanced = () => setAdvancedOpen((prev) => !prev);

  return (
    <form
      className={clsx(
        'flex flex-row bg-gray-800 w-120 rounded-l-2xl',
        className,
      )}
      id={FORM_ID}
      onMouseDown={stopPropagation}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex overflow-hidden flex-1 flex-col gap-2 py-3 px-6 pb-4 border-r border-r-gray-700 w-full">
        <DashletFormHeader
          graphType={'Population Exploration'}
          reset={reset}
          cancel={cancel}
        />
        <div className="flex flex-col gap-4 pt-2 h-full overflow-y-auto overflow-x-hidden">
          <Input
            {...register('name')}
            className="w-full"
            label="Name"
            onFocus={() => setFocus('name')}
          />

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <Input
              label="NUMBER OF SAMPLES"
              type="number"
              min={MIN_NUM_OF_SAMPLES}
              max={MAX_NUM_OF_SAMPLES}
              containerProps={{ className: 'w-full' }}
              error={errors.numOfSamples?.message}
              {...register('numOfSamples', {
                required: { value: true, message: 'Value is required' },
                min: {
                  value: MIN_NUM_OF_SAMPLES,
                  message: `Value must be greater or equal to ${MIN_NUM_OF_SAMPLES}`,
                },
                max: {
                  value: MAX_NUM_OF_SAMPLES,
                  message: `Value must be less or equal to ${MAX_NUM_OF_SAMPLES}`,
                },
              })}
            />
            <InfoGuide title="Set the number of samples to be displayed in the population exploration" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <SimpleAutocomplete
              title="Projection metric"
              label="PROJECTION METRIC"
              small={false}
              clean={false}
              options={projectionMetricOptions}
              icon={<BboxIcon />}
              className="w-full flex-1"
              {...projectionMetricField}
            />
            <InfoGuide title="Use a dimensionality reduction technique that optimizes the distribution of values across a selected metric" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <SimpleAutocomplete
              title="Domain Gap Metadata"
              label="DOMAIN GAP METADATA"
              small={false}
              clean={false}
              options={domainGapMetadataOptions}
              icon={<BboxIcon />}
              className="w-full flex-1"
              value={domainGapMetadataFieldValue}
              onChange={domainGapMetadataField.onChange}
            />
            <InfoGuide title="Choose the metadata on which you want to analyze the domain gap" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <SimpleAutocomplete
              title="Reduction Algorithm"
              label="REDUCTION ALGORITHM"
              small={false}
              clean={false}
              options={[
                { value: ReductionAlgorithm.Pca, label: 'PCA' },
                { value: ReductionAlgorithm.Tsne, label: 'TSNE' },
              ]}
              icon={<BboxIcon />}
              className="w-full flex-1"
              {...reduceAlgorithmField}
            />
            <InfoGuide title="Select the algorithm to use for dimensionality reduction" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <button
              type="button"
              className="flex items-center gap-2 text-sm text-gray-400"
              onClick={toggleAdvanced}
            >
              {advancedOpen ? <Up /> : <Down />}
              Advanced Options
            </button>
          </div>

          <Collapse in={advancedOpen}>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row w-full gap-2 items-center">
                <SelectMultiple
                  label={'Metadata Based Balancing'}
                  options={balanceFieldsNames}
                  optionToLabel={removeMetadataPrefix}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  error={(errors.balanceBy as any)?.message}
                  {...balanceField}
                  value={balanceField.value || []}
                  className="w-full flex-1"
                />

                <InfoGuide title="Create a balanced population exploration with equal amounts of samples from each value of the selected metadata" />
              </div>

              {balanceField.value?.length > 0 && (
                <div className="flex flex-row w-full gap-1 -mt-4 items-center">
                  <Flag
                    title="Approximate Balance"
                    subtitle={null}
                    {...shouldFillRemainingWithUnbalanceField}
                  />
                  <InfoGuide title="When enabled, if balancing is unable to produce enough samples, the rest of the samples are added in an unbalanced manner" />
                </div>
              )}

              <div className="flex flex-row w-full gap-2 items-center">
                <SelectMultiple
                  label={'Additional Analysis'}
                  options={Object.values(OptionalAnalysis).map((value) => ({
                    label: value,
                    value: value,
                  }))}
                  error={errors.additionalAnalysis?.message}
                  {...additonalAnalysisField}
                  value={additonalAnalysisField.value || []}
                  className="w-full flex-1"
                />

                <InfoGuide title="Create an additional analysis" />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </form>
  );
}
