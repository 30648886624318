import { SessionTest } from '@tensorleap/api-client';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { VisualizationFilter } from '../core/types/filters';
import { mapToVisualizationFilters, mapToEsFilters } from './modelTestHelpers';
import { ModelTestsFormProps } from './ModelTestsCard';
import { ModelTestsCardContentDatasetFilters } from './ModelTestsCardContentDatasetFilters';

export interface DatasetFiltersControllerProps {
  modelTest: SessionTest;
  editable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<ModelTestsFormProps, any>;
}

export function DatasetFiltersController({
  modelTest,
  editable,
  form,
}: DatasetFiltersControllerProps): JSX.Element {
  const [testFilters, setTestFilters] = useState<VisualizationFilter[]>([]);

  const { control } = form;
  const {
    field: { onChange },
  } = useController({
    name: 'datasetFilter',
    control,
    defaultValue: modelTest.datasetFilter,
  });

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const handleFiltersChange = useCallback(
    async (newFilter: VisualizationFilter) => {
      setTestFilters((filters) => [newFilter, ...filters]);
    },
    [],
  );

  useEffect(() => {
    onChangeRef.current(mapToEsFilters(testFilters));
  }, [testFilters]);

  useEffect(() => {
    if (!editable) {
      setTestFilters(modelTest.datasetFilter.map(mapToVisualizationFilters));
    }
  }, [modelTest.datasetFilter, editable]);

  return (
    <ModelTestsCardContentDatasetFilters
      editable={editable}
      testFilters={testFilters}
      onFilterChange={setTestFilters}
      onGlobalFilterChange={handleFiltersChange}
    />
  );
}
