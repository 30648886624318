import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m8.143 13.906 6.476-3.328c.254-.124.381-.315.381-.571 0-.257-.127-.448-.381-.572L8.143 6.107a.767.767 0 0 0-.762-.01C7.127 6.22 7 6.414 7 6.678v6.655c0 .25.127.444.381.583.143.055.27.083.38.083a.809.809 0 0 0 .382-.094Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 17.931a7.931 7.931 0 1 0 0-15.862 7.931 7.931 0 0 0 0 15.862ZM10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlayIcon;
