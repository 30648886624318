import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { URLS_ENUM, getIdFromUrl } from '../url/url-builder';

function isStringInEnum(str: string, strEnum: Record<string, string>) {
  return Object.values(strEnum).some((val) => val === str);
}

export function useSelectedUrlTab<T extends string = string>(
  pathPrefix: string,
  sectionName: URLS_ENUM,
  urlEnum: Record<string, string>,
  defalutTab?: T,
): [T | undefined, (_: T | undefined) => void] {
  const history = useHistory();
  const { pathname } = useLocation();

  const tabFromUrl = getIdFromUrl(pathname, sectionName);

  const setTab = useCallback(
    (value: T | undefined) => {
      const { search } = document.location;
      if (value) {
        history.push({
          pathname: `${pathPrefix}${sectionName}/${value}`,
          search,
        });
      } else {
        history.push({
          pathname: `${pathPrefix}`,
          search,
        });
      }
    },
    [history, pathPrefix, sectionName],
  );

  useEffect(() => {
    if (defalutTab && !tabFromUrl) {
      history.replace({
        pathname: `${pathPrefix}${sectionName}/${defalutTab}`,
        search: document.location.search,
      });
    }
  }, [defalutTab, history, pathPrefix, sectionName, tabFromUrl]);

  const tab: T | undefined = useMemo(() => {
    if (!tabFromUrl || !isStringInEnum(tabFromUrl, urlEnum)) return undefined;

    return tabFromUrl as T | undefined;
  }, [tabFromUrl, urlEnum]);

  return [tab, setTab];
}
