import { useMemo } from 'react';
import { Node } from '@tensorleap/api-client';

import { Divider, IconButton } from '../ui/mui';
import { XClose } from '../ui/icons';
import { useDescriptors } from '../network-editor/descriptor/utils';
import { EntityDescriptor } from '../network-editor/descriptor/types';
import { lastExistedProp } from '../core/array-helper';
import { DisplayNodeName } from '../network-editor/DysplayNodeName';
import { Title } from '../ui/atoms/Title';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export interface DetailsProps {
  node: Node;
}

type NodeDetailsProps = DetailsProps & {
  onClose: () => void;
};
export function NodeDetails({ node, onClose }: NodeDetailsProps): JSX.Element {
  const descriptors = useDescriptors(node);
  const reversedDescriptors = useMemo(() => {
    const overrideNodeTypeDetails = descriptors.some(
      (d) => d.type === 'node' && d.overrideDetails,
    );
    return descriptors.reverse().map((d) => ({
      ...d,
      customDetails:
        d.type === 'nodeType' && overrideNodeTypeDetails
          ? undefined
          : d.CustomDetails,
    }));
  }, [descriptors]);
  const title = useMemo(
    () => lastExistedProp(descriptors as EntityDescriptor[], 'detailsTitle'),
    [descriptors],
  );

  return (
    <div
      className="h-full flex flex-col w-full bg-gray-900 z-50"
      id={TOUR_SELECTORS_ENUM.NETWORK_EDITOR_PANE_ID}
    >
      <div className="flex items-center justify-between px-4 pt-2">
        <Title small bottomBorderClassName="border-b-network-500">
          {title}
        </Title>
        <IconButton size="small" onClick={onClose}>
          <XClose />
        </IconButton>
      </div>
      <h6 className="font-bold px-4 pb-4 text-sm tracking-normal text-primary-500">
        <DisplayNodeName node={node} />
      </h6>

      <Divider />
      <div className="flex-1 w-full max-h-full overflow-auto">
        {reversedDescriptors.map(
          ({ name, customDetails: CustomDetails }) =>
            CustomDetails && <CustomDetails key={name} node={node} />,
        )}
      </div>
    </div>
  );
}
