import { TestStatus } from '@tensorleap/api-client';

import { PRINT_LOG_KEY } from './consts';
import { FileMap } from './dataset-editor-content';
import { fetchSourceCode } from './source-code';
import { FileHierarchy } from './types';

export function getErrorColorStyle(errorName?: string): string {
  return errorName === PRINT_LOG_KEY ? 'text-success-50' : 'text-error-500';
}

export function getErrorNameTitle(errorName?: string): string {
  if (errorName !== PRINT_LOG_KEY) {
    return `${errorName?.toUpperCase()} ERROR`;
  }
  return errorName.toUpperCase();
}

export function testStatusToBg(testStatus?: TestStatus): string {
  switch (testStatus) {
    case TestStatus.TestFail: {
      return 'bg-error-500';
    }
    case TestStatus.TestSuccess: {
      return 'bg-secondary-500';
    }
    case TestStatus.BeforeTest:
    case TestStatus.DuringTest:
    default: {
      return 'bg-warning-400';
    }
  }
}

export async function fileListToMap(files: File[]): Promise<FileMap> {
  const fileContents = await Promise.all<string>(files.map((f) => f.text()));
  const fileNameToContent: FileMap = Object.fromEntries(
    fileContents.map((content, index) => [files[index].name, content]),
  );
  return fileNameToContent;
}

export function toFileHierarchy(fileMap: FileMap): FileHierarchy {
  const fileStruct: FileHierarchy = {};

  for (const [filename, content] of Object.entries(fileMap)) {
    if (filename.includes('/')) {
      let cursor = fileStruct;
      const nameParts = filename.split('/');

      for (let i = 0; i < nameParts.length; i++) {
        const part = nameParts[i];
        const isLast = i === nameParts.length - 1;

        if (isLast) {
          cursor[part] = content;
        } else {
          cursor[part] ??= {};
          cursor = cursor[part] as { [dir: string]: FileHierarchy };
        }
      }
    } else {
      fileStruct[filename] = content;
    }
  }

  return fileStruct;
}

export async function fetchFileMap(url: string): Promise<FileMap> {
  const files = await fetchSourceCode(url);
  if (!files) return {};

  return fileListToMap(files);
}
