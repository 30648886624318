import {
  IssueActionType,
  IssueActivity,
  SlimUserData,
} from '@tensorleap/api-client';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { Chip } from '../ui/atoms/Chip';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { TagIcon, UserIcon, ChatboxOutline } from '../ui/icons';
import { CardBody, Card, CardHeader } from '../ui/molecules/Card';
import { getConstColorByString } from '../ui/molecules/colorTemplates';
import { IssueMdTextarea } from './IssueMdTextarea';
import { ThreeDotsMenu } from '../ui/ThreeDotsMenu';
import { Button } from '../ui/atoms/Button';

export type IssueActivityCardProps = {
  issueActivity: IssueActivity;
  orgUsers: SlimUserData[];
  deleteActivity: (commentToRemove: IssueActivity) => Promise<void>;
  editActivity: (
    index: number,
    updatedActivity: IssueActivity,
  ) => Promise<void>;
  index: number;
  uploadImages: (
    commment: string,
    imagesToUpload: Record<string, File>,
  ) => Promise<string>;
};

const TYPE_TITLE_DICTIONARY: Record<IssueActionType, string> = {
  TagsAdded: 'added tags',
  Assignment: 'assigned issue to',
  Comment: 'commented',
};

const typeIconDictionary: Record<IssueActionType, JSX.Element> = {
  TagsAdded: <TagIcon />,
  Assignment: <UserIcon />,
  Comment: <ChatboxOutline />,
};

export function IssueActivityCard({
  issueActivity,
  orgUsers,
  deleteActivity,
  editActivity,
  index,
  uploadImages,
}: IssueActivityCardProps): JSX.Element {
  const [readOnly, setReadOnly] = useState(true);
  const [commentValue, setCommentValue] = useState<string | undefined>(
    issueActivity?.action?.comment?.message,
  );
  const [imagesToUpload, setImagesToUpload] = useState<Record<string, File>>(
    {},
  );

  const menuOptions = [
    { title: 'Edit Comment', onClick: () => setReadOnly(false) },
    { title: 'Delete Comment', onClick: () => deleteActivity(issueActivity) },
  ];
  const {
    actionType: type,
    createdBy,
    createdAt: creationDate,
    action,
  } = issueActivity;
  const shortDate = useMemo(
    () => format(creationDate, 'MMM d'),
    [creationDate],
  );

  const createdByName = useMemo(() => {
    return orgUsers.find((x) => x.cid === createdBy)?.local.name || '';
  }, [createdBy, orgUsers]);

  const assignedUserName = useMemo(() => {
    return (
      orgUsers.find((x) => x.cid === action.assignment?.assignedUser)?.local
        .name || ''
    );
  }, [action.assignment?.assignedUser, orgUsers]);

  const title = (
    <div className="flex flex-row justify-center items-center gap-2">
      <CircledIcon text={createdByName} dropShadow borderStyle="border-2" />
      {TYPE_TITLE_DICTIONARY[type]}
      <span className="ml-auto">{shortDate}</span>
      <div className="w-4">
        {type === 'Comment' && <ThreeDotsMenu options={menuOptions} />}
      </div>
    </div>
  );

  const updateComment = useCallback(async () => {
    const updatedComment = await uploadImages(
      commentValue || '',
      imagesToUpload,
    );
    const updatedActivity = {
      ...issueActivity,
      action: {
        ...issueActivity.action,
        comment: {
          ...issueActivity.action.comment,
          message: updatedComment || '',
        },
      },
    };

    await editActivity(index, updatedActivity);
    setReadOnly(true);
    setCommentValue(updatedComment);
    setImagesToUpload({});
  }, [
    commentValue,
    editActivity,
    imagesToUpload,
    index,
    issueActivity,
    uploadImages,
  ]);

  const cancelUpdateComment = useCallback(() => {
    setCommentValue(issueActivity?.action?.comment?.message);
    setReadOnly(true);
  }, [issueActivity?.action?.comment?.message]);

  return (
    <Card className="!w-full !rounded-none">
      <CardHeader
        className="!rounded-none"
        icon={<CircledIcon>{typeIconDictionary[type]}</CircledIcon>}
        title={title}
        uppercase={false}
      />
      <CardBody className="bg-gray-800 !p-0">
        <div className="flex-1">
          {type === 'TagsAdded' ? (
            <div className="flex gap-2 flex-wrap !p-4">
              {action.tagsAdded?.tags.map((tag, index) => (
                <Chip
                  className={clsx(
                    'text-lg capitalize',
                    getConstColorByString(tag),
                  )}
                  key={index}
                >
                  {tag}
                </Chip>
              ))}
            </div>
          ) : type == 'Assignment' ? (
            <span className="flex flex-row capitalize gap-2 !p-4">
              <CircledIcon text={assignedUserName} borderStyle="border-2" />
              {assignedUserName}
            </span>
          ) : type == 'Comment' ? (
            <>
              <IssueMdTextarea
                message={commentValue}
                setMessage={setCommentValue}
                imagesToUpload={imagesToUpload}
                setImagesToUpload={setImagesToUpload}
                readOnly={readOnly}
                className={clsx(readOnly ? '!h-fit !bg-gray-800' : '')}
              />
              {!readOnly && (
                <div className="flex w-full gap-1 py-1 pr-1">
                  <Button
                    variant="outline"
                    className="flex ml-auto w-fit h-4 justify-center items-center"
                    onClick={() => cancelUpdateComment()}
                  >
                    <span className="!text-xs w-fit">cancel</span>
                  </Button>
                  <Button
                    className="flex w-fit h-4 justify-center items-center"
                    onClick={updateComment}
                  >
                    <span className="!text-xs w-fit">save</span>
                  </Button>
                </div>
              )}
            </>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
}
