import { ReactNode, useCallback, useMemo } from 'react';
import clsx from 'clsx';

import { fakeSetter } from '../core/fakeSetter';
import { StateObject } from '../core/types';
import { ButtonPopup, DrawerTabs } from '../ui/atoms/DrawerTabs';
import { DrawerSideTab } from '../ui/atoms/DrawerSideTab';
import { Drawer } from '../ui/atoms/Drawer';
import { CodeIcon, LayersIcon } from '../ui/icons';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export enum NetworkTabsEnum {
  NodeDetails = 'node-details',
  CodeIntegration = 'code-integration',
}

type NetworkDrawerProps = {
  nodeDetailsContent: ReactNode;
  codeIntegrationContent: ReactNode;
};

export function NetworkDrawer({
  nodeDetailsContent,
  codeIntegrationContent,
}: NetworkDrawerProps) {
  const { openNetworkTab, setOpenNetworkTab } = useNetworkMapContext();
  const isOpen = !!openNetworkTab;
  const isOpenState: StateObject = useMemo(
    () => ({
      state: isOpen,
      setState: fakeSetter(isOpen, (v) => {
        setOpenNetworkTab(
          v ? openNetworkTab || NetworkTabsEnum.NodeDetails : undefined,
        );
      }),
    }),
    [isOpen, openNetworkTab, setOpenNetworkTab],
  );

  const onMenuItemClick = useCallback(
    (drawerType: NetworkTabsEnum | undefined) => {
      setOpenNetworkTab(openNetworkTab === drawerType ? undefined : drawerType);
    },
    [openNetworkTab, setOpenNetworkTab],
  );

  const nodeDetailsMenuOption = useMemo(
    () => (
      <DrawerSideTab
        onClick={() => onMenuItemClick(NetworkTabsEnum.NodeDetails)}
        selected={openNetworkTab === 'node-details'}
        icon={<LayersIcon className="rotate-180 h-auto w-3/4" />}
        buttonColor="blue"
      >
        node details
      </DrawerSideTab>
    ),
    [openNetworkTab, onMenuItemClick],
  );

  const codeIntegrationMenuOption = useMemo(
    () => (
      <DrawerSideTab
        onClick={() => onMenuItemClick(NetworkTabsEnum.CodeIntegration)}
        selected={openNetworkTab === 'code-integration'}
        icon={<CodeIcon className="rotate-180 h-auto w-3/4" />}
        buttonColor="orange"
        tourId={TOUR_SELECTORS_ENUM.CODE_INTEGRATION_EXPAND_BUTTON_ID}
      >
        code integration
      </DrawerSideTab>
    ),
    [openNetworkTab, onMenuItemClick],
  );

  const subContent = useMemo(() => {
    const drawerMenuOptions: ButtonPopup[] = [
      { key: 'node-details', button: nodeDetailsMenuOption },
      { key: 'code-integration', button: codeIntegrationMenuOption },
    ];

    return (
      <div>
        <DrawerTabs drawerButtonsPopups={drawerMenuOptions} />
      </div>
    );
  }, [nodeDetailsMenuOption, codeIntegrationMenuOption]);

  const drawerContent = useMemo(() => {
    switch (openNetworkTab) {
      case 'node-details': {
        return nodeDetailsContent;
      }
      case 'code-integration': {
        return codeIntegrationContent;
      }
      default: {
        return null;
      }
    }
  }, [openNetworkTab, nodeDetailsContent, codeIntegrationContent]);

  return (
    <Drawer
      openState={isOpenState}
      drawerClassName="absolute right-0 z-50 h-full w-auto"
      contentClassName="bg-gray-900"
      position="right"
      subContent={subContent}
      drawerSize="md"
      closeOnBlur={false}
    >
      <div
        className={clsx(
          'flex flex-col justify-start items-end',
          'h-full w-fit min-w-[20rem]',
          'bg-gray-900 border-l-2 border-gray-700 pt-2 transition-[width]',
        )}
      >
        {isOpen && drawerContent}
      </div>
    </Drawer>
  );
}
