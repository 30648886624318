import { Setter } from './types';

// Enable to create a fake setter api
export function fakeSetter<T>(
  previousValue: T,
  onSet: (t: T) => void,
): Setter<T> {
  return (newValueOrFunc: ((_: T) => T) | T) => {
    const newValue =
      typeof newValueOrFunc === 'function'
        ? (newValueOrFunc as (_: T) => T)(previousValue)
        : newValueOrFunc;
    onSet(newValue);
  };
}
