import { FilterOperatorType, NumberOrString } from '@tensorleap/api-client';
import { LegendData, useScatterData } from '../ScatterDataContext';
import { LabelsScatterLegend } from './LabelsScatterLegend';
import { RangeScatterLegend } from './RangeScatterLegend';
import { ShapesLegend } from './ShapeLegend';
import {
  ClusterVisualizationFilter,
  EQVisualizationFilter,
  INVisualizationFilter,
  VisualizationFilter,
} from '../../core/types/filters';
import { useDashletScatterContext } from '../dashlet/PopulationExploration/DashletScatterContext';
import { trimStorageUrlProject } from '../../core/useProjectStorage';
import { useDashletFields } from '../../core/data-fetching/DashletFieldsContext';

export function ScatterLegendLoader() {
  const { legendData } = useScatterData();

  if (!legendData || legendData.isLoading) return <></>;
  return <ScatterLegend legendData={legendData} />;
}

export interface ScatterLegendProps {
  legendData: LegendData;
}

export function ScatterLegend({ legendData }: ScatterLegendProps): JSX.Element {
  const { clusterBlobPaths, scatterData } = useScatterData();
  const { dashletFields } = useDashletFields();
  const { colorField, sizeField, isColorFieldRangeable, isSizeFieldRangeable } =
    legendData;

  const showSizeOrShape =
    sizeField !== undefined &&
    scatterData.metadata[sizeField] &&
    !isSizeFieldRangeable;

  const showColorLegend =
    colorField !== undefined && scatterData.metadata[colorField] !== undefined;

  const showLabelsColorLegend = showColorLegend && !isColorFieldRangeable;
  const showRangeColorLegend = showColorLegend && isColorFieldRangeable;

  const {
    filters: { updateDashletFilters, dashletFilters },
  } = useDashletScatterContext();

  const addFilterOnClick = (
    key: string,
    label: NumberOrString | NumberOrString[],
  ) => {
    if (!key) return;

    let filter: VisualizationFilter;

    const isCluster = clusterBlobPaths && clusterBlobPaths[key];

    if (isCluster && !Array.isArray(label)) {
      const url = clusterBlobPaths[key][label];
      const mappedUrl = trimStorageUrlProject(url); // fixing wrong path

      const clusterFilter: ClusterVisualizationFilter = {
        field: key,
        operator: FilterOperatorType.Cluster,
        value: {
          url: mappedUrl,
          state: 'ready',
        },
        pin: false,
      };
      filter = clusterFilter;
    } else if (key.startsWith('cluster') || isCluster) {
      console.warn(`Cluster filter is not supported for this key: ${key}`);
      return;
    } else {
      let field = key;
      if (dashletFields) {
        const allESFields = [
          ...dashletFields.numericFields,
          ...dashletFields.aggregatableFields,
        ];
        if (!allESFields.includes(field)) {
          const fieldESRealName = allESFields.find(
            (f) => f.toLowerCase() === `${field}.keyword`.toLowerCase(),
          );
          if (fieldESRealName) {
            field = fieldESRealName;
          } else {
            console.warn(`Field ${field} not found in ES fields`);
            return;
          }
        }
      }

      if (Array.isArray(label)) {
        const inFilter: INVisualizationFilter = {
          field,
          operator: FilterOperatorType.In,
          value: label as string[],
          pin: false,
        };
        filter = inFilter;
      } else {
        const eqFilter: EQVisualizationFilter = {
          field,
          operator: FilterOperatorType.Equal,
          value: label,
          pin: false,
        };
        filter = eqFilter;
      }
    }

    updateDashletFilters([...dashletFilters, filter]);
  };

  return (
    <div className="flex flex-col h-full gap-2 overflow-y-auto overflow-x-hidden">
      {showRangeColorLegend && <RangeScatterLegend />}
      {showLabelsColorLegend && (
        <LabelsScatterLegend onLegendClick={addFilterOnClick} />
      )}
      {showSizeOrShape && <ShapesLegend onLegendClick={addFilterOnClick} />}
    </div>
  );
}
