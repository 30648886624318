import {
  ClusterInsight,
  DuplicationInsight,
  InsightSubCategoryDSCuration,
  InsightSubCategoryPerformance,
  OverfittingInsight,
  ScatterInsightBase,
  UnderRepresentationInsight,
  DataLeakageInsight,
} from '@tensorleap/api-client';

export function isWeakClusterInsight(
  insightData: ScatterInsightBase,
): insightData is ClusterInsight {
  return insightData.type === 'weak_cluster';
}

export function isRedundantSamplesClusterInsight(
  insightData: ScatterInsightBase,
): insightData is ClusterInsight {
  return insightData.type === 'redundant_samples_cluster';
}

export function isOverfittingClusterInsight(
  insightData: ScatterInsightBase,
): insightData is OverfittingInsight {
  return insightData.type === 'overfitting_cluster';
}

export function isUnderRepresentationClusterInsight(
  insightData: ScatterInsightBase,
): insightData is UnderRepresentationInsight {
  return insightData.type === 'under_representation_cluster';
}

export function isDuplicationInsightClusterInsight(
  insightData: ScatterInsightBase,
): insightData is DuplicationInsight {
  return insightData.type === 'duplication_insight';
}

export function isDataLeakageInsightClusterInsight(
  insightData: ScatterInsightBase,
): insightData is DataLeakageInsight {
  return insightData.type === 'data_leakage_insight';
}

type InsightTypeStyle = {
  insightCategory: InsightCategoryType;
  chart: {
    title: string;
    countClassName: string;
    titleClassName: string;
  };
  card: {
    title: string;
    headerBorderClassName: string;
    headerIconClassName: string;
  };
  circularProgress?: {
    color: string;
  };
};

export const INSIGHT_CATEGORIES = ['ds curation', 'performance'] as const;

export type InsightCategoryType = (typeof INSIGHT_CATEGORIES)[number];

export type InsightSubCategoryType =
  | InsightSubCategoryDSCuration
  | InsightSubCategoryPerformance;

export const SCATTER_INSIGHT_TYPE_MAP: Record<
  InsightSubCategoryType,
  InsightTypeStyle
> = {
  over_fit: {
    insightCategory: 'performance',
    chart: {
      title: 'Over Fit',
      titleClassName: 'text-error-300',
      countClassName: 'text-error-500',
    },
    card: {
      title: 'Overfitting Cluster',
      headerBorderClassName: 'border-b-error-500',
      headerIconClassName: 'text-error-500',
    },
    circularProgress: {
      color: 'error',
    },
  },
  failing_clusters: {
    insightCategory: 'performance',
    chart: {
      title: 'Low Performance',
      countClassName: 'text-cyan-500',
      titleClassName: 'text-cyan-300',
    },
    card: {
      title: 'Low Performance Cluster',
      headerBorderClassName: 'border-b-cyan-500',
      headerIconClassName: 'text-cyan-500',
    },
  },
  under_presentation: {
    insightCategory: 'ds curation',
    chart: {
      title: 'Under Representation',

      countClassName: 'text-success-500',
      titleClassName: 'text-success-300',
    },
    card: {
      title: 'Under Representation Cluster',
      headerBorderClassName: 'border-b-success-500',
      headerIconClassName: 'text-success-500',
    },
  },
  over_presentation: {
    insightCategory: 'ds curation',
    chart: {
      title: 'Over Representaion',
      countClassName: 'text-warning-500',
      titleClassName: 'text-warning-300',
    },
    card: {
      title: 'Over Representaion Cluster',
      headerBorderClassName: 'border-b-warning-500',
      headerIconClassName: 'text-warning-500',
    },
  },
  data_leakage: {
    insightCategory: 'ds curation',
    chart: {
      title: 'Data Leakage',
      countClassName: 'text-colorful6-500',
      titleClassName: 'text-colorful6-300',
    },
    card: {
      title: 'Data Leakage Cluster',
      headerBorderClassName: 'border-b-colorful6-500',
      headerIconClassName: 'text-colorful6-500',
    },
  },
};

export const extractDigest = (key?: string) => {
  if (!key) return undefined;
  const match = key.match(/digest\/(.*?)\/insights/);
  return match ? match[1] : undefined;
};
