import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { Button, ButtonProps } from './Button';
import { IconButton, Tooltip } from '@material-ui/core';

export type ButtonColor = 'blue' | 'yellow' | 'orange';

const ROTATION_STYLES: Record<string, string> = {
  selected: [
    '!bg-gray-900',
    'rounded-r-none rounded-l-md',
    'border-t-gray-700 border-b-gray-700 border-t-2 border-b-2',
  ].join(' '),
  unselected: ['rounded-r-none rounded-l-md', 'border-gray-700 border-2'].join(
    ' ',
  ),
} as const;

const BORDER_COLOR_STYLES: Record<ButtonColor, string> = {
  yellow: 'border-l-insight-500 border-l-4',
  blue: 'border-l-primary-500 border-l-4',
  orange: 'border-l-dataset-500 border-l-4',
};

export type DrawerSideTabProps = ButtonProps &
  PropsWithChildren<{
    selected?: boolean;
    icon?: JSX.Element;
    buttonColor?: ButtonColor;
    notification?: {
      icon: JSX.Element;
      tooltipMsg?: string;
    };
    tourId?: string;
  }>;

export const DrawerSideTab = React.forwardRef<
  HTMLButtonElement,
  DrawerSideTabProps
>(
  (
    {
      selected = false,
      className = '',
      icon,
      buttonColor = 'blue',
      notification,
      children,
      tourId,
      ...args
    },
    ref,
  ) => {
    const notificationIconButton =
      notification !== undefined && !selected ? (
        <Tooltip title={notification.tooltipMsg ?? ''} placement="right" arrow>
          <IconButton
            onClick={args.onClick}
            className="absolute -top-1 -left-2 w-6 h-6 bg-warning-400 rounded-full shadow-lg z-50"
          >
            {notification.icon}
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      );

    return (
      <div className="relative">
        {notificationIconButton}
        <Button
          className={clsx(
            'w-8 min-h-[8rem] py-2 mb-4 bg-gray-900 ml-0.5',
            ROTATION_STYLES[selected ? 'selected' : 'unselected'],
            selected && BORDER_COLOR_STYLES[buttonColor],
            className,
          )}
          variant="dark"
          ref={ref}
          {...args}
          tourId={tourId}
        >
          <div
            className={clsx(
              className,
              '[writing-mode:vertical-lr]',
              'rotate-180',
            )}
          >
            <div className="flex flex-row h-full w-full items-center justify-center gap-2 pr-0.5 pb-1">
              {icon}
              {children}
            </div>
          </div>
        </Button>
      </div>
    );
  },
);
DrawerSideTab.displayName = 'SideButton';
