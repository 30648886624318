import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgViIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M7.752 16c-.408 0-.744-.168-1.008-.504l-3.24-4.248c-.3-.468-.312-.936-.036-1.404.276-.48.678-.684 1.206-.612.348.06.63.228.846.504l2.124 2.772 5.328-8.532a1.24 1.24 0 0 1 .738-.54c.312-.084.642-.036.99.144.276.18.456.426.54.738.084.312.036.642-.144.99l-6.3 10.08c-.24.384-.576.576-1.008.576L7.752 16Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgViIcon;
