import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgEta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M7.94 1.034A12.698 12.698 0 0 1 13 0c1.767 0 3.45.345 5.05 1.034 1.607.683 2.989 1.607 4.146 2.77 1.163 1.157 2.087 2.539 2.77 4.146C25.656 9.55 26 11.233 26 13s-.345 3.453-1.034 5.06c-.683 1.6-1.607 2.979-2.77 4.136-1.157 1.163-2.539 2.087-4.146 2.77C16.45 25.656 14.767 26 13 26s-3.453-.345-5.06-1.034c-1.6-.683-2.982-1.607-4.145-2.77-1.157-1.157-2.08-2.536-2.77-4.136A12.797 12.797 0 0 1 0 13c0-1.767.342-3.45 1.025-5.05.69-1.607 1.613-2.989 2.77-4.146C4.958 2.641 6.34 1.717 7.94 1.034Zm9.187 2.18A10.3 10.3 0 0 0 13 2.363c-1.44 0-2.82.283-4.136.85-1.311.56-2.44 1.313-3.389 2.261-.948.948-1.705 2.08-2.271 3.398A10.398 10.398 0 0 0 2.364 13c0 1.44.28 2.82.84 4.136.566 1.311 1.323 2.44 2.271 3.389.948.948 2.078 1.702 3.389 2.262 1.317.566 2.696.85 4.136.85a10.3 10.3 0 0 0 4.127-.85c1.317-.56 2.45-1.314 3.398-2.262s1.702-2.078 2.262-3.389c.566-1.317.85-2.696.85-4.136a10.3 10.3 0 0 0-.85-4.127c-.56-1.317-1.314-2.45-2.262-3.398s-2.08-1.702-3.398-2.262ZM12.16 5.077c.234-.234.514-.35.84-.35.326 0 .603.116.831.35.234.228.35.505.35.831v6.602l3.205 3.194c.227.228.341.508.341.84 0 .333-.114.613-.341.84a1.142 1.142 0 0 1-.84.342c-.333 0-.613-.114-.84-.341L12.16 13.84a1.142 1.142 0 0 1-.342-.84V5.91c0-.327.114-.604.342-.832Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEta;
