import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgAnalysisIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12.333 2.333v1.334h-.666V13a2.666 2.666 0 1 1-5.334 0V3.667h-.666V2.333h6.666Zm-2 5.334H7.667V13a1.333 1.333 0 1 0 2.666 0V7.667ZM9.667 11a.667.667 0 1 1 0 1.333.667.667 0 0 1 0-1.333ZM8.333 9a.667.667 0 1 1 0 1.333.667.667 0 0 1 0-1.333Zm2-5.333H7.667v2.666h2.666V3.667Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAnalysisIcon;
