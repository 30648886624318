import { Issue, SlimUserData, UpdateIssueParams } from '@tensorleap/api-client';
import clsx from 'clsx';
import { ChatboxOutline, GitIcon } from '../ui/icons';
import { CardBody, Card, CardFooter } from '../ui/molecules/Card';
import { Divider } from '../dashboard/Divider';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { useMemo } from 'react';
import { Tooltip } from '../ui/mui';
import { format } from 'date-fns';
import { fullIssueTypeText, useUserLocalName } from './issueHelper';
import { IssueTags } from './IssueTags';

export type IssueCardProps = {
  issue: Issue | undefined;
  orgUsers: SlimUserData[];
  colorOnSelect?: boolean;
  updateIssue: (updateIssueParams: UpdateIssueParams) => Promise<void>;
};

export function IssueCard({
  issue,
  orgUsers,
  colorOnSelect,
  updateIssue,
}: IssueCardProps): JSX.Element {
  const createdAtFormatted = useMemo(
    () => issue && format(issue.createdAt, 'MMM dd, HH:mm'),
    [issue],
  );
  const createdByName = useUserLocalName(issue?.assignment, orgUsers);

  if (!issue) {
    return <></>;
  }
  return (
    <Card fixedSize={false} className="w-full">
      <CardBody
        className={clsx(
          'flex-col h-fit justify-start items-start gap-2 rounded-t-xl',
          colorOnSelect && 'bg-primary-900',
        )}
      >
        <div className="flex flex-row w-full h-full">
          <span className="break-words line-clamp-3">
            <span className="text-gray-500 mr-2">{issue.index}:</span>
            <span>{issue.title}</span>
          </span>
        </div>
        <div className="flex flex-wrap w-full h-full gap-2 bg">
          <IssueTags
            issue={issue}
            updateIssue={updateIssue}
            isEditable={false}
          />
        </div>
      </CardBody>
      <CardFooter
        className={clsx({
          'bg-secondary-950': issue.status === 'AtWork',
          'bg-error-950': issue.status === 'Closed',
          'bg-primary-950': colorOnSelect,
        })}
      >
        <div className="flex flex-row w-full justify-start items-center gap-4">
          <CircledIcon text={createdByName} borderStyle="border-2" />
          <span>
            <ChatboxOutline /> {issue.activities.length}
          </span>
          {issue.branch && (
            <Tooltip title={issue.branch} placement="bottom">
              <div className="flex justify-center items-center w-fit h-fit">
                <GitIcon className="w-5 h-auto" />
              </div>
            </Tooltip>
          )}
          <Divider vertical></Divider>
          <span>{fullIssueTypeText[issue.status]}</span>
          <span className="ml-auto">{createdAtFormatted}</span>
        </div>
      </CardFooter>
    </Card>
  );
}
