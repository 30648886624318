import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTextPlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M9.9 12.333c.157-.171.23-.427.23-.757V6.01h1.668c.274 0 .49-.06.637-.186.146-.13.218-.301.218-.508a.649.649 0 0 0-.218-.506c-.143-.132-.36-.192-.637-.192H6.795c-.265 0-.476.06-.626.185a.645.645 0 0 0-.219.513c0 .206.07.377.212.507h.001c.144.128.358.187.632.187h1.667v5.567c0 .327.074.58.23.751a.8.8 0 0 0 .61.254.785.785 0 0 0 .598-.248Zm10.858-3.505a.624.624 0 0 0 .19-.47.584.584 0 0 0-.196-.458c-.13-.121-.322-.177-.569-.177H16.97V5.925h3.495c.257 0 .457-.056.59-.177a.62.62 0 0 0 .202-.48.598.598 0 0 0-.202-.47c-.133-.124-.333-.181-.59-.181H16.31c-.227 0-.419.034-.572.104a.662.662 0 0 0-.338.336 1.33 1.33 0 0 0-.105.57v5.812c0 .343.075.604.237.768.166.166.43.242.777.242h4.277c.254 0 .454-.057.59-.182a.63.63 0 0 0 .208-.485.626.626 0 0 0-.208-.49c-.136-.128-.336-.188-.59-.188H16.97v-2.1h3.214c.25 0 .445-.055.575-.176Zm-2.15 14.307h.001c.157-.171.23-.427.23-.756V16.81h1.668c.274 0 .49-.059.637-.186.146-.13.218-.3.218-.507a.649.649 0 0 0-.218-.507c-.143-.131-.36-.192-.637-.192h-5.003c-.265 0-.476.06-.626.186a.645.645 0 0 0-.219.513c0 .206.07.376.212.506.145.128.358.187.633.187h1.667v5.568c0 .326.074.58.23.75v.001a.8.8 0 0 0 .61.253.785.785 0 0 0 .598-.248ZM6.64 16.453l1.89 2.751-2.09 3.043a.8.8 0 0 0 1.32.906l1.74-2.535 1.74 2.535a.8.8 0 0 0 1.32-.906l-2.09-3.043 1.89-2.751a.8.8 0 1 0-1.32-.906L9.5 17.791l-1.54-2.244a.8.8 0 1 0-1.32.906Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.1}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.667 1.333H3.333a2 2 0 0 0-2 2v21.334a2 2 0 0 0 2 2h21.334a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2ZM3.333 0A3.333 3.333 0 0 0 0 3.333v21.334A3.333 3.333 0 0 0 3.333 28h21.334A3.333 3.333 0 0 0 28 24.667V3.333A3.333 3.333 0 0 0 24.667 0H3.333Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTextPlaceholderIcon;
