import useSWR, { KeyedMutator } from 'swr';
import api from '../api-client';
import { useMergedObject } from '../useMergedObject';
import { REFRESH_INTERVALS } from './consts';

export interface UseArchiveInsights {
  archivedInsights: { [digest: string]: number[] };
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<{ [digest: string]: number[] }>;
}

export interface UseArchiveInsgihtsProps {
  projectId: string;
  popExpDigests?: string[];
}

export function useFetchArchiveInsights({
  projectId,
  popExpDigests,
}: UseArchiveInsgihtsProps): UseArchiveInsights {
  const {
    data: archivedInsightsMap,
    error,
    mutate,
  } = useSWR<{ [digest: string]: number[] }, Error>(
    projectId + popExpDigests?.toString(),
    async () => {
      const archiveInsightsMap = popExpDigests
        ? await api.getArchiveInsights({
            projectId,
            popExpDigests,
          })
        : {};
      return archiveInsightsMap;
    },
    {
      refreshInterval: REFRESH_INTERVALS.archivedInsights,
    },
  );

  return useMergedObject({
    archivedInsights: archivedInsightsMap || {},
    error,
    isLoading: !error && !archivedInsightsMap,
    refetch: mutate,
  });
}
