import clsx from 'clsx';
import { XClose, Vi1 } from '../icons';
import { IconButton } from '../mui';

export interface AcceptRejectIconButtonProps {
  accept?: boolean;
  className?: string;
  onClick: () => void;
}

export function AcceptRejectIconButton({
  accept = false,
  className,
  onClick,
}: AcceptRejectIconButtonProps): JSX.Element {
  return (
    <IconButton
      onClick={onClick}
      className={clsx(
        'border-2 rounded-2xl w-10 h-8',
        accept
          ? 'bg-success-900 border-success-600 text-success-600 hover:bg-success-800'
          : 'bg-error-900 border-error-600 text-error-600 hover:bg-error-800',
        className,
      )}
    >
      {accept ? <Vi1 /> : <XClose />}
    </IconButton>
  );
}
