import { useCallback, useState } from 'react';
import { Setter } from './types';

// This is a hack until we remove Date usages
const DATE_FIELDS = ['createdAt', 'updatedAt', 'lastAccessed'];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function fixDateFields(x: any): any {
  DATE_FIELDS.forEach((field) => {
    const parsedDate = Date.parse(x[field]);
    if (parsedDate) {
      x[field] = new Date(parsedDate);
    }
  });
  return x;
}

// Based on https://usehooks.com/useLocalStorage/
export function useLocalStorage<T>(
  key: string,
): [T | undefined, Setter<T | undefined>];

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, Setter<T>];

export function useLocalStorage<T>(
  key: string,
  initialValue?: T,
): [T, Setter<T>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item === 'null') return null;
      return item ? fixDateFields(JSON.parse(item)) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (value === undefined) {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
}
