import { Tooltip } from '@material-ui/core';
import { truncateLongtail } from '../../../core/formatters/string-formatting';
import clsx from 'clsx';
import { Fragment, ReactNode, useMemo } from 'react';
import { metaDataTableBlackListFilter } from '../VisualizationDisplay/SampleVisDisplay';
import { uniq } from 'lodash';

export type DisplayMetadataProps = {
  content: Record<string, unknown>;
  className?: string;
  smallKey?: boolean;
  keyEndSubsetLength?: number;
};

const METADATA_BOTTOM = 'border-b border-gray-800 border-solid';
export function DisplayMetadata({
  content,
  className,
  smallKey,
  keyEndSubsetLength,
}: DisplayMetadataProps) {
  const entries = Object.entries(content);
  const gridColsClass = smallKey
    ? 'grid-cols-[minmax(300px,_.5fr)_minmax(220px,_1fr)]'
    : 'grid-cols-[minmax(300px,_1fr)_minmax(220px,_1fr)]';
  return (
    <div
      className={clsx(
        'grid w-full overflow-auto h-fit',
        gridColsClass,
        className,
      )}
      style={{
        gridTemplateRows: `repeat(${entries.length}, minmax(40px, auto))`,
      }}
    >
      {entries
        .filter(([key, _]) => metaDataTableBlackListFilter(key))
        .map(([key, val], index) => (
          <Fragment key={index}>
            <DisplayKey
              value={key}
              className={METADATA_BOTTOM}
              endSubsetLength={keyEndSubsetLength}
            />
            <div
              className={clsx(
                'flex font-bold px-2 items-center',
                METADATA_BOTTOM,
              )}
            >
              <DisplayValue value={val} key={index} />
            </div>
          </Fragment>
        ))}
    </div>
  );
}

function DisplayKey({
  value: key,
  className,
  endSubsetLength = 15,
}: {
  value: string;
  className?: string;
  endSubsetLength?: number;
}) {
  return (
    <Tooltip arrow title={key} placement="top-start">
      <span className={clsx('truncate pl-4 pt-2 text-gray-300', className)}>
        {truncateLongtail({
          value: key,
          startSubsetLength: 15,
          endSubsetLength: endSubsetLength,
        })}
      </span>
    </Tooltip>
  );
}

export type DisplayMultiMetadataProps = {
  content: {
    title: ReactNode;
    data: Record<string, unknown>;
    bgClass?: string;
  }[];
  keysLabel?: ReactNode;
  ketEndSubsetLength?: number;
  className?: string;
  smallKey?: boolean;
};

export function DisplayMultiMetadata({
  keysLabel,
  content,
  className,
  ketEndSubsetLength,
  smallKey,
}: DisplayMultiMetadataProps) {
  const keys = useMemo(
    () => uniq(content.flatMap(({ data }) => Object.keys(data))),
    [content],
  );
  const gridColsClass = smallKey ? 'minmax(300px, .5fr)' : 'minmax(300px, 1fr)';

  if (!keys.length) {
    return (
      <div className="text-gray-500 p-6 text-center uppercase">no data</div>
    );
  }
  return (
    <div
      className={clsx('grid w-full overflow-auto h-fit', className)}
      style={{
        gridTemplateRows: `auto repeat(${keys.length}, minmax(40px, auto))`,
        gridTemplateColumns: `${gridColsClass} repeat(${content.length}, minmax(220px, 1fr))`,
      }}
    >
      <div
        className={clsx(
          'flex font-bold px-3 py-2 items-center',
          METADATA_BOTTOM,
        )}
      >
        {keysLabel || 'keys'}
      </div>
      {content.map(({ title, bgClass }, index) => (
        <div
          key={index}
          className={clsx(
            'flex px-2 py-2 items-center',
            METADATA_BOTTOM,
            bgClass,
          )}
        >
          {title}
        </div>
      ))}
      {keys.map((key, index) => (
        <Fragment key={index}>
          <DisplayKey
            value={key}
            className={METADATA_BOTTOM}
            endSubsetLength={ketEndSubsetLength}
          />
          {content.map(({ data, bgClass }, index) => (
            <div
              key={index}
              className={clsx(
                'flex font-bold px-2 items-center',
                METADATA_BOTTOM,
                bgClass,
              )}
            >
              <DisplayValue value={data[key]} key={index} />
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
}

export function DisplayValue({
  value,
  nested = 0,
  arrayIndex,
}: {
  value: unknown;
  nested?: number;
  arrayIndex?: number;
}) {
  const isPrimitive = typeof value !== 'object' || value === null;
  let prefix: JSX.Element | null = null;
  if (arrayIndex !== undefined) {
    prefix = <span className="pr-2 h-8">-</span>;
  }
  if (isPrimitive) {
    return (
      <>
        {prefix}
        {String(value)}
      </>
    );
  }
  const isArray = Array.isArray(value);
  const nestedStyle = { paddingLeft: `${nested * 10}px` };
  if (isArray) {
    return (
      <div className="flex py-1" style={nestedStyle}>
        {prefix}
        <div className="flex flex-col gap-1">
          {value.length ? (
            value.map((val, index) => (
              <div key={index}>
                <DisplayValue
                  value={val}
                  nested={nested + 1}
                  arrayIndex={index}
                />
              </div>
            ))
          ) : (
            <span className="text-gray-300">-</span>
          )}
        </div>
      </div>
    );
  }
  const isObject = typeof value === 'object';
  if (isObject) {
    return (
      <div className="flex py-1" style={nestedStyle}>
        {prefix}
        <div className={clsx('flex flex-col gap-1')}>
          {Object.entries(value).map(([key, val], index) => (
            <div key={index}>
              <Tooltip arrow title={key} placement="top-start">
                <span className={clsx('truncate text-gray-300')}>
                  {truncateLongtail({
                    value: key,
                    startSubsetLength: 15,
                    endSubsetLength: 15,
                  })}
                </span>
              </Tooltip>
              {': '}
              <DisplayValue value={val} key={index} nested={nested + 1} />
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
}

export function PreviewMetadata(): JSX.Element {
  return (
    <div className="w-10 h-10 mr-2 text-xs bg-gray-700 flex items-center text-center whitespace-pre-wrap">
      META DATA
    </div>
  );
}
