import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgOpenPanel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M2 7h20v11.25H2V7Zm1.25 1.25V17h12.5V8.25H3.25ZM20.75 17V8.25H17V17h3.75Zm-9.092-7.47 3.096 3.095-3.096 3.096-.879-.88 1.582-1.591H8.25V12h4.111l-1.582-1.592.88-.879Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgOpenPanel;
