import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTipsOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M0 0h24v19.5h-7.5L12 24l-4.5-4.5H0V0Zm22.5 18V1.5h-21V18h6.621L12 21.879 15.879 18H22.5Z"
      fill="currentColor"
    />
    <path
      d="M9.492 4.06A6.616 6.616 0 0 1 12 3.566c.863 0 1.698.164 2.508.492.81.328 1.53.808 2.16 1.44a6.467 6.467 0 0 1 1.44 2.16c.328.81.492 1.645.492 2.508 0 .862-.164 1.698-.492 2.508a6.466 6.466 0 0 1-1.44 2.16 6.466 6.466 0 0 1-2.16 1.44 6.616 6.616 0 0 1-2.508.492 6.616 6.616 0 0 1-2.508-.492 6.465 6.465 0 0 1-2.16-1.44 6.465 6.465 0 0 1-1.44-2.16 6.616 6.616 0 0 1-.492-2.508c0-.863.164-1.699.492-2.508.328-.81.808-1.53 1.44-2.16a6.466 6.466 0 0 1 2.16-1.44Zm.005 4.452L7.78 6.797a5.398 5.398 0 0 0-.881 5.147c.2.578.494 1.109.881 1.593l1.716-1.715A2.933 2.933 0 0 1 9 10.167c0-.603.166-1.155.497-1.655Zm5.873 5.874-1.715-1.716c-.5.331-1.052.497-1.655.497a2.933 2.933 0 0 1-1.655-.497L8.63 14.386a5.398 5.398 0 0 0 5.147.881c.578-.2 1.109-.494 1.593-.881Zm-2.1-5.49a1.723 1.723 0 0 0-1.27-.53c-.497 0-.922.177-1.275.53a1.73 1.73 0 0 0-.525 1.27c0 .498.175.923.525 1.276.353.35.778.525 1.275.525.497 0 .92-.175 1.27-.525.353-.353.53-.778.53-1.275 0-.497-.177-.92-.53-1.27Zm.507-3.83a5.398 5.398 0 0 0-3.554 0c-.578.2-1.109.495-1.593.882l1.715 1.716A2.933 2.933 0 0 1 12 7.167c.603 0 1.155.166 1.655.497l1.715-1.716a5.318 5.318 0 0 0-1.593-.881Zm2.442 1.73-1.716 1.716c.331.5.497 1.052.497 1.655s-.166 1.155-.497 1.655l1.716 1.715A5.398 5.398 0 0 0 17.1 8.39a5.317 5.317 0 0 0-.881-1.593Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTipsOn;
