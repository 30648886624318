import React, { useState, useCallback, useEffect } from 'react';
import api from '../../../core/api-client';
import { BackButton } from '../../../ui/atoms/BackButton';
import { useScatterData } from '../../ScatterDataContext';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';
import {
  SampleAnalysisAlgo,
  SampleAnalysisParams,
  SampleIdentity,
} from '@tensorleap/api-client';
import { useCurrentProject } from '../../../core/CurrentProjectContext';
import { Button } from '../../../ui/atoms/Button';
import { Select } from '../../../ui/atoms/Select';
import { toInteger } from 'lodash';
import { useLocalStorage } from '../../../core/useLocalStorage';
import { AlgoSelect } from '../../../ui/atoms/AlgoSelect';

export interface SampleAnalysisDrawerProps {
  closeSampleAnalysisDrawer: () => void;
  selectedSampleIdentities: SampleIdentity[];
}

export function SampleAnalysisDrawer({
  closeSampleAnalysisDrawer,
  selectedSampleIdentities,
}: SampleAnalysisDrawerProps): JSX.Element {
  const { currentVersion: { cid: versionId } = {}, fetchValidProjectCid } =
    useCurrentProject();
  const projectId = fetchValidProjectCid();
  const { sessionRunId, epoch } = useScatterData();

  const [selectedSample, setSelectedSample] = useState<SampleIdentity>(
    selectedSampleIdentities[0],
  );

  const [selectedAlgo, setSelectedAlgo] = useLocalStorage<SampleAnalysisAlgo>(
    'sampleAnalysisAlgo',
    SampleAnalysisAlgo.FocusLayerCam,
  );

  useEffect(() => {
    if (
      !selectedSampleIdentities.find(
        ({ index, state }) =>
          index === selectedSample?.index && state === selectedSample?.state,
      )
    ) {
      setSelectedSample(selectedSampleIdentities[0]);
    }
  }, [selectedSample?.index, selectedSample?.state, selectedSampleIdentities]);

  const analyzeSample = useCallback(async () => {
    if (!versionId) {
      console.error('Invalid version');
      return;
    }

    if (!selectedSample) {
      console.error('Invalid sample');
      return;
    }

    const sampleAnalysisParams: SampleAnalysisParams = {
      projectId,
      sessionRunId,
      sampleIdentity: selectedSample,
      fromEpoch: epoch,
      algo: selectedAlgo,
    };

    return await api.sampleAnalysis(sampleAnalysisParams);
  }, [versionId, selectedSample, projectId, sessionRunId, epoch, selectedAlgo]);

  return (
    <div
      className="flex p-4 gap-4 flex-col bg-gray-850 w-full border-2 border-gray-800 rounded-lg"
      id={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_DRAWER_ID}
    >
      <BackButton onClick={closeSampleAnalysisDrawer} />

      <div className="flex flex-col gap-4">
        <label htmlFor="sample-select" className="text-white">
          Select a sample to analyze:
        </label>

        <Select
          value={selectedSampleIdentities.findIndex(
            ({ index, state }) =>
              index === selectedSample?.index &&
              state === selectedSample?.state,
          )}
          options={selectedSampleIdentities.map((_, index) => index)}
          label={'Sample'}
          onChange={(indx) =>
            setSelectedSample(selectedSampleIdentities[toInteger(indx)])
          }
          optionToLabel={(index) => {
            const sampleIdentity = selectedSampleIdentities[index];
            return `${sampleIdentity?.state} - ${sampleIdentity?.index}`;
          }}
          small
        />

        <AlgoSelect
          selectedAlgo={selectedAlgo}
          setSelectedAlgo={setSelectedAlgo}
          small
        />
      </div>

      <div className="flex justify-end">
        <Button
          variant="inverted-blue"
          onClick={analyzeSample}
          className="min-w-[190px] h-8"
          tourId={
            TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_SAMPLE_ANALYSIS_BUTTON_ID
          }
        >
          Analyze Sample
        </Button>
      </div>
    </div>
  );
}
