import { DashboardIcon, NnMapBoldIcon } from './icons';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { CardTabs } from './atoms/CardTabs';
import { useSelectedUrlTab } from '../core/useSelectedUrlTab';
import { URLS_ENUM, buildVersionUrl } from '../url/url-builder';

export enum ProjectTabs {
  Dashboard = 'dashboard',
  Network = 'network',
}

const TABS = [
  {
    icon: <NnMapBoldIcon />,
    value: ProjectTabs.Network,
  },
  {
    icon: <DashboardIcon />,
    value: ProjectTabs.Dashboard,
  },
];

export function ProjectCardTabs(): JSX.Element {
  const { currentProjectId, currentVersionId } = useCurrentProject();

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const versionUrl = buildVersionUrl(currentProjectId, currentVersionId);

  const [currentTab, setCurrentTab] = useSelectedUrlTab<ProjectTabs>(
    versionUrl,
    URLS_ENUM.TAB,
    ProjectTabs,
    ProjectTabs.Network,
  );

  return currentTab ? (
    <CardTabs
      className="-mb-2 mt-2 ml-20"
      onChange={setCurrentTab}
      value={currentTab}
      options={TABS}
      selectedTabBgClassName="after:bg-gray-900 bg-gray-900"
    />
  ) : (
    <> </>
  );
}
