import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSelectionPlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <g clipPath="url(#selection-plus-icon_svg__a)" fill="currentColor">
      <path d="M8.75 12.5V14H6.5v-1.5h2.25ZM5 12.5V14H2v-3h1.5v1.5H5Zm-3-3V7.25h1.5V9.5H2Zm0-3.75v-3h3v1.5H3.5v1.5H2Zm4.5-3h2.25v1.5H6.5v-1.5Zm4.5 0h3v3h-1.5v-1.5H11v-1.5Zm3 4.5V9.5h-1.5V7.25H14ZM17.36 14.906a3.881 3.881 0 0 1-1.04 1.796 3.909 3.909 0 0 1-1.8 1.042 3.94 3.94 0 0 1-2.053-.006 3.875 3.875 0 0 1-1.795-1.037 3.903 3.903 0 0 1-1.041-1.8 4.004 4.004 0 0 1-.003-2.05 3.91 3.91 0 0 1 1.042-1.8 3.881 3.881 0 0 1 1.796-1.038c.685-.18 1.37-.18 2.057-.004a3.902 3.902 0 0 1 1.8 1.04c.515.516.86 1.114 1.037 1.796a3.967 3.967 0 0 1 0 2.061Zm-3.295 1.205a.32.32 0 0 0 .096-.236v-1.332h1.333a.32.32 0 0 0 .235-.096.327.327 0 0 0 .1-.24v-.662a.326.326 0 0 0-.1-.24.32.32 0 0 0-.235-.095h-1.332v-1.332a.32.32 0 0 0-.095-.235.326.326 0 0 0-.24-.1h-.662a.326.326 0 0 0-.24.1.32.32 0 0 0-.095.236v1.332h-1.333a.32.32 0 0 0-.235.096.326.326 0 0 0-.1.24v.662c0 .093.033.173.1.239a.32.32 0 0 0 .235.096h1.332v1.332a.32.32 0 0 0 .095.235c.066.066.146.1.24.1h.662a.326.326 0 0 0 .24-.1Z" />
    </g>
    <defs>
      <clipPath id="selection-plus-icon_svg__a">
        <path fill="currentColor" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSelectionPlusIcon;
