import {
  isBTWVisualizationFilter,
  isClusterOrNotClusterVisualizationFilter,
  isEQVisualizationFilter,
  isINOrNotInVisualizationFilter,
  isNEVisualizationFilter,
  isNotBTWVisualizationFilter,
} from '../core/filters';
import { VisualizationFilter } from '../core/types/filters';
import {
  buildBetweenCondition,
  buildInCondition,
  buildEqualCondition,
  buildNotEqualCondition,
  TestCondition,
  buildNotBetweenCondition,
  buildClusterCondition,
} from './conditions';
import { FieldValueExtractor } from './utils';

export const buildTestConditionFromFilterConverter =
  <T>(extractFieldValue?: FieldValueExtractor<T>) =>
  (filter: VisualizationFilter): TestCondition<T> => {
    if (isINOrNotInVisualizationFilter(filter)) {
      return buildInCondition<T>(filter.field, filter.value, extractFieldValue);
    }
    if (isNotBTWVisualizationFilter(filter)) {
      return buildNotBetweenCondition(
        filter.field,
        filter.value.gte,
        filter.value.lt,
        extractFieldValue,
      );
    }
    if (isBTWVisualizationFilter(filter)) {
      return buildBetweenCondition(
        filter.field,
        filter.value.gte,
        filter.value.lt,
        extractFieldValue,
      );
    }
    if (isNEVisualizationFilter(filter)) {
      return buildNotEqualCondition(
        filter.field,
        filter.value,
        extractFieldValue,
      );
    }
    if (isEQVisualizationFilter(filter)) {
      return buildEqualCondition(filter.field, filter.value, extractFieldValue);
    }
    if (isClusterOrNotClusterVisualizationFilter(filter)) {
      return buildClusterCondition(
        filter.field,
        filter.value.url,
        extractFieldValue,
      );
    }
    throw Error(`[buildTestConditionFromFilter]:: Unknown Filter`);
  };
