import clsx from 'clsx';
import { useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import { useMounted } from '../core/useMounted';
import { Button } from '../ui/atoms/Button';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { Check1, CrownIcon, SocialShare } from '../ui/icons';
import { CircularCenteredProgressBar } from '../ui/atoms/CircularCenteredProgressBar';
import { UseFetchAllSlimUsers } from '../core/data-fetching/users';
import { URLS_ENUM } from '../url/url-builder';

export interface TeamManagementTopBarProps {
  maxActiveUsers: number;
}

export function TeamManagementTopBar({
  maxActiveUsers,
}: TeamManagementTopBarProps): JSX.Element {
  const { user } = useAuth();
  const isMounted = useMounted();
  const { activeUsersCount } = UseFetchAllSlimUsers();

  const [isShowingCopiedIcon, setIsShowingCopiedIcon] = useState(false);

  return (
    <div className="flex flex-row w-full justify-start items-end bg-black py-4 px-20">
      <div className="flex flex-row h-fit w-fit justify-end items-center gap-8">
        <div className="flex flex-col h-40 w-fit justify-center items-center relative">
          <CrownIcon className="w-14 h-14 text-warning-300 absolute top-1 -left-4 -rotate-45" />
          <CircledIcon
            className="flex !w-28 !h-28"
            textSizeClass="text-6xl"
            text={user?.local.name}
            dropShadow
            borderStyle="border-2"
          />
        </div>
        <span className="text-4xl">{user?.local.name}</span>
      </div>
      <div className="flex flex-row gap-2 w-fit h-40 ml-auto justify-center items-center">
        <CircularCenteredProgressBar
          max={maxActiveUsers}
          current={activeUsersCount}
          text="active users"
        />
        <Button
          className="flex flex-col ml-10 justify-center items-center text-gray-200"
          variant="text"
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin}${URLS_ENUM.SIGNUP}`,
            );
            setIsShowingCopiedIcon(true);
            setTimeout(() => {
              isMounted.current && setIsShowingCopiedIcon(false);
            }, 2000);
          }}
        >
          <SocialShare />
          <span>Copy Team</span>
          <span> Invitation Link</span>
        </Button>
        <Check1
          className={clsx(
            'text-secondary-500',
            !isShowingCopiedIcon && 'opacity-0',
          )}
        />
      </div>
    </div>
  );
}
