import { useCallback, useEffect } from 'react';
import { IconButton } from '../ui/mui';

import { useSnackbar } from './SnackbarContext';
import { NotificationCard } from './molecules/NotificationCard';
import { XClose } from './icons';
import { PushNotificationResponse } from '@tensorleap/api-client';
import { usePrevious } from '../core/usePrevious';
import { usePushNotifications } from '../core/PushNotificationsContext';
import { isPushNotificationMessage } from '../core/websocket-message-types';

export function useNotificationSnackbar(): void {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const displaySnackbar = useCallback(
    ({ data }: PushNotificationResponse) => {
      try {
        const notification = data;

        enqueueSnackbar(notification.title, {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          style: { top: 55 },
          content: function Toast(key) {
            return (
              <NotificationCard
                key={key}
                {...notification}
                cardClassName="w-96"
                menu={
                  <IconButton onClick={() => closeSnackbar(key)}>
                    <XClose />
                  </IconButton>
                }
              />
            );
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const { lastServerMessage } = usePushNotifications();
  const prevServerMessage = usePrevious(lastServerMessage);

  useEffect(() => {
    if (
      lastServerMessage &&
      lastServerMessage !== prevServerMessage &&
      isPushNotificationMessage(lastServerMessage)
    ) {
      displaySnackbar(lastServerMessage);
    }
  }, [displaySnackbar, lastServerMessage, prevServerMessage]);
}
