import { Down, MagnifyingGlass, Target1, Up, XClose } from '../../../ui/icons';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { InteractiveTooltip } from '../../../ui/molecules/InteractiveTooltip';
import { StringOrNumberValue } from '../../../filters/FilterValueInputs';
import { FilterFieldMeta } from '../../../filters/helpers';
import { Select } from '../../../ui/atoms/Select';
import { useCallback, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useDebounce } from '../../../core/useDebounce';
import clsx from 'clsx';

export type FindValue = {
  key?: string;
  exact?: boolean;
  value?: string;
};

export function Find({
  open,
  toggleOpen,
  value,
  onChange,
  fields,
}: {
  open: boolean;
  toggleOpen: () => void;
  fields: FilterFieldMeta[];
  value: FindValue;
  onChange: (value: FindValue) => void;
}): JSX.Element {
  return (
    <InteractiveTooltip
      onClose={() => {}}
      open={open}
      content={
        <FindContent
          fields={fields}
          value={value}
          onChange={onChange}
          onClose={toggleOpen}
        />
      }
    >
      <Button onClick={() => toggleOpen()}>
        <MagnifyingGlass />
        {open ? <Up /> : <Down />}
      </Button>
    </InteractiveTooltip>
  );
}

type FindContentProps = {
  label?: string;
  value: FindValue;
  fields: FilterFieldMeta[];
  onChange: (value: FindValue) => void;
  onClose: () => void;
};

function FindContent({
  value,
  fields,
  onChange,
  onClose,
}: FindContentProps): JSX.Element {
  const { control, handleSubmit, watch } = useForm<FindValue>({
    mode: 'onChange',
  });

  const { field: valueField } = useController({
    control,
    name: 'value',
    rules: { required: true },
    defaultValue: value.value,
  });

  const { field: fieldKey } = useController({
    control,
    name: 'key',
    defaultValue: value.key,
  });

  const { field: exactField } = useController({
    control,
    name: 'exact',
    defaultValue: value.exact ?? false,
  });

  const fieldsOptions = useMemo(() => {
    return [undefined, ...fields.map(({ field }) => field)];
  }, [fields]);

  const onSubmit = (data: FindValue) => {
    onChange(data);
  };

  const debounceUpdate = useDebounce(() => {
    const data = {
      key: watch('key'),
      value: watch('value'),
      exact: watch('exact'),
    };
    onChange(data);
  }, 1000);

  const selectedField = useMemo(
    () => fields.find((f) => f.field === fieldKey.value),
    [fields, fieldKey.value],
  );

  const onFieldChange = useCallback(
    (value) => {
      valueField.onChange('');
      fieldKey.onChange(value);
      fieldKey.value = value; // to trigger re-render
      debounceUpdate();
    },
    [valueField, debounceUpdate, fieldKey],
  );

  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex justify-between items-center">
        <span className=" text-base">FIND SAMPLES</span>
        <IconButton onClick={onClose} className="w-6 h-6">
          <XClose />
        </IconButton>
      </div>
      <form
        className="flex items-center flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          label="SPECIFIC FIELD"
          required={false}
          className="w-full"
          options={fieldsOptions}
          value={fieldKey.value}
          onChange={onFieldChange}
        />
        <div className="flex items-center gap-2">
          <StringOrNumberValue
            type="string"
            label="VALUE"
            value={valueField.value}
            onChange={(e) => {
              valueField.onChange(e);
              debounceUpdate();
            }}
            enum={selectedField?.enum}
          />
          <Tooltip title="Exact match">
            <IconButton
              className={clsx(
                'w-6 h-6',
                exactField.value && 'text-primary-500',
              )}
              onClick={() => {
                exactField.onChange(!exactField.value);
                debounceUpdate();
              }}
            >
              <Target1 />
            </IconButton>
          </Tooltip>
        </div>
      </form>
    </div>
  );
}
