import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSettingsCog2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12 8.464a3.535 3.535 0 1 0 0 7.07 3.535 3.535 0 0 0 0-7.07ZM9.643 12a2.357 2.357 0 1 1 4.714 0 2.357 2.357 0 0 1-4.714 0Z"
      fill="currentColor"
    />
    <path
      d="M18.058 18.596 16.7 18a1.351 1.351 0 0 0-1.211.067 1.322 1.322 0 0 0-.648 1.01l-.163 1.476a.725.725 0 0 1-.553.626 9.17 9.17 0 0 1-4.252 0 .725.725 0 0 1-.552-.626l-.163-1.475a1.337 1.337 0 0 0-1.25-1.183 1.331 1.331 0 0 0-.607.11l-1.358.596a.723.723 0 0 1-.82-.17 9.479 9.479 0 0 1-2.127-3.67.726.726 0 0 1 .263-.798l1.2-.887a1.336 1.336 0 0 0 0-2.152l-1.2-.884a.726.726 0 0 1-.263-.8 9.475 9.475 0 0 1 2.129-3.666.723.723 0 0 1 .82-.168L7.294 6A1.341 1.341 0 0 0 9.16 4.92l.165-1.476a.725.725 0 0 1 .562-.628 10.31 10.31 0 0 1 2.124-.245c.709.008 1.414.09 2.106.245a.725.725 0 0 1 .562.628l.164 1.476a1.337 1.337 0 0 0 1.326 1.193c.183 0 .365-.039.534-.114l1.352-.594a.723.723 0 0 1 .82.168A9.472 9.472 0 0 1 21 9.24a.725.725 0 0 1-.262.798l-1.198.886A1.34 1.34 0 0 0 18.993 12c0 .424.206.824.549 1.076l1.199.885a.727.727 0 0 1 .262.8 9.479 9.479 0 0 1-2.127 3.666.722.722 0 0 1-.819.17Zm-4.28-.155a2.484 2.484 0 0 1 2.242-1.727c.396-.022.79.049 1.153.207l1.056.463a8.3 8.3 0 0 0 1.548-2.67l-.934-.688v-.001a2.52 2.52 0 0 1-1.029-2.026c0-.807.39-1.556 1.027-2.024h.002l.932-.69a8.295 8.295 0 0 0-1.548-2.669l-1.048.46-.001.002a2.49 2.49 0 0 1-1.01.213 2.515 2.515 0 0 1-2.498-2.24v-.002l-.128-1.147a9.017 9.017 0 0 0-1.532-.152 9.2 9.2 0 0 0-1.552.152L10.33 5.05a2.52 2.52 0 0 1-3.508 2.03l-1.05-.463a8.295 8.295 0 0 0-1.548 2.67l.935.688a2.515 2.515 0 0 1 0 4.05l-.935.69a8.3 8.3 0 0 0 1.548 2.674l1.056-.463a2.51 2.51 0 0 1 3.502 2.02v.004l.127 1.15c1.02.2 2.067.2 3.086 0l.127-1.153c.02-.173.055-.343.109-.506h-.001Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettingsCog2;
