import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m13.924 8.065-4.68 4.68a.317.317 0 0 1-.463 0l-4.68-4.68a.318.318 0 0 1 0-.462l.502-.503a.318.318 0 0 1 .462 0l3.947 3.948L12.96 7.1a.318.318 0 0 1 .462 0l.502.503a.317.317 0 0 1 0 .462Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDown;
