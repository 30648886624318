import {
  Issue,
  IssueActionType,
  IssueActivity,
  IssueStatus,
  UpdateIssueParams,
} from '@tensorleap/api-client';
import { useCallback, useMemo } from 'react';
import { useAuth } from '../auth/AuthContext';
import { fullIssueTypeText, getUserId, useUserLocalName } from './issueHelper';
import { useTeamUsers } from '../core/data-fetching/users';
import { useSaveVersionState } from '../version-control/useSaveVersionState';
import { IssueTags } from './IssueTags';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { format } from 'date-fns';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { EditableSelectCell } from '../ui/atoms/EditableSelectCell';
import clsx from 'clsx';

export type IssueInfoProps = {
  selectedIssue: Issue | undefined;
  updateIssue: (updateIssueParams: UpdateIssueParams) => Promise<void>;
  isExpanded: boolean;
};

export function IssueInfo({
  selectedIssue,
  updateIssue,
  isExpanded,
}: IssueInfoProps): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const orgUsers = useTeamUsers();
  const { user } = useAuth();
  const { allBranches } = useSaveVersionState();

  const assigneeNames = useMemo(
    () => orgUsers.map((x) => x.local.name),
    [orgUsers],
  );

  const branchOptions = useMemo(() => {
    return allBranches.map(({ title }) => title);
  }, [allBranches]);

  const createdByName = useUserLocalName(selectedIssue?.createdBy, orgUsers);

  const assignedUserName = useUserLocalName(
    selectedIssue?.assignment,
    orgUsers,
  );

  const createdAtFormatted = useMemo(
    () =>
      selectedIssue
        ? format(selectedIssue.createdAt, 'MMM dd, yyyy, HH:mm')
        : null,
    [selectedIssue],
  );

  const updateStatus = useCallback(
    async (newStatus: string) => {
      if (!newStatus || !selectedIssue?.cid) {
        return;
      }
      await updateIssue({
        cid: selectedIssue.cid,
        status: newStatus as IssueStatus,
        projectId,
      });
    },
    [projectId, selectedIssue?.cid, updateIssue],
  );

  const updateAssignee = useCallback(
    async (
      newAssignee: string | undefined,
      createdByUserId: string | undefined,
    ) => {
      const assignedToUserId = getUserId(newAssignee, orgUsers);
      if (!assignedToUserId || !createdByUserId || !selectedIssue) {
        return;
      }

      const updatedActivities: IssueActivity[] = [
        ...selectedIssue.activities,
        {
          createdAt: new Date(),
          updatedAt: new Date(),
          createdBy: createdByUserId,
          actionType: IssueActionType.Assignment,
          action: { assignment: { assignedUser: assignedToUserId } },
        },
      ];
      await updateIssue({
        projectId,
        cid: selectedIssue.cid,
        assignment: assignedToUserId,
        activities: updatedActivities,
      });
    },
    [orgUsers, projectId, selectedIssue, updateIssue],
  );

  const updateBranch = useCallback(
    async (newBranch: string | undefined) => {
      if (!newBranch || !selectedIssue?.cid) {
        return;
      }
      await updateIssue({
        projectId,
        cid: selectedIssue.cid,
        branch: newBranch,
      });
    },
    [projectId, selectedIssue?.cid, updateIssue],
  );
  return (
    <div className="flex flex-1 flex-col gap-4 mx-4">
      <div className="flex flex-1 flex-col h-fit flex-wrap pr-10 ">
        <span className="w-fit h-full mt-2 pr-4">Tags:</span>
        <IssueTags
          issue={selectedIssue}
          isEditable={true}
          updateIssue={updateIssue}
        />
      </div>
      <div
        className={clsx(
          'flex w-full h-full gap-4',
          isExpanded ? 'flex-row' : 'flex-col',
        )}
      >
        <div className="flex gap-4 flex-col w-full h-fit">
          <div className="flex flex-row w-full h-fit justify-start items-center gap-4">
            <EditableSelectCell
              value={selectedIssue?.status}
              label="Status"
              optionToLabel={(x) => fullIssueTypeText[x as IssueStatus]}
              options={Object.keys(IssueStatus)}
              onChange={(x) =>
                updateStatus((x as IssueStatus) || IssueStatus.Open)
              }
            />
          </div>
          <div className="flex w-full h-fit justify-center items-center">
            <div className="flex flex-row w-full h-fit justify-start items-center gap-4">
              <EditableSelectCell
                value={selectedIssue?.branch}
                label="Branch"
                options={branchOptions}
                onChange={(x) => updateBranch(x as string)}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 flex-col w-full h-fit">
          <EditableSelectCell
            value={assignedUserName}
            label="Assignee"
            options={assigneeNames}
            onChange={(x) => updateAssignee(x as string, user?.cid)}
          />
          <div className="flex flex-col w-80 gap-4 break-words flex-wrap">
            <div className="flex flex-row w-full h-fit gap-2 mx-3">
              <span className="text-gray-400">Created At:</span>
              <span>{createdAtFormatted}</span>
              <span className="text-gray-400">by</span>
              {<CircledIcon text={createdByName} borderStyle="border-2" />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
