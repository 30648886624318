import { MappingError, MappingErrorType } from '@tensorleap/api-client';
import yaml from 'js-yaml';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDatasets } from './DatasetsContext';
import { useNetworkMapContext } from './NetworkMapContext';
import { useDebounce } from './useDebounce';
import { useCurrentProject } from './CurrentProjectContext';
import api from './api-client';
import { reorganizeMap } from '../network-editor/autoorganize';
import { GraphErrorKind } from '../network-editor/wizard/types';
import download from 'downloadjs';

export const FILE_MAPPING_NAME = 'leap_mapping.yaml';
const IGNORE_YAML_MAPPING_KEYS = ['id', 'arg_names'];

export type AllMappingErrorTypes =
  | GraphErrorKind.datasetMappingCreateUpdateApply
  | GraphErrorKind.datasetMappingApplyError
  | GraphErrorKind.datasetMappingApplyWarning;

export const MAPPING_ERROR_TYPES_MAP: Record<
  MappingErrorType,
  AllMappingErrorTypes
> = {
  datasetMappingApplyError: GraphErrorKind.datasetMappingApplyError,
  datasetMappingApplyWarning: GraphErrorKind.datasetMappingApplyWarning,
};

export interface MappingUtils {
  addOrUpdateNetworkMapping: () => void;
  applyDatasetMapping: () => Promise<void>;
  mappingErrors: MappingError[] | undefined;
  downloadMapping: () => void;
}
export function useMappingUtils(): MappingUtils {
  const {
    currentModelGraph,
    overrideModelGraph,
    isModelLayersGrouped,
    toggleModelNodesGrouping,
  } = useCurrentProject();
  const {
    handleSave,
    datasetMappingFileExists,
    datasetMappingFileContent,
    networkMapping,
    virtualFs,
  } = useDatasets();
  const { ungroupedNetworkModelGraph } = useNetworkMapContext();

  const { setFileMap, datasetVersion, setCurrentFileName, hasUnsavedCode } =
    virtualFs;

  const [apiApplyMappingErrors, setApiApplyMappingErrors] = useState<
    MappingError[] | undefined
  >();
  const applyDatasetMapping = useCallback(async () => {
    if (!currentModelGraph || !ungroupedNetworkModelGraph) {
      return;
    }
    const applyMappingResponse = await api.applyDatasetMapping({
      modelGraph: ungroupedNetworkModelGraph,
      yaml: datasetMappingFileContent,
      datasetVersionId: datasetVersion?.cid,
    });
    if (!applyMappingResponse) {
      return;
    }
    const { mappingErrors, modelGraph } = applyMappingResponse;
    setApiApplyMappingErrors(mappingErrors);
    if (!modelGraph) {
      return;
    }
    modelGraph.nodes = reorganizeMap(modelGraph.nodes);

    if (isModelLayersGrouped) {
      toggleModelNodesGrouping(modelGraph, isModelLayersGrouped);
    } else {
      overrideModelGraph(modelGraph);
    }
  }, [
    currentModelGraph,
    datasetVersion?.cid,
    datasetMappingFileContent,
    isModelLayersGrouped,
    overrideModelGraph,
    toggleModelNodesGrouping,
    ungroupedNetworkModelGraph,
  ]);

  const updateVirtualFsFile = useCallback(
    (value, fileName) => {
      setFileMap((current) => ({ ...current, [fileName]: value }));
    },
    [setFileMap],
  );

  const debounceHandleSave = useDebounce(
    (noParse: boolean) => handleSave(noParse),
    1000,
  );

  const addOrUpdateNetworkMapping = useCallback(() => {
    const noParse = !hasUnsavedCode;
    if (datasetMappingFileExists) {
      updateVirtualFsFile(networkMapping, FILE_MAPPING_NAME);
    } else {
      setFileMap((current) => {
        setCurrentFileName(FILE_MAPPING_NAME);
        return { [FILE_MAPPING_NAME]: networkMapping, ...current };
      });
    }
    debounceHandleSave(noParse);
  }, [
    hasUnsavedCode,
    datasetMappingFileExists,
    debounceHandleSave,
    updateVirtualFsFile,
    networkMapping,
    setFileMap,
    setCurrentFileName,
  ]);

  useEffect(() => {
    setApiApplyMappingErrors(undefined);
  }, [datasetMappingFileContent]);

  const downloadMapping = useCallback(() => {
    download(networkMapping, FILE_MAPPING_NAME, 'text/yaml');
  }, [networkMapping]);

  return useMemo(
    () => ({
      mappingErrors: apiApplyMappingErrors,
      addOrUpdateNetworkMapping,
      applyDatasetMapping,
      downloadMapping,
    }),
    [
      addOrUpdateNetworkMapping,
      apiApplyMappingErrors,
      applyDatasetMapping,
      downloadMapping,
    ],
  );
}

export function compareYamlStringsIgnoreId(
  yamlStringA: string,
  yamlStringB: string,
): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function deepEqual(a: any, b: any): boolean {
    if (a === b || (Number.isNaN(a) && Number.isNaN(b))) return true;

    if (
      typeof a !== 'object' ||
      a === null ||
      typeof b !== 'object' ||
      b === null
    ) {
      return false;
    }

    if (Array.isArray(a) && Array.isArray(b)) {
      return (
        a.length === b.length &&
        a.every((item) => b.some((i) => deepEqual(item, i)))
      );
    }

    const keysA = Object.keys(a).filter(
      (k) => !IGNORE_YAML_MAPPING_KEYS.includes(k),
    );
    const keysB = Object.keys(b).filter(
      (k) => !IGNORE_YAML_MAPPING_KEYS.includes(k),
    );

    return (
      keysA.length === keysB.length &&
      keysA.every((k) => keysB.includes(k) && deepEqual(a[k], b[k]))
    );
  }

  try {
    const yamlA = yaml.load(yamlStringA);
    const yamlB = yaml.load(yamlStringB);

    return deepEqual(yamlA, yamlB);
  } catch (error) {
    console.error('Failed to compare yaml mappings', error);
    return false;
  }
}
