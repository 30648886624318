import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useDebounce } from '../../../core/useDebounce';

export function useDebounceOnInputChange(
  value: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
  ms: number,
): [value: string, onChange: ChangeEventHandler<HTMLInputElement>] {
  const [currentValue, setCurrentValue] = useState(value);

  const emitOnChange = useDebounce(onChange, ms);
  const onCurrentChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setCurrentValue(e.target.value);
      emitOnChange(e);
    },
    [emitOnChange],
  );

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return [currentValue, onCurrentChange];
}
