import { useMemo } from 'react';
import { Issue, IssueStatus, UpdateIssueParams } from '@tensorleap/api-client';
import { IssueCard } from './IssueCard';
import { useTeamUsers } from '../core/data-fetching/users';
import { isAfter } from 'date-fns';
import { Setter } from '../core/types';

export type AllIssuesDrawerContentParams = {
  issues: Issue[];
  selectedIssue: Issue | undefined;
  setSelectedIssue: Setter<Issue | undefined>;
  updateIssue: (updateIssueParams: UpdateIssueParams) => Promise<void>;
  isExpanded: boolean;
};

const statusSortOrder: Record<IssueStatus, number> = {
  AtWork: 0,
  Open: 1,
  Closed: 2,
};

export function AllIssuesDrawerContent({
  issues,
  selectedIssue,
  setSelectedIssue,
  updateIssue,
  isExpanded,
}: AllIssuesDrawerContentParams): JSX.Element {
  const orgUsers = useTeamUsers();
  const sortedIssues = useMemo(
    () =>
      issues.sort(
        (
          { createdAt: createdAtA, status: statusA }: Issue,
          { createdAt: createdAtB, status: statusB }: Issue,
        ) => {
          return (
            statusSortOrder[statusA] - statusSortOrder[statusB] ||
            (isAfter(createdAtA, createdAtB) ? -1 : 1)
          );
        },
      ),
    [issues],
  );

  return (
    <div className="flex flex-col h-full w-full gap-5 items-center overflow-auto pb-20 mt-5 mb-20 scrollbar-hide px-6">
      {sortedIssues.map((issue, index) => (
        <div
          className="w-full"
          onClick={() => setSelectedIssue(issue)}
          key={index}
        >
          <IssueCard
            issue={issue}
            orgUsers={orgUsers}
            updateIssue={updateIssue}
            colorOnSelect={isExpanded && issue.cid === selectedIssue?.cid}
          />
        </div>
      ))}
    </div>
  );
}
