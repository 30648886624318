import { IconButton, TextField, Checkbox, Tooltip } from '../ui/mui';
import { XClose } from '../ui/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { getBranchTitle, useSaveVersionState } from './useSaveVersionState';
import { ImportModelType } from '@tensorleap/api-client';
import api, { addBasePathToURL } from '../core/api-client';
import { uploadFile } from '../core/file-helper';
import { LoadingSvg } from '../ui/atoms/LoadingSvg';
import { Select } from '../ui/atoms/Select';
import { Input } from '../ui/atoms/Input';
import { buildRenderInputText } from '../ui/atoms/utils/buildRenderInputText';
import { useToggle } from '../core/useToggle';
import { useDatasets } from '../core/DatasetsContext';
import { Button } from '../ui/atoms/Button';
import { Autocomplete } from '@mui/material';

const MODEL_FORMAT: Record<ImportModelType, string> = {
  JSON_TF2: '.json',
  ONNX: '.onnx',
  PB_TF2: '.gz',
  H5_TF2: '.h5',
};

const MODEL_FORMAT_OPTIONS = Object.keys(MODEL_FORMAT);

export interface ImportModelProps {
  currentProjectId: string;
  onSaveCancelled: () => void;
}

export function ImportModel({
  currentProjectId,
  onSaveCancelled,
}: ImportModelProps): JSX.Element {
  const {
    revisionName,
    allBranches,
    branch,
    modelName,
    datasetId,
    datasetOptions,
    handleDatasetChange,
    handleRevisionChange,
    handleBranchChange,
    handleBranchFilterOptions,
    handleOptionalBranch,
  } = useSaveVersionState();

  const { getDatasetSpecificVersionMappingFileContent } = useDatasets();

  const fileRef = useRef<HTMLInputElement>();
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [transformInputsToggle, setTransformInputsToggle] = useToggle(false);

  const [fileType, setFileType] = useState<ImportModelType>(
    ImportModelType.JsonTf2,
  );

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [isUploading, setIsUploading] = useState(false);

  const handleFileTypeChange = useCallback((changedValue) => {
    setFileType(changedValue);
    setSelectedFile(null);
    if (fileRef?.current?.value) fileRef.current.value = '';
  }, []);

  const changeFileHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        setSelectedFile(null);
        return;
      }
      event.preventDefault();
      setSelectedFile(event.target.files[0]);
    },
    [],
  );

  const handleImportModel = useCallback(async () => {
    if (!branch || !selectedFile) {
      return;
    }

    setIsUploading(true);
    try {
      const { url, fileName } = await api.getUploadSignedUrl({
        fileName: selectedFile.name,
      });
      const urlWithBasePath = addBasePathToURL(url);
      await uploadFile(urlWithBasePath, selectedFile);
      const mappingYamlContent =
        await getDatasetSpecificVersionMappingFileContent(datasetId);
      await api.importModel({
        fileName,
        versionName: revisionName,
        projectId: currentProjectId,
        branchName: branch.title,
        model_type: fileType,
        modelName,
        transform_inputs:
          fileType === ImportModelType.Onnx && transformInputsToggle,
        datasetId,
        mappingYaml: mappingYamlContent,
      });
    } catch (e) {
      console.error(e);
    } finally {
      onSaveCancelled();
      setIsUploading(false);
    }
  }, [
    branch,
    selectedFile,
    revisionName,
    currentProjectId,
    fileType,
    modelName,
    transformInputsToggle,
    datasetId,
    getDatasetSpecificVersionMappingFileContent,
    onSaveCancelled,
  ]);

  useEffect(() => {
    firstInputRef.current?.focus();
  }, []);

  const renderExperimentInputText = useMemo(
    () => buildRenderInputText({ label: 'Experiment' }),
    [],
  );

  const inputBgClass = 'bg-gray-850 w-full';

  return (
    <div className="flex flex-col p-2 gap-2 bg-primary-800 items-start">
      <div className="flex flex-row justify-between items-start w-full">
        <span className="font-normal text-xs leading-relaxed">
          Describe Your Model:
        </span>
        <IconButton onClick={onSaveCancelled} size="small">
          <XClose />
        </IconButton>
      </div>
      <Input
        ref={firstInputRef}
        label="Version Name"
        value={revisionName}
        onChange={handleRevisionChange}
        containerProps={{ className: inputBgClass }}
        small
      />
      <Autocomplete
        className={inputBgClass}
        size="small"
        value={branch}
        onChange={handleBranchChange}
        filterOptions={handleBranchFilterOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        getOptionLabel={handleOptionalBranch}
        renderOption={(props, option) => (
          <li {...props}>{getBranchTitle(option)}</li>
        )}
        options={allBranches}
        renderInput={renderExperimentInputText}
      />
      <span className="font-normal text-xs leading-relaxed pb-2">
        Upload File:
      </span>
      <Select
        label="File Type"
        className={inputBgClass}
        value={fileType}
        onChange={handleFileTypeChange}
        options={MODEL_FORMAT_OPTIONS}
        small
      />
      <TextField
        className={inputBgClass}
        InputProps={{
          className: '!max-h-11',
        }}
        variant="outlined"
        type="search"
        inputRef={fileRef}
        inputProps={{
          accept: MODEL_FORMAT[fileType],
          type: 'file',
          onChange: changeFileHandler,
        }}
      />
      <Select
        label="Select Dataset (Optional)"
        required={false}
        className={inputBgClass}
        value={datasetId}
        onChange={handleDatasetChange}
        options={datasetOptions}
        small
      />
      <div className="flex justify-between mt-2 w-full">
        <div
          className={clsx(
            'flex flex-row items-center',
            fileType !== ImportModelType.Onnx && 'invisible',
          )}
        >
          <Tooltip
            title="Check this checkbox in case your inputs are in channels first format,
           it will transform your inputs from channels first to channels last format"
          >
            <Checkbox
              checked={transformInputsToggle}
              color="default"
              onChange={setTransformInputsToggle}
            />
          </Tooltip>
          Transform inputs
        </div>
        <div>
          <Tooltip
            title={
              !branch || !revisionName || !selectedFile || isUploading
                ? 'Please fill all the required fields'
                : 'Import'
            }
          >
            <Button
              onClick={handleImportModel}
              disabled={
                !branch || !revisionName || !selectedFile || isUploading
              }
            >
              {isUploading ? (
                <>
                  <LoadingSvg />
                  Uploading...
                </>
              ) : (
                'Import'
              )}
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
