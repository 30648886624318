import clsx from 'clsx';
import { Paper } from '../ui/mui';
import { Alert, FwArrowIcon } from '../ui/icons';
import { ActionButton } from '../ui/molecules/ActionButton';
import { useCallback } from 'react';
import api from '../core/api-client';
import { useHistory } from 'react-router-dom';
import { URLS_ENUM } from '../url/url-builder';

export function ConcurrentUsersPage(): JSX.Element {
  const history = useHistory();
  const onRun = useCallback(async () => {
    await api.resolveConcurrentUsersConflict();
    history.push(URLS_ENUM.WELCOME);
    window.location.reload();
  }, [history]);

  return (
    <div className="flex flex-row justify-center items-center h-full">
      <Paper
        component="main"
        className="flex flex-col h-[230px] w-[450px] rounded-2xl drop-shadow-xl bg-gray-800"
      >
        <div
          className={clsx(
            'text-sm',
            'leading-relaxed',
            'uppercase',
            'text-white',
            'rounded-t-2xl',
            'flex',
            'flex-row',
            'justify-start',
            'items-center',
            'content-center',
            'pl-4',
            'gap-4',
            'bg-dashboard-500',
            'h-12',
          )}
        >
          <Alert />
          <h1>logged out</h1>
        </div>

        <p className="flex p-4 flex-1 justify-center items-center text-base">
          Another user used these credentials to login, and logged you out of
          the system. If you need to continue your work here, you can login
          again and terminate the other session.
        </p>

        <div className="flex flex-row justify-end items-center p-4 bg-gray-850 rounded-b-2xl h-14">
          <ActionButton icon={<FwArrowIcon className="p-1" />} onRun={onRun}>
            log me in
          </ActionButton>
        </div>
      </Paper>
    </div>
  );
}
