import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgMetricIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
    <path
      d="M10.875 15.44c.6 0 .9.255.9.765 0 .53-.3.795-.9.795h-5.07c-.61 0-.915-.265-.915-.795 0-.51.305-.765.915-.765h1.56V8.6l-1.44.885a.776.776 0 0 1-.375.12c-.21 0-.39-.09-.54-.27a.954.954 0 0 1-.21-.6c0-.29.13-.515.39-.675l2.325-1.44c.3-.18.585-.27.855-.27.28 0 .505.085.675.255.17.17.255.405.255.705v8.13h1.575ZM15.354 17.135c-1.25 0-2.205-.46-2.865-1.38-.66-.93-.99-2.28-.99-4.05 0-1.76.33-3.1.99-4.02.66-.93 1.615-1.395 2.865-1.395 1.25 0 2.205.465 2.865 1.395.66.92.99 2.26.99 4.02 0 1.76-.33 3.105-.99 4.035-.66.93-1.615 1.395-2.865 1.395Zm0-1.515c.68 0 1.175-.31 1.485-.93.31-.63.465-1.625.465-2.985 0-1.37-.155-2.36-.465-2.97-.31-.62-.805-.93-1.485-.93s-1.175.31-1.485.93c-.31.62-.465 1.61-.465 2.97s.155 2.355.465 2.985c.31.62.805.93 1.485.93Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMetricIcon;
