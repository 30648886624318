import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M8.787 1.247A.808.808 0 0 1 9.379 1c.23 0 .426.082.586.247.165.161.248.356.248.586V8.5h6.666c.23 0 .426.082.586.247.165.161.248.356.248.586 0 .23-.083.428-.248.593a.798.798 0 0 1-.586.24h-6.666v6.667c0 .23-.083.426-.248.586a.787.787 0 0 1-.586.248.808.808 0 0 1-.592-.248.798.798 0 0 1-.241-.586v-6.666H1.879a.82.82 0 0 1-.833-.834c0-.23.08-.425.24-.586A.808.808 0 0 1 1.88 8.5h6.667V1.833c0-.23.08-.425.24-.586Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlus;
