import { pick } from 'lodash';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useLocalStorage } from './useLocalStorage';

const FEATURE_FLAGS = {
  noAnimation: false,
  publishableProject: false,
  useUploadDownloadProject: false,
  useDashboardRangeFilter: false,
} as const;

export type FeatureFlagRecord = Readonly<
  Record<keyof typeof FEATURE_FLAGS, boolean>
>;

export const FEATURE_FLAGS_KEY = 'feature-flags';
export interface IFeatureFlagsContext {
  featureFlags: FeatureFlagRecord;
  toggleFeatureFlag: (_name: keyof FeatureFlagRecord) => void;
  setFeatureFlag: (_name: keyof FeatureFlagRecord, _value: boolean) => void;
}
export const FeatureFlagsContext = createContext<IFeatureFlagsContext>({
  featureFlags: FEATURE_FLAGS,
  setFeatureFlag: (_name, _value) => undefined,
  toggleFeatureFlag: (_name) => undefined,
});

type FeatureFlagsProviderProps = PropsWithChildren<{
  defaultFlags?: Partial<FeatureFlagRecord>;
}>;

export const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({
  children,
  defaultFlags = {},
}) => {
  const [storedFeatureFlags, setStoredFeatureFlags] =
    useLocalStorage<FeatureFlagRecord>(FEATURE_FLAGS_KEY, FEATURE_FLAGS);

  const featureFlags = useMemo(() => {
    const merged = { ...FEATURE_FLAGS, ...defaultFlags, ...storedFeatureFlags };
    const withoutOldKeys = pick(
      merged,
      Object.keys(FEATURE_FLAGS),
    ) as FeatureFlagRecord;
    return withoutOldKeys;
  }, [defaultFlags, storedFeatureFlags]);

  const setFeatureFlag = useCallback(
    (name: keyof FeatureFlagRecord, value: boolean) => {
      setStoredFeatureFlags((ffRecord) => ({
        ...ffRecord,
        [name]: value,
      }));
    },
    [setStoredFeatureFlags],
  );
  const toggleFeatureFlag = useCallback(
    (name: keyof FeatureFlagRecord) => {
      setStoredFeatureFlags((ffRecord) => ({
        ...ffRecord,
        [name]: !ffRecord[name],
      }));
    },
    [setStoredFeatureFlags],
  );

  const value = useMemo(
    () => ({
      featureFlags,
      setFeatureFlag,
      toggleFeatureFlag,
    }),
    [featureFlags, setFeatureFlag, toggleFeatureFlag],
  );
  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}
