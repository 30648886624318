import { useMemo } from 'react';
import { Node } from '@tensorleap/engine-contract';

import { useDescriptors } from './descriptor/utils';
import { lastExistedProp } from '../core/array-helper';

export function DisplayNodeName({ node }: { node: Node }) {
  const descriptors = useDescriptors(node);
  const getDisplayName = useMemo(
    () => lastExistedProp(descriptors, 'getDisplayName'),
    [descriptors],
  );

  return <>{getDisplayName?.(node) ?? node.name}</>;
}
