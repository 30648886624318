import {
  createContext,
  FC,
  useCallback,
  useMemo,
  useState,
  ChangeEvent,
  useContext,
  useEffect,
} from 'react';
import {
  Dataset,
  DatasetVersion,
  GenericBaseImage,
  TestStatus,
} from '@tensorleap/api-client';
import api from './api-client';
import useAsyncEffect from './useAsyncEffect';

import {
  VirtualFS,
  useVirtualFS,
} from '../assets-management/code-integration/utils/dataset-editor-content';
import { Setter } from './types';
import { generateConnectionsMapYaml } from '../network-editor/networkAssetsMapping';
import { useFetchDatasets } from './data-fetching/datasets';
import { extractErrorsAndInfoFromDatasetStatus } from '../assets-management/code-integration/DatasetUtils';
import { useLocalStorage } from './useLocalStorage';
import { KeyedMutator } from 'swr';
import { useFetchDatasetVersions } from './data-fetching/dataset-versions';
import { usePushNotifications } from './PushNotificationsContext';
import { isDatasetParsePushMessage } from './websocket-message-types';
import { uploadSourceCode } from '../assets-management/code-integration/utils/source-code';
import { useHistory } from 'react-router-dom';
import { useNetworkMapContext } from './NetworkMapContext';
import { FILE_MAPPING_NAME, compareYamlStringsIgnoreId } from './mappingUtils';
import { NetworkTabsEnum } from '../network-editor/NetworkDrawer';
import { orderBy, uniq } from 'lodash';
import { useFetchGenericBaseImageTypes } from './data-fetching/genericBaseImageTypes';

const DATASET_FONT_SIZE_KEY = 'dataset-font-size';
const DEFAULT_FONT_SIZE = 12;
const MAX_FONT_SIZE = 28;
const MIN_FONT_SIZE = 7;

export interface DatasetsContextInterface {
  changeDatasetContext: (
    datasetId: string | undefined,
    datasetVersion: DatasetVersion | undefined,
  ) => void;

  dataset: Dataset | undefined;
  virtualFs: VirtualFS;
  fontSize: number;
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
  fullScreenMode: boolean;
  setFullScreenMode: Setter<boolean>;
  isErrorMessageOpen: boolean;
  setIsErrorMessageOpen: Setter<boolean>;
  datasetName: string;
  setDatasetName: Setter<string>;
  handleDatasetNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  switchDatasetVersion: (version: DatasetVersion) => void;
  datasets: Dataset[];
  fetchDatasets: KeyedMutator<Dataset[]>;
  datasetsMap: Map<string, Dataset>;
  datasetVersions: DatasetVersion[];
  fetchDatasetVersions: KeyedMutator<DatasetVersion[]>;
  editorDatasetVersion: DatasetVersion | undefined;
  datasetMappingFileExists: boolean;
  networkAndDatasetMappingsAreEqual: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEditorChange: (value: any) => void;
  errors: Record<string, string>;
  selectedErrorFn: string | undefined;
  setSelectedErrorFn: Setter<string | undefined>;
  handleSaveNewDataset: () => void;
  handleSave: (noParse?: boolean) => void;
  resetChanges: () => void;
  datasetMappingFileContent: string;
  getDatasetSpecificVersionMappingFileContent: (
    datasetId: string | undefined,
  ) => Promise<string | undefined>;
  handleEditorDatasetVersionSelected: (id: string | undefined) => void;
  networkMapping: string;
  updateCodeIntegrationVersion: (vfsOnly?: boolean) => Promise<void>;
  branchOptions: string[];
  branchOptionsWithValidVersion: string[];
  handleBranchChange: (newBranch?: string) => void;
  codeIntegrationVersionToVersionIndexMap: Map<string, number>;
  baseImageTypes: GenericBaseImage[];
  selectedGenericBaseImageType: string;
  handleSelectedGenericBaseImageChange: (
    value: string | undefined,
    option: GenericBaseImage | null,
  ) => void;
}

export const DatasetsContext = createContext<DatasetsContextInterface>({
  changeDatasetContext: () => undefined,
  dataset: undefined,
  virtualFs: {} as VirtualFS,
  fontSize: DEFAULT_FONT_SIZE,
  increaseFontSize: () => undefined,
  decreaseFontSize: () => undefined,
  fullScreenMode: false,
  setFullScreenMode: () => undefined,
  isErrorMessageOpen: true,
  setIsErrorMessageOpen: () => undefined,
  datasetName: '',
  setDatasetName: () => undefined,
  handleDatasetNameChange: () => undefined,
  switchDatasetVersion: () => undefined,
  editorDatasetVersion: undefined,
  datasets: [],
  fetchDatasets: async () => Promise.resolve([]),
  datasetsMap: new Map(),
  datasetVersions: [],
  fetchDatasetVersions: async () => Promise.resolve([]),
  datasetMappingFileExists: false,
  networkAndDatasetMappingsAreEqual: false,
  handleEditorChange: () => undefined,
  errors: {},
  selectedErrorFn: undefined,
  setSelectedErrorFn: () => undefined,
  handleSaveNewDataset: () => undefined,
  handleSave: () => undefined,
  resetChanges: () => undefined,
  datasetMappingFileContent: '',
  getDatasetSpecificVersionMappingFileContent: () => Promise.resolve(undefined),
  handleEditorDatasetVersionSelected: () => undefined,
  networkMapping: 'string',
  updateCodeIntegrationVersion: () => Promise.resolve(undefined),
  branchOptions: [],
  branchOptionsWithValidVersion: [],
  handleBranchChange: () => undefined,
  codeIntegrationVersionToVersionIndexMap: new Map(),
  baseImageTypes: [],
  selectedGenericBaseImageType: '',
  handleSelectedGenericBaseImageChange: () => undefined,
});

export const DatasetsProvider: FC = ({ children }) => {
  const [datasetId, setDatasetId] = useState<string | undefined>();

  const virtualFs = useVirtualFS();
  const {
    fileMap,
    setFileMap,
    entryFile,
    currentFileName,
    datasetVersion,
    originalFileMap,
    secretManagerId,
    commit,
    setCommit,
    setBranch,
    branch,
    setSecretManagerId,
    getDatasetVersionFileMap,
    switchDatasetVersion,
  } = virtualFs;

  const { datasets, refetch: fetchDatasets } = useFetchDatasets();

  const { baseImageTypes, defaultBaseImageType } =
    useFetchGenericBaseImageTypes();

  const [selectedGenericBaseImageType, setSelectedGenericBaseImageType] =
    useState<string>(
      datasetVersion?.genericBaseImageType || defaultBaseImageType,
    );

  useEffect(() => {
    if (datasetVersion?.cid && defaultBaseImageType) {
      setSelectedGenericBaseImageType(
        datasetVersion?.genericBaseImageType || defaultBaseImageType,
      );
    }
  }, [
    datasetVersion?.cid,
    datasetVersion?.genericBaseImageType,
    defaultBaseImageType,
  ]);

  const { datasetVersions, refetch: fetchDatasetVersions } =
    useFetchDatasetVersions({ datasetId });

  const { lastServerMessage } = usePushNotifications();

  const history = useHistory();

  const refetchDatasetData = useCallback(async () => {
    await fetchDatasets();
    await fetchDatasetVersions();
  }, [fetchDatasets, fetchDatasetVersions]);

  useAsyncEffect(async () => {
    if (!isDatasetParsePushMessage(lastServerMessage)) {
      return;
    }
    await refetchDatasetData();
  }, [lastServerMessage]);

  const [fontSize, setFontSize] = useLocalStorage(
    DATASET_FONT_SIZE_KEY,
    DEFAULT_FONT_SIZE,
  );

  const increaseFontSize = useCallback(() => {
    setFontSize((currentFontSize) =>
      Math.min(currentFontSize + 1, MAX_FONT_SIZE),
    );
  }, [setFontSize]);

  const decreaseFontSize = useCallback(() => {
    setFontSize((currentFontSize) =>
      Math.max(currentFontSize - 1, MIN_FONT_SIZE),
    );
  }, [setFontSize]);

  const [datasetName, setDatasetName] = useState('');

  const [fullScreenMode, setFullScreenMode] = useState(false);

  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(true);

  const handleDatasetNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setDatasetName(event.target.value);
    },
    [setDatasetName],
  );

  const datasetsMap = useMemo(
    () => new Map(datasets.map((ds) => [ds.cid, ds])),
    [datasets],
  );

  const { ungroupedNetworkModelGraph, setOpenNetworkTab } =
    useNetworkMapContext();

  const networkMapping = useMemo(() => {
    if (!ungroupedNetworkModelGraph) {
      return '';
    }

    return generateConnectionsMapYaml(ungroupedNetworkModelGraph);
  }, [ungroupedNetworkModelGraph]);

  const datasetMappingFileExists = useMemo(
    () => Object.keys(fileMap).includes(FILE_MAPPING_NAME),
    [fileMap],
  );

  const dataset = useMemo(
    () => (datasetId ? datasetsMap.get(datasetId) : undefined),
    [datasetId, datasetsMap],
  );

  const updateCodeIntegrationVersion = useCallback(
    async (vfsOnly = false) => {
      await fetchDatasetVersions();
      const fetchedDatasets = await fetchDatasets();

      const fetchedDataset = datasetId
        ? fetchedDatasets?.find((ds) => ds.cid === datasetId)
        : undefined;

      const latestVersion = fetchedDataset?.latestVersions.find(
        ({ branch }) => branch === fetchedDataset.defaultBranch,
      )?.latestValid;

      if (!latestVersion) {
        return;
      }

      const currentDataset = fetchedDatasets?.find(
        (dataset) => dataset.cid === latestVersion.datasetId,
      );

      const newDatasetVersions = (await fetchDatasetVersions()) || [];
      if (!newDatasetVersions.length) {
        console.error('Failed to fetch the new dataset versions');
      }
      if (!currentDataset) {
        return;
      }

      switchDatasetVersion(newDatasetVersions[0]);
      if (!vfsOnly) {
        // updateDataset(currentDataset);
      }
      setOpenNetworkTab(NetworkTabsEnum.CodeIntegration);
    },
    [
      datasetId,
      fetchDatasetVersions,
      fetchDatasets,
      setOpenNetworkTab,
      switchDatasetVersion,
    ],
  );

  const handleEditorChange = useCallback(
    (value) => {
      setFileMap((current) => ({ ...current, [currentFileName]: value }));
    },
    [currentFileName, setFileMap],
  );

  const handleSave = useCallback(
    async (noParse?: boolean) => {
      const datasetId = dataset?.cid;

      if (!datasetId) {
        console.error(
          'The current dataset is not defined. something went wrong',
        );
        return;
      }

      const codeUrl = await uploadSourceCode(dataset?.cid, fileMap);
      if (!codeUrl) {
        console.error('Failed to upload source code');
        return;
      }

      if (noParse && datasetVersion) {
        await api.saveDatasetVersionNoParse({
          fromDatasetVersionId: datasetVersion?.cid,
          secretManagerId,
          codeUrl,
          codeEntryFile: entryFile,
          branch,
          note: commit,
          genericBaseImageType: selectedGenericBaseImageType,
        });
        await updateCodeIntegrationVersion();
      } else {
        await api.saveDatasetVersion({
          datasetId,
          setup: datasetVersion?.metadata.setup,
          secretManagerId,
          codeUrl,
          codeEntryFile: entryFile,
          branch,
          note: commit,
          genericBaseImageType: selectedGenericBaseImageType,
        });
        await updateCodeIntegrationVersion(true);
      }
    },
    [
      dataset?.cid,
      fileMap,
      datasetVersion,
      secretManagerId,
      entryFile,
      branch,
      commit,
      selectedGenericBaseImageType,
      updateCodeIntegrationVersion,
    ],
  );

  const resetChanges = useCallback(() => {
    setDatasetName(dataset?.name || '');
    setSecretManagerId(datasetVersion?.metadata.secretManagerId);
    setBranch(datasetVersion?.branch);
    setCommit(datasetVersion?.note ?? '');

    if (!dataset) {
      setFileMap({});
      switchDatasetVersion(undefined);
    } else {
      setFileMap(originalFileMap);
    }
  }, [
    dataset,
    datasetVersion?.metadata.secretManagerId,
    datasetVersion?.branch,
    datasetVersion?.note,
    originalFileMap,
    switchDatasetVersion,
    setFileMap,
    setSecretManagerId,
    setBranch,
    setCommit,
  ]);

  const datasetMappingFileContent = useMemo(
    () => fileMap[FILE_MAPPING_NAME],
    [fileMap],
  );

  const getDatasetSpecificVersionMappingFileContent = useCallback(
    async (datasetId: string | undefined) => {
      if (!datasetId) {
        return undefined;
      }
      const dataset = datasetsMap.get(datasetId);
      if (!dataset) {
        console.error('Something went wrong, failed to find the dataset');
        return;
      }

      const lastValidDatasetVersionId = dataset.latestVersions.find(
        ({ branch }) => branch === dataset.defaultBranch,
      )?.latestValid?.cid;
      if (!lastValidDatasetVersionId) {
        console.error(
          'Something went wrong, failed to find the latest valid dataset version',
        );
        return;
      }
      const datasetVersionfileMap = await getDatasetVersionFileMap(
        lastValidDatasetVersionId,
      );
      return datasetVersionfileMap[FILE_MAPPING_NAME];
    },
    [datasetsMap, getDatasetVersionFileMap],
  );

  useAsyncEffect(async () => {
    if (
      isDatasetParsePushMessage(lastServerMessage) &&
      lastServerMessage.datasetId === datasetId
    ) {
      const newDatasetVersions = await fetchDatasetVersions();

      if (!newDatasetVersions) {
        console.error('Something went wrong, failed to fetch dataset versions');
        return;
      }

      if (lastServerMessage.datasetVersionId === datasetVersion?.cid) {
        const updatedVersion = newDatasetVersions.find(
          (v) => v.cid === datasetVersion.cid,
        );

        if (!updatedVersion) {
          console.error(
            'Something went wrong, failed to find the updated dataset version',
          );
          return;
        }

        switchDatasetVersion(updatedVersion);
      }
    }
  }, [lastServerMessage]);

  const networkAndDatasetMappingsAreEqual = useMemo(
    () =>
      datasetMappingFileExists &&
      compareYamlStringsIgnoreId(datasetMappingFileContent, networkMapping),
    [datasetMappingFileExists, datasetMappingFileContent, networkMapping],
  );

  const errors = useMemo(
    () =>
      datasetVersion?.metadata?.setupStatus
        ? extractErrorsAndInfoFromDatasetStatus(
            datasetVersion.metadata.setupStatus,
          )
        : {},
    [datasetVersion?.metadata?.setupStatus],
  );

  const [selectedErrorFn, setSelectedErrorFn] = useState<string>();

  const changeDatasetContext = useCallback(
    (
      newDatasetId: string | undefined,
      newDatasetVersion: DatasetVersion | undefined,
    ) => {
      if (!newDatasetId || newDatasetId !== datasetId) {
        setDatasetId(newDatasetId);
        resetChanges();
      }

      if (newDatasetVersion && newDatasetVersion.cid !== datasetVersion?.cid)
        switchDatasetVersion(newDatasetVersion);
    },
    [datasetId, datasetVersion, resetChanges, switchDatasetVersion],
  );

  const handleSaveNewDataset = useCallback(async () => {
    const { dataset: newDataset } = await api.addDataset({
      name: datasetName,
    });

    if (!newDataset) {
      console.error('Failed to create a new dataset');
      return;
    }

    const datasetId = newDataset.cid;

    setDatasetId(datasetId);

    const codeUrl = await uploadSourceCode(datasetId, fileMap);
    if (!codeUrl) {
      console.error('Failed to upload source code');
      return;
    }

    await api.saveDatasetVersion({
      datasetId,
      setup: datasetVersion?.metadata.setup,
      secretManagerId,
      codeUrl,
      codeEntryFile: entryFile,
      branch,
      note: commit,
    });

    history.push(`/assets/datasets?id=${datasetId}`);

    const latestValid = newDataset.latestVersions.find(
      ({ branch }) => branch === newDataset.defaultBranch,
    )?.latestValid;

    changeDatasetContext(datasetId, latestValid);
    const newDatasetVersions = await fetchDatasetVersions();
    if (!newDatasetVersions?.[0]) {
      console.error;
      return;
    }
    switchDatasetVersion(newDatasetVersions[0]);
  }, [
    changeDatasetContext,
    datasetName,
    datasetVersion?.metadata.setup,
    branch,
    commit,
    entryFile,
    fetchDatasetVersions,
    fileMap,
    history,
    secretManagerId,
    switchDatasetVersion,
  ]);

  const handleEditorDatasetVersionSelected = useCallback(
    (id: string | undefined) => {
      const selectedDatasetVersion = datasetVersions.find((v) => v.cid === id);
      if (!selectedDatasetVersion) {
        console.error(
          'Something is wrong, failed to find the selected dataset version',
          id,
        );
        return;
      }
      switchDatasetVersion(selectedDatasetVersion);
    },
    [datasetVersions, switchDatasetVersion],
  );

  const branchOptions = useMemo(
    () => uniq(datasetVersions.map((v) => v.branch)),
    [datasetVersions],
  );
  const branchOptionsWithValidVersion = useMemo(
    () =>
      uniq(
        datasetVersions
          .filter(({ testStatus }) => testStatus === TestStatus.TestSuccess)
          .map(({ branch }) => branch),
      ),
    [datasetVersions],
  );

  const handleBranchChange = useCallback(
    async (newBranch?: string) => {
      setBranch(newBranch);
    },
    [setBranch],
  );

  const { codeIntegrationVersionToVersionIndexMap } = useMemo(
    () => createCodeIntegrationVersionToVersionIndexMap(datasetVersions),
    [datasetVersions],
  );

  const handleSelectedGenericBaseImageChange = useCallback((_, option) => {
    setSelectedGenericBaseImageType(option?.id || '');
  }, []);

  const value = useMemo<DatasetsContextInterface>(
    () => ({
      changeDatasetContext,
      dataset,
      virtualFs,
      fontSize,
      increaseFontSize,
      decreaseFontSize,
      fullScreenMode,
      setFullScreenMode,
      isErrorMessageOpen,
      setIsErrorMessageOpen,
      datasetName,
      setDatasetName,
      secretManagerId,
      setSecretManagerId,
      switchDatasetVersion,
      handleDatasetNameChange,
      editorDatasetVersion: datasetVersion,
      datasets,
      fetchDatasets,
      datasetsMap,
      datasetVersions,
      fetchDatasetVersions,
      datasetMappingFileExists,
      networkAndDatasetMappingsAreEqual,
      handleEditorChange,
      errors,
      selectedErrorFn,
      setSelectedErrorFn,
      handleSaveNewDataset,
      handleSave,
      resetChanges,
      datasetMappingFileContent,
      getDatasetSpecificVersionMappingFileContent,
      handleEditorDatasetVersionSelected,
      networkMapping,
      updateCodeIntegrationVersion,
      handleBranchChange,
      branchOptions,
      branchOptionsWithValidVersion,
      codeIntegrationVersionToVersionIndexMap,
      baseImageTypes,
      selectedGenericBaseImageType,
      handleSelectedGenericBaseImageChange,
    }),
    [
      changeDatasetContext,
      dataset,
      virtualFs,
      fontSize,
      increaseFontSize,
      decreaseFontSize,
      fullScreenMode,
      isErrorMessageOpen,
      datasetName,
      secretManagerId,
      setSecretManagerId,
      switchDatasetVersion,
      handleDatasetNameChange,
      datasetVersion,
      datasets,
      fetchDatasets,
      datasetsMap,
      datasetVersions,
      fetchDatasetVersions,
      datasetMappingFileExists,
      networkAndDatasetMappingsAreEqual,
      handleEditorChange,
      errors,
      selectedErrorFn,
      handleSaveNewDataset,
      handleSave,
      resetChanges,
      datasetMappingFileContent,
      getDatasetSpecificVersionMappingFileContent,
      handleEditorDatasetVersionSelected,
      networkMapping,
      updateCodeIntegrationVersion,
      handleBranchChange,
      branchOptions,
      branchOptionsWithValidVersion,
      codeIntegrationVersionToVersionIndexMap,
      baseImageTypes,
      selectedGenericBaseImageType,
      handleSelectedGenericBaseImageChange,
    ],
  );

  return (
    <DatasetsContext.Provider value={value}>
      {children}
    </DatasetsContext.Provider>
  );
};

export const useDatasets = (): DatasetsContextInterface =>
  useContext(DatasetsContext);

type CodeIntegrationVersionToVersionIndexMap = {
  codeIntegrationVersionToVersionIndexMap: Map<string, number>;
  maxVersionByBranch: Map<string, number>;
};

export function createCodeIntegrationVersionToVersionIndexMap(
  datasetVersions: DatasetVersion[],
): CodeIntegrationVersionToVersionIndexMap {
  const orderedByCreation = orderBy(
    datasetVersions,
    ({ createdAt }) => new Date(createdAt),
    'asc',
  );
  const maxVersionByBranch = new Map<string, number>();
  const codeIntegrationVersionToVersionIndexMap = new Map<string, number>();

  orderedByCreation.forEach((dsVersion) => {
    const index = (maxVersionByBranch.get(dsVersion.branch) || 0) + 1;
    maxVersionByBranch.set(dsVersion.branch, index);
    codeIntegrationVersionToVersionIndexMap.set(dsVersion.cid, index);
  });
  return { codeIntegrationVersionToVersionIndexMap, maxVersionByBranch };
}
