import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
export type FetchState =
  | 'loading'
  | 'updating'
  | 'idel'
  | 'refreshing'
  | 'failed';

const loadingState = new Set(['loading', 'refreshing', 'updating']);

export function isLoading(state: FetchState): boolean {
  return loadingState.has(state);
}

type FetcherFunc<T, K> = ((key: K) => Promise<T>) | (() => Promise<T>);

export function useFetcher<T, E = Error, K = unknown>(
  fetcher: FetcherFunc<T, K>,
  key?: K | null,
) {
  const [data, setData] = useState<T>();
  const [state, setState] = useState<FetchState>('idel');
  const [error, setError] = useState<E>();
  const ref = useRef<{ fetcher: FetcherFunc<T, K>; key?: K | null }>({
    fetcher,
    key,
  });
  const fetch = useCallback(
    async (action: 'loading' | 'refreshing' | 'updating' = 'loading') => {
      const _fetcher = ref.current.fetcher;
      const _key = ref.current.key;
      if (_key === null) return;
      setState(action);
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value = await (_key ? _fetcher(_key) : (_fetcher as any)());
        const stop =
          _fetcher !== ref.current.fetcher || _key !== ref.current.key;
        if (stop) return;
        setData(value);
        setState('idel');
      } catch (e) {
        console.error('[Fetcher::fetch]', e);
        setState('failed');
        setError(e as E);
      }
    },
    [],
  );
  const refresh = useCallback(() => fetch('refreshing'), [fetch]);
  const update = useCallback(() => fetch('updating'), [fetch]);

  useEffect(() => {
    ref.current = { key, fetcher };
    fetch();
    return () => {
      setData(undefined);
      setError(undefined);
      setState('idel');
    };
  }, [key, fetcher, fetch]);

  return useMemo(
    () => ({
      refresh,
      update,
      data,
      state,
      error,
    }),
    [data, state, error, refresh, update],
  );
}
