import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgGraphIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M7.073 13.72c.075.199.112.423.112.672 0 .45-.15.835-.45 1.16-.3.299-.674.448-1.124.448-.45 0-.837-.15-1.161-.449-.3-.324-.45-.71-.45-1.159 0-.448.15-.822.45-1.121a1.588 1.588 0 0 1 1.161-.486c.175 0 .387.05.637.15l1.874-1.87a1.902 1.902 0 0 1-.113-.672c0-.45.15-.823.45-1.122.3-.299.674-.449 1.124-.449.45 0 .825.15 1.124.449.325.3.487.673.487 1.121 0 .2-.05.424-.15.673l1.874 1.87c.25-.1.475-.15.675-.15.2 0 .424.05.674.15l2.66-2.655c-.1-.249-.15-.473-.15-.673 0-.448.15-.822.45-1.121A1.588 1.588 0 0 1 18.39 8c.45 0 .824.162 1.124.486.325.299.487.673.487 1.121 0 .45-.162.835-.487 1.16-.3.298-.675.448-1.124.448-.2 0-.425-.05-.675-.15l-2.66 2.655c.1.249.15.473.15.672 0 .45-.163.835-.487 1.16-.3.299-.675.448-1.125.448-.45 0-.836-.15-1.161-.449-.3-.324-.45-.71-.45-1.159 0-.199.05-.423.15-.672l-1.873-1.87c-.25.1-.475.15-.675.15-.175 0-.387-.05-.637-.15l-1.873 1.87Z"
      fill="currentColor"
    />
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGraphIcon;
