import clsx from 'clsx';
import { compactNumberFormatter } from '../core/formatters/number-formatting';
import {
  InsightCategoryType,
  InsightSubCategoryType,
  SCATTER_INSIGHT_TYPE_MAP,
} from './utils';
import { LoadingDot } from '../ui/LoadingDot';
import { LoadingStatus } from '../core/data-fetching/loading-status';
import { LinearProgress } from '@material-ui/core';
import { ClassNameProp } from '../core/types';
import { CircularProgressBar } from './InsightCircularProgressBar';

export type InsightResult = Record<InsightSubCategoryType, number>;

type InsightSummaryChartProps = InsightResult &
  ClassNameProp & {
    toggleInsightsFilters: (_: InsightSubCategoryType) => void;
    insightsFilters: Record<InsightSubCategoryType, boolean>;
    insightCategoryType: InsightCategoryType;
  };

export type InsightSummaryChartCardProps = {
  count: number;
  title: string;
  titleClassName: string;
  countClassName: string;
  insightSubType: InsightSubCategoryType;
  toggleInsightsFilters: (_: InsightSubCategoryType) => void;
  insightsFilters: Record<InsightSubCategoryType, boolean>;
};

function InsightSummaryChartCard({
  count = 0,
  title,
  titleClassName,
  countClassName,
  insightSubType,
  toggleInsightsFilters,
  insightsFilters,
}: InsightSummaryChartCardProps) {
  return (
    <div
      onClick={
        count > 0 ? () => toggleInsightsFilters(insightSubType) : undefined
      }
      className={clsx(
        (!count || insightsFilters[insightSubType]) && 'opacity-50',
        count && 'cursor-pointer',
        'bg-gray-800 flex flex-1 justify-center text-center items-center border-gray-700 border border-solid rounded gap-2 h-11 px-2',
      )}
    >
      <span className={clsx(countClassName, 'text-xl font-black')}>
        {compactNumberFormatter.format(count)}
      </span>

      <div
        className={clsx(
          titleClassName,
          'flex flex-wrap text-sm leading-3 gap-1',
        )}
      >
        {title.split(' ').map((word, index) => (
          <span key={index}>{word}</span>
        ))}
      </div>
    </div>
  );
}

export function InsightModelSummaryChart({
  className,
  toggleInsightsFilters,
  insightsFilters,
  insightCategoryType,
  ...data
}: InsightSummaryChartProps) {
  const filteredInsightTypes = Object.keys(SCATTER_INSIGHT_TYPE_MAP).filter(
    (type) =>
      SCATTER_INSIGHT_TYPE_MAP[type as InsightSubCategoryType]
        .insightCategory === insightCategoryType,
  );

  return (
    <div className={clsx('flex w-full justify-between gap-2', className)}>
      {filteredInsightTypes.map((key) => (
        <InsightSummaryChartCard
          key={key}
          count={data[key as InsightSubCategoryType]}
          insightSubType={key as InsightSubCategoryType}
          toggleInsightsFilters={toggleInsightsFilters}
          insightsFilters={insightsFilters}
          {...SCATTER_INSIGHT_TYPE_MAP[key as InsightSubCategoryType].chart}
        />
      ))}
    </div>
  );
}

export type InsightSummaryProps = {
  summary: InsightResult;
  toggleInsightsFilters: (_: InsightSubCategoryType) => void;
  insightsFilters: Record<InsightSubCategoryType, boolean>;
  isWaitingForUpdate?: boolean;
  loadingStatus: LoadingStatus;
  insightCategoryType: InsightCategoryType;
  dashboardsSummaryData: CircularProgressBar[];
};

export function InsightSummary({
  summary,
  toggleInsightsFilters,
  insightsFilters,
  loadingStatus,
  insightCategoryType,
  dashboardsSummaryData,
}: InsightSummaryProps) {
  const isLoading =
    loadingStatus === 'loading' || loadingStatus === 'refreshing';

  const hasDashboards = dashboardsSummaryData.length > 0;
  return (
    <div className="flex flex-col gap-2 pb-1 relative">
      <div className="flex flex-row justify-start items-center gap-2">
        {loadingStatus === 'updating' && <LoadingDot />}
      </div>

      {hasDashboards && (
        <div className="flex flex-row gap-1">
          <CircularProgressBar dashboardsSummaryData={dashboardsSummaryData} />
        </div>
      )}

      <InsightModelSummaryChart
        className="self-center"
        {...summary}
        toggleInsightsFilters={toggleInsightsFilters}
        insightsFilters={insightsFilters}
        insightCategoryType={insightCategoryType}
      />
      {isLoading && <LinearProgress className="absolute inset-x-0 bottom-0" />}
    </div>
  );
}
