import { Dashlet } from '@tensorleap/api-client';
import { useMemo } from 'react';
import {
  dashboardToUpdateParams,
  useDashboardContext,
} from './DashboardContext';
import { useFetchDashboards } from '../core/data-fetching/dashboards';
import { useCurrentProject } from '../core/CurrentProjectContext';

export function useDashboard(id: string) {
  const { currentProjectId } = useCurrentProject();
  if (!currentProjectId) {
    throw new Error('No current project');
  }
  const { removeDashlet, updateDashboard, setIsAddDashletsOpen } =
    useDashboardContext();

  const { dashboards } = useFetchDashboards({
    projectId: currentProjectId,
  });

  const dashboard = useMemo(
    () => dashboards?.find(({ cid }) => cid === id),
    [id, dashboards],
  );

  const actions = useMemo(
    () => ({
      removeDashlet: (visualId: string) => removeDashlet(id, visualId),
      openAddDashlets: () => setIsAddDashletsOpen(true),
      async updateLayout(items: Dashlet[]) {
        if (!dashboard) return;
        updateDashboard(dashboardToUpdateParams({ ...dashboard, items }));
      },
    }),
    [id, dashboard, updateDashboard, removeDashlet, setIsAddDashletsOpen],
  );

  return useMemo(
    () => ({
      dashboard,
      ...actions,
    }),
    [dashboard, actions],
  );
}
