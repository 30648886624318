import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgVerticalGraphIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.667 1.333H3.333a2 2 0 0 0-2 2v21.334a2 2 0 0 0 2 2h21.334a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2ZM3.333 0A3.333 3.333 0 0 0 0 3.333v21.334A3.333 3.333 0 0 0 3.333 28h21.334A3.333 3.333 0 0 0 28 24.667V3.333A3.333 3.333 0 0 0 24.667 0H3.333Z"
      fill="currentColor"
    />
    <path
      d="M6 16.938c0-.188.2-.309.366-.222l1.565.82a1.75 1.75 0 0 0 2.337-.694l2.543-4.525a.25.25 0 0 1 .167-.122l2.402-.503a1.75 1.75 0 0 0 1.087-.727l2.093-3.067a.25.25 0 0 1 .426.02l2.983 5.467a.25.25 0 0 1 .031.12v8.245a.25.25 0 0 1-.25.25H6.25a.25.25 0 0 1-.25-.25v-4.812Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgVerticalGraphIcon;
