import { HeatmapChartsParams } from '@tensorleap/api-client';
import { useMemo } from 'react';
import { XYChartVizProps } from './interfaces';
import { MultiCharts } from '../../../../ui/charts/visualizers/MultiCharts';
import {
  chartSplitToSplitAgg,
  getSplitLabels,
  selectedSessionRunToSessionRunsToEpochs,
  toIntervalOrLimit,
} from './utils';
import { ChartRequestData } from '../../../../ui/charts/common/interfaces';
import api from '../../../../core/api-client';
import { useGetChart } from '../../../../core/data-fetching/getChart';
import { useMergedObject } from '../../../../core/useMergedObject';

export function HeatmapViz({
  graphParams,
  filters,
  localFilters,
  onFiltersChange,
  sessionRuns,
  chartType,
  projectId,
}: XYChartVizProps): JSX.Element {
  const params = useMemo<HeatmapChartsParams>(() => {
    const {
      aggregation: colorAggregationMethod,
      color: colorField,
      xAxisDataDistribution: xDataDistributionType,
      xAxisSizeInterval,
      xAxisField: xField,
      xAxisOrder: xOrderParams,
      yAxisDataDistribution: yDataDistributionType,
      yAxisSizeInterval,
      yAxisField: yField,
      yAxisOrder: yOrderParams,
      flipColorRange,
      firstSplit,
      secondSplit,
    } = graphParams;

    const sessionRunsToEpochs =
      selectedSessionRunToSessionRunsToEpochs(sessionRuns);

    const { verticalSplit, horizontalSplit } = getSplitLabels(
      'inner',
      firstSplit,
      secondSplit,
    );

    return {
      projectId,
      x: chartSplitToSplitAgg(
        {
          field: xField,
          distribution: xDataDistributionType,
          order: xOrderParams,
          ...toIntervalOrLimit(
            xDataDistributionType,
            Number(xAxisSizeInterval),
          ),
        },
        null,
      ),
      y: chartSplitToSplitAgg(
        {
          field: yField,
          distribution: yDataDistributionType,
          order: yOrderParams,
          ...toIntervalOrLimit(
            yDataDistributionType,
            Number(yAxisSizeInterval),
          ),
        },
        null,
      ),
      color: {
        field: colorField,
        aggregation: colorAggregationMethod,
      },
      sessionRunsToEpochs,
      verticalSplit,
      horizontalSplit,
      filters,
      flipColorRange,
      showAllEpochs: false,
    };
  }, [filters, graphParams, projectId, sessionRuns]);

  const {
    xAxisField,
    yAxisField,
    color,
    flipColorRange,
    xAxisSizeInterval,
    yAxisSizeInterval,
    xAxisDataDistribution,
    yAxisDataDistribution,
  } = graphParams;

  const chartRequestData = useMergedObject<ChartRequestData>({
    xField: xAxisField,
    yField: yAxisField,
    colorField: color,
    flipColorRange,
    dataDistribution: xAxisDataDistribution,
    yDataDistribution: yAxisDataDistribution,
    xSizeInterval: Number(xAxisSizeInterval),
    ySizeInterval: Number(yAxisSizeInterval),
    orderByParam: graphParams.xAxisOrderBy,
    orderParams: graphParams.xAxisOrder,
    yOrderParams: graphParams.yAxisOrder,
  });

  const { multiChartsResponse, isLoading, error } = useGetChart({
    params,
    func: async (x) => await api.getHeatmapChart(x),
  });

  return (
    <MultiCharts
      xyChartsResponse={multiChartsResponse}
      chartRequestData={chartRequestData}
      onFiltersChange={onFiltersChange}
      localFilters={localFilters}
      chartType={chartType}
      autoScaleY={graphParams.autoScaleY}
      isLoading={isLoading}
      error={error}
      scaleTypeY={graphParams.scaleTypeY}
      horizontalSplit={params.horizontalSplit ?? null}
      verticalSplit={params.verticalSplit ?? null}
    />
  );
}
