import { Divider, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { Fragment, ReactNode, useCallback } from 'react';

import { Button } from './Button';

export interface IconTabs<T extends string> {
  selected: T;
  setSelected: (viewState: T) => void;
  tabs: { label: T; icon: JSX.Element }[];
  className?: string;
}

export function IconTabs<T extends string = string>({
  selected: currentViewState,
  setSelected,
  tabs,
  className,
}: IconTabs<T>): JSX.Element {
  return (
    <div
      className={clsx(
        'flex flex-col gap-1 items-center overflow-auto justify-center',
        className,
      )}
    >
      <Divider className="w-5" />

      {tabs.map((item) => {
        return (
          <Fragment key={item.label}>
            <TabIconButton
              setSelected={setSelected}
              value={item.label}
              icon={item.icon}
              selected={currentViewState}
            />
            <Divider className="w-5" />
          </Fragment>
        );
      })}
    </div>
  );
}

interface TabIconButtonProps<T> {
  setSelected: (viewState: T) => void;
  value: T;
  icon: JSX.Element;
  selected: T;
}

function TabIconButton<T extends NonNullable<ReactNode>>({
  setSelected,
  value,
  icon,
  selected,
}: TabIconButtonProps<T>): JSX.Element {
  const handleOnClick = useCallback(() => {
    setSelected(value);
  }, [setSelected, value]);

  return (
    <Tooltip title={value} placement="right" arrow>
      <Button
        onClick={handleOnClick}
        variant="action"
        className={clsx(
          'bg-inherit active: fill-inherit border-x-4 duration-75 ',
          selected === value
            ? 'border-l-dashboard-400 border-r-transparent'
            : ' border-transparent ',
        )}
      >
        {icon}
      </Button>
    </Tooltip>
  );
}
