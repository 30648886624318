import { MenuItem, Select } from '@material-ui/core';
import { SlimTeam } from '@tensorleap/api-client';
import { ChangeEvent, useCallback } from 'react';
import api from '../core/api-client';
import { useFetchMachineTypes } from '../core/data-fetching/machine-types';
import { UseFetchTeams } from '../core/data-fetching/teams';

export interface MachineTypeSelectorProps {
  team: SlimTeam;
}

export function MachineTypeSelector({
  team,
}: MachineTypeSelectorProps): JSX.Element {
  const { options, defaultGpuType } = useFetchMachineTypes();

  const { refetch: refetchTeams } = UseFetchTeams();

  const machineTypeId = team.machineTypeId || defaultGpuType;

  const handleMachineTypeChange: (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => void = useCallback(
    async (e) => {
      const newMachineTypeId = e.target.value as string;
      if (options.every((o) => o.id !== newMachineTypeId)) {
        console.error(
          'How the selectedMachineType is not in the machineTypesOptions?',
        );
        return;
      }
      await api.setMachineType({
        machineTypeId: e.target.value as string,
      });
      refetchTeams();
    },
    [options, refetchTeams],
  );

  return (
    <Select
      value={machineTypeId}
      key={machineTypeId}
      onChange={handleMachineTypeChange}
      variant="outlined"
      className="h-10 w-full text-left"
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.displayName}
        </MenuItem>
      ))}
    </Select>
  );
}
