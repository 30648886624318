import { LastSmallIcon } from '../../../ui/icons';
import { SimpleAutocomplete } from '../../../ui/molecules/SimpleAutocomplete';
import { useCallback, useMemo } from 'react';

export type EpochSelectorProps = {
  epochs: number[];
  value: number;
  onSelect: (epoch: number) => void;
  allowSwitchEpochs?: boolean;
};

export function EpochSelector({
  epochs,
  value,
  onSelect,
  allowSwitchEpochs,
}: EpochSelectorProps) {
  const onChange = useCallback(
    (value: string | undefined) => {
      const epoch = parseInt(value || '');
      if (isNaN(epoch)) {
        console.error('Epoch is not a number', value);
        return;
      }

      onSelect(epoch);
    },
    [onSelect],
  );

  const options = useMemo(
    () => epochs.map((e) => e?.toString() || 0),
    [epochs],
  );

  return (
    <div className="flex flex-row items-center">
      <LastSmallIcon />
      {allowSwitchEpochs ? (
        <div className="w-10">
          <SimpleAutocomplete
            title=""
            narrow
            hideArrow
            ultraSmall
            options={options}
            value={value?.toString() || 0}
            onChange={onChange}
          />
        </div>
      ) : (
        <span>{value?.toString() || 0}</span>
      )}
    </div>
  );
}
