import { ReactNode, useCallback, useState } from 'react';
import clsx from 'clsx';
import { DashboardBar } from './DashboardBar';
import { DashboardLayout } from './DashboardLayout';
import { useDashboard } from './useDashboard';
import { EditDashboardNameDialog } from './EditDashboardNameDialog';
import { AddDashboardProps, useDashboardContext } from './DashboardContext';
import { FetchStateLinearProgress } from '../ui/atoms/FetchStateLinearProgress';
import { DashboardPlaceholder, TextAction } from './utils';
import {
  DashboardDrawer,
  DrawerTabsEnum,
} from './DashboardDrawer/DashboardDrawer';
import { Dashlet as DashletComp } from './dashlet/Dashlet';
import { Dashlet } from '@tensorleap/api-client';
import { useModelTestState } from '../model-tests/useModelTestState';
import { clone } from 'lodash';
import { mapToEsFilters } from '../model-tests/modelTestHelpers';
import { InsightsContextProvider } from '../insights/InsightsContext';
import { useSelectedUrlTab } from '../core/useSelectedUrlTab';
import { URLS_ENUM, buildTabUrl } from '../url/url-builder';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { ProjectTabs } from '../ui/ProjectCardTabs';
import { useFetchDashboards } from '../core/data-fetching/dashboards';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { DashletFieldsProvider } from '../core/data-fetching/DashletFieldsContext';
import { useDashlet } from './useDashlet';

export type DashboardPaneProps = {
  className?: string;
};
export function DashboardsPane({ className }: DashboardPaneProps) {
  return (
    <InsightsContextProvider>
      <Dashboards className={className} />
    </InsightsContextProvider>
  );
}

export function Dashboards({ className }: DashboardPaneProps) {
  const { currentProjectId, currentVersionId } = useCurrentProject();
  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const { addDashboard, updateDashboard, selected } = useDashboardContext();

  const { dashboards, isLoading } = useFetchDashboards({
    projectId: currentProjectId,
  });

  const [pinned, setIsPinned] = useState(false);

  const { globalFilters, organizeDashboard } = useDashboardContext();

  const [dialog, setDialog] = useState<ReactNode>(undefined);

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const tabUrl = buildTabUrl(
    currentProjectId,
    currentVersionId,
    ProjectTabs.Dashboard,
  );

  const [selectedDrawerTab, setSelectedDrawerTab] =
    useSelectedUrlTab<DrawerTabsEnum>(tabUrl, URLS_ENUM.PANEL, DrawerTabsEnum);

  const modelTestsState = useModelTestState();

  const addDashboardDialog = useCallback(async () => {
    await new Promise((resolve) =>
      setDialog(
        <EditDashboardNameDialog
          open
          onClose={() => resolve(undefined)}
          onSubmit={addDashboard}
        />,
      ),
    );
    setDialog(undefined);
  }, [addDashboard]);

  const renameDashboard = useCallback(async () => {
    const selectedDashboard = dashboards?.find(({ cid }) => cid === selected);
    if (!selectedDashboard) {
      console.error(`Can't open rename dialog - Dashboard not selected`);
      return;
    }
    const { cid, ...dashboard } = selectedDashboard;
    const { name, description } = selectedDashboard;
    const update = (newNameAndDescription: AddDashboardProps) => {
      return updateDashboard({
        dashboardId: cid,
        ...dashboard,
        ...newNameAndDescription,
      });
    };

    await new Promise((resolve) =>
      setDialog(
        <EditDashboardNameDialog
          value={{ name, description }}
          open
          onClose={() => resolve(undefined)}
          onSubmit={update}
        />,
      ),
    );
    setDialog(undefined);
  }, [updateDashboard, dashboards, selected]);

  const addModelTestFromFilters = useCallback(() => {
    // click on the dashbaord area close the drawer so make the action async to do it after
    setTimeout(() => setSelectedDrawerTab(DrawerTabsEnum.Tests), 0);
    modelTestsState.tests.create({
      datasetFilter: clone(mapToEsFilters(globalFilters)),
    });
  }, [setSelectedDrawerTab, modelTestsState, globalFilters]);

  const closeDrawerIfNotPinned = useCallback(() => {
    !pinned && setSelectedDrawerTab(undefined);
  }, [pinned, setSelectedDrawerTab]);

  return (
    <div className="flex flex-row h-full w-full overflow-x-hidden relative">
      <section
        className={clsx(
          'flex h-full bg-gray-900 flex-1 flex-grow overflow-hidden flex-col',
          'relative',
          className,
        )}
      >
        <div
          className="flex h-full flex-1 flex-col"
          onClick={closeDrawerIfNotPinned}
        >
          {dialog}
          <div className="flex  h-11 mt-4 px-6">
            <DashboardBar
              className="flex-1"
              addDashboard={addDashboardDialog}
              renameDashboard={renameDashboard}
              addModelTestFromFilters={addModelTestFromFilters}
              organizeDashboard={organizeDashboard}
            />
          </div>
          <div className={'flex flex-col flex-1 min-w-full overflow-hidden'}>
            <FetchStateLinearProgress isLoading={isLoading} />
            {selected ? (
              <DashboardLayoutContainer id={selected} />
            ) : isLoading ? (
              <DashboardLoadingPlaceholder />
            ) : dashboards?.length ? (
              <NotSelectedDashboardPlaceholder />
            ) : (
              <NoDashboardsPlaceholder add={addDashboardDialog} />
            )}
          </div>
        </div>
      </section>
      <div className="flex w-fit h-full flex-shrink">
        <DashboardDrawer
          selectedDrawerTab={selectedDrawerTab}
          setSelectedDrawerTab={setSelectedDrawerTab}
          modelTestsState={modelTestsState}
          pinned={pinned}
          setIsPinned={setIsPinned}
        />
      </div>
    </div>
  );
}

export type DashboardLayoutContainerProps = {
  id: string;
};
function DashboardLayoutContainer({ id }: DashboardLayoutContainerProps) {
  const { dashboard, updateLayout, openAddDashlets } = useDashboard(id);
  const { currentProjectId } = useCurrentProject();
  if (!currentProjectId) {
    throw new Error('no current-project, we shouldnt get here');
  }
  const { selectedSessionRuns } = useDashlet(id);
  const sessionRunIds = selectedSessionRuns.map(({ id }) => id);

  const renderDashlet = useCallback(
    (item: Dashlet) => (
      <DashletFieldsProvider
        projectId={currentProjectId}
        sessionRunIds={sessionRunIds}
      >
        <DashletComp {...item} />
      </DashletFieldsProvider>
    ),
    [currentProjectId, sessionRunIds],
  );

  return dashboard?.items?.length ? (
    <DashboardLayout<Dashlet>
      key={dashboard.cid}
      className="flex-1 overflow-auto -mx-1 px-6"
      onChange={updateLayout}
      items={dashboard.items}
      renderDashlet={renderDashlet}
    />
  ) : (
    <NoVisualizationsPlaceholder add={openAddDashlets} />
  );
}

function NoVisualizationsPlaceholder({ add }: { add: () => void }) {
  return (
    <DashboardPlaceholder>
      No dashlets. <TextAction onClick={add}>Add New Dashlet</TextAction>
    </DashboardPlaceholder>
  );
}

function NotSelectedDashboardPlaceholder() {
  return <DashboardPlaceholder>Please choose a dashboard</DashboardPlaceholder>;
}

function DashboardLoadingPlaceholder() {
  return <DashboardPlaceholder> Loading...</DashboardPlaceholder>;
}

function NoDashboardsPlaceholder({ add }: { add: () => void }) {
  return (
    <DashboardPlaceholder>
      No dashboards.{' '}
      <TextAction
        onClick={add}
        tourId={TOUR_SELECTORS_ENUM.ADD_NEW_DASHBOARD_BUTTON_ID}
      >
        Add New Dashboard
      </TextAction>
    </DashboardPlaceholder>
  );
}
