import clsx from 'clsx';
import { ClassNameProp } from '../core/types';

export type DividerProps = ClassNameProp & {
  vertical?: boolean;
  small?: boolean;
};

export function Divider({ className, vertical, small }: DividerProps) {
  return (
    <div
      className={clsx(
        className,
        'bg-gray-500',
        vertical
          ? `${small ? 'min-h-[16px]' : 'min-h-[20px]'} min-w-[1px] mx-2`
          : 'min-w-full min-h-[1px] my-2',
      )}
    ></div>
  );
}
