import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFieldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M6.333 11h-.667V3h.667V2H3.666v1h.667v8h-.667v1h2.667v-1Z"
      fill="currentColor"
    />
    <path
      d="M6.677 4v1.333h8.99v8.334H2.333V5.333h.98V4H1v11h16V4H6.677Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFieldIcon;
