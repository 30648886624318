import clsx from 'clsx';
import { Truncate } from './Truncate';

export interface TextDisplayBoxProps {
  header: string;
  text: string;
  className?: string;
}

export function TextDisplayBox({
  header,
  text,
  className,
}: TextDisplayBoxProps): JSX.Element {
  return (
    <div
      className={clsx(
        className,
        'flex flex-col gap-1 bg-gray-850 border-[1px] border-gray-800 rounded-lg px-3 py-1 w-full',
      )}
    >
      <span className="font-medium text-xs text-gray-600 leading-normal uppercase">
        {header}
      </span>
      <Truncate value={text} className="text-sm leading-normal" />
    </div>
  );
}
