import {
  DatasetSetup,
  DatasetVersion,
  Node as NodeData,
} from '@tensorleap/api-client';

import { Node } from '@tensorleap/engine-contract';
import { NodeLabels, NodeWithLabels } from './descriptor/types';
import {
  isGroundTruthNode,
  isInputNode,
  isInputsNode,
  isLayerNode,
} from './graph-calculation/utils';
import { NodeDescriptor } from './interfaces';

export function isCustomLayerNode(node: Node) {
  return node.name === 'CustomLayer';
}

export function isPredictionNode(node: NodeWithLabels): boolean {
  return (
    !!node.labels &&
    node.labels.length > 0 &&
    node.labels.includes(NodeLabels.Prediction)
  );
}

type OutputData = {
  name: string;
  type: string;
};

export function getDatasetOutputData(
  datasetSetup?: DatasetSetup,
): OutputData[] {
  const inputs = datasetSetup?.inputs ?? [{ name: 'output' }];
  return inputs.map(({ name }) => ({
    name,
    type: 'Unknown',
  }));
}

export function getInputOutputData(node: Node): OutputData[] {
  return [
    {
      name: node.data.output_name || 'Input',
      type: 'Unknown',
    },
  ];
}

export function getGroundTruthOutputData(node: NodeData): OutputData[] {
  const name = node.data.output_name;
  return name ? [{ name, type: 'Unknown' }] : [];
}

export function getNodeOutputDataByNodeDescriptor(
  componentDescriptor: NodeDescriptor,
): OutputData[] {
  return componentDescriptor.outputs_data.outputs.map(({ name }) => ({
    name,
    type: '', // todo:
  }));
}

export function shouldShowShape(node: NodeData): boolean {
  return isLayerNode(node) || isGroundTruthNode(node) || isInputNode(node);
}

export function getInputsData(
  node: NodeData,
  componentDescriptor?: NodeDescriptor,
  customInputKeys?: string[],
): {
  isDynamicInput: boolean;
  namePattern?: string;
  inputsData: {
    name: string;
    approval_connection: string[];
  }[];
} {
  const isDynamicInput = !!componentDescriptor?.inputs_data.add_input_by_drag;
  if (!isDynamicInput) {
    return {
      isDynamicInput,
      namePattern: undefined,
      inputsData:
        node.name === 'Model'
          ? Object.keys(node.inputs).map((key) => ({
              name: key,
              approval_connection: [
                'Input',
                'Inputs',
                'Dataset',
                'Layer',
                'CustomLayer',
              ],
            }))
          : componentDescriptor?.inputs_data.input_names_by_args
            ? (node.data['arg_names'] || []).map((name: string) => ({
                name,
                approval_connection:
                  componentDescriptor.inputs_data?.inputs[0]
                    ?.approval_connection || [],
              }))
            : componentDescriptor?.inputs_data.inputs,
    };
  }
  const { name: namePattern, approval_connection } = componentDescriptor
    ?.inputs_data.inputs[0] || { name: '', approval_connection: [] };
  const namePerfixRegExp = new RegExp(`^${node.id}-`);
  const inputNames =
    customInputKeys?.map((key) => key.replace(namePerfixRegExp, '')) || [];
  const inputsData = inputNames.map((name) => ({
    name,
    approval_connection,
  }));
  inputsData.push({
    name:
      inputNames.length > 0
        ? (Math.max(...inputNames.map(Number)) + 1).toString()
        : '0',
    approval_connection,
  });

  return {
    isDynamicInput,
    namePattern,
    inputsData,
  };
}

export function getOutputData(
  node: NodeData,
  componentDescriptor?: NodeDescriptor,
  datasetSetup?: DatasetSetup,
): OutputData[] | undefined {
  // todo: unify types
  const nodeType = componentDescriptor?.type || node.name;
  return (
    isInputsNode(node)
      ? getDatasetOutputData(datasetSetup)
      : isGroundTruthNode(node)
        ? getGroundTruthOutputData(node)
        : isInputNode(node)
          ? getInputOutputData(node)
          : componentDescriptor
            ? getNodeOutputDataByNodeDescriptor(componentDescriptor)
            : nodeType === 'Model'
              ? Object.keys(node.outputs).map((key) => ({ name: key }))
              : []
  ).map(({ name }) => ({
    name,
    type: nodeType === 'Model' ? 'Layer' : nodeType,
  }));
}

export function datasetHasSetup(dataset?: DatasetVersion): boolean {
  return !!dataset?.metadata?.setup;
}

export function getSpecificNodes(
  nodes: ROMap<string, Node>,
  filterFunc: (n: Node) => boolean,
): Readonly<Node[]> {
  return Array.from(nodes.values()).filter((n) => filterFunc(n));
}
