import useSWR, { KeyedMutator } from 'swr';
import { Dataset } from '@tensorleap/api-client';

import api from '../api-client';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseDatasets {
  datasets: Dataset[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<Dataset[]>;
}
export function useFetchDatasets(): UseDatasets {
  const {
    data: datasets,
    error,
    mutate,
  } = useSWR<Dataset[], Error>(
    CacheKey.DATASETS,
    async () => {
      const { datasets } = await api.getDatasets();
      return datasets;
    },
    { refreshInterval: REFRESH_INTERVALS.datasets },
  );
  return useMergedObject({
    datasets: datasets || [],
    error,
    isLoading: !error && !datasets,
    refetch: mutate,
  });
}
