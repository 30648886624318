import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUpdateMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8 2.2 9.3 5.013 4.8 2.2.3 5.013V15.7l4.5-2.813L9.3 15.7l4.5-2.813V10.3h-1.125v1.963l-2.812 1.758V5.988l2.812-1.758V7.6H13.8V2.2ZM4.238 3.883v8.029L1.425 13.67V5.64l2.813-1.757Zm1.125-.009 3.375 2.114v8.033l-3.375-2.11V3.875Z"
      fill="currentColor"
    />
    <path
      d="m18.12 8.957-3.372 2.707V9.637h-4.323v-1.36h4.323V6.25l3.372 2.707Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUpdateMapIcon;
