import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '../ui/mui';
import { Input } from '../ui/atoms/Input';
import { Button } from '../ui/atoms/Button';
import { useCallback, useState } from 'react';
import { uploadFile } from '../core/file-helper';
import { getApiBasePath } from '../core/api-client';

export interface NewProjectDialogProps {
  onClose: () => void;
}

export function UploadProjectDialog({
  onClose,
}: NewProjectDialogProps): JSX.Element {
  const [name, setName] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const isNotValid = !name || !selectedFile;
  const handleUpload = useCallback(async () => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Display a confirmation message
      e.preventDefault();
      e.returnValue = '';
    };
    try {
      if (isNotValid) return;
      const path = `${getApiBasePath()}/projects/uploadProject/${name}`;
      setIsUploading(true);

      window.addEventListener('beforeunload', handleBeforeUnload);
      await uploadFile(path, selectedFile);
      setIsUploading(false);
      onClose();
    } catch (err) {
      setIsUploading(false);
    } finally {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [name, selectedFile, isNotValid, onClose]);

  const changeFileHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        setSelectedFile(null);
        return;
      }
      event.preventDefault();
      setSelectedFile(event.target.files[0]);
    },
    [],
  );

  return (
    <Dialog
      open
      onClose={isUploading ? undefined : onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle>UPLOAD PROJECT</DialogTitle>
      <DialogContent>
        <div className="w-64 px-2 flex flex-col space-y-4">
          {isUploading && (
            <div className="bg-black/50 z-10 absolute inset-0 flex items-center justify-center">
              <CircularProgress size={50} />
            </div>
          )}
          <Input
            label="NAME"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            InputProps={{
              className: '!max-h-11',
            }}
            variant="outlined"
            type="search"
            inputProps={{
              accept: '.zip',
              type: 'file',
              onChange: changeFileHandler,
            }}
          ></TextField>
        </div>
      </DialogContent>
      <DialogActions className="p-4">
        <Button onClick={onClose} variant="text">
          CANCEL
        </Button>
        <Button disabled={isNotValid} onClick={handleUpload}>
          UPLOAD
        </Button>
      </DialogActions>
    </Dialog>
  );
}
