import clsx from 'clsx';
import { PropsWithChildren, useMemo } from 'react';
import { CHIP_COLOR_TEMPLATES } from '../molecules/colorTemplates';
import { Tooltip } from '../mui';

export type CircledIconProps = PropsWithChildren<{
  text?: string;
  label?: string;
  className?: string;
  borderStyle?: string;
  dropShadow?: boolean;
  textSizeClass?: `text-${'xs' | 'md' | 'lg' | `${number}xl`}`;
}>;
export function CircledIcon({
  text,
  label,
  children,
  className,
  borderStyle,
  dropShadow,
  textSizeClass = 'text-xs',
}: CircledIconProps): JSX.Element {
  const createByInitials = useMemo(() => {
    return (
      (text &&
        text
          .split(' ')
          .map((x) => x.charAt(0))
          .slice(0, 2)
          .join('')) ||
      '?'
    );
  }, [text]);
  const colorStyle =
    createByInitials && createByInitials !== '?'
      ? CHIP_COLOR_TEMPLATES[createByInitials.charCodeAt(0) % 10]
      : undefined;
  return (
    <Tooltip title={label || text || 'Unassigned'} placement="bottom">
      <div
        className={clsx(
          'flex justify-center items-center rounded-full bg-gray-600 w-7 h-7',
          { 'drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]': dropShadow },
          colorStyle,
          borderStyle,
          className,
        )}
      >
        {children ? (
          <div className="flex justify-center items-center">{children}</div>
        ) : text ? (
          <span
            className={clsx(
              'flex justify-center items-center uppercase',
              textSizeClass,
            )}
          >
            {createByInitials}
          </span>
        ) : (
          <span>?</span>
        )}
      </div>
    </Tooltip>
  );
}
