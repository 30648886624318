import { useCallback, useMemo } from 'react';
import { Position } from '../../../core/position';
import { USER_UNIQUE_NAME } from '../../../layer-details/UserUniqueName';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import { GraphWarningMsg } from '../errors';
import {
  GraphErrorKind,
  GraphWarningKind,
  NetworkWizardCategory,
  NetworkWizardData,
  NetworkWizardErrorSeverity,
  QuickFixProps,
} from '../types';

const NODES_OFFSET = 300;

export type GraphWarningDataProps = {
  category?: NetworkWizardCategory;
  nodeId: string;
  message?: string;
  type: GraphWarningKind;
};
export function useGraphWarningData(
  graphWarningProps: GraphWarningDataProps[],
): NetworkWizardData[] {
  const {
    nodes,
    addNewNode,
    selectNode,
    addNewConnection,
    connections,
    changeNodeProperty,
    datasetSetup,
    getNewNodeId,
    currentDatasetSetup,
    onFitNodeToScreen,
  } = useNetworkMapContext();

  const showNode = useCallback(
    (nodeId: string) => {
      if (nodeId) {
        onFitNodeToScreen(nodeId);
        selectNode(nodeId);
      }
    },
    [onFitNodeToScreen, selectNode],
  );

  const addVisualizer = useCallback(
    (nodeId: string, visName: string | undefined) => {
      if (!visName) return;

      const visualizerProps = datasetSetup?.visualizers.find(
        (v) => v.name === visName,
      );
      if (!visualizerProps) {
        console.warn(`${visName} is not a valid visualizer name`);
        return;
      }

      const suggestedNode = nodes.get(nodeId);
      if (!suggestedNode) {
        console.warn(`Node #${nodeId} does not exist`);
        return;
      }

      const [suggestedNodePosX, suggestedNodePosY] = suggestedNode.position;
      const visualizerNodePosition: Position = [
        suggestedNodePosX + NODES_OFFSET,
        suggestedNodePosY - NODES_OFFSET,
      ];

      const { outputName = '' } =
        connections?.find(
          ({ outputNodeId }) => outputNodeId === suggestedNode.id,
        ) || {};

      const visualizerNodeId = getNewNodeId();
      addNewNode({ name: 'Visualizer', position: visualizerNodePosition });
      changeNodeProperty({
        nodeId: visualizerNodeId,
        nodeDataPropsToUpdate: {
          visualizer_name: visualizerProps.name,
          visualizer_type: visualizerProps.type,
          arg_names: visualizerProps.arg_names,
          [USER_UNIQUE_NAME]: visualizerProps.name,
        },
      });

      addNewConnection({
        outputNodeId: suggestedNode.id,
        outputName,
        inputNodeId: visualizerNodeId,
        inputName: visualizerProps.arg_names[0] || 'data',
        isDynamicInput: false,
      });

      showNode(nodeId);
      selectNode(visualizerNodeId);
    },
    [
      addNewConnection,
      addNewNode,
      changeNodeProperty,
      connections,
      datasetSetup?.visualizers,
      getNewNodeId,
      nodes,
      selectNode,
      showNode,
    ],
  );

  const addMetric = useCallback(
    (nodeId: string, metricName: string | undefined) => {
      if (!metricName) return;

      const suggestedNode = nodes.get(nodeId);
      if (!suggestedNode) {
        console.warn(`Node #${nodeId} does not exist`);
        return;
      }

      const [suggestedNodePosX, suggestedNodePosY] = suggestedNode.position;
      const metricNodePosition: Position = [
        suggestedNodePosX + NODES_OFFSET,
        suggestedNodePosY - NODES_OFFSET,
      ];

      const { outputName = '' } =
        connections?.find(
          ({ outputNodeId }) => outputNodeId === suggestedNode.id,
        ) || {};

      const metricNodeId = getNewNodeId();
      addNewNode({ name: 'Metric', position: metricNodePosition });

      const metric = (currentDatasetSetup?.metrics || []).find(
        ({ name }) => name === metricName,
      );

      if (!metric) {
        console.error(`${metricName} is not a valid metric name`);
        return;
      }

      const arg_names = metric.arg_names;

      if (arg_names.length === 0) {
        console.error(`${metricName} does not have any arguments`);
        return;
      }

      changeNodeProperty({
        nodeId: metricNodeId,
        nodeDataPropsToUpdate: {
          metric_name: metricName,
          name: metricName,
          arg_names,
          [USER_UNIQUE_NAME]: metricName,
        },
      });

      const inputName =
        arg_names.find((argName) => argName.toLowerCase().includes('pred')) ||
        arg_names[0];

      addNewConnection({
        outputNodeId: suggestedNode.id,
        outputName,
        inputNodeId: metricNodeId,
        inputName,
        isDynamicInput: false,
      });

      showNode(nodeId);
      selectNode(metricNodeId);
    },
    [
      addNewConnection,
      addNewNode,
      changeNodeProperty,
      connections,
      currentDatasetSetup?.metrics,
      getNewNodeId,
      nodes,
      selectNode,
      showNode,
    ],
  );

  return useMemo(() => {
    return graphWarningProps.map(({ nodeId, type, message, category }) => {
      const calculateKey = () => nodeId || '' + type || '';
      const createQuickFix = (
        nodeId: string,
        warningType: GraphWarningKind,
      ): QuickFixProps | undefined => {
        switch (warningType) {
          case 'predictionNeedsMetric': {
            return {
              title: 'Metrics',
              selectOptions:
                currentDatasetSetup?.metrics?.map(({ name }) => name) || [],
              onSelect: (metricName?: string) => addMetric(nodeId, metricName),
            };
          }
          case 'gtNeedsVisualization':
          case 'inputNeedsVisualization':
          case 'predictionNeedsVisualization': {
            const selectOptions =
              datasetSetup?.visualizers.map(({ name }) => name) || [];

            return {
              title: 'Visualizers',
              selectOptions,
              onSelect: (vizName?: string) => addVisualizer(nodeId, vizName),
            };
          }
          default: {
            console.warn(`${warningType} is not supported yet`);
          }
        }
      };

      const quickFix = createQuickFix(nodeId, type);
      return {
        errorType: GraphErrorKind.node,
        category: category || NetworkWizardCategory.VIS,
        calculateKey,
        key: calculateKey(),
        title: 'suggestion',
        message: message || GraphWarningMsg[type],
        showNode: () => showNode(nodeId),
        quickFixes: quickFix ? [quickFix] : [],
        errorSeverity: NetworkWizardErrorSeverity.WARNING,
        showNodeFooter: true,
      };
    });
  }, [
    addMetric,
    addVisualizer,
    currentDatasetSetup?.metrics,
    datasetSetup?.visualizers,
    graphWarningProps,
    showNode,
  ]);
}
