import {
  CategoryValue,
  CategoryMeta,
  StringCategoryTypes,
} from '../core/category/types';
import { convertCategoriesMetaToFilterCategoriesMeta } from '../filters/utils';

export type ProjectCategoriesNames =
  | 'data_format'
  | 'network_tasks'
  | 'storage_location';

export type ProjectCategoriesValue = CategoryValue<ProjectCategoriesNames>;

export const PROJECT_CATEGORIES_META: CategoryMeta<ProjectCategoriesNames>[] = [
  {
    type: StringCategoryTypes.String,
    name: 'DATA FORMAT',
    category: 'data_format',
    enum: [
      {
        value: 'text',
      },
      {
        value: 'number',
      },
      {
        value: 'image',
      },
      {
        value: 'audio',
      },
      {
        value: 'time',
      },
      {
        value: 'map_coordinates',
      },
      {
        value: 'video',
      },
    ],
  },
  {
    type: StringCategoryTypes.StringArray,
    name: 'NETWORK TASKS',
    category: 'network_tasks',
    enum: [
      'object_detection',
      'classification',
      'segmentation',
      'sentiment_analysis',
      'enhancement',
    ],
  },
  {
    type: StringCategoryTypes.String,
    name: 'STORAGE LOCATION',
    category: 'storage_location',
    enum: [
      {
        value: 'aws',
        name: 'AWS',
      },
      {
        value: 'google_cloud',
        name: 'Google Cloud',
      },
      {
        value: 'ms_azure',
        name: 'MS Azure',
      },
      {
        value: 'local',
      },
    ],
  },
];

export const FILTER_PROJECT_CATEGORIES_META: CategoryMeta<ProjectCategoriesNames>[] =
  convertCategoriesMetaToFilterCategoriesMeta(PROJECT_CATEGORIES_META);
