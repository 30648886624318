import { createContext, useContext, useMemo } from 'react';
import useSWR from 'swr';
import { UseFetchDashletFields } from './dashlet-fields';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import api from '../api-client';

const DashletFieldsContext = createContext<UseFetchDashletFields | undefined>(
  undefined,
);

export const DashletFieldsProvider: React.FC<{
  projectId: string;
  sessionRunIds: string[];
}> = ({ projectId, sessionRunIds, children }) => {
  const sessionIds = useMemo(() => sessionRunIds.join(','), [sessionRunIds]);
  const fetcheKey = `${CacheKey.DASHLET_FIELDS}-${projectId}-${sessionIds}`;
  const {
    data: dashletFields,
    error,
    mutate,
  } = useSWR(
    fetcheKey,
    async () => {
      const projectField = await api.getDashletFields({
        projectId,
        sessionRunIds: [],
      });
      let notActiveFields: string[] = [];

      if (sessionRunIds.length) {
        const sessionFields = await api.getDashletFields({
          projectId,
          sessionRunIds,
        });
        const selectedSessionAggregatableFieldsSet = new Set(
          sessionFields.aggregatableFields,
        );
        const selectedSessionNumericFieldsSet = new Set(
          sessionFields.numericFields,
        );

        const allFields = [
          ...projectField.aggregatableFields,
          ...projectField.numericFields,
        ];

        notActiveFields = allFields.filter(
          (field) =>
            !selectedSessionAggregatableFieldsSet.has(field) &&
            !selectedSessionNumericFieldsSet.has(field),
        );
      }

      return {
        aggregatableFields: projectField.aggregatableFields,
        numericFields: projectField.numericFields,
        notActiveFields,
      };
    },
    { refreshInterval: REFRESH_INTERVALS.dashletFields },
  );

  const value = useMemo(
    () => ({
      dashletFields,
      error,
      isLoading: !error && !dashletFields,
      refetch: mutate,
    }),
    [error, dashletFields, mutate],
  );

  return (
    <DashletFieldsContext.Provider value={value}>
      {children}
    </DashletFieldsContext.Provider>
  );
};

export const useDashletFields = (): UseFetchDashletFields => {
  const context = useContext(DashletFieldsContext);
  if (!context) {
    throw new Error(
      'useDashletFields must be used within a DashletFieldsProvider',
    );
  }
  return context;
};
