import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLegendIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M4.714 11.2c.217 0 .412.09.583.27a.857.857 0 0 1 0 1.26c-.171.18-.366.27-.583.27h-.857c-.217 0-.417-.09-.6-.27A.885.885 0 0 1 3 12.1c0-.24.08-.45.24-.63a.822.822 0 0 1 .617-.27h.857Zm0-3.6c.217 0 .412.09.583.27a.857.857 0 0 1 0 1.26c-.171.18-.366.27-.583.27h-.857c-.217 0-.417-.09-.6-.27A.885.885 0 0 1 3 8.5c0-.24.08-.45.24-.63a.822.822 0 0 1 .617-.27h.857Zm0-3.6c.217 0 .412.09.583.27a.857.857 0 0 1 0 1.26c-.171.18-.366.27-.583.27h-.857c-.217 0-.417-.09-.6-.27A.885.885 0 0 1 3 4.9c0-.24.08-.45.24-.63A.822.822 0 0 1 3.857 4h.857Zm3.429 1.8c-.217 0-.417-.09-.6-.27a.885.885 0 0 1-.257-.63c0-.24.085-.45.257-.63.183-.18.383-.27.6-.27h6c.24 0 .44.09.6.27.171.18.257.39.257.63s-.091.45-.274.63c-.172.18-.366.27-.583.27h-6Zm6 1.8c.24 0 .44.09.6.27.171.18.257.39.257.63s-.091.45-.274.63c-.172.18-.366.27-.583.27h-6c-.217 0-.417-.09-.6-.27a.885.885 0 0 1-.257-.63c0-.24.085-.45.257-.63.183-.18.383-.27.6-.27h6Zm0 3.6c.24 0 .44.09.6.27.171.18.257.39.257.63s-.091.45-.274.63c-.172.18-.366.27-.583.27h-6c-.217 0-.417-.09-.6-.27a.885.885 0 0 1-.257-.63c0-.24.085-.45.257-.63.183-.18.383-.27.6-.27h6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLegendIcon;
