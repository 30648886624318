import { Job, Session, SlimVersion, StatusEnum } from '@tensorleap/api-client';

export function parsePositiveInt(textValue?: string): number | '' {
  return textValue ? Math.max(parseInt(textValue), 1) : '';
}

export function getSourceNetworkName(version: SlimVersion): string {
  return version?.notes || version?.createdAt?.toDateString() || '';
}

export interface isEvaluateOrTrainingProcessAllowedParams {
  isValid: boolean;
  selectedSession?: Session;
  selectedVersion?: SlimVersion;
  createNewSession?: boolean;
}

export function isEvaluateOrTrainingProcessAllowed({
  isValid,
  selectedSession,
  selectedVersion,
  createNewSession,
}: isEvaluateOrTrainingProcessAllowedParams): boolean {
  if (createNewSession) {
    return isValid && !!selectedVersion;
  }

  if (!selectedSession?.sessionWeights?.length) {
    return false;
  }

  const sessionRunHasWeights = !!selectedSession?.sessionWeights?.length;

  return isValid && sessionRunHasWeights;
}

export function sessionHasTrainedEpochs(session: Session): boolean {
  const sessionWeightsExist = !!session?.sessionWeights?.length;
  const isImportOrHasFinishedEpoch = !!session?.sessionWeights?.some(
    ({ status }) => status === 'FINISHED',
  );
  return sessionWeightsExist && isImportOrHasFinishedEpoch;
}

export function getSessionEpochs(session: Session | undefined): number[] {
  if (!session?.sessionWeights) {
    return [];
  }

  return Array.from(
    new Set(
      session?.sessionWeights
        .filter(({ status }) => status === StatusEnum.Finished)
        .map(({ epoch }) => epoch)
        .sort((a, b) => b - a) || [],
    ),
  );
}

export function isVersionMatchToHash(
  version: SlimVersion,
  hash?: string | null,
): boolean {
  return version.hash != undefined && hash === version.hash;
}

export function getJobTypeDescription(job: Job): string {
  return job.subType || job.type.toLowerCase().replace(/_/g, ' ');
}

export function removeMetadataPrefix(metadata: string): string {
  return metadata.startsWith('metadata.')
    ? metadata.split('metadata.')[1]
    : metadata;
}

export function removeMetricsPrefix(metrics: string): string {
  return metrics.startsWith('metrics.')
    ? metrics.split('metrics.')[1]
    : metrics;
}
