import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useLocalStorage } from '../core/useLocalStorage';
import { Drawer } from '../ui/atoms/Drawer';
import { GitIcon } from '../ui/icons';
import { VersionControlPane } from './VersionControlPane';
import { useVersionControlPanelContext } from '../core/VersionControlPanelContext';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { SessionInfoDialog } from './SessionInfoDialog';
import { useVersionControl } from '../core/VersionControlContext';
import { ExportDialog } from '../actions-dialog/ExportDialog';
import { EditSessionRunDialog } from './EditSessionRunDialog';
import { DeleteVersionDialog } from './DeleteVersionDialog';
import { DeleteSessionRunDialog } from './DeleteSessionRunDialog';

const VERSION_CONTROL_PINNED_KEY = 'VERSION_CONTROL_PINNED';

export function VersionControlWithDrawer() {
  const {
    versionControlAction: action,
    handleVersionActionCancelled: cancelAction,
    versionControlOpenState: openState,
  } = useVersionControlPanelContext();

  const {
    sessionToShowItsEpochs,
    setSessionToShowItsEpochs,
    versionToExport,
    handleExportVersion,
    sessionRunToDelete,
    editSessionRun,
    versionToDelete,
  } = useVersionControl();

  const { currentProjectName: projectName, currentVersion } =
    useCurrentProject();

  const currentVersionId = currentVersion?.cid;

  const currentVersionNotes = currentVersion?.notes;

  const [pinned, setPined] = useLocalStorage(VERSION_CONTROL_PINNED_KEY, true);
  const isPersist = !!action || pinned;

  const togglePin = useCallback(() => {
    if (pinned && !action) openState.setState(false);
    setPined(!pinned);
  }, [pinned, setPined, action, openState]);

  const onCancelAction = useCallback(() => {
    if (!pinned) openState.setState(false);
    cancelAction();
  }, [pinned, cancelAction, openState]);

  const open = useCallback(() => openState.setState(true), [openState]);

  return (
    <>
      {sessionToShowItsEpochs && (
        <SessionInfoDialog
          session={sessionToShowItsEpochs}
          onClose={() => setSessionToShowItsEpochs(undefined)}
        />
      )}
      {versionToExport?.sessions[0]?.sessionWeights && (
        <ExportDialog
          isOpen={true}
          onClose={() => handleExportVersion(null)}
          sessionWeights={versionToExport.sessions[0].sessionWeights}
        />
      )}
      {sessionRunToDelete && <DeleteSessionRunDialog />}

      {editSessionRun && <EditSessionRunDialog sessionRun={editSessionRun} />}
      {versionToDelete && <DeleteVersionDialog />}
      <Drawer
        persist={isPersist}
        drawerClassName={clsx(
          (!openState.state || !isPersist) && 'absolute',
          'h-full z-50 bg-gray-850',
          openState.state && 'border-solid border-r border-r-gray-700',
        )}
        openState={openState}
        subContent={
          <Tooltip title="Versions" placement="right" arrow>
            <div
              onClick={open}
              id={TOUR_SELECTORS_ENUM.VERSION_CONTROL_EXPAND_BUTTON_ID}
              className={clsx(
                openState.state && 'opacity-0',
                'transition-opacity',
                'pointer-events-auto',
                'h-10 w-8',
                'text-version-600',
                'flex items-center',
                'absolute top-20 -right-8',
                'bg-gray-850 hover:bg-gray-800',
                'border border-gray-600 border-l-0 rounded-r-full',
                'text-white cursor-pointer',
              )}
            >
              <GitIcon />
            </div>
          </Tooltip>
        }
      >
        {openState.state ? (
          <VersionControlPane
            className="h-full"
            shelveAction={action}
            projectName={projectName}
            currentVersionNotes={currentVersionNotes}
            currentVersionId={currentVersionId}
            onActionCancelled={onCancelAction}
            togglePin={togglePin}
            pinned={pinned}
          />
        ) : (
          <div className="w-80" />
        )}
      </Drawer>
    </>
  );
}
