import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPictureType = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M5.008 6.008v6H13v-6H5.008Zm0-1.008H13c.281 0 .516.102.703.305a.917.917 0 0 1 .305.703v6a.992.992 0 0 1-.305.726.954.954 0 0 1-.703.282H5.008c-.281 0-.524-.094-.727-.282A1.035 1.035 0 0 1 4 12.008v-6c0-.281.094-.516.281-.703A.992.992 0 0 1 5.008 5Zm2.25 3.305a.701.701 0 0 1-.516.21.782.782 0 0 1-.539-.21.782.782 0 0 1-.21-.54c0-.202.07-.374.21-.515a.741.741 0 0 1 .54-.234c.202 0 .374.078.515.234.156.14.234.313.234.516a.741.741 0 0 1-.234.539Zm-.75 1.71.984-1.007L8.5 10.016 10.492 8l1.5 1.5V11H6.508v-.984Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPictureType;
