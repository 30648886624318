import { useRef, RefObject, useEffect, DependencyList, useMemo } from 'react';

export function useMemoWithLatestRef<T>(
  factory: () => T,
  deps: DependencyList | undefined,
): [T, RefObject<T>] {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const currentVal = useMemo(factory, deps);
  const ref = useRef(currentVal);

  useEffect(() => {
    ref.current = currentVal;
  }, [currentVal]);

  return [currentVal, ref];
}
