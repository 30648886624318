import { useCallback, useMemo } from 'react';
import {
  NetworkWizardData,
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardErrorSeverity,
} from '../types';
import { GraphNodeInputErrorType } from '../errors';

export function useGraphNodeInputErrorData({
  title,
  msg,
  nodeId,
  inputName,
  fitNodeToScreen,
  category,
}: GraphNodeInputErrorType & {
  fitNodeToScreen: (_: string) => void;
}): NetworkWizardData[] {
  const showNodeProp = useCallback(() => {
    fitNodeToScreen(nodeId);
  }, [nodeId, fitNodeToScreen]);

  const calculateKey = useCallback(
    () => msg + nodeId + inputName,
    [inputName, msg, nodeId],
  );

  return useMemo(
    () => [
      {
        errorType: GraphErrorKind.nodeInput,
        category: category || NetworkWizardCategory.INPUTS,
        title: title || 'INVALID NODE INPUT',
        message: msg,
        calculateKey,
        showNodeFooter: true,
        showNode: showNodeProp,
        errorSeverity: NetworkWizardErrorSeverity.ERROR,
        key: calculateKey(),
      },
    ],
    [calculateKey, category, msg, showNodeProp, title],
  );
}
