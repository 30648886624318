import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="m19.193 6.244-4.851 10.422c-.42 1.138-1.078 1.707-1.977 1.707-.689 0-1.213-.3-1.572-.898-.42-.72-.39-1.423.09-2.112l6.334-10.197c.39-.599.898-.734 1.527-.404.6.33.749.823.45 1.482Zm1.707 3.01c-.449-.51-.928-.524-1.437-.045-.48.42-.494.899-.045 1.438 1.707 1.856 2.56 4.057 2.56 6.603 0 .27.09.51.27.719.21.18.464.27.764.27.27 0 .494-.09.674-.27A.99.99 0 0 0 24 17.25c0-3.114-1.033-5.78-3.1-7.996ZM13.31 7.592c.27.03.509-.045.718-.225.21-.18.33-.404.36-.674a.908.908 0 0 0-.225-.718c-.18-.21-.404-.33-.674-.36h-.539c-.21-.03-.36-.045-.449-.045-3.174 0-5.885 1.153-8.13 3.46C2.122 11.304 1 14.06 1 17.294c0 .27.09.51.27.719.21.18.449.27.718.27.3 0 .54-.09.719-.27a.99.99 0 0 0 .314-.719c0-2.695.914-4.986 2.74-6.873 1.857-1.887 4.104-2.83 6.739-2.83 0-.03.135-.045.404-.045l.405.045Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDashboardIcon;
