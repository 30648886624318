import { NumberOrString } from '@tensorleap/api-client';

export function capitalizeFirstLetter(str: string): string {
  if (!str) {
    return '';
  }
  if (str.length === 1) {
    return str.toUpperCase();
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export interface TruncateLongtailParams {
  value?: NumberOrString;
  startSubsetLength?: number;
  endSubsetLength?: number;
}

export const truncateLongtail = ({
  value = '',
  startSubsetLength = 10,
  endSubsetLength = 10,
}: TruncateLongtailParams): string => {
  const strLabel = String(value);
  if (strLabel.length <= startSubsetLength + endSubsetLength) {
    return strLabel;
  }
  const start = strLabel.slice(0, startSubsetLength);
  const end = strLabel.slice(
    strLabel.length - endSubsetLength,
    strLabel.length,
  );
  return `${start}...${end}`;
};
