import { useCallback, useReducer } from 'react';
import { useLocalStorage } from './useLocalStorage';

// Based on https://usehooks.com/useToggle/
export function useToggle(initialValue = false): [boolean, () => void] {
  // Returns the tuple [state, dispatch]
  // Normally with useReducer you pass a value to dispatch to indicate what action to
  // take on the state, but in this case there's only one action.
  return useReducer((state) => !state, initialValue);
}

export function useLocalStorageToggle(
  key: string,
  initialValue = false,
): [boolean, () => void] {
  const [value, setValue] = useLocalStorage<boolean>(key, initialValue);
  const toggle = useCallback(() => setValue((v) => !v), [setValue]);
  return [value, toggle];
}
