import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { PanZoom, PanZoomParams, PanZoomControl } from './PanZoom';
import { Node } from './Node';
import { NodeConnection, ConnectionDragLayer } from './NodeConnection';
import { Popover, PopoverProps } from '../ui/mui';
import { MapContextMenu } from './MapContextMenu';
import { Position } from '../core/position';
import { useContextMenuPopoverState } from '../core/useContextMenuPopoverState';
import { Connection } from './interfaces/Connection';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { COMPONENT_DESCRIPTORS_MAP } from './interfaces';
import { useCallbackRef } from '../core/useCallbackRef';
import { getOrSetDefault } from '../core/map-helper';
import { ConnectionWithType } from './interfaces/drag-and-drop';
import React from 'react';
import { useDebounce } from '../core/useDebounce';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { Version } from '@tensorleap/api-client';

const STATIC_POPOVER_PROPS: Partial<PopoverProps> = {
  anchorReference: 'anchorPosition',
  transformOrigin: { vertical: 'top', horizontal: 'left' },
  transitionDuration: { appear: 0, enter: 0, exit: 0 },
};

export interface NetworkEditorParams
  extends Omit<PanZoomControl, 'onFitNodeZoomToScreen'> {
  onFitNodeToScreen: (nodeId: string) => void;
}
export function NetworkEditor({
  panZoomParams,
  panZoomParamsRef,
  onFitMapToScreen,
}: NetworkEditorParams): JSX.Element {
  const {
    isContextMenuOpen,
    setIsContextMenuOpen,
    contextMenuPosition,
    openContextMenu,
    handleContextMenuClose,
  } = useContextMenuPopoverState();
  const { clearNodeSelection, addNewNode } = useNetworkMapContext();

  const handleItemClick = useCallback(
    (title: string) => {
      if (!panZoomParamsRef.current) return;
      setIsContextMenuOpen(false);

      const { zoom, position, domTarget } = panZoomParamsRef.current;

      const mousePosition = [
        (contextMenuPosition[0] -
          (domTarget.current?.offsetLeft || 0) -
          position[0]) /
          zoom,
        (contextMenuPosition[1] -
          (domTarget.current?.offsetTop || 0) -
          position[1]) /
          zoom,
      ] as Position;

      addNewNode({ name: title, position: mousePosition });
    },
    [contextMenuPosition, addNewNode, panZoomParamsRef, setIsContextMenuOpen],
  );

  return (
    <>
      <PanZoom
        onContextMenu={openContextMenu}
        onClick={clearNodeSelection}
        {...panZoomParams}
      >
        <NetworkEditorContent
          panZoomParamsRef={panZoomParamsRef}
          onFitMapToScreen={onFitMapToScreen}
        />
      </PanZoom>
      <Popover
        {...STATIC_POPOVER_PROPS}
        open={isContextMenuOpen}
        onClose={handleContextMenuClose}
        onMouseLeave={handleContextMenuClose}
        anchorPosition={{
          top: contextMenuPosition[1],
          left: contextMenuPosition[0],
        }}
      >
        <MapContextMenu onItemClick={handleItemClick} />
      </Popover>
    </>
  );
}

interface NetworkEditorContentProps {
  panZoomParamsRef: RefObject<PanZoomParams>;
  onFitMapToScreen: PanZoomControl['onFitMapToScreen'];
}

const NetworkEditorContent = React.memo<NetworkEditorContentProps>(
  ({ panZoomParamsRef, onFitMapToScreen }) => {
    const {
      nodes,
      connections,
      selectedNodeId,
      updateNodePosition,
      selectNode,
      addNewConnection,
      deleteOneOrManyConnections,
      nodeRefs,
      nodesShapesRef,
      currentDatasetSetup,
      datasetSetup,
    } = useNetworkMapContext();

    const { currentVersion } = useCurrentProject();

    const [latestLoadedVersion, setLatestLoadedVersion] = useState<Version>();

    const refAddNewConnection = useCallbackRef(addNewConnection);

    const [isLoadingNodes, setIsLoadingNodes] = useState(true);

    const debounceNodesLoadingIsDone = useDebounce(() => {
      setIsLoadingNodes(false);
      onFitMapToScreen(Array.from(nodes.values()), nodeRefs.current);
    }, nodes.size);

    const notifyIsLoadingNodes = useCallback(() => {
      if (isLoadingNodes || currentVersion?.cid !== latestLoadedVersion?.cid) {
        setIsLoadingNodes(true);
        setLatestLoadedVersion(currentVersion);
        debounceNodesLoadingIsDone();
      }
    }, [
      currentVersion,
      debounceNodesLoadingIsDone,
      isLoadingNodes,
      latestLoadedVersion?.cid,
    ]);

    const notifyIsLoadingNodesRef = useCallbackRef(notifyIsLoadingNodes);

    const onConnectionRemoved = useCallback(
      (connection: Connection, isDynamicInput: boolean) => {
        deleteOneOrManyConnections([connection], isDynamicInput);
      },
      [deleteOneOrManyConnections],
    );

    const refOnConnectionRemoved = useCallbackRef(onConnectionRemoved);

    const inputsConnections = useRef<
      Map<string, Map<string, ConnectionWithType>>
    >(new Map());

    useEffect(() => {
      const newNodeInputConnectionsWithNewRefs = connections?.reduce(
        (ret, connection) => {
          const { inputNodeId, inputName, outputNodeId } = connection;
          getOrSetDefault(
            ret,
            inputNodeId,
            () => new Map<string, ConnectionWithType>(),
          ).set(inputName, {
            connection,
            type: nodes.get(outputNodeId)?.data.type,
          });
          return ret;
        },
        new Map<string, Map<string, ConnectionWithType>>(),
      );

      const currentInputsConnectionsMapCopy = new Map(
        inputsConnections.current,
      );

      currentInputsConnectionsMapCopy.forEach((_, nodeId) => {
        if (!newNodeInputConnectionsWithNewRefs.has(nodeId)) {
          currentInputsConnectionsMapCopy.delete(nodeId);
        }
      });

      newNodeInputConnectionsWithNewRefs.forEach(
        (newNodeInputConnections, nodeId) => {
          const currentNodeInputConnections =
            inputsConnections.current.get(nodeId);

          if (
            currentNodeInputConnections === undefined ||
            newNodeInputConnections.size !== currentNodeInputConnections.size ||
            Array.from(newNodeInputConnections.entries()).some(
              ([key, value]) =>
                currentNodeInputConnections.get(key)?.connection !==
                value.connection,
            )
          ) {
            currentInputsConnectionsMapCopy.set(
              nodeId,
              newNodeInputConnections,
            );
          }
        },
      );

      inputsConnections.current = currentInputsConnectionsMapCopy;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connections]);

    return !nodeRefs.current ? (
      <div />
    ) : (
      <>
        {!isLoadingNodes && (
          <>
            {(connections || []).map((connection) => (
              <NodeConnection
                key={`${connection.outputNodeId}-${connection.outputName}-${connection.inputNodeId}-${connection.inputName}`}
                connection={connection}
                outputNode={nodes.get(connection.outputNodeId)}
                inputNode={nodes.get(connection.inputNodeId)}
                outputSocketElem={nodeRefs.current
                  .get(connection.outputNodeId)
                  ?.outputRefs.get(connection.outputName)}
                inputSocketElem={nodeRefs.current
                  .get(connection.inputNodeId)
                  ?.inputRefs.get(connection.inputName)}
              />
            ))}
            <ConnectionDragLayer
              nodesMap={nodes}
              nodeRefs={nodeRefs}
              panZoomParamsRef={panZoomParamsRef}
            />
          </>
        )}

        {Array.from(nodes.values(), (node) => (
          <Node
            key={node.id}
            node={node}
            panZoomParamsRef={panZoomParamsRef}
            isSelected={node.id === selectedNodeId}
            onSelect={selectNode}
            inputConnections={inputsConnections.current?.get(node.id)}
            onPositionChanged={updateNodePosition}
            onNewConnection={refAddNewConnection}
            onConnectionRemoved={refOnConnectionRemoved}
            componentDescriptor={COMPONENT_DESCRIPTORS_MAP.get(node.name)}
            nodeRefs={nodeRefs}
            nodeShape={nodesShapesRef.current.get(node.id)}
            currentDatasetSetup={currentDatasetSetup}
            datasetSetup={datasetSetup}
            notifyIsLoadingNodes={notifyIsLoadingNodesRef}
          />
        ))}
      </>
    );
  },
);

NetworkEditorContent.displayName = 'NetworkEditorContent';
