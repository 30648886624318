import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgXCloseIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M15 4.209 13.791 3 9 7.791 4.209 3 3 4.209 7.791 9 3 13.791 4.209 15 9 10.209 13.791 15 15 13.791 10.209 9 15 4.209Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgXCloseIcon2;
