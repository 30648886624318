import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgStopwatchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.649 2.488a10.35 10.35 0 0 1 2.702 0 .6.6 0 0 1-.078 1.195h-2.546a.6.6 0 0 1-.078-1.195Zm7.748.94a.6.6 0 0 1 .817-.08A11.859 11.859 0 0 1 21.4 5.537a.6.6 0 0 1-.9.789l-.614-.614-1.22 1.219a8.85 8.85 0 1 1-.849-.849l1.22-1.219-.614-.615a.6.6 0 0 1-.027-.82Zm-.996 3.904A7.65 7.65 0 1 0 6.6 18.168 7.65 7.65 0 0 0 17.4 7.332Zm-2.075 9.592-3.75-3.75a.6.6 0 1 1 .848-.848l3.75 3.75a.6.6 0 0 1-.848.848Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgStopwatchIcon;
