import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgForwardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M13.533 6.217A.737.737 0 0 0 13 6a.708.708 0 0 0-.527.223.708.708 0 0 0-.223.527c0 .21.074.389.223.533l3.966 3.967H4.75a.708.708 0 0 0-.527.223A.708.708 0 0 0 4 12c0 .207.074.385.223.533.144.145.32.217.527.217h11.69l-3.967 3.967a.715.715 0 0 0-.223.533c0 .207.074.383.223.527.144.149.32.223.527.223.21 0 .389-.072.533-.217l5.25-5.25A.75.75 0 0 0 19 12a.75.75 0 0 0-.217-.533l-5.25-5.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgForwardIcon;
