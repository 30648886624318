import { useCallback, useMemo, useRef, useState } from 'react';
import { useMergedObject } from './useMergedObject';
import { Setter } from './types';

export type UseMultiSelectMode = 'add' | 'subtract' | 'select';

export type UseMultiSelect<ID> = {
  mode: UseMultiSelectMode;
  setMode: Setter<UseMultiSelectMode>;
  setPressedMode: Setter<UseMultiSelectMode | undefined>;
  selected: Set<ID> | undefined;
  setSelected: (newSelected: Set<ID> | undefined) => void;
  marked: Set<ID> | undefined;
  setMarked: (newMarked: Set<ID> | undefined) => void;
  nextSelected: Set<ID> | undefined;
  selectNext: () => void;
  someSelected: boolean;
  clear: () => void;
  calcAndSetSelection: (
    newSelected: Set<ID>,
    mode?: UseMultiSelectMode,
  ) => void;
};

export function useMultiSelect<ID>(): UseMultiSelect<ID> {
  const [_mode, setMode] = useState<UseMultiSelectMode>('select');
  const [pressedMode, setPressedMode] = useState<UseMultiSelectMode>();
  const mode = pressedMode ?? _mode;
  const [selected, _setSelected] = useState<Set<ID>>();
  const [marked, _setMarked] = useState<Set<ID>>();
  const markedRef = useRef<Set<ID> | undefined>(marked);
  const selectedRef = useRef<Set<ID> | undefined>(selected);

  const nextSelected = useMemo(() => {
    return calcNextSelection(mode, selected, marked);
  }, [mode, selected, marked]);

  const setSelected = useCallback((newSelected: Set<ID> | undefined) => {
    selectedRef.current = newSelected;
    _setSelected(newSelected);
  }, []);

  const setMarked = useCallback((newMarked: Set<ID> | undefined) => {
    markedRef.current = newMarked;
    _setMarked(newMarked);
  }, []);

  const selectNext = useCallback(() => {
    setMarked(undefined);
    setSelected(nextSelected);
  }, [nextSelected, setMarked, setSelected]);

  const clear = useCallback(() => {
    setSelected(undefined);
    setMarked(undefined);
  }, [setSelected, setMarked]);

  const calcAndSetSelection = useCallback(
    (marked: Set<ID>, calcMode: UseMultiSelectMode = mode) => {
      setMarked(undefined);
      const newSelection = calcNextSelection(
        calcMode,
        selectedRef.current,
        marked,
      );
      setSelected(newSelection);
    },
    [setSelected, setMarked, mode],
  );

  return useMergedObject({
    mode,
    setMode,
    setPressedMode,
    selected,
    setSelected,
    marked,
    setMarked,
    nextSelected,
    selectNext,
    someSelected: !!selected?.size,
    clear,
    calcAndSetSelection,
  });
}

function calcNextSelection<ID>(
  mode: UseMultiSelectMode,
  selected?: Set<ID>,
  marked?: Set<ID>,
): Set<ID> {
  if (mode === 'select') {
    return marked || selected || new Set();
  }
  const next = new Set(selected);
  if (!marked) {
    return next;
  }
  marked.forEach((id) => {
    if (mode === 'add') {
      next.add(id);
    } else if (mode === 'subtract') {
      next.delete(id);
    }
  });
  return next;
}
