import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNewFileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M15.188 6.188a.54.54 0 0 0-.17-.401l-3.93-3.938a.57.57 0 0 0-.4-.161h-6.75a1.125 1.125 0 0 0-1.126 1.125v12.375a1.125 1.125 0 0 0 1.126 1.124h10.124a1.125 1.125 0 0 0 1.126-1.125v-9ZM11.25 3.607l2.018 2.018H11.25V3.607Zm2.813 11.58H3.937V2.813h6.188v3.375a.563.563 0 0 0 .563.562h3.374v8.438Z"
      fill="currentColor"
    />
    <path
      d="M12.007 10a.618.618 0 0 0-.194-.46.629.629 0 0 0-.46-.194l-2.111.003.003-2.11a.629.629 0 0 0-.194-.46.635.635 0 0 0-.92 0 .629.629 0 0 0-.195.46l.004 2.11-2.11-.003a.629.629 0 0 0-.46.194.644.644 0 0 0-.202.46c.002.175.07.328.201.46.13.13.283.194.46.194l2.111-.003-.004 2.11c0 .178.065.331.195.46a.635.635 0 0 0 .92 0 .629.629 0 0 0 .194-.46l-.003-2.11 2.11.003c.178 0 .331-.064.46-.194a.618.618 0 0 0 .195-.46Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNewFileIcon;
