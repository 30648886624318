import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgImageVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      d="M12.833 3A2.167 2.167 0 0 1 15 5.167v7.666A2.167 2.167 0 0 1 12.833 15H5.167A2.167 2.167 0 0 1 3 12.833V5.167A2.167 2.167 0 0 1 5.167 3h7.666Zm.387 10.934-3.87-3.79a.5.5 0 0 0-.644-.048l-.056.047-3.871 3.791c.121.043.252.066.388.066h7.666c.136 0 .266-.023.387-.066l-3.87-3.79 3.87 3.79ZM12.833 4H5.167A1.167 1.167 0 0 0 4 5.167v7.666c0 .139.024.272.069.396l3.882-3.8a1.5 1.5 0 0 1 2.013-.078l.085.078 3.882 3.801c.044-.124.069-.257.069-.397V5.167A1.167 1.167 0 0 0 12.833 4Zm-1.665 1.333a1.501 1.501 0 1 1 0 3.003 1.501 1.501 0 0 1 0-3.003Zm0 1a.501.501 0 1 0 0 1.003.501.501 0 0 0 0-1.003Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgImageVideoIcon;
