import { Input } from '../ui/atoms/Input';
import { Select } from '../ui/atoms/Select';
import { LinearProgress, Switch } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { normalizeName } from './FeatureFlagsPage';
import { InputNote } from '../ui/atoms/utils/InputNote';
import api from './api-client';
import { Title } from '../ui/atoms/Title';
import { MainNavBar } from '../ui/MainNavBar';
import { ToggleButtonGroup } from '../ui/atoms/ToggleButtonGroup';
import {
  BoolSchema,
  NumberSchema,
  SettingSchema,
  SettingValue,
  StringSchema,
} from '@tensorleap/api-client';
import { useEngineSettings } from './data-fetching/engineSettings';

export type SettingsSchema = { [key: string]: SettingSchema };
export type SettingValues = { [key: string]: SettingValue };

export function SettingsPage() {
  const { data, mutate } = useEngineSettings();

  return (
    <div className="flex flex-col items-stretch w-screen h-screen overflow-hidden">
      <MainNavBar />
      <div className="flex flex-col gap-4 items-start justify-start p-4 h-[calc(100%-4rem)]">
        <Title small className="px-2">
          Engine Settings
        </Title>
        {data && (
          <div className="flex h-full w-full overflow-auto">
            <SettingsCategory
              schema={data.schema}
              value={data.values}
              setValue={async (keyName, value) => {
                const params =
                  value === undefined
                    ? { unset: [keyName] }
                    : { set: [{ keyName, value }] };
                await api.updateEngineSettings(params);
                await mutate();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

type SettingsCategoryProps = {
  schema: SettingsSchema;
  value: Record<string, SettingValue | undefined>;
  setValue: (key: string, value?: SettingValue) => Promise<void>;
};

export function SettingsCategory({
  schema,
  value: originalValue,
  setValue,
}: SettingsCategoryProps) {
  return (
    <div className="p-2 flex flex-col gap-4 w-160 h-full">
      {Object.entries(schema).map(([key, schema]) => {
        const onChange = (value?: SettingValue) => setValue(key, value);
        const value = originalValue[key];

        if (isStringSchema(schema)) {
          return (
            <StringSetting
              key={key}
              name={key}
              schema={schema}
              value={value as string}
              onChange={onChange}
            />
          );
        } else if (isBoolSchema(schema)) {
          return (
            <BooleanSetting
              key={key}
              name={key}
              schema={schema}
              value={value as boolean}
              onChange={onChange}
            />
          );
        } else if (isNumberSchema(schema)) {
          return (
            <NumberSetting
              name={key}
              key={key}
              schema={schema}
              value={value as number}
              onChange={onChange}
            />
          );
        }

        console.warn(`Unsupported schema type: ${schema['type']}`);
      })}
    </div>
  );
}

function isStringSchema(schema: SettingSchema): schema is StringSchema {
  return schema.type === 'string';
}

function isBoolSchema(schema: SettingSchema): schema is BoolSchema {
  return schema.type === 'boolean';
}

function isNumberSchema(schema: SettingSchema): schema is NumberSchema {
  return schema.type === 'number';
}

function useSettingSchema<S extends SettingSchema>(
  key: string,
  originalValue: S['def'],
  schema: S,
  onChange: (value: S['def']) => void,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(originalValue);
  const useDefault = schema.def !== undefined && value === undefined;
  const description = schema.description ?? '';

  const save = useCallback(
    async (value: S['def']) => {
      try {
        setIsLoading(true);
        await onChange(value);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    },
    [onChange],
  );
  const label = useMemo(() => {
    return schema.title ?? normalizeName(key);
  }, [key, schema.title]);
  const toggleUseDefault = useCallback(async () => {
    if (useDefault) {
      setValue(schema.def);
      await save(schema.def);
    } else {
      save(undefined);
      await save(undefined);
    }
  }, [save, schema.def, useDefault]);
  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);
  const valueOrDefault: S['def'] =
    useDefault && value === undefined ? schema.def : value;
  return {
    label,
    description,
    isLoading,
    value: valueOrDefault,
    setValue,
    save,
    toggleUseDefault,
    useDefault,
  };
}

type StringSettingProps = {
  name: string;
  schema: StringSchema;
  value: string;
  onChange: (value?: string | null) => void;
};

function useStringValidator(schema: StringSchema, value: StringSchema['def']) {
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(true);
  const validate = useCallback(
    (value: StringSchema['def']) => {
      if (schema.required && !value) {
        setError('Required');
        setIsValid(false);
      } else if (!value) {
        setError('');
        setIsValid(true);
        return;
      } else if (schema.options && !schema.options.includes(value)) {
        setError('Invalid value, not in enum');
        setIsValid(false);
      } else if (schema.maxLength && value.length > schema.maxLength) {
        setError(`Max length is ${schema.maxLength}`);
        setIsValid(false);
      } else if (schema.minLength && value.length < schema.minLength) {
        setError(`Min length is ${schema.minLength}`);
        setIsValid(false);
      } else if (schema.pattern && !new RegExp(schema.pattern).test(value)) {
        setError(`Invalid value, pattern(${schema.pattern}) not match`);
        setIsValid(false);
      } else {
        setError('');
        setIsValid(true);
      }
    },
    [schema],
  );
  useEffect(() => {
    validate(value);
  }, [value, validate]);
  return { error, isValid };
}

export function StringSetting({
  name,
  value: originalValue,
  schema,
  onChange,
}: StringSettingProps) {
  const {
    label,
    description,
    isLoading,
    useDefault,
    value,
    setValue,
    save,
    toggleUseDefault,
  } = useSettingSchema(name, originalValue, schema, onChange);
  const { error, isValid } = useStringValidator(schema, value);

  let labelOrPlaceholder = label;
  if (schema.placeholder && value === null) {
    labelOrPlaceholder = schema.placeholder;
  }
  return (
    <Setting
      isValid={isValid}
      isLoading={isLoading}
      useDefault={useDefault}
      defaultValue={schema.def}
      toggleUseDefault={toggleUseDefault}
      onSubmit={() => save(value)}
    >
      {schema.options ? (
        <Select
          small
          label={labelOrPlaceholder}
          disabled={isLoading}
          info={description}
          options={schema.options}
          error={error}
          value={toInputValue(value)}
          onChange={(newVal) => {
            const val = fromInputValue(newVal);
            setValue(val);
            isValid && save(val);
          }}
        />
      ) : (
        <Input
          small
          label={labelOrPlaceholder}
          error={error}
          disabled={isLoading}
          info={description}
          value={toInputValue(value)}
          maxLength={schema.maxLength}
          minLength={schema.minLength}
          onBlur={() => {
            isValid && save(value);
          }}
          pattern={schema.pattern}
          onChange={(e) => setValue(fromInputValue(e.target.value))}
        />
      )}
    </Setting>
  );
}
type BooleanSettingProps = {
  name: string;
  schema: BoolSchema;
  value: boolean;
  onChange: (value?: boolean) => void;
};
export function BooleanSetting({
  name,
  value: originalValue,
  schema,
  onChange,
}: BooleanSettingProps) {
  const {
    label,
    description,
    isLoading,
    save,
    useDefault,
    toggleUseDefault,
    value,
    setValue,
  } = useSettingSchema(name, originalValue, schema, onChange);
  return (
    <Setting
      isValid={true}
      isLoading={isLoading}
      useDefault={useDefault}
      toggleUseDefault={toggleUseDefault}
      defaultValue={schema.def}
      onSubmit={() => save(value)}
    >
      <div className="flex flex-col">
        <label className="flex items-center gap-2">
          {label}
          <Switch
            disabled={isLoading}
            checked={!!value}
            onChange={() => {
              setValue(!value);
              save(!value);
            }}
          />
        </label>
        <InputNote info>{description}</InputNote>
      </div>
    </Setting>
  );
}

type NumberSettingProps = {
  name: string;
  schema: NumberSchema;
  value: number;
  onChange: (value: NumberSchema['def']) => void;
};

function useNumberValidator(schema: NumberSchema, value: NumberSchema['def']) {
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(true);
  const validate = useCallback(
    (value: NumberSchema['def']) => {
      if (schema.required && (value === undefined || value === null)) {
        setError('Required');
        setIsValid(false);
      } else if (value === undefined || value === null) {
        setError('');
        setIsValid(true);
      } else if (schema.options && !schema.options.includes(value)) {
        setError('Invalid value, not in enum');
        setIsValid(false);
      } else if (schema.max && value > schema.max) {
        setError(`Max value is ${schema.max}`);
        setIsValid(false);
      } else if (schema.min && value < schema.min) {
        setError(`Min value is ${schema.min}`);
        setIsValid(false);
      } else {
        setError('');
        setIsValid(true);
      }
    },
    [schema],
  );
  useEffect(() => {
    validate(value);
  }, [value, validate]);
  return { error, isValid };
}

export function NumberSetting({
  name,
  value: originalValue,
  schema,
  onChange,
}: NumberSettingProps) {
  const {
    label,
    description,
    isLoading,
    save,
    value,
    setValue,
    useDefault,
    toggleUseDefault,
  } = useSettingSchema(name, originalValue, schema, onChange);
  const { error, isValid } = useNumberValidator(schema, value);
  let labelOrPlaceholder = label;
  if (schema.placeholder && value === null) {
    labelOrPlaceholder = schema.placeholder;
  }

  return (
    <Setting
      isValid={isValid}
      isLoading={isLoading}
      useDefault={useDefault}
      toggleUseDefault={toggleUseDefault}
      defaultValue={schema.def}
      onSubmit={() => isValid && save(value)}
    >
      {schema.options ? (
        <Select
          small
          label={labelOrPlaceholder}
          disabled={isLoading}
          info={description}
          optionToLabel={(v) => v.toString()}
          options={schema.options}
          error={error}
          value={toInputValue(value)}
          onChange={(newVal) => {
            const val = fromInputValue(newVal, Number);
            setValue(val);
            isValid && save(val);
          }}
        />
      ) : (
        <Input
          small
          label={labelOrPlaceholder}
          error={error}
          onBlur={() => isValid && save(value)}
          disabled={isLoading}
          info={description}
          value={toInputValue(value)}
          step={schema.step}
          type="number"
          min={schema.min}
          max={schema.max}
          onChange={(e) => {
            const val = (e.target as HTMLInputElement).value;
            setValue(fromInputValue(val, Number));
          }}
        />
      )}
    </Setting>
  );
}

type SettingProps = {
  children: React.ReactNode;
  isLoading: boolean;
  defaultValue?: SettingValue;
  isValid: boolean;
  useDefault: boolean;
  toggleUseDefault: () => void;
  onSubmit: () => void;
};

const ModeOptions = [
  { value: 'custom', disabled: true },
  { value: 'default', title: 'Use default value' },
];

function Setting({
  children,
  isLoading,
  onSubmit,
  useDefault,
  toggleUseDefault,
  defaultValue,
  isValid,
}: SettingProps) {
  const isDefault = defaultValue !== undefined;
  return (
    <form
      className="flex gap-2 relative"
      onSubmit={(e) => {
        e.preventDefault();
        isValid && onSubmit();
      }}
      action=""
    >
      {isLoading && <LinearProgress className=" absolute -top-2 inset-x-0" />}
      <div className="flex-1">{children}</div>
      <div className="flex gap-2 items-start">
        {isDefault && (
          <ToggleButtonGroup
            className="h-9"
            onChange={toggleUseDefault}
            value={useDefault ? 'default' : 'custom'}
            options={ModeOptions}
          />
        )}
      </div>
    </form>
  );
}

function toInputValue(value?: number | string | null): string {
  return value === null || value === undefined ? '' : String(value);
}

function fromInputValue(value?: string): string | null;
function fromInputValue<T>(
  value: string | undefined,
  convert: (value: string) => T,
): T | null;

function fromInputValue<T>(
  value?: string,
  convert?: (value: string) => T,
): T | string | null {
  if (value === '' || value === undefined) {
    return null;
  }
  return convert ? convert(value) : value;
}
