import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPreviewTip2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className,
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.7.95a2.392 2.392 0 0 1 1.692-.7h13.216A2.392 2.392 0 0 1 18 2.642v9.44a2.392 2.392 0 0 1-2.392 2.392h-2.994c-.367 0-.72.146-.979.405l-1.611 2.612a1.448 1.448 0 0 1-2.047 0L6.365 14.88a1.385 1.385 0 0 0-.979-.406H2.392A2.392 2.392 0 0 1 0 12.082v-9.44C0 2.007.252 1.399.7.95Zm1.692.307a1.385 1.385 0 0 0-1.385 1.385v9.44a1.384 1.384 0 0 0 1.385 1.385h2.994c.634 0 1.243.252 1.691.7L8.69 16.78a.44.44 0 0 0 .622 0l1.612-2.611a2.392 2.392 0 0 1 1.69-.701h2.995a1.385 1.385 0 0 0 1.385-1.385v-9.44a1.385 1.385 0 0 0-1.385-1.385H2.392Z"
      fill="currentColor"
    />
    <circle cx={5} cy={7.5} r={1} fill="currentColor" />
    <circle cx={9} cy={7.5} r={1} fill="currentColor" />
    <circle cx={13} cy={7.5} r={1} fill="currentColor" />
  </svg>
);

export default SvgPreviewTip2Icon;
