import { XYChartProps } from '../common/interfaces';
import { NumberOrString } from '@tensorleap/api-client';
import { NoDataChart } from '../common/NoDataChart';
import { LazyLoadTable } from '../../model-list/table/Table';
import { ModelFields } from '../../model-list/types';
import { sort, useSort } from '../../model-list/sorter';
import { useMemo } from 'react';
import { getNormalizedParsedChartPoints } from '../hooks/utils';

export function TableChart({
  graphData,
  chartRequestData,
}: XYChartProps): JSX.Element {
  const chartData = useMemo(
    () => getNormalizedParsedChartPoints({ graphData, chartRequestData }),
    [chartRequestData, graphData],
  );

  const keys = new Set(chartData.flatMap((chart) => Object.keys(chart)));
  const fields: ModelFields<Record<string, NumberOrString>> = Array.from(
    keys,
  ).map((key, index) => ({
    accessorKey: key,
    label: key,
    sortable: {
      level: index === 0 ? 'primary' : 'secondary',
      direction: 'asc',
    },
  }));

  const sorter = useSort(fields);

  const sortedData = useMemo(
    () => sort(chartData, sorter.sortBy),
    [chartData, sorter.sortBy],
  );

  if (!chartData?.length) {
    return <NoDataChart />;
  }

  return (
    <LazyLoadTable
      className="min-h-full"
      fields={fields}
      data={sortedData}
      sorter={sorter}
    />
  );
}
