import clsx from 'clsx';
import { SampleList } from './SampleList';
import { UseSampleListState } from '../SampleAnalysis/useSampleListState';
import { SampleVisDisplay } from './SampleVisDisplay';
import { SelectedSessionRun } from '../../../ui/molecules/useModelFilter';
import { VisPayloadElements, VisPayloadType } from './visDataHelpers';
import { ContainerWrapperType } from './types';
import { SampleIdentity } from '@tensorleap/api-client';
import { RefObject, useEffect, useMemo } from 'react';
import { MouseHandler } from '../../../core/useSelectionGroup';
import { SamplesActionsDrawer } from '../../ScatterAnalyzerView/SamplesActionsDrawer';
import { LoadMoreSamplesState } from '../../../core/data-fetching/fullVisualizations';

export const SAMPLE_LIST_CLASS = 'border-r border-gray-800 border-solid';

export interface VisDisplayProps {
  selectedSessionRuns: SelectedSessionRun[];
  sampleListState: UseSampleListState;
  visPayloadElements?: VisPayloadElements;
  containerType: ContainerWrapperType;
  ref?: RefObject<HTMLDivElement>;
  mouseHandler: MouseHandler;
  toggleMultiSampleSelection: boolean;
  setToggleMultiSampleSelection: () => void;
  selectedPayloadType: VisPayloadType;
  setSelectedPayloadType: (payloadType: VisPayloadType) => void;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  deselectSample: (sample: SampleIdentity) => void;
  showAsGallery: boolean;
  selectedSampleIdentities?: SampleIdentity[];
  loadMoreSamplesState?: LoadMoreSamplesState;
  filterSelectedSamples?: () => void;
}

export function VisDisplay({
  selectedSessionRuns,
  sampleListState,
  visPayloadElements,
  containerType,
  ref,
  mouseHandler,
  toggleMultiSampleSelection,
  setToggleMultiSampleSelection,
  selectedPayloadType,
  setSelectedPayloadType,
  removeSample,
  deselectSample,
  showAsGallery,
  selectedSampleIdentities,
  filterSelectedSamples,
  loadMoreSamplesState: LoadMoreSamplesState,
}: VisDisplayProps): JSX.Element {
  const showSampleList = useMemo(
    () =>
      containerType === 'sampleAnalysis' || sampleListState.samples.length > 1,
    [containerType, sampleListState.samples.length],
  );
  useEffect(() => {
    if (
      !toggleMultiSampleSelection &&
      sampleListState.samples.length > 0 &&
      sampleListState.activeSamples.length === 0
    ) {
      sampleListState.selectFirstSample();
    }
  }, [toggleMultiSampleSelection, sampleListState]);

  return (
    <div className="flex flex-col h-full w-full bg-gray-850 overflow-hidden">
      <div ref={ref} className="flex h-full max-h-full w-full overflow-hidden">
        {showSampleList && (
          <SampleList
            className={clsx(SAMPLE_LIST_CLASS, 'w-40')}
            showAnalyzeElements={containerType === 'sampleAnalysis'}
            toggleMultiSampleSelection={toggleMultiSampleSelection}
            setToggleMultiSampleSelection={setToggleMultiSampleSelection}
            removeSample={removeSample}
            filterSelectedSamples={filterSelectedSamples}
            {...sampleListState}
          />
        )}
        <SampleVisDisplay
          activeSamples={sampleListState.activeSamples}
          selectedSessionRuns={selectedSessionRuns}
          visPayloadElements={visPayloadElements}
          containerType={containerType}
          mouseHandler={mouseHandler}
          selectedPayloadType={selectedPayloadType}
          setSelectedPayloadType={setSelectedPayloadType}
          removeSample={removeSample}
          deselectSample={deselectSample}
          showAsGallery={showAsGallery}
          loadMoreSamplesState={LoadMoreSamplesState}
        />
      </div>
      {selectedSampleIdentities !== undefined &&
        containerType === 'populationExploration' && (
          <SamplesActionsDrawer
            selectedSampleIdentities={selectedSampleIdentities}
          />
        )}
    </div>
  );
}
