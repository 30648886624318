import { CategoryOption, NormalizedOption, CategoryMeta } from './types';

export function normalizeOption(option: CategoryOption): NormalizedOption {
  const value = typeof option === 'string' ? option : option.value;
  const name = (option as { name: string })?.name
    ? (option as { name: string }).name
    : capitalizeFirstLetter(value.replace('_', ' '));

  return {
    value,
    name,
  };
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

type MappedCategoriesMeta = Record<
  string,
  { name: string; options: Record<string, string> }
>;
export function mapCategoriesMeta(
  categoriesMeta: CategoryMeta[],
): MappedCategoriesMeta {
  const mapped: MappedCategoriesMeta = {};
  categoriesMeta.reduce(
    (mappedAccumulator, { category, name, enum: options }) => {
      const mappedEnum = Object.fromEntries(
        options.map(normalizeOption).map(({ value, name }) => [value, name]),
      );

      mappedAccumulator[category] = {
        name: name || category,
        options: mappedEnum,
      };
      return mappedAccumulator;
    },
    mapped,
  );
  return mapped;
}
