// Dashlet.tsx
import { BaseVisualizationProps, DashletCard } from '../DashletCard';
import clsx from 'clsx';
import { SampleIdentity } from '@tensorleap/api-client';
import { SelectedAssetId } from '../VisualizationDisplay/types';
import { SampleAnalysisTopBar } from './SampleAnalysisTopBar';
import { useToggle } from '../../../core/useToggle';
import { SampleAnalysisVisDisplay } from '../../SampleAnalysisView/SampleAnalysisVisDisplay';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';
import { useMouseHandler } from '../../../core/useMouseHandler';
import { useRef } from 'react';

type AnalyzerVisualizationProps = BaseVisualizationProps & {
  defaultSampleIds?: SampleIdentity[];
  defaultAssetId?: SelectedAssetId;
};

export function SampleAnalysisDashlet({
  className,
  selectedSessionRuns,
  remove,
  duplicate,
  defaultSampleIds,
}: AnalyzerVisualizationProps) {
  const [fullScreenMode, toggleFullScreenMode] = useToggle(false);

  const mouseContainerRef = useRef<HTMLDivElement>(null);
  const { mouseHandler } = useMouseHandler(mouseContainerRef);

  return (
    <DashletCard
      fullScreenMode={fullScreenMode}
      toggleFullScreenMode={toggleFullScreenMode}
      className={clsx('flex flex-col overflow-hidden', className)}
    >
      <div
        ref={mouseContainerRef}
        className="flex flex-1 bg-gray-850 flex-col overflow-hidden"
        id={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID}
      >
        <SampleAnalysisTopBar
          remove={remove}
          duplicate={duplicate}
          fullScreenMode={fullScreenMode}
          toggleFullScreenMode={toggleFullScreenMode}
        />
        <div className="flex overflow-hidden max-h-full flex-1 items-stretch bg-gray-850">
          <SampleAnalysisVisDisplay
            defaultSampleIds={defaultSampleIds}
            selectedSessionRuns={selectedSessionRuns}
            mouseHandler={mouseHandler}
          />
        </div>
      </div>
    </DashletCard>
  );
}
