import useSWR, { KeyedMutator } from 'swr';
import { SlimVisualization } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { useMergedObject } from '../useMergedObject';
import { useMemo } from 'react';

export interface UseFetchSessionRunsVisualization {
  slimVisualizations?: SlimVisualization[] | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<SlimVisualization[] | undefined>;
}

export function useFetchSessionRunsVisualization(
  projectId: string,
  sessionRunIds: string[],
): UseFetchSessionRunsVisualization {
  const orderedSessionRunIds = useMemo(
    () => sessionRunIds.sort(),
    [sessionRunIds],
  );
  const {
    data: slimVisualizations,
    error,
    mutate,
  } = useSWR<SlimVisualization[] | undefined, Error>(
    `${CacheKey.SESSION_VISUALIZATIONS}-${orderedSessionRunIds}`,
    async () => {
      if (!orderedSessionRunIds.length) {
        return undefined;
      }
      const { slimVisualizations } = await api.getSessionRunsVisualizations({
        sessionRunIds: orderedSessionRunIds,
        projectId,
      });
      return slimVisualizations;
    },
    { refreshInterval: REFRESH_INTERVALS.slimVisualizations },
  );

  return useMergedObject({
    slimVisualizations,
    error,
    isLoading: !error && !slimVisualizations,
    refetch: mutate,
  });
}
